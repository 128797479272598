import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import { ColumnT, FilterOptionsT, FilterOptionT } from './ComplianceTableTypes';

import './compliance-table-filter.scss';

type PropsT = {
  columns: Array<ColumnT>
  filters: FilterOptionsT,
  setFilters: Function,
  initialState: FilterOptionsT
}

export const ComplianceTableFilter: React.FC<PropsT> = (props: PropsT) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  
  useEffect(() => {
    let show = false;
    for (const [key, value] of Object.entries(props.filters)) {
      if (value.length) {
        show = true;
        break;
      }
    }
    setIsVisible(show);
  }, [props.filters]);
  
  const handleRemove = (column: ColumnT, filter: FilterOptionT) => {
    const filtersByGroup = props.filters[column.accessor].filter((item) => {
      return item.id !== filter.id;
    });
    props.setFilters({ ...props.filters, [column.accessor]: filtersByGroup });
  }
  
  const handleRemoveByGroup = (column: ColumnT) => {
    props.setFilters({ ...props.filters, [column.accessor]: [] });
  }
  
  const handleRemoveAll = () => {
    props.setFilters(props.initialState);
  }
  
  if (!isVisible) return <></>;
  
  return (
    <div className="compliance-table__filters">
      <div className="filter-header">
        <span className="filter-title">Active Filters</span>
        <span className="filter-button-remove-all" onClick={ handleRemoveAll }>Clear All</span>
      </div>
      {
        props.columns.map((column: ColumnT) => {
          return (props.filters[column.accessor]?.length)
            ? (
              <div key={ column.accessor } className="filter-group">
                <span className="filter-group-title">{ column.header }: </span>
                {
                  props.filters[column.accessor].map((filter: FilterOptionT) => (
                    <span key={`${column.accessor}-${filter.id}`} className="filter-value">
                      { filter.name }
                      <FA icon={['fas', 'times']} onClick={ () => handleRemove(column, filter) } />
                    </span>
                  ))
                }
                <span className="filter-button-remove-by-group" onClick={() => handleRemoveByGroup(column)}>
                  <FA icon={['fas', 'times']} />
                </span>
              </div>
            )
            : <></>
        })
      }
    </div>
  )
}