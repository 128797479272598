import React, {Fragment, FunctionComponent} from 'react';

import {Skill} from '../../../interfaces/entity/Skill';

import './work-record-skill-tree.scss';

interface WRSkillTreeProps {
  skills: Array<Skill>;
}

export const WRSkillTree: FunctionComponent<WRSkillTreeProps> = (props: WRSkillTreeProps) => {
  const {skills} = {...props};

  if (skills) {
    return (
      <div className="work-record-skills">
        {skills.map((skill: Skill) => (
          <div
            key={skill.id}
            className={`work-record-skill work-record-skill--level-${skill.hierarchyLevel} clearfix`}>
            <div className="work-record-skill__name">{skill.originalName}</div>
            <div className="work-record-skill__level">{skill.level}</div>
          </div>
        ))}
      </div>
    );
  }

  return <Fragment/>;
}
