import {
  AUTHORIZATION_CHANGE_IS_CAPTCHA_NEEDED,
  AUTHORIZATION_CHANGE_IS_LOGGED_IN,
  AUTHORIZATION_CHANGE_IS_TOKEN_VALID,
  AuthorizationCaptchaState,
  AuthorizationLoggedInState,
  AuthorizationTokenState,
  AuthorizationTypes
} from './types';

const defaultLoggedInState: AuthorizationLoggedInState = {
  isLoggedIn: false
}

export const authorizationReducer = (state: AuthorizationLoggedInState = defaultLoggedInState, action: AuthorizationTypes) => {
  if (action.type === AUTHORIZATION_CHANGE_IS_LOGGED_IN) {
    return {...state, isLoggedIn: action.payload}
  }

  return state;
}

const defaultTokenState: AuthorizationTokenState = {
  isTokenValid: true
}

export const tokenReducer = (state: AuthorizationTokenState = defaultTokenState, action: AuthorizationTypes) => {
  if (action.type === AUTHORIZATION_CHANGE_IS_TOKEN_VALID) {
    return {...state, isTokenValid: action.payload}
  }

  return state;
}

const defaultCaptchaState: AuthorizationCaptchaState = {
  isCaptchaNeeded: false
}

export const captchaReducer = (state: AuthorizationCaptchaState = defaultCaptchaState, action: AuthorizationTypes) => {
  if (action.type === AUTHORIZATION_CHANGE_IS_CAPTCHA_NEEDED) {
    return {...state, isCaptchaNeeded: action.payload}
  }

  return state;
}
