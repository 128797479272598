import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ColumnT, FilterOptionT, FilterOptionsT } from './ComplianceTableTypes';

import useOutsideCloser from '../../../hooks/outsideClickHook';

import './compliance-table-column-filter.scss';

type PropsT = {
  column: ColumnT,
  options: Array<FilterOptionT>,
  filters: FilterOptionsT,
  setFilters: Function
}

export const ComplianceTableColumnFilter: React.FC<PropsT> = (props: PropsT) => {
  const { filters, setFilters } = { ...props };
  const [searchValue, setSearchValue] = useState<string>('');
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [checked, setChecked] = useState<Array<number>>([]);
  
  const ref = useRef(null);
  
  const options = useMemo(() => {
    return props.options.filter((option: FilterOptionT) => {
      return option.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    })
  }, [props.options, searchValue]);
  
  useEffect(() => {
    if (filters[props.column.accessor] === undefined) return;
    const filtered = filters[props.column.accessor].map((filter) => {
      return filter.id;
    });
    setChecked(filtered);
  }, [filters[props.column.accessor]]);
  
  useEffect(() => {
    if (!props.options.length) return;
    setIsCheckedAll(checked.length === props.options.length);
  }, [checked]);
  
  const onCheckedAll = () => {
    if (props.options.length) {
      const currentChecked = [...checked];
      props.options.forEach((option) => {
        if (isCheckedAll) {
          currentChecked.splice(currentChecked.indexOf(option.id), 1);
        } else if (!currentChecked.includes(option.id)) {
          currentChecked.push(option.id);
        }
      });
      setChecked(currentChecked);
    }
  };
  
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = event.target.value ? Number(event.target.value) : undefined;
    const currentChecked = [...checked];
    if (currentValue !== undefined) {
      if (event.target.checked) {
        currentChecked.push(currentValue);
      } else {
        currentChecked.splice(currentChecked.indexOf(currentValue), 1);
      }
      setChecked(currentChecked);
    }
  }
  
  const handleApply = (event: React.MouseEvent<HTMLButtonElement>) => {
    const checkedFull: Array<FilterOptionT> = props.options.filter((option: FilterOptionT) => {
      return checked.indexOf(option.id) > -1;
    });
    setFilters({ ...filters, [props.column.accessor]: checkedFull });
    props.column.isFilterOpen = false;
    const element: HTMLElement = event.target as HTMLElement;
    const th: HTMLElement = element.closest('th') as HTMLElement;
    th.classList.remove('filtering');
    const wrapper: HTMLElement = element.closest('div.filter-wrapper') as HTMLElement;
    wrapper.classList.remove('active');
  }
  
  const handleClose = () => {
    // @ts-ignore
    const element = ref.current as HTMLElement;
    if (props.column.isFilterOpen && element) {
      element.classList.remove('active');
      const th: HTMLElement = element.closest('th') as HTMLElement;
      th.classList.remove('filtering');
      props.column.isFilterOpen = false;
      if (filters[props.column.accessor].length !== checked.length) {
        const filtered = filters[props.column.accessor].map((filter) => {
          return filter.id;
        });
        setChecked(filtered);
      }
    }
  }
  
  useOutsideCloser(ref, handleClose);
  
  return (
    <div className="filter-wrapper" ref={ ref }>
      <input
        type="text"
        className="filter-input"
        onChange={ (e) => setSearchValue(e.currentTarget.value || '') }
        placeholder="Start typing"
      />
      <p
        className="filter-select-all"
        onClick={ onCheckedAll }>
        { !isCheckedAll ? 'Select All' : 'Deselect All' }
      </p>
      <div className="filter-checkbox-list">
        {
          options.map((option: FilterOptionT) => (
              <div className="filter-checkbox-item-wrapper" key={ option.id }>
                <label className="filter-checkbox-item-label">
                  <input
                    type="checkbox"
                    value={ option.id }
                    className="filter-checkbox-item-input"
                    checked={ (checked.indexOf(option.id) !== -1) }
                    onChange={ handleCheck }
                  />
                  { option.name }
                </label>
              </div>
            )
          )
        }
      </div>
      <div className="filter-button-wrapper">
        <button
          type={ 'button' }
          className="filter-button"
          // disabled={ !checked.length }
          onClick={ handleApply }>
          Apply
        </button>
      </div>
    </div>
  );
}
