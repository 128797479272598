import React, { useEffect, useState } from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import { useSelector, useStore } from 'react-redux';

import { GlobalState } from '../store/types';
import { AUTHORIZATION_LOGGED_IN_STATE, AUTHORIZATION_TOKEN_STATE } from '../store/reducers';

import AuthClient from '../services/http/AuthClient';
import { Authorization } from '../services/authorization/Authorization';

import { GoogleSigninBtn } from '../components/LoginForm/GoogleSigninBtn';
import { Spinner } from '../components/Spinner/Spinner';

import { ReactComponent as LogoWhite } from './../assets/images/logo-white.svg';

import './login-page.scss';

export const LoginPage: React.FC = () => {
  const location = useLocation();
  // @ts-ignore
  const pathname = location?.state?.from?.pathname || '/';
  const { getState, subscribe } = useStore();
  const { isTokenValid } = useSelector((state: GlobalState) => state[AUTHORIZATION_TOKEN_STATE]);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(getState()[AUTHORIZATION_LOGGED_IN_STATE].isLoggedIn);
  useEffect(() => {
    setViewport(isLoggedIn);
    return subscribe(() => {
      setIsLoggedIn(getState()[AUTHORIZATION_LOGGED_IN_STATE].isLoggedIn)
    });
  }, [isLoggedIn]);
  
  useEffect(() => {
    if (!isTokenValid) {
      AuthClient.unsetToken();
      Authorization.saveToken('');
    }
  }, [isTokenValid]);

  const setViewport = (isLoggedIn: boolean) => {
    const contentValue = isLoggedIn ? 'width=1024, initial-scale=1.0' : 'width=device-width, initial-scale=1.0';
    const viewport = document.querySelector('meta[name=viewport]') as HTMLElement;
    if (viewport) {
      viewport.setAttribute('content', contentValue);
    }
  }

  if (isLoggedIn) {
    return <Redirect to={ { pathname } } />
  }

  return (
    <div className="login-page">
      <div className="d-flex align-items-center w-100 h-100">
        <div className="container">
          <div className="row">
            <div className="col text-center text-md-right">
              <div className="login-page--logo">
                <LogoWhite data-testid="loginPageLogo" />
              </div>
            </div>
            <div className="col h-auto">
              <div className="form-signin">
                <h1 className="h4">Sign in</h1>
                <GoogleSigninBtn />
              </div>
            </div>
            <div className="col d-none d-lg-block" />
          </div>
        </div>
        <Spinner />
      </div>
    </div>
  );
}
