import {Value as ValueInterface} from '../interfaces/Value';

export default class ValuePhysicalLocation implements ValueInterface<string> {
  valueId: number | null = null;
  value: string = '';

  id: number | null = null;
  cityName: string = '';
  countryName: string = '';
  region: string = '';
  timeZone: string = '';

  getValue(): number | null {
    return this.id;
  }
}