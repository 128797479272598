import * as yup from 'yup';
import { VALIDATION_SPECIAL_CHARACTERS, VALIDATION_XSS } from '../constants';

yup.addMethod<yup.StringSchema>(yup.string, 'testSpecialChars', function() {
  return this.test('test-special-chars', 'Special characters not allowed', function (value) {
    const { createError, path } = this;
    if (value) {
      const matches = value.match(VALIDATION_SPECIAL_CHARACTERS);
      if (matches) {
        return createError({ path, message: `Special characters: ${matches.join(', ')} not allowed` });
      }
    }
    return true;
  })
});
yup.addMethod<yup.StringSchema>(yup.string, 'testXSS', function() {
  return this.test('test-xss', 'Your text contains XSS words', function (value) {
    const { createError, path } = this;
    if (value) {
      const matches = value.match(VALIDATION_XSS);
      if (matches) {
        return createError({ path, message: `Your text contains XSS words: ${matches.join(', ')}`});
      }
    }
    return true;
  })
});

declare module 'yup' {
  interface StringSchema<TType extends yup.Maybe<string> = string | undefined, TContext = yup.AnyObject, TDefault = undefined, TFlags extends yup.Flags = ''> extends yup.Schema<TType, TContext, TDefault, TFlags> {
    testSpecialChars(): StringSchema<TType, TContext>;
    testXSS(): StringSchema<TType, TContext>;
  }
}

export default yup;