import React, {Fragment, FunctionComponent} from 'react';
import {FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome';

import DateHelper from '../../../utils/date/DateHelper';

import './employee-table-filters.scss';

interface EmployeeTableFilterProps {
  columnsMap: Array<any>;
  filters: any;
  setFilter: Function;
  setAllFilters: Function;
  setFilterHeight: Function;
}

const datesColumns = ['start_date', 'end_date', 'company_start_date', 'trial_end_date', 'date_of_birth'];

export const EmployeeTableFilter: FunctionComponent<EmployeeTableFilterProps> = (props: EmployeeTableFilterProps) => {
  const { columnsMap, filters, setFilter, setAllFilters, setFilterHeight } = { ...props };
  let info: any = [];
  filters.forEach((item: any) => {
    let values = (typeof item.value === 'string') ? [item.value] : item.value;
    if (values.length) {
      if (datesColumns.indexOf(item.id) !== -1) {
        values = values
          .filter((item: Date) => item !== undefined)
          .map((item: Date) => DateHelper.getFormattedDate(item));
      }
      info.push({
        id: item.id,
        label: columnsMap[item.id],
        values: values
      });
    }
  });

  const removeValue = (id: string, value: string) => {
    const filterValue: any = filters.filter((item: any) => (item.id === id))[0].value;
    if (typeof filterValue === 'string' || datesColumns.indexOf(id) !== -1) {
      setFilter(id, undefined);
    } else {
      filterValue.splice(filterValue.indexOf(value), 1);
      setFilter(id, filterValue);
    }
  }

  const removeValues = (id: string) => {
    setFilter(id, undefined);
  }

  if (info.length) {
    return (
      <div className="table-filter-wrapper" ref={el => {
        if (el) {
          setFilterHeight(el.getBoundingClientRect().height);
        } else {
          setFilterHeight(0);
        }
      }}>
        <div>
          <span className="caption">
            Active Filters
          </span>
          <span className="clear-all" onClick={() => setAllFilters([])}>
            Clear All
          </span>
        </div>
        <div>
          {info.map((item: any) =>
            <span key={ item.label } className="attribute-filter-wrapper">
              <span className="label">{ item.label }:</span>
              <span className="values-wrapper">
                 {item.values.map((value: string) =>
                   <span key={value} className="value">
                     {value}
                     <FA icon={['fas', 'times']} onClick={() => removeValue(item.id, value)}/>
                   </span>
                 )}
              </span>
              <span className="value-all" onClick={() => removeValues(item.id)}>
                <FA icon={['fas', 'times']}/>
              </span>
            </span>
          )}
          {/*{JSON.stringify(filters)}*/}
        </div>
      </div>
    );
  }

  return <Fragment/>;
}
