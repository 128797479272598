import {WorkRecord} from '../../../../interfaces/WorkRecord';

export interface ActivateWorkRecordStateInterface {
  startDate: Date | null;
  endDate: Date | null;
}

const initialState: ActivateWorkRecordStateInterface = {
  startDate: null,
  endDate: null
}

export default class ActivateWorkRecordState {
  public static generateFromWorkRecord(workRecord?: WorkRecord): ActivateWorkRecordStateInterface {
    let state = initialState;
    if (workRecord) {
      state.startDate = workRecord.startDate;
      state.endDate = workRecord.endDate;
    }
    return state;
  }
}
