import {MT_SET_DIRECT_ATTRIBUTES,
  MT_SET_DIRECT_ATTRIBUTES_DEFAULT,
  MT_SET_SUBORDINATES_ATTRIBUTES,
  MT_SET_SUBORDINATES_ATTRIBUTES_DEFAULT,
  MtTypes, MtState} from './types';

const defaultState: MtState = {
  directAttributes: [],
  directAttributesDefault: [],
  subordinatesAttributes: [],
  subordinatesAttributesDefault: []
}

export const mtReducer = (state: MtState = defaultState, action: MtTypes) => {
  switch (action.type) {
    case MT_SET_DIRECT_ATTRIBUTES:
      return {...state, directAttributes: action.payload}
    case MT_SET_DIRECT_ATTRIBUTES_DEFAULT:
      return {...state, directAttributesDefault: action.payload}
    case MT_SET_SUBORDINATES_ATTRIBUTES:
      return {...state, subordinatesAttributes: action.payload}
    case MT_SET_SUBORDINATES_ATTRIBUTES_DEFAULT:
      return {...state, subordinatesAttributesDefault: action.payload}
    default:
      return state;
  }
}
