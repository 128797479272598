import {HCSnapshotLocationResponse} from "../../interfaces/http/hrDashboard/hcSnapshot/HCSnapshotLocationResponse";
import {
  LocationForLocationResponse, LocationForOrgStructureResponse
} from "../../interfaces/http/hrDashboard/hcSnapshot/LocationResponse";
import {HeadcountMetricResponse} from "../../interfaces/http/hrDashboard/hcSnapshot/HeadcountMetricResponse";
import {UnitReportItemResponse} from "../../interfaces/http/hrDashboard/hcSnapshot/UnitReportItemResponse";
import {HCSnapshotOrgStructureResponse} from "../../interfaces/http/hrDashboard/hcSnapshot/HCSnapshotOrgStructureResponse";
import {OrgStructureResponse} from "../../interfaces/http/hrDashboard/hcSnapshot/OrgStructureResponse";
import {
  DEPARTMENT_TYPE,
  HCSnapshotLocationSettings, HCSnapshotOrgStructureSettings,
  LOCATION_TYPE,
  ORG_STRUCTURE_TYPE, UNIT_TYPE
} from "../../interfaces/entity/hrDashboard/HCSnapshotSettings";

class HCDashboardHelper {
  public convertDataToHCSnapshotLocationResponse(data: any, compareDate: Date | null): HCSnapshotLocationResponse {
    return {
      // showDiff: !!compareDate,
      billable_fte: data.billable_fte,
      non_billable_fte: data.non_billable_fte,
      compare_date: new Date(data.compare_date),
      for_date: new Date(data.for_date),
      fte_ratio: data.fte_ratio,
      fte_total: data.fte_total,
      head_count_billable: data.head_count_billable,
      head_count_non_billable: data.head_count_non_billable,
      head_count_total: data.head_count_total,
      head_count_metric: this.convertDataToHeadcountMetricResponse(data.head_count_metric),
      head_count_metric_diff: this.convertDataToHeadcountMetricResponse(data.head_count_metric_diff),
      locations: this.convertDataToLocationResponse(data.locations)
    };
  }

  public convertDataToLocationResponse(data: any): Array<LocationForLocationResponse> {
    return data.map((location: any) => ({
      billable_fte: location.billable_fte,
      fte_ratio: location.fte_ratio,
      fte_total: location.fte_total,
      head_count_billable: location.head_count_billable,
      head_count_non_billable: location.head_count_non_billable,
      head_count_total: location.head_count_total,
      id: location.id,
      name: location.name,
      non_billable_fte: location.non_billable_fte,
      head_count_metric: this.convertDataToHeadcountMetricResponse(location.head_count_metric),
      head_count_metric_diff: this.convertDataToHeadcountMetricResponse(location.head_count_metric_diff),
      unit_report_item: this.convertDataToUnitReportItemResponse(location.unit_report_item)
    }));
  }

  public convertDataToUnitReportItemResponse(data: any): UnitReportItemResponse {
    return {
      billable_fte: data.billable_fte,
      non_billable_fte: data.non_billable_fte,
      fte_ratio: data.fte_ratio,
      fte_total: data.fte_total,
      head_count_billable: data.head_count_billable,
      head_count_metric: this.convertDataToHeadcountMetricResponse(data.head_count_metric),
      head_count_metric_diff: this.convertDataToHeadcountMetricResponse(data.head_count_metric_diff),
      head_count_non_billable: data.head_count_non_billable,
      head_count_total: data.head_count_total,
      id: data.id,
      type: data.type,
      unit_code_name: data.unit_code_name,
      unit_name: data.unit_name,
      units: data.units.map((unit: any) => this.convertDataToUnitReportItemResponse(unit))
    }
  }

  public convertDataToHeadcountMetricResponse(data: any): HeadcountMetricResponse {
    return {
      billable_fte: data.billable_fte,
      fte_ratio: data.fte_ratio,
      fte_total: data.fte_total,
      head_count_billable: data.head_count_billable,
      head_count_non_billable: data.head_count_non_billable,
      head_count_total: data.head_count_total,
      non_billable_fte: data.non_billable_fte
    };
  }

  public convertDataToHCSnapshotOrgStructureResponse(data: any, compareDate: Date | null): HCSnapshotOrgStructureResponse {
    return {
      billable_fte: data.billable_fte,
      non_billable_fte: data.non_billable_fte,
      compare_date: new Date(data.compare_date),
      for_date: new Date(data.for_date),
      fte_ratio: data.fte_ratio,
      fte_total: data.fte_total,
      head_count_billable: data.head_count_billable,
      head_count_non_billable: data.head_count_non_billable,
      head_count_total: data.head_count_total,
      head_count_metric: this.convertDataToHeadcountMetricResponse(data.head_count_metric),
      head_count_metric_diff: this.convertDataToHeadcountMetricResponse(data.head_count_metric_diff),
      org_structures: this.convertDataToOrgStructureResponse(data.org_structures)
    };
  }

  public convertDataToOrgStructureResponse(data: any): Array<OrgStructureResponse> {
    return data.map((orgStructure: any) => ({
      billable_fte: orgStructure.billable_fte,
      fte_ratio: orgStructure.fte_ratio,
      fte_total: orgStructure.fte_total,
      head_count_billable: orgStructure.head_count_billable,
      head_count_non_billable: orgStructure.head_count_non_billable,
      head_count_total: orgStructure.head_count_total,
      id: orgStructure.id,
      name: orgStructure.name,
      non_billable_fte: orgStructure.non_billable_fte,
      head_count_metric: this.convertDataToHeadcountMetricResponse(orgStructure.head_count_metric),
      head_count_metric_diff: this.convertDataToHeadcountMetricResponse(orgStructure.head_count_metric_diff),
      locations: this.convertDataToLocationForOrgStructureResponse(orgStructure.locations)
    }));
  }

  public convertDataToLocationForOrgStructureResponse(data: any): Array<LocationForOrgStructureResponse> {
    return data.map((location: any) => ({
      billable_fte: location.billable_fte,
      fte_ratio: location.fte_ratio,
      fte_total: location.fte_total,
      head_count_billable: location.head_count_billable,
      head_count_non_billable: location.head_count_non_billable,
      head_count_total: location.head_count_total,
      id: location.id,
      name: location.name,
      non_billable_fte: location.non_billable_fte,
      head_count_metric: this.convertDataToHeadcountMetricResponse(location.head_count_metric),
      head_count_metric_diff: this.convertDataToHeadcountMetricResponse(location.head_count_metric_diff),
      units: location.units.map((unit: any) => this.convertDataToUnitReportItemResponse(unit))
    }));
  }

  public initializeLocationSettings(data: HCSnapshotLocationResponse): Array<HCSnapshotLocationSettings> {
    let locationSettings: Array<HCSnapshotLocationSettings> = [];
    if (data.locations && data.locations.length && locationSettings.length === 0) {
      data.locations.forEach((location) => {
        let dataLocation: HCSnapshotLocationSettings = {
          id: parseInt(location.id),
          parentId: null,
          name: location.name,
          expand: false,
          type: LOCATION_TYPE
        };
        locationSettings.push(dataLocation);

        if (location.unit_report_item.units && location.unit_report_item.units.length) {
          location.unit_report_item.units.forEach((orgStructure) => {
            let dataOrgStructure: HCSnapshotLocationSettings = {
              id: orgStructure.id,
              parentId: null,
              locationId: parseInt(location.id),
              name: orgStructure.unit_name,
              expand: false,
              type: ORG_STRUCTURE_TYPE
            };
            locationSettings.push(dataOrgStructure);

            if (orgStructure.units && orgStructure.units.length) {
              orgStructure.units.forEach((department) => {
                let dataDepartment: HCSnapshotLocationSettings = {
                  id: department.id,
                  parentId: orgStructure.id,
                  locationId: parseInt(location.id),
                  name: department.unit_name,
                  expand: false,
                  type: DEPARTMENT_TYPE
                };
                locationSettings.push(dataDepartment);

                if (department.units && department.units.length) {
                  department.units.forEach((unit) => {
                    let dataUnit: HCSnapshotLocationSettings = {
                      id: unit.id,
                      parentId: department.id,
                      locationId: parseInt(location.id),
                      name: unit.unit_name,
                      expand: false,
                      type: UNIT_TYPE
                    };
                    locationSettings.push(dataUnit);
                  });
                }
              });
            }
          });
        }
      });
    }
    return locationSettings;
  }

  public initializeOrgStructureSettings(data: HCSnapshotOrgStructureResponse): Array<HCSnapshotOrgStructureSettings> {
    let orgStructureSettings: Array<HCSnapshotOrgStructureSettings> = [];
    if (data.org_structures && data.org_structures.length && orgStructureSettings.length === 0) {
      data.org_structures.forEach((orgStructure) => {
        let dataOrgStructure: HCSnapshotOrgStructureSettings = {
          id: parseInt(orgStructure.id),
          parentId: null,
          name: orgStructure.name,
          expand: false,
          type: ORG_STRUCTURE_TYPE
        };
        orgStructureSettings.push(dataOrgStructure);

        if (orgStructure.locations && orgStructure.locations.length) {
          orgStructure.locations.forEach((location) => {
            let dataLocation: HCSnapshotOrgStructureSettings = {
              id: parseInt(location.id),
              parentId: null,
              orgStructureId: parseInt(orgStructure.id),
              name: location.name,
              expand: false,
              type: LOCATION_TYPE
            };
            orgStructureSettings.push(dataLocation);

            if (location.units && location.units.length) {
              location.units.forEach((department) => {
                let dataDepartment: HCSnapshotOrgStructureSettings = {
                  id: department.id,
                  parentId: parseInt(location.id),
                  orgStructureId: parseInt(orgStructure.id),
                  name: department.unit_name,
                  expand: false,
                  type: DEPARTMENT_TYPE
                };
                orgStructureSettings.push(dataDepartment);

                if (department.units && department.units.length) {
                  department.units.forEach((unit) => {
                    let dataUnit: HCSnapshotOrgStructureSettings = {
                      id: unit.id,
                      parentId: department.id,
                      orgStructureId: parseInt(orgStructure.id),
                      name: unit.unit_name,
                      expand: false,
                      type: UNIT_TYPE
                    };
                    orgStructureSettings.push(dataUnit);
                  });
                }
              });
            }
          });
        }
      });
    }
    return orgStructureSettings;
  }
}

export default new HCDashboardHelper();
