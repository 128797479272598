import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';

import './wr-navigation-list.scss';

import {WorkRecord as WorkRecordInterface} from '../../../interfaces/WorkRecord';

import {AllocationIcon} from '../../Icon/AllocationIcon';
import {CVIcon} from '../../Icon/CVIcon';
import {FeedbackIcon} from '../../Icon/FeedbackIcon';
import {FinanceIcon} from '../../Icon/FinanceIcon';
import {LeavesIcon} from '../../Icon/LeavesIcon';
import {WRIcon} from '../../Icon/WRIcon';
import PermissionWRService from "../../../services/security/PermissionWRService";
import {useSelector} from "react-redux";
import {GlobalState} from "../../../store/types";

interface WRNavigationLinkListProps {
  wr: WorkRecordInterface;
}

export const WRNavigationLinkList: FunctionComponent<WRNavigationLinkListProps> = (props: WRNavigationLinkListProps) => {
  const wr:WorkRecordInterface = props.wr;
  const {myStaff} = useSelector((state: GlobalState) => state.staff);
  const [isClickableLinkFinance, setIsClickableLinkFinance] = useState(false);

  useEffect(() => {
    if (myStaff) {
      PermissionWRService.setUser(myStaff);
      setIsClickableLinkFinance(PermissionWRService.isOwner() ? true : false);
    }
  }, [myStaff, wr])


  if (wr) {
    return (
      <ul className="work-record-navigation-item-body">
        <li>
          <NavLink to={`/staff/${wr.staffId}/work-records/${wr.id}/main`} activeClassName="active">
            <WRIcon/> <span className="label">Work Record</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/staff/${wr.staffId}/work-records/${wr.id}/education`}>
            <WRIcon/> <span className="label">Education</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/staff/${wr.staffId}/work-records/${wr.id}/documents`}>
            <WRIcon/> <span className="label">Documents</span>
          </NavLink>
        </li>
        <li>
          <a
            href={`https://rms.star.global/allocations?code=${wr.code}`}
            target="_blank"
            rel="noopener noreferrer">
            <AllocationIcon/> <span className="label">Allocations</span>
          </a>
        </li>
        <li>
          <a
            href={`https://rms.star.global/vacation?code=${wr.code}`}
            target="_blank"
            rel="noopener noreferrer">
            <LeavesIcon/> <span className="label">Leaves</span>
          </a>
        </li>
        <li>
          <a
            className={`${isClickableLinkFinance ? '' : 'work-record-navigation-item--greyout'}`}
            href={`https://ccs.star.global/#/my/data`}
            target="_blank"
            rel="noopener noreferrer">
            <FinanceIcon/> <span className="label">Finance</span>
          </a>
        </li>
        <li>
          <NavLink to={`/staff/${wr.staffId}/work-records/${wr.id}/feedbacks`}>
            <FeedbackIcon/> <span className="label">Feedbacks</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/staff/${wr.staffId}/work-records/${wr.id}/cv`}>
            <CVIcon/> <span className="label">CV</span>
          </NavLink>
        </li>
      </ul>
    );
  }

  return <Fragment/>;
}
