import React, { ChangeEvent, useEffect, useState } from 'react';

import store from '../../../store/store';
import { setOverlayIsVisible } from '../../../store/overlay/actions';

import StaffClient from '../../../services/http/StaffClient';

import useDebounce from '../../../hooks/debounce';
import useOutsideClick from "../../../hooks/outsideClick";

import { Spinner } from '../../Spinner/Spinner';
import { QuickSearchFilteredItems } from './QuickSearchFilteredItems/QuickSearchFilteredItems';

import './quick-search.scss';

export type StatusType = {
  name: string
  visible: boolean
}

export const QuickSearch: React.FC = () => {

  const [showResult, setShowResult] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [list, setList] = useState<Array<any>>([]);
  const [statuses, setStatuses] = useState<Array<StatusType>>([
    { name:'Offer Accepted', visible: true },
    { name:'Active', visible: true },
    { name:'Inactive', visible: true }
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(searchString.toLowerCase(), 500);

  const { ref, isVisible, setIsVisible } = useOutsideClick(false);

  const handleClick = () => {
    if (isVisible) return;
    setIsVisible(true);
    store.dispatch(setOverlayIsVisible(true));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value;
    setSearchString(value);
  };

  const statusToggle = ({name, visible}: StatusType) => {
    setStatuses(statuses.map(status =>
      status.name === name
        ? { ...status, visible: !visible }
        : status
    ))
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsLoading(true);
      StaffClient.search(debouncedSearchTerm).then((response: any) => {
        setShowResult(true);
        setList(response.length ? response : []);
        setIsLoading(false);
      })
    } else {
      setShowResult(false);
      setList([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!isVisible) {
      setSearchString('');
      setList([]);
      setShowResult(false);
      store.dispatch(setOverlayIsVisible(false));
    }
  }, [isVisible]);

  return (
    <div className={`nav-item--quick-search quick-search ${isVisible ? 'active' : ''}`}>
      <input type="text"
             placeholder={ isVisible ? 'Search by name' : '' }
             value={ searchString }
             onClick={ handleClick }
             onChange={ (e) => handleChange(e) }
             ref={ ref }
             data-testid="testQuickSearch" />
      {
        isVisible && showResult
          ? (
            <div className="quick-search--results">
              { isLoading && <Spinner/> }
              {
                (list.length)
                  ? statuses.map((status: StatusType, index: number) =>
                    <QuickSearchFilteredItems key={`status-${index}`} status={status} list={list} statusToggle={statusToggle} />
                  )
                  : <p className="quick-search--results_no-results">No Results Found</p>
              }
            </div>
          )
          : <></>
      }
    </div>
  )
};