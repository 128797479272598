import { CVDataInterface, CVInterface } from '../../interfaces/CVInterface'
import CVModel from '../../models/CV'

class CVMapper {

  public createCVFromResponse(data: any): CVInterface {
    const cvModel: CVInterface = new CVModel()
    cvModel.id = data.id
    cvModel.startDate = data.start_date
    cvModel.updatedAt = data.updated_at
    cvModel.status = data.status
    cvModel.data = this.createCVDataFromResponse(data.cv.data)
    return cvModel
  }
  
  public createCVDataFromResponse(data: any): CVDataInterface {
    const formatted = {}
    for (let key in data) {
      Object.assign(formatted, {[key]: data[key]})
    }
    return formatted
  }
}

export default new CVMapper()
