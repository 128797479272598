import React, { FunctionComponent, Fragment } from 'react';
import { HR_DASHBOARD_CONTENT_TABS } from '../../../../constants';
import { LeaversDetailItem } from '../LeaversDetailItem/LeaversDetailItem';
import useWindowDimensions from '../../../../hooks/windowDimensionsHook';

// Styles
import './leavers-details.scss';

interface Props {
  activeTab: string,
  locations: Array<any>,
  orgStructures: Array<any>,
  departments: Array<any>,
  reasonsToLeave: Array<any>
}

export const LeaversDetails: FunctionComponent<Props> = (props: Props) => {
  const { activeTab, locations, orgStructures, departments, reasonsToLeave } = {...props};
  const { height } = useWindowDimensions();
  const getLeaversDataHeight = (): number => {
    return height - 230;
  }

  const allLocationsEmpty = !(locations.reduce((acc, item) => {
    acc += item.amount;
    return acc;
  }, 0));

  const allOrgStructuresEmpty = !(orgStructures.reduce((acc, item) => {
    acc += item.amount;
    return acc;
  }, 0));

  const allDepartmentsEmpty = !(departments.reduce((acc, item) => {
    acc += item.amount;
    return acc;
  }, 0));

  return (
    <div className="leavers-details" style={{height: getLeaversDataHeight()}}>
      <div className="leavers-details-container">
        <div className={`tab-content${(activeTab === HR_DASHBOARD_CONTENT_TABS.locations.key) ? ' tab-active' : ''}`}>
          {
            (locations?.length && !allLocationsEmpty)
              ? (
                <div>
                  {
                    locations.map((location: any, i: number) => {
                      return location.amount
                        ? (
                          <LeaversDetailItem
                            key={ i }
                            label={ location.location_name }
                            data={ location }
                            reasonsToLeave={ reasonsToLeave }
                          />
                        )
                        : <Fragment key={ i } />
                    })
                  }
                </div>
              )
              : <div className="empty">There are no details for locations</div>
          }
        </div>
        <div className={`tab-content${(activeTab === HR_DASHBOARD_CONTENT_TABS.units.key) ? ' tab-active' : ''}`}>
          {
            (orgStructures?.length && !allOrgStructuresEmpty)
              ? (
                <div>
                  {
                    orgStructures.map((orgStructure: any, i: number) => {
                      return orgStructure.amount
                        ? (
                          <LeaversDetailItem
                            key={ i }
                            label={ orgStructure.org_structure_name }
                            data={ orgStructure }
                            reasonsToLeave={ reasonsToLeave }
                          />
                        )
                        : <Fragment key={ i } />
                    })}
                </div>
              )
              : <div className="empty">There are no details for units</div>
          }
        </div>
        <div className={`tab-content${(activeTab === HR_DASHBOARD_CONTENT_TABS.departments.key) ? ' tab-active' : ''}`}>
          {
            (departments?.length > 0 && !allDepartmentsEmpty)
              ? (
                <div>
                  {
                    departments.map((department: any, i: number) => {
                      return department.amount
                        ? (
                          <LeaversDetailItem
                            key={ i }
                            label={ department.department_name }
                            data={ department }
                            reasonsToLeave={ reasonsToLeave }
                          />
                        )
                        : <Fragment key={ i } />
                    })
                  }
                </div>
              )
              : <div className="empty">There are no details for departments</div>
          }
        </div>
      </div>
    </div>
  );
}
