import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import Select from 'react-select';

import store from '../../../../store/store';
import { dispatchPopupState } from '../../../../store/popup/types';
import { setShouldUpdate } from '../../../../store/workRecord/actions';
import { loaderActions } from '../../../../store/loader/actions';

import dictionaryStore from '../../../../services/dictionary/DictionaryStore';
import wrHelper from '../../../../services/data/WorkRecordHelper';
import { WorkRecord } from '../../../../interfaces/WorkRecord';

import { PopupCloser } from '../../../Popup/PopupCloser';
import TurnoverState, { TurnoverStateInterface } from './TurnoverState';
import { schema } from './TurnoverFormSchema';

import './turnover-form.scss';

interface TurnoverFormProps {
  workRecord?: WorkRecord
}

export const TurnoverForm: React.FC<TurnoverFormProps> = (props: TurnoverFormProps) => {
  const [initialState, setInitialState] = useState<TurnoverStateInterface | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [turnoverList, setTurnoverList] = useState<Array<any>>([]);

  const submission = (values: TurnoverStateInterface) => {
    setIsSaving(true);
    store.dispatch(loaderActions.showLoader());
    const workRecord = props.workRecord;
    if (workRecord) {
      wrHelper.updateTurnover(workRecord, values.turnover)
        .then((data: any) => {
          store.dispatch(setShouldUpdate(true));
          dispatchPopupState('turnover', false);
        })
        .finally(() => {
          setIsSaving(false);
          store.dispatch(loaderActions.hideLoader());
        })
    }
  };

  useEffect(() => {
    if (initialState == null) {
      setInitialState(TurnoverState.generateFromWorkRecord(props.workRecord));
    }
    if (!isLoaded) {
      dictionaryStore.getByAlias('turnover').then((data: any) => {
        if (typeof data !== 'undefined') {
          const options = data.map((item: string) => ({ label: item, value: item }));
          setTurnoverList(options);
          setIsLoaded(true);
        }
      });
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  if (initialState) {
    return (
      <Formik
        initialValues={ initialState }
        validationSchema={ schema }
        onSubmit={ values => { submission(values) }}>
        {({ values, setFieldValue }) => (
          <Form className="edb-form form-turnover">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="turnover">Set Data</label>
                  <Select
                    id="turnover"
                    name="turnover"
                    value={ turnoverList.filter((item) => item.value === values.turnover) }
                    options={ turnoverList }
                    isSearchable={ false }
                    isClearable={ !!values.turnover }
                    maxMenuHeight={ 200 }
                    menuPlacement={ 'bottom' }
                    onChange={ (selected) => setFieldValue('turnover', selected ? selected.value : '') }
                    classNamePrefix={ 'select-field-custom' }
                  />
                  <ErrorMessage name="turnover"/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-center form-buttons">
                <PopupCloser popupName="turnover">
                  <button className="edb-btn edb-btn--secondary" type="reset">
                    Cancel
                  </button>
                </PopupCloser>
                <button type="submit" className="edb-btn edb-btn--primary" disabled={ isSaving }>
                  { isSaving ? 'Saving' : 'Save' }
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  return <></>;
}
