import {ET_SET_ATTRIBUTES, ET_SET_ATTRIBUTES_DEFAULT, EtTypes} from './types';

export function setEtAttributes(attributes: any): EtTypes {
  return {
    type: ET_SET_ATTRIBUTES,
    payload: {
      attributes: attributes
    }
  }
}

export function setEtAttributesDefault(attributesDefault: any): EtTypes {
  return {
    type: ET_SET_ATTRIBUTES_DEFAULT,
    payload: {
      attributesDefault: attributesDefault
    }
  }
}

