import { combineReducers } from 'redux';
import { authorizationReducer, captchaReducer, tokenReducer } from './authorization/reducers';
import { etReducer } from './employeeTable/reducers';
import { mtReducer } from './myTeamTable/reducers';
import { dictionaryReducer } from './dictionary/reducers';
import { staffReducer } from './staff/reducers';
import { workRecordReducer } from './workRecord/reducers';
import { popupReducer } from './popup/reducers';
import { overlayReducer } from './overlay/reducers';
import { cvReducer } from './cv/reducers';
import { permissionReducer } from './permission/reducers';
import { uiReducers } from './loader/reducers';

import {reducer as toastrReducer} from 'react-redux-toastr';
import {reportReducer} from "./report/reducers";

export const AUTHORIZATION_LOGGED_IN_STATE = 'authorization';
export const AUTHORIZATION_TOKEN_STATE = 'authorizationToken';
export const AUTHORIZATION_CAPTCHA_STATE = 'authorizationCaptcha';
export const EMPLOYEE_TABLE_STATE = 'employeeTable';
export const MY_TEAM_TABLE_STATE = 'myTeamTable';
export const DICTIONARY_STATE = 'dictionary';
export const STAFF_STATE = 'staff';
export const WORK_RECORD_STATE = 'workRecord';
export const POPUP_STATE = 'popup';
export const OVERLAY_STATE = 'overlay';
export const CV_STATE = 'cv';
export const PERMISSION_STATE = 'permission';
export const LOADER_STATE = 'loader';

export default combineReducers({
  authorization: authorizationReducer,
  authorizationToken: tokenReducer,
  authorizationCaptcha: captchaReducer,
  employeeTable: etReducer,
  myTeamTable: mtReducer,
  dictionary: dictionaryReducer,
  staff: staffReducer,
  workRecord: workRecordReducer,
  popup: popupReducer,
  overlay: overlayReducer,
  cv: cvReducer,
  permission: permissionReducer,
  loader: uiReducers,
  report: reportReducer,
  toastr: toastrReducer
})

