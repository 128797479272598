import React, { useEffect, useState } from 'react';

import { Staff } from '../../../interfaces/Staff';
import { WorkRecord } from '../../../interfaces/WorkRecord';
import { CVInterface } from '../../../interfaces/CVInterface';

type PropsType = {
  staff: Staff,
  workRecord: WorkRecord,
  cv: CVInterface | null
}

export const WRCvInfo: React.FC<PropsType> = (props: PropsType) => {
  const { staff, workRecord, cv } = { ...props }
  const [location, setLocation] = useState<string | null>(null);
  useEffect(() => {
    if (cv?.data.location) {
      setLocation(cv.data.location);
    } else if (workRecord?.location) {
      setLocation(`${workRecord.location.value}, ${workRecord.location.country}`);
    }
  }, [cv, workRecord]);
  return (
    <section className="border-bottom">
      <div className="box fullName">
        <p><b>{ staff.firstName?.value } { staff.lastName?.value }</b></p>
      </div>
      <div className="box location">
        <p>{ location }</p>
      </div>
      <div className="box position">
        <p>{ workRecord.position?.value }</p>
      </div>
    </section>
  )
}
