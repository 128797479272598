import React, { useContext, useState } from 'react';
import { toastr } from 'react-redux-toastr';

import { dispatchIndexedPopupState } from '../../../../store/popup/types';

import educationHelper from '../../../../services/data/EducationHelper';
import EducationMapper from '../../../../mappers/response/EducationMapper';

import { EducationItem as EducationItemInterface } from '../../../../interfaces/EducationItem';

import { EducationListContext } from '../../../WR/WREducation/WREducation';

import { EducationItemForm } from './EducationItemForm';

interface EditEducationFormWrapperProps {
  popupName: string;
  educationItem: EducationItemInterface;
}

export const EditEducationFormWrapper: React.FC<EditEducationFormWrapperProps> =
  (props: EditEducationFormWrapperProps) => {
    const {popupName, educationItem} = {...props};
    const [saving, setSaving] = useState(false);
    const context = useContext(EducationListContext);
    const update = (values: object) => {
      setSaving(true);
      educationHelper.update(educationItem, values).then((data: any) => {
        const ei: EducationItemInterface = EducationMapper.creatEducationItemFromResponse(data);
        context.updateList();
        setSaving(false);
        toastr.success('', 'Education item successfully updated');
        dispatchIndexedPopupState(popupName, String(ei.id), false);
      });
    }

    return (
      <EducationItemForm
        submitFunction={ update }
        educationItem={ educationItem }
        popupName={ popupName }
        handleCancel={() => {
          dispatchIndexedPopupState(props.popupName, String(educationItem.id), false);
        }}
        saving={saving}
      />
    );
  }
