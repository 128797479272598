import DocumentItem from '../../models/DocumentItem';
import { DocumentItemResponse } from '../../interfaces/http/document/DocumentItemResponse';
import { ComplianceItemI } from '../../interfaces/DocumentItem';

class DocumentMapper {
  public creatDocumentItemFromResponse(data: DocumentItemResponse): ComplianceItemI {
    let documentItem: ComplianceItemI = new DocumentItem();
    documentItem.id = data.id;
    documentItem.createdAt = data.created_at;
    documentItem.document = data.document;
    documentItem.documentId = data.document_id;
    documentItem.documentLink = data.document_link;
    documentItem.documentPath = data.document_path;
    documentItem.dueDate = data.due_date;
    documentItem.date = data.hasOwnProperty('date') ? data.date : '';
    documentItem.peoplePartnerId = data.people_partner_id;
    documentItem.peoplePartnerName = data.people_partner_name;
    documentItem.status = data.status;
    documentItem.statusName = data.status_name;
    documentItem.staffId = data.staff_id;
    documentItem.fullName = data.full_name;

    return documentItem;
  }
}

export default new DocumentMapper();
