import {Value as ValueInterface} from '../interfaces/Value';

export default class ValueString implements ValueInterface<string> {
  valueId: number | null = null;
  value: string;

  constructor(value: string = '') {
    this.value = value;
  }

  getValue(): string {
    return this.value;
  }
}
