import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import FileSaver from 'file-saver';

import { GlobalState } from '../../store/types';

import ReportClient from '../../services/http/ReportClient';

import ReportMapper from "../../mappers/response/ReportMapper";

import { Spinner } from '../Spinner/Spinner';
import { DownloadIcon } from '../Icon/DownloadIcon';
import { ReportList } from './ReportList/ReportList';

import { TABLE_CONFIG } from '../../constants';

import './report.scss';

interface Params {
  reportId: string
}

export const ReportWrapper = () => {
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [list, setList] = useState<Array<any>>([]);
  const [type, setType] = useState<number|undefined>(undefined);
  const [columnsHeader, setColumnsHeader] = useState<Array<any>>(TABLE_CONFIG.reportTable.columns);
  const [columnsBody, setColumnsBody] = useState<Array<any>>([]);
  const params = useParams<Params>();
  
  const { config } = useSelector((state: GlobalState) => state.report);

  const getData = () => {
    ReportClient.getReport(params.reportId)
      .then((data: any) => {
        setType(data.type);
        if (data._data.length === 1) {
          setTitle(data._data.length + ' item found for ' + data.title);
        } else {
          setTitle(data._data.length + ' items found for ' + data.title);
        }
        if (data._data) {
          const items = data._data.map((item: any) => {
            return ReportMapper.createFromResponse(item);
          });
          setList(items);
        }
      })
      .catch(e => {
        setError(e);
        toastr.error('', `Error was occurred: ${e}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!isLoading) return;
    getData();
  }, [isLoading]);
  
  useEffect(() => {
    if (type !== undefined && config) {
      const configByType = config[type];
      if (configByType) {
        const aliases = Object.keys(configByType);
        const columnsHeaderByReport: Array<any> = [];
        columnsHeader.forEach((column) => {
          if (aliases.indexOf(column.name) === -1) {
            if (!column.configurable) {
              columnsHeaderByReport.push(column);
            }
          } else {
            columnsHeaderByReport.push(column);
          }
        });
        const columnsBodyByReport = [ ...columnsHeaderByReport ];
        columnsBodyByReport.shift();
        columnsBodyByReport.shift();
        setColumnsHeader(columnsHeaderByReport);
        setColumnsBody(columnsBodyByReport);
      }
    }
  }, [config, type]);

  const exportCSV = () => {
    setIsLoading(true);
    ReportClient.getReportCSV(params.reportId).then((data: any) => {
      if (data) {
        const file = new Blob([data], {
          type: 'text/csv'
        });
        FileSaver.saveAs(file, 'report.csv');
      }
      setIsLoading(false);
    });
  }

  return (
    <div className="report-wrapper">
      {!error && <div className="report-data-wrapper">
        <div className="report-header">
          <div className="title float-left">
            { title }
          </div>
          <div className="export float-right">
            <div className="export-link">
              <span className="table-settings__control-link mr-5" onClick={ exportCSV }>
                <span className="table-settings__control-icon">
                  <DownloadIcon />
                </span>
                Export to CSV
              </span>
            </div>
          </div>
        </div>
        <ReportList isLoading={ isLoading } list={ list } setList={ setList } columnsHeader={ columnsHeader } columnsBody={ columnsBody } />
      </div>}
      {
        error &&
        <div className="report-error">
          <div className="message">
            { error }
          </div>
        </div>
      }
      { isLoading ? <Spinner/> : <></> }
    </div>
  );
}