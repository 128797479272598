import React from 'react';
import VersionClient from '../../services/http/VersionClient';
import { COMMON } from '../../constants';

export const VersionComponent: React.FC = () => {
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [beVersion, setBeVersion] = React.useState(null);
  
  React.useEffect(() => {
    setIsLoading(true);
    VersionClient.get()
      .then((response: any) => {
        if (response?.data) {
          setBeVersion(response.data.version)
        }
      })
      .catch()
      .finally(() => setIsLoading(false));
  }, []);
  
  if (isLoading) return <></>
  
  return (
    <div className="card" style={{ width: '400px' }}>
      <ul className="list-group list-group-flush">
        <li className="list-group-item">Current front-end version: { COMMON.VERSION }</li>
        { beVersion && <li className="list-group-item">Current back-end version: { beVersion }</li> }
      </ul>
    </div>
  )
}
