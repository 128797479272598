import {
  WORK_RECORD_SET_ACTIVE_ID,
  WORK_RECORD_SET_MAIN_ID,
  WORK_RECORD_SET_SHOULD_UPDATE,
  WORK_RECORD_SET_WORK_RECORD,
  WorkRecordState,
  WorkRecordTypes
} from './types';

const defaultState: WorkRecordState = {
  mainId: NaN,
  activeId: NaN,
  workRecord: null,
  shouldUpdate: false,
  cvData: null,
  loading: false,
  cvDataForbidden: false,
}

export const workRecordReducer = (state: WorkRecordState = defaultState, action: WorkRecordTypes): WorkRecordState => {
  switch (action.type) {
    case WORK_RECORD_SET_MAIN_ID:
      return {...state, mainId: action.payload}
    case WORK_RECORD_SET_ACTIVE_ID:
      return {...state, activeId: action.payload}
    case WORK_RECORD_SET_WORK_RECORD:
      return {...state, workRecord: action.payload}
    case WORK_RECORD_SET_SHOULD_UPDATE:
      return {...state, shouldUpdate: action.payload}
    default:
      return state;
  }
}
