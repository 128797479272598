import React, {FunctionComponent} from 'react';

import './status.scss';

interface StatusProps {
  value: string;
  isWR?: boolean;
}

export const Status: FunctionComponent<StatusProps> = (props: StatusProps) => {
  const { value, isWR } = { ...props };
  let uiValue = value;
  let className:string = 'status-common';

  switch (value) {
    case 'Active':
      uiValue = 'Active';
      className = 'status-active';
      break;
    case 'Offer Accepted':
      uiValue = (isWR) ? 'Offer Accepted' : 'JO Accepted';
      className = (isWR) ? 'status-jo-wr' :'status-jo';
      break;
    case 'Inactive':
      uiValue = 'Inactive';
      className = 'status-inactive';
      break;
    case 'Deleted':
      uiValue = 'Deleted';
      className = 'status-inactive';
      break;
  }

  className = (isWR) ? `${className} status-wr` : className;

  return (
    <span className={ `status ${className}` }>
      { uiValue }
    </span>
  )
}
