import React from 'react';
import Parser from 'html-react-parser';
import { NavLink } from 'react-router-dom';
import { HR_DASHBOARD, ROUTERS } from '../../../constants';

export interface HRDashboardSidebarTabInfoInterface {
  label: string,
  link: string,
  key: string,
  svg: string
}

interface HRDashboardSidebarTabProps {
  tabInfo: HRDashboardSidebarTabInfoInterface
}

export const HRDashboardSidebarTab: React.FC<HRDashboardSidebarTabProps> = (props: HRDashboardSidebarTabProps) => {
  const { tabInfo } = { ...props };

  return (
    <NavLink
      className="menu-item"
      activeClassName="menu-item--active"
      to={`${HR_DASHBOARD.HR_DASHBOARD_BASE_URL}${tabInfo.link}`}
      key={tabInfo.key}
      isActive={(match, location) => {
        if (tabInfo.key === 'compliance') {
          return match?.url === location.pathname ||
            location.pathname === HR_DASHBOARD.HR_DASHBOARD_BASE_URL + ROUTERS.COMPLIANCE_ALL_ROUTE;
        }
        return match?.url === location.pathname;
      }}>
      { Parser(tabInfo.svg) }
      <span className='menu-item__name'>{ tabInfo.label }</span>
    </NavLink>
  );
}
