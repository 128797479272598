import * as Yup from 'yup';

export const schema = Yup.object()
  .shape({
    trialStatus: Yup.string()
      .required('Trial status is required')
  })
  .shape({
    trialEndDate: Yup.date()
      .required('Trial end date is required')
  });