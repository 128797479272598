import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';

class EducationClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getEducationItems(staffId: number) {
    return this.http.get(`${ENDPOINTS.EDUCATION_EDUCATION_ITEM_STAFF}/${staffId}`);
  }

  public getDegrees() {
    return this.http.get(ENDPOINTS.EDUCATION_DEGREES);
  }

  public getSchools() {
    return this.http.get(ENDPOINTS.EDUCATION_SCHOOLS);
  }

  public create(body: any) {
    return this.http.post(`${ENDPOINTS.EDUCATION_EDUCATION_ITEM}/`, body);
  }

  public update(body: any, id: number) {
    return this.http.put(`${ENDPOINTS.EDUCATION_EDUCATION_ITEM}/${id}`, body);
  }

  public delete(id: number) {
    return this.http.delete(`${ENDPOINTS.EDUCATION_EDUCATION_ITEM}/${id}`);
  }
}

export default new EducationClient(http);
