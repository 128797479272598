import {Staff as StaffInterface} from '../../interfaces/Staff';
import {ROLES} from '../../constants/WORK_RECORD';

class PermissionStaffService {
  private params: any;
  private user: StaffInterface | null = null;

  public setParams(params: any) {
    this.params = params;
  }

  public setUser(user: StaffInterface) {
    this.user = user;
  }

  public hasRole(roles: Array<any>): boolean {
    let userRoles = this.user?.permissions.roles || [];
    //every
    return userRoles.some((role: string) => roles.includes(role));
  }

  public isGranted(elementName: string, action: string, staff: StaffInterface) {
    let granted = false;
    let userPermissions = (this.user)
      ? (this.user.permissions.attributes) ? this.user.permissions.attributes : {}
      : {};
    //@ts-ignore
    let permissions: any = userPermissions[elementName] ?? [];
    Object.keys(permissions).forEach((roleName: string) => {
      // business logic:
      // 1. Not an owner of profile - apply all roles permissions except role Owner
      if (granted || (roleName === ROLES.OWNER && !this.isOwner())) {
        return;
      }
      granted = permissions[roleName].indexOf(action.toUpperCase()) !== -1;
    });
    return granted;
  }


  public isGrantedArrayAttrs(attributes: Array<any>, action: string, staff: StaffInterface): boolean {
    let hasPermission = false;
    attributes.forEach((value) => {
      hasPermission = hasPermission || this.isGranted(value, action, staff);
    });
    return hasPermission;
  }

  public isOwner() {
    const staffId = (this.params && this.params.staffId) ? parseInt(this.params.staffId) : 0;
    return this.user && (staffId === this.user.id);
  }
}

export default new PermissionStaffService();
