export const reportTypes = Object.freeze({
  SET_CONFIG: 'SET_CONFIG'
});

export interface ReportStateI {
  config: Array<any>
}

export interface SetConfigActionI {
  type: typeof reportTypes.SET_CONFIG,
  payload: Array<any>
}

export type ReportTypes = SetConfigActionI;