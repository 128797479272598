export const AUTHORIZATION_CHANGE_IS_LOGGED_IN = 'AUTHORIZATION_CHANGE_IS_LOGGED_IN';
export const AUTHORIZATION_CHANGE_IS_TOKEN_VALID = 'AUTHORIZATION_CHANGE_IS_TOKEN_VALID';
export const AUTHORIZATION_CHANGE_IS_CAPTCHA_NEEDED = 'AUTHORIZATION_CHANGE_IS_CAPTCHA_NEEDED';

export interface AuthorizationLoggedInState {
  isLoggedIn: boolean
}

export interface AuthorizationTokenState {
  isTokenValid: boolean
}

export interface AuthorizationCaptchaState {
  isCaptchaNeeded: boolean
}

interface ChangeIsLoggedInAction {
  type: typeof AUTHORIZATION_CHANGE_IS_LOGGED_IN
  payload: boolean
}

interface ChangeIsTolenValidAction {
  type: typeof AUTHORIZATION_CHANGE_IS_TOKEN_VALID
  payload: boolean
}

interface ChangeIsCaptchaNeededAction {
  type: typeof AUTHORIZATION_CHANGE_IS_CAPTCHA_NEEDED
  payload: boolean
}

export type AuthorizationTypes = ChangeIsLoggedInAction | ChangeIsTolenValidAction | ChangeIsCaptchaNeededAction;
