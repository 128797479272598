import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {ErrorMessage, Field, FormikErrors, FormikTouched, FormikValues} from 'formik';

// store
import { GlobalState } from '../../../../store/types';
import { STAFF_STATE } from '../../../../store/reducers';

import dictionaryStore from '../../../../services/dictionary/DictionaryStore';
import permissionService from '../../../../services/security/PermissionWRService';
import { WorkRecord } from '../../../../interfaces/WorkRecord';
import { Staff } from '../../../../interfaces/Staff';

// components
import { SelectFieldCustom } from '../../../Forms/SelectFieldCustom';
import { SwitcherField } from '../../../Forms/SwitcherField';
import { DateField } from '../../../Forms/DateField';
import { SliderField } from '../../../Forms/SliderField';
import { PopoverAttributeFuture } from "../../../Popovers/PopoverAttributeFuture/PopoverAttributeFuture";

import { ATTRIBUTE_NAMES, COMMON } from '../../../../constants';

const labels = ATTRIBUTE_NAMES['wrPositionDescription'];

const MODE_STAFF_ADD = 'MODE_STAFF_ADD';
const MODE_WR_ADD = 'MODE_WR_ADD';
const MODE_WR_EDIT = 'MODE_WR_EDIT';
type ModeType = typeof MODE_STAFF_ADD | typeof MODE_WR_ADD | typeof MODE_WR_EDIT;

interface PositionDescriptionFormFieldsProps {
  values: any;
  workRecord: WorkRecord;
  staff: Staff | null,
  mode: ModeType,
  formErrors: Array<string>;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  setFieldValue: Function;
}

export const PositionDescriptionFormFields: React.FC<PositionDescriptionFormFieldsProps> =
  (props: PositionDescriptionFormFieldsProps) => {
    const { values, workRecord, staff, formErrors, setFieldValue } = { ...props };
    permissionService.setParams(useParams());
    permissionService.setUser(useSelector((state: GlobalState) => state[STAFF_STATE].myStaff));

    const [isLoaded, setIsLoaded] = useState(false);
    const [employments, setEmployment] = useState([]);
    const [positions, setPositions] = useState([]);
    const [seniorityList, setSeniorityList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [locations, setLocations] = useState([]);
    const [billingLevels, setBillingLevels] = useState([]);
    const [activeStaff, setActiveStaff] = useState([]);
    const [grLevels, setGRLevels] = useState([]);
    const [costCenterList, setCostCenterList] = useState([]);

    useEffect(() => {
      if (!isLoaded) {
        dictionaryStore.getByAlias('employment_type').then((data: any) => {
          if (typeof data !== 'undefined') {
            setEmployment(data);
          }
        });
        dictionaryStore.getByAlias('position').then((data: any) => {
          if (typeof data !== 'undefined') {
            setPositions(data);
          }
        });
        dictionaryStore.getByAlias('seniority').then((data: any) => {
          if (typeof data !== 'undefined') {
            const list = data.map((item: string) => {
              return { id: item, name: item };
            });
            setSeniorityList(list);
          }
        });
        //TODO Move to dictionary store
        dictionaryStore.getByAlias('unit').then((data: any) => {
          if (typeof data !== 'undefined') {
            setUnitList(data.map((unit: any) => {
              return {
                id: unit.id,
                name: unit.name,
                group: unit.org_structure ? unit.org_structure.name : ''
              }
            }));
          }
        });
        dictionaryStore.getByAlias('location').then((data: any) => {
          if (typeof data !== 'undefined') {
            setLocations(data.map((location: any) => {
              return { id: location.id, name: `${location.name}, ${location.country.name}` }
            }));
          }
        });
        dictionaryStore.getByAlias('billing_levels').then((data: any) => {
          if (typeof data !== 'undefined') {
            setBillingLevels(data);
          }
        });
        dictionaryStore.getByAlias('gr_levels').then((data: any) => {
          if (typeof data !== 'undefined') {
            const list = data.map((item: any) => {
              return { id: item.id, name: `${item.level} ${item.title}` }
            });
            setGRLevels(list);
          }
        });
        dictionaryStore.getActiveStaff().then((data: any) => {
          if (typeof data !== 'undefined') {
            setActiveStaff(data.map((staff: any) => {
              return { id: staff.id, name: staff.full_name }
            }));
          }
        });
        dictionaryStore.getByAlias('cost_center').then((data: any) => {
          if (typeof data !== 'undefined') {
            setCostCenterList(data);
          }
        });
        setFieldValue('companyStartDate', staff && staff.companyStartDate ? staff.companyStartDate : null);
        setIsLoaded(true);
      }
      return () => {
        setIsLoaded(true);
      }
    }, [isLoaded]);

    const checkValidationClassName = (fieldName: string) => {
      return (props.errors[fieldName] && props.touched[fieldName]) || formErrors.includes(fieldName) ? ' invalid-field' : '';
    };

    useEffect(() => {
      if (!values.startDate) return;
      if (values.trialPeriod) {
        if (!values.trialEndDate) {
          const date = new Date(values.startDate);
          date.setDate(date.getDate() + 91);
          setFieldValue('trialEndDate', date);
        }
      } else {
        setFieldValue('trialEndDate', null);
      }
      
    }, [values.trialPeriod, values.startDate]);
  
    useEffect(() => {
      if (values.endDate && values.endDate < values.applyChanges) {
        setFieldValue('applyChanges', values.endDate);
      }
    }, [values.endDate]);

    return (
      <>
        <div className="wr-form--position">
          {
            (props.mode === MODE_WR_ADD) ? <p className="wr-form--title">Position Description</p> : <></>
          }
          <div className="form-row">
            <div className="form-group col-6 px-2" id="positionWrapper" tabIndex={-1}>
              <label className="wr-form--label">{labels['position']} <span className="text-danger">*</span></label>
              {
                workRecord.attributesFuture.get('position')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('position')}/>
                  : ''
              }
              <SelectFieldCustom
                id="position"
                name="position"
                options={ positions }
                isDisabled={ !permissionService.isGranted('position', 'edit', workRecord) }
                className={ checkValidationClassName('position') }
                data-testid="formWorkRecordPosition"
              />
            </div>
            <div className="form-group col-3 px-2">
              <label className="wr-form--label">{labels['seniority']}</label>
              {
                workRecord.attributesFuture.get('seniority')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('seniority')}/>
                  : ''
              }
              <SelectFieldCustom
                id="seniority"
                name="seniority"
                options={ seniorityList }
                isDisabled={ !permissionService.isGranted('seniority', 'edit', workRecord) }
              />
            </div>
            <div className="form-group col-3 px-2" id="unitWrapper" tabIndex={-1}>
              <label className="wr-form--label">{labels['unit']} <span className="text-danger">*</span></label>
              {
                workRecord.attributesFuture.get('unit')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('unit')}/>
                  : ''
              }
              <SelectFieldCustom
                id="unit"
                name="unit"
                options={ unitList }
                isDisabled={ !permissionService.isGranted('unit', 'edit', workRecord) }
                className={ checkValidationClassName('unit') }
                data-testid="formWorkRecordUnit"
                group={ true }
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col px-2">
              <label className="wr-form--label">{labels['isBillable']}</label>
              {
                workRecord.attributesFuture.get('isBillable')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('isBillable')}/>
                  : ''
              }
              <SwitcherField
                id="isBillable"
                name="isBillable"
                disabled={!permissionService.isGranted('is_billable', 'edit', workRecord)}
              />
            </div>
          </div>
          {
            (values.isBillable) &&
            <div className="form-row">
              <div className="form-group col-6 px-2" hidden={!values.isBillable}>
                <label className="wr-form--label">{labels['billingLevel']}</label>
                {
                  workRecord.attributesFuture.get('billingLevel')
                    ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('billingLevel')}/>
                    : ''
                }
                <SelectFieldCustom
                  id="billingLevel"
                  name="billingLevel"
                  options={ billingLevels }
                  isDisabled={ !permissionService.isGranted('billing_level', 'edit', workRecord) }
                />
              </div>
              <div className="form-group col-6 px-2">
                <label className="wr-form--label d-inline">{labels['maxBillableLoad']}</label>
                {
                  workRecord.attributesFuture.get('maxBillableLoad')
                    ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('maxBillableLoad')}/>
                    : ''
                }
                <SliderField
                  id="maxBillableLoad"
                  name="maxBillableLoad"
                  valueLabel='%'
                  disabled={!permissionService.isGranted('max_billable_load', 'edit', workRecord)}
                />
              </div>
            </div>
          }
        </div>

        <div className="wr-form--employment">
          <p className="wr-form--title">Employment</p>
          {
            (props.mode === MODE_STAFF_ADD)
              ? (
                <div className="form-row">
                  <div className="form-group col-6 px-2">
                    <label className="wr-form--label">{ labels['employment'] } <span className="text-danger">*</span></label>
                    {
                      workRecord.attributesFuture.get('employment')
                        ? <PopoverAttributeFuture future={ workRecord.attributesFuture.get('employment') } />
                        : ''
                    }
                    <SelectFieldCustom
                      id="employment"
                      name="employment"
                      options={ employments }
                      isDisabled={ !permissionService.isGranted('employment', 'edit', workRecord) }
                      className={ checkValidationClassName('employment') }
                      data-testid="formWorkRecordEmployment"
                    />
                  </div>
                  <div className="form-group col-3 px-2">
                    <label className="wr-form--label">{labels['startDate']} <span className="text-danger">*</span></label>
                    <DateField
                      autoComplete='off'
                      id="startDate"
                      name="startDate"
                      className={ `form-control ${checkValidationClassName('startDate')}` }
                      disabled={!permissionService.isGranted('start_date', 'edit', workRecord)}
                      popperPlacement="bottom-start"
                      onChange={ () => {} }
                      testId="formWorkRecordStartDate"
                    />
                  </div>
                  <div className="form-group col-3 px-2">
                    <label className="wr-form--label">{ labels['endDate'] }</label>
                    <DateField
                      autoComplete='off'
                      id="endDate"
                      name="endDate"
                      className={ `form-control ${checkValidationClassName('endDate')}` }
                      disabled={!permissionService.isGranted('end_date', 'edit', workRecord)}
                      popperPlacement="bottom-start"
                      onChange={ () => {} }
                    />
                    <ErrorMessage name="endDate">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                  </div>
                </div>
              )
              : (
                <div className="form-row">
                  <div className="form-group col-3 px-2">
                    <label className="wr-form--label">{ labels['employment'] } <span className="text-danger">*</span></label>
                    {
                      workRecord.attributesFuture.get('employment')
                        ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('employment')} />
                        : ''
                    }
                    <SelectFieldCustom
                      id="employment"
                      name="employment"
                      options={ employments }
                      isDisabled={ !permissionService.isGranted('employment', 'edit', workRecord) }
                      className={ checkValidationClassName('employment') }
                      data-testid="formWorkRecordEmployment"
                    />
                  </div>
                  <div className="form-group col-3 px-2">
                    <label className="wr-form--label">{labels['startDate']} <span className="text-danger">*</span></label>
                    <DateField
                      autoComplete='off'
                      id="startDate"
                      name="startDate"
                      className={ `form-control ${checkValidationClassName('startDate')}` }
                      disabled={!permissionService.isGranted('start_date', 'edit', workRecord)}
                      popperPlacement="bottom-start"
                      onChange={() => {}}
                    />
                  </div>
                  <div className="form-group col-3 px-2">
                    <label className="wr-form--label">{labels['endDate']}</label>
                    <DateField
                      autoComplete='off'
                      id="endDate"
                      name="endDate"
                      className={ `form-control ${checkValidationClassName('endDate')}` }
                      disabled={!permissionService.isGranted('end_date', 'edit', workRecord)}
                      popperPlacement="bottom-start"
                      onChange={() => {}}
                    />
                    <ErrorMessage name="endDate">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                  </div>
                  <div className="form-group col-3 px-2">
                    <label className="wr-form--label">{labels['companyStartDate']}</label>
                    <DateField
                      autoComplete='off'
                      id="companyStartDate"
                      name="companyStartDate"
                      className="form-control"
                      disabled={true}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              )
          }
          <div className="form-row">
            <div className="form-group col-4 px-2" id="peoplePartnerWrapper" tabIndex={-1}>
              <label className="wr-form--label">{labels['peoplePartner']}</label>
              {
                workRecord.attributesFuture.get('peoplePartner')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('peoplePartner')}/>
                  : ''
              }
              <SelectFieldCustom
                id="peoplePartner"
                name="peoplePartner"
                options={ activeStaff }
                isDisabled={ !permissionService.isGranted('people_partner', 'edit', workRecord) }
              />
            </div>
            <div className="form-group col-4 px-2">
              <label className="wr-form--label">{labels['recruitmentId']}</label>
              {
                workRecord.attributesFuture.get('recruitmentId')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('recruitmentId')}/>
                  : ''
              }
              <Field
                className="form-control"
                disabled={!permissionService.isGranted('recruitment_id', 'edit', workRecord)}
                as="input"
                type="text"
                id="recruitmentId"
                name="recruitmentId"/>
            </div>
            {
              props.mode === MODE_WR_EDIT && values.jiraRecId && (
                <div className="form-group col-4 px-2">
                  <label className="wr-form--label">{labels['jiraRecId']}</label>
                  {
                    workRecord.attributesFuture.get('jiraRecId')
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('jiraRecId')}/>
                      : ''
                  }
                  <Field
                    className="form-control"
                    disabled={ !permissionService.isGranted('jira_rec_id', 'edit', workRecord) }
                    as="input"
                    type="text"
                    id="jiraRecId"
                    name="jiraRecId"
                  />
                </div>
              )
            }
          </div>
          <div className="form-row">
            <div className="form-group col-3 px-2">
              <label className="wr-form--label">{labels['trialPeriod']}</label>
              <SwitcherField
                id="trialPeriod"
                name="trialPeriod"
                disabled={!permissionService.isGranted('trial_status', 'edit', workRecord)}
              />
            </div>
            <div className="form-group col-3 px-2">
              {
                (values.trialPeriod) ?
                  <>
                    <label className="wr-form--label">{ labels['trialEndDate'] }</label>
                    <DateField
                      autoComplete="off"
                      id="trialEndDate"
                      name="trialEndDate"
                      disabled={ !permissionService.isGranted('trial_end_date', 'edit', workRecord) }
                      className={ `form-control ${checkValidationClassName('trialEndDate')}` }
                      popperPlacement="bottom-start"
                      onChange={() => {}}
                    />
                    <ErrorMessage name="trialEndDate">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                  </>
                  : <></>
              }
            </div>
            <div className="form-group col-6 px-2">
              <label className="wr-form--label d-inline">{labels['maxTotalLoad']}</label>
              {
                workRecord.attributesFuture.get('maxTotalLoad')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('maxTotalLoad')}/>
                  : ''
              }
              <SliderField
                id="maxTotalLoad"
                name="maxTotalLoad"
                valueLabel='%'
                min={1}
                disabled={!permissionService.isGranted('max_total_load', 'edit', workRecord)}
              />
            </div>
          </div>
        </div>

        <div className="wr-form--location">
          <p className="wr-form--title">Location</p>
          <div className="form-row">
            <div className="form-group col-6 px-2" id="locationWrapper" tabIndex={-1}>
              <label className="wr-form--label">{ labels['location'] } <span className="text-danger">*</span></label>
              {
                workRecord.attributesFuture.get('location')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('location')}/>
                  : ''
              }
              <SelectFieldCustom
                id="location"
                name="location"
                options={ locations }
                isDisabled={ !permissionService.isGranted('location', 'edit', workRecord) }
                className={ checkValidationClassName('location') }
                data-testid="formWorkRecordLocation"
              />
            </div>
            <div className="form-group col-6 px-2" id="costLocationWrapper" tabIndex={-1}>
              <label className="wr-form--label">{ labels['costLocation'] } <span className="text-danger">*</span></label>
              {
                workRecord.attributesFuture.get('costLocation')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('costLocation')}/>
                  : ''
              }
              <SelectFieldCustom
                id="costLocation"
                name="costLocation"
                options={ locations }
                isDisabled={ !permissionService.isGranted('cost_location', 'edit', workRecord) }
                className={ checkValidationClassName('costLocation') }
                data-testid="formWorkRecordCostLocation"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-6 px-2" id="costCenterWrapper" tabIndex={ -1 }>
              <label className="wr-form--label">{ labels['costCenter'] } <span className="text-danger">*</span></label>
              {
                workRecord.attributesFuture.get('costCenter')
                  ? <PopoverAttributeFuture future={ workRecord.attributesFuture.get('costCenter') } />
                  : ''
              }
              <SelectFieldCustom
                id="costCenter"
                name="costCenter"
                options={ costCenterList }
                isDisabled={ !permissionService.isGranted('cost_center', 'edit', workRecord) }
                className={ checkValidationClassName('costCenter') }
                data-testid="formWorkRecordCostCenter"
              />
            </div>
          </div>
        </div>

        <div className="wr-form--subordination">
          <p className="wr-form--title">Subordination</p>
          <div className="form-row">
            <div className="form-group col-6 px-2" id="mentorWrapper" tabIndex={-1}>
              <label className="wr-form--label">{labels['mentor']}</label>
              {
                workRecord.attributesFuture.get('mentor')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('mentor')}/>
                  : ''
              }
              <SelectFieldCustom
                id="mentor"
                name="mentor"
                options={ activeStaff }
                isDisabled={ !permissionService.isGranted('mentor', 'edit', workRecord) }
                menuPlacement={ 'top' }
              />
            </div>
            <div className="form-group col-6 px-2" id="lineManagerWrapper" tabIndex={ -1 }>
              <label className="wr-form--label">{ labels['lineManager'] }</label>
              {
                workRecord.attributesFuture.get('lineManager')
                  ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('lineManager')}/>
                  : ''
              }
              <SelectFieldCustom
                id="lineManager"
                name="lineManager"
                options={ activeStaff }
                isDisabled={ !permissionService.isGranted('line_manager', 'edit', workRecord) }
                menuPlacement={ 'top' }
              />
            </div>
          </div>
        </div>

        {
          (props.mode !== MODE_STAFF_ADD)
            ? (
              <div className="form-row">
                <div className="form-group col-6 px-2">
                  <label className="wr-form--label">{ labels['grLevel'] }</label>
                  {
                    workRecord.attributesFuture.get('grLevel')
                      ? <PopoverAttributeFuture future={workRecord.attributesFuture.get('grLevel')}/>
                      : ''
                  }
                  <SelectFieldCustom
                    id="grLevel"
                    name="grLevel"
                    options={ grLevels }
                    isDisabled={ !permissionService.isGranted('gr_level', 'edit', workRecord) }
                    menuPlacement={ 'top' }
                  />
                </div>
                <div className="form-group col-6 px-2"></div>
              </div>
            )
            : <></>
        }
      </>
    );
  };
