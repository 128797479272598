import React, { ReactElement, PropsWithChildren, cloneElement } from 'react';

import './filter.scss';

interface FilterTriggerProps {
  column: any
}

const FILTER_TRIGGER_CLASSNAME = 'filter-trigger';
export const FILTER_WRAPPER_CLASSNAME = 'filter-wrapper';

const FILTER_WIDTH = 200;

export const FilterTrigger: React.FC<FilterTriggerProps> = (props: PropsWithChildren<FilterTriggerProps>) => {
  const column = props.column;
  const handleClick = (event: any) => {
    let element: HTMLElement = event.target;
    do {
      element = element.parentElement as HTMLElement;
    } while (element.className !== FILTER_TRIGGER_CLASSNAME);
    const th: HTMLElement = (element.parentElement as HTMLElement).closest('th') as HTMLElement;
    const wrapper: HTMLElement = element.nextElementSibling as HTMLElement;
    wrapper.style.width = th.clientWidth > FILTER_WIDTH ? `${th.clientWidth}px` : `${FILTER_WIDTH}px`;
    const isOpen = !column.isFilterOpen;
    column.isFilterOpen = isOpen;
    if (isOpen) {
      th.classList.add('filtering');
      wrapper.classList.add('active');
    } else {
      th.classList.remove('filtering');
      wrapper.classList.remove('active');
    }
  };

  return (
    <span className={ FILTER_TRIGGER_CLASSNAME }>
      { cloneElement(props.children as ReactElement, { onClick: handleClick }) }
    </span>
  );
}
