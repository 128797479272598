import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux';

import permissionService from '../../../../services/security/PermissionStaffService';

import { Staff } from '../../../../interfaces/Staff';

import { GlobalState } from '../../../../store/types';
import { STAFF_STATE } from '../../../../store/reducers';

import { ATTRIBUTES_MAP } from '../../../../constants';

const names = ATTRIBUTES_MAP.staffBackend;

interface Props {
  staff: Staff;
  name: string;
  permission?: string;
  attribute: ReactElement;
}

export const AttributeWithPermission: React.FC<Props> = (props: Props) => {
  const {staff, name, permission, attribute} = {...props};
  const action: string = (!permission) ? 'view' : permission;
  permissionService.setUser(useSelector((state: GlobalState) => state[STAFF_STATE].myStaff));
  if (permissionService.isGranted(names[name], action, staff)) {
    return (attribute);
  }

  return <></>;
};
