import React from 'react';
import {ComplianceItemI, DocumentItem as DocumentItemInterface} from "../../../interfaces/DocumentItem";
import {PopupIndexedTrigger} from "../../Popup/PopupIndexedTrigger";
import {PopupIndexed} from "../../Popup/PopupIndexed";
import {Document} from "../Forms/Document";

import './document-item.scss';

interface DocumentItemProps {
  documentItem: ComplianceItemI;
  key: number;
}

export const DocumentItem: React.FC<DocumentItemProps> = (props: DocumentItemProps) => {
  const { documentItem } = {...props};
  const isExpired = (documentItem: ComplianceItemI) => {
    let today = new Date();
    let dueDate = (documentItem.dueDate) ? new Date(documentItem.dueDate) : today;
    return dueDate < today && documentItem.status === 0;
  };

  return <tr className={isExpired(documentItem) ? 'expired' : ''}>
      <td>
        <PopupIndexedTrigger popupName="document" popupIndex={String(documentItem.id)}>
          <span className="document-name">{documentItem.document}</span>
        </PopupIndexedTrigger>
        <PopupIndexed
          popupName="document"
          popupIndex={String(documentItem.id)}
          id="document"
          title="Accept Document/Policy"
          size="lg">
          <Document popupName="document" document={documentItem}/>
        </PopupIndexed>
      </td>
      <td>{documentItem.statusName}</td>
      <td>{documentItem.dueDate}</td>
      <td>{documentItem.date}</td>
    </tr>;
}
