import {WorkRecord} from '../../../../interfaces/WorkRecord';
import ValueDictionary from '../../../../models/ValueDictionary';

export interface CloseWorkRecordStateInterface {
  startDate: Date | null;
  endDate: Date | null;
  trialStatus: string;
  trialEndDate: Date | null;
  turnover: string;
  reasonToLeave: string;
  nextEmployer: string;
}

const initialState: CloseWorkRecordStateInterface = {
  startDate: null,
  endDate: null,
  trialStatus: '',
  trialEndDate: null,
  turnover: '',
  reasonToLeave: '',
  nextEmployer: ''
}

export default class CloseWorkRecordState {
  public static generateFromWorkRecord(workRecord?: WorkRecord): CloseWorkRecordStateInterface {
    let state = initialState;
    if (workRecord) {
      state.startDate = workRecord.startDate;
      state.endDate = workRecord.endDate;
      state.trialStatus = workRecord.trialStatus;
      state.trialEndDate = workRecord.trialEndDate;
      const turnover = workRecord.turnover as ValueDictionary;
      state.turnover = (turnover) ? turnover.value : '';
      const reasonToLeave = workRecord.reasonToLeave as ValueDictionary;
      state.reasonToLeave = (reasonToLeave && reasonToLeave.id) ? reasonToLeave.id.toString() : '';
      state.nextEmployer = workRecord.nextEmployer.value;
    }
    return state;
  }
}
