import { loaderTypes } from './types';

const initialState = {
  isShown: false,
};

export const uiReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case loaderTypes.SHOW_LOADER:
      return { ...state, isShown: true };
    case loaderTypes.HIDE_LOADER:
      return { ...state, isShown: false };
    default:
      return state;
  }
};