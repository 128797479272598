import React, {FunctionComponent, useEffect, useState} from 'react';
import { Doughnut } from 'react-chartjs-2';
import {CHART_COMMON} from '../../../../constants/HR_DASHBOARD';
import './leavers-chart-doughnut.scss'

interface Props {
  data: Array<any>,
  reasonsData: Array<any>
}

export const LeaversChartDoughnut: FunctionComponent<Props> = (props: Props) => {
  const {data, reasonsData} = {...props};
  const [dataSet, setDataSet] = useState<Array<any>>([]);
  const [labels, setLabels] = useState<Array<any>>([]);
  const [colors, setColors] = useState<Array<any>>([]);
  const [detailUrls, setDetailUrls] = useState<Array<any>>([]);

  useEffect(() => {
    const chartData = data.reduce((acc: any, item: any) => {
      const reasonsArr = reasonsData;
      const reasonsLength = reasonsArr.length;
      for(let i = 0; i < reasonsLength; i++){
        if(item.reason_to_leave_id === reasonsArr[i].id){
          acc.data.push(item.amount);
          acc.labels.push(reasonsArr[i].description);
          acc.colors.push(reasonsArr[i].color);
          acc.detailUrls.push(reasonsArr[i].detail_url);
          break;
        }
      }

      return acc;
    }, {
      data:[],
      labels:[],
      colors:[],
      detailUrls:[]
    })

    setDataSet(chartData.data);
    setLabels(chartData.labels);
    setColors(chartData.colors);
    setDetailUrls(chartData.detailUrls);
  }, [data, reasonsData]);

  const state = {
    labels: labels,
    datasets: [
      {
        backgroundColor: colors,
        data: dataSet
      }
    ]
  }

  const handleHover = (event: any) => {
    event.target.style.cursor = 'pointer';
  }

  const handleClick = (event: any, item: any) => {
    const index = item[0]._index;
    window.open('/report/' + detailUrls[index], "_blank")
  }

  return (
    <div className="leavers-chart-doughnut-wrapper">
      <Doughnut
        width={100}
        height={100}
        data={state}
        options={{
          legend:{
            display: false,
          },
          devicePixelRatio: CHART_COMMON.devicePixelRatio,
          responsive: true,
          cutoutPercentage: 80,
          onHover: handleHover,
          onClick: handleClick
        }}
      />
    </div>
  );
}
