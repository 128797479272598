import {Value as ValueInterface} from '../interfaces/Value';

export default class ValueNumber implements ValueInterface<number> {
  valueId: number | null = null;
  value: number = NaN;

  constructor(value: number = NaN) {
    this.value = value;
  }

  getValue(): number {
    return this.value;
  }
}
