import React, {FunctionComponent, useMemo} from 'react';

import './select-column.scss';

interface SelectColumnFilterProps {
  column: {
    filterValue: string;
    preFilteredRows: Array<any>;
    setFilter: Function;
    id: string;
  };
}

export const SelectColumnFilter: FunctionComponent<SelectColumnFilterProps> = (props: SelectColumnFilterProps) => {
  const column = props.column;
  const {filterValue, preFilteredRows, setFilter, id} = {...column};
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    //@ts-ignore
    return [...options.values()]
  }, [id, preFilteredRows])


  return (
    <select
      className="select-filter-wrapper"
      value={filterValue}
      onChange={event => {
        setFilter(event.target.value || undefined)
      }}>
      <option value="">All</option>
      {options.map((option, i: number) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}
