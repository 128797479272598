import React, {Fragment, FunctionComponent, useContext, useEffect, useState} from 'react';

import {Skill} from '../../../../interfaces/entity/Skill';
import {SkillEditFormContext, SkillEditFormContextInterface} from './SkillEditForm';

import './../../WRSkillTree/work-record-skill-tree.scss';

const isHidden = (id: number, list: Array<number>) => {
  return list.indexOf(id) === -1;
}

export const SkillPreview: FunctionComponent = () => {
  const context = useContext<SkillEditFormContextInterface>(SkillEditFormContext);
  const filterSkills = context.filteredSkills;
  const formSkills = context.formSkills;
  const [showList, setShowList] = useState<Array<number>>([]);
  const [skillsMap, setSkillMap] = useState<{ [key: number]: Skill }>([]);

  useEffect(() => {
    const listMap: { [key: number]: Skill } = [];
    filterSkills.forEach((skill: Skill) => {
      listMap[skill.id] = skill;
    });

    let show: Array<number> = [];
    const map: { [key: number]: Skill } = [];
    formSkills.forEach((skill: Skill) => {
      map[skill.id] = skill;
      show.push(skill.id);
      if (listMap[skill.id]) {
        show = show.concat(listMap[skill.id].parents);
      }
    });
    setShowList(show);
    setSkillMap(map);
  }, [formSkills]);

  if (showList.length) {
    return (
      <div className="work-record-skills work-record-skills--preview">
        {filterSkills.map((skill: Skill) => (
          <div
            key={skill.id}
            className={
              `work-record-skill work-record-skill--level-${skill.hierarchyLevel} clearfix` +
              `${(isHidden(skill.id, showList)) ? ' hidden' : ''}`
            }>
            <div className="work-record-skill__name">{skill.originalName}</div>
            <div className="work-record-skill__level">
              {(Object.keys(skillsMap).indexOf(String(skill.id)) !== -1)
                ? skill.level
                : ''
              }
            </div>
          </div>
        ))}
      </div>
    );
  }

  return <Fragment/>;
}
