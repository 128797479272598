import {WorkRecord} from '../../../../interfaces/WorkRecord';

export interface TurnoverStateInterface {
  turnover: string,
}

const initialState = {
  turnover: '',
}

export default class TurnoverState {
  public static generateFromWorkRecord(workRecord?: WorkRecord): TurnoverStateInterface {
    let state = initialState;
    if (workRecord) {
      state.turnover = workRecord.turnover.value;
    }
    return state;
  }
}
