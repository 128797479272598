export const ROUTERS = {
  HC_SNAPSHOT_ROUTE: '/headcount-snapshot',
  HEADCOUNT_ROUTE: '/headcount',
  TENURE_ROUTE: '/tenure/all',
  TURNOVER_ROUTE: '/turnover',
  LEAVERS_ROUTE: '/leavers',
  COMPLIANCE_ROUTE: '/compliance',
  COMPLIANCE_SUBORDINATES_ROUTE: '/compliance/subordinates',
  COMPLIANCE_ALL_ROUTE: '/compliance/all'
}

export const SIDEBAR_TABS = [
  {
    label: 'HC Snapshot',
    link: ROUTERS.HC_SNAPSHOT_ROUTE,
    key: 'hc-snapshot',
    svg: '<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 13"><path fill="#647483" fill-rule="evenodd" d="M14.16 10.68l-2.496-2.076a5.484 5.484 0 0 0 1.2-3.318V3.054a.522.522 0 0 0 0-.09c-.27-1.59-1.998-2.652-3.45-2.652-1.452 0-3.186 1.068-3.45 2.658a.51.51 0 0 0 0 .09v2.22a5.484 5.484 0 0 0 1.2 3.318L4.692 10.68a.528.528 0 0 0-.24.444v.762a.882.882 0 0 0 .858.9h8.232a.882.882 0 0 0 .858-.9v-.762c0-.18-.09-.346-.24-.444zm-.75 1.074H5.442V11.4l2.754-2.346c.045-.043.09-.085.138-.126A.522.522 0 0 0 8.4 8.82a.378.378 0 0 0 0-.33.528.528 0 0 0-.042-.126 3.66 3.66 0 0 1-.12-.144c-1.272-1.032-1.29-2.916-1.29-2.934V3.108c.186-.924 1.35-1.758 2.472-1.758 1.122 0 2.286.834 2.472 1.758V5.28a4.2 4.2 0 0 1-1.29 2.934 3.858 3.858 0 0 1-.12.144.528.528 0 0 0-.042.12.24.24 0 0 0 0 .168.246.246 0 0 0 .036.168c.013.04.031.078.054.114h.036c.03.037.064.069.102.096L13.41 11.4V11.754zm-9.492-3.39a3.774 3.774 0 0 1-.12-.144c-1.272-1.032-1.29-2.916-1.29-2.934V3.108c.186-.924 1.35-1.758 2.472-1.758.14 0 .281.012.42.036A.498.498 0 0 0 6 .96a.516.516 0 0 0-.402-.6 3.27 3.27 0 0 0-.6-.054c-1.452 0-3.186 1.068-3.45 2.658a.522.522 0 0 0 0 .09V5.28a5.484 5.484 0 0 0 1.2 3.318L.24 10.68a.528.528 0 0 0-.24.444v.762a.882.882 0 0 0 .858.9H3a.522.522 0 0 0 0-1.038H.99V11.4l2.754-2.346A.492.492 0 0 0 3.852 9h.03a.522.522 0 0 0 .06-.12.36.36 0 0 0 0-.318.528.528 0 0 0-.024-.198z"/></svg>'
  },
  {
    label: 'Headcount',
    link: ROUTERS.HEADCOUNT_ROUTE,
    key: 'headcount',
    svg: '<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 13"><path fill="#647483" fill-rule="evenodd" d="M14.16 10.68l-2.496-2.076a5.484 5.484 0 0 0 1.2-3.318V3.054a.522.522 0 0 0 0-.09c-.27-1.59-1.998-2.652-3.45-2.652-1.452 0-3.186 1.068-3.45 2.658a.51.51 0 0 0 0 .09v2.22a5.484 5.484 0 0 0 1.2 3.318L4.692 10.68a.528.528 0 0 0-.24.444v.762a.882.882 0 0 0 .858.9h8.232a.882.882 0 0 0 .858-.9v-.762c0-.18-.09-.346-.24-.444zm-.75 1.074H5.442V11.4l2.754-2.346c.045-.043.09-.085.138-.126A.522.522 0 0 0 8.4 8.82a.378.378 0 0 0 0-.33.528.528 0 0 0-.042-.126 3.66 3.66 0 0 1-.12-.144c-1.272-1.032-1.29-2.916-1.29-2.934V3.108c.186-.924 1.35-1.758 2.472-1.758 1.122 0 2.286.834 2.472 1.758V5.28a4.2 4.2 0 0 1-1.29 2.934 3.858 3.858 0 0 1-.12.144.528.528 0 0 0-.042.12.24.24 0 0 0 0 .168.246.246 0 0 0 .036.168c.013.04.031.078.054.114h.036c.03.037.064.069.102.096L13.41 11.4V11.754zm-9.492-3.39a3.774 3.774 0 0 1-.12-.144c-1.272-1.032-1.29-2.916-1.29-2.934V3.108c.186-.924 1.35-1.758 2.472-1.758.14 0 .281.012.42.036A.498.498 0 0 0 6 .96a.516.516 0 0 0-.402-.6 3.27 3.27 0 0 0-.6-.054c-1.452 0-3.186 1.068-3.45 2.658a.522.522 0 0 0 0 .09V5.28a5.484 5.484 0 0 0 1.2 3.318L.24 10.68a.528.528 0 0 0-.24.444v.762a.882.882 0 0 0 .858.9H3a.522.522 0 0 0 0-1.038H.99V11.4l2.754-2.346A.492.492 0 0 0 3.852 9h.03a.522.522 0 0 0 .06-.12.36.36 0 0 0 0-.318.528.528 0 0 0-.024-.198z"/></svg>'
  },
  {
    label: 'Tenure',
    link: ROUTERS.TENURE_ROUTE,
    key: 'tenure',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 13"><g fill="none" fill-rule="evenodd" stroke="#647483" stroke-linecap="round" stroke-linejoin="round"><path d="M1.5.5v3a4 4 0 0 0 8 0v-3"/><path d="M9.5 14.5v-3a4 4 0 0 0-8 0v3M.5.5h10M10.5 14.5H.5"/></g></svg>'
  },
  {
    label: 'Turnover',
    link: ROUTERS.TURNOVER_ROUTE,
    key: 'turnover',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 13"><g fill="none" fill-rule="evenodd" stroke="#647483" stroke-linecap="round" stroke-linejoin="round"><path d="M13.5 1.5v10h-12v-10M7.5 14.5v-3M4.5 14.5h6M7.5 1.5v-1M1.5 9.5l5-4M8.5 7.5l-2-2"/><g><path d="M8.5 7.5l5-4M.5 1.5h14"/></g></g></svg>'
  },
  {
    label: 'Leavers',
    link: ROUTERS.LEAVERS_ROUTE,
    key: 'leavers',
    svg: '<svg class="svg-fill" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 13"><path fill="#647483" fill-rule="evenodd" d="M14.16 10.68l-2.496-2.076a5.484 5.484 0 0 0 1.2-3.318V3.054a.522.522 0 0 0 0-.09c-.27-1.59-1.998-2.652-3.45-2.652-1.452 0-3.186 1.068-3.45 2.658a.51.51 0 0 0 0 .09v2.22a5.484 5.484 0 0 0 1.2 3.318L4.692 10.68a.528.528 0 0 0-.24.444v.762a.882.882 0 0 0 .858.9h8.232a.882.882 0 0 0 .858-.9v-.762c0-.18-.09-.346-.24-.444zm-.75 1.074H5.442V11.4l2.754-2.346c.045-.043.09-.085.138-.126A.522.522 0 0 0 8.4 8.82a.378.378 0 0 0 0-.33.528.528 0 0 0-.042-.126 3.66 3.66 0 0 1-.12-.144c-1.272-1.032-1.29-2.916-1.29-2.934V3.108c.186-.924 1.35-1.758 2.472-1.758 1.122 0 2.286.834 2.472 1.758V5.28a4.2 4.2 0 0 1-1.29 2.934 3.858 3.858 0 0 1-.12.144.528.528 0 0 0-.042.12.24.24 0 0 0 0 .168.246.246 0 0 0 .036.168c.013.04.031.078.054.114h.036c.03.037.064.069.102.096L13.41 11.4V11.754zm-9.492-3.39a3.774 3.774 0 0 1-.12-.144c-1.272-1.032-1.29-2.916-1.29-2.934V3.108c.186-.924 1.35-1.758 2.472-1.758.14 0 .281.012.42.036A.498.498 0 0 0 6 .96a.516.516 0 0 0-.402-.6 3.27 3.27 0 0 0-.6-.054c-1.452 0-3.186 1.068-3.45 2.658a.522.522 0 0 0 0 .09V5.28a5.484 5.484 0 0 0 1.2 3.318L.24 10.68a.528.528 0 0 0-.24.444v.762a.882.882 0 0 0 .858.9H3a.522.522 0 0 0 0-1.038H.99V11.4l2.754-2.346A.492.492 0 0 0 3.852 9h.03a.522.522 0 0 0 .06-.12.36.36 0 0 0 0-.318.528.528 0 0 0-.024-.198z"/></svg>'
  },
  {
    label: 'Compliance',
    link: ROUTERS.COMPLIANCE_SUBORDINATES_ROUTE,
    key: 'compliance',
    svg: '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 13"><g fill="none" fill-rule="evenodd" stroke="#647483" stroke-linecap="round" stroke-linejoin="round"><path d="M1 .5h11M3.5 4h6M3.5 7h6M3.5 10h6M1 .5v14M12 .5v14M12 14.5H1"/></g></svg>'
  }
]

export const HR_DASHBOARD_KEYWORDS = {
  LABEL_AS_FOR: 'As For',
  LABEL_COMPARE_DATE: 'Compare To'
}

export const HR_DASHBOARD_GROUP_BY_TYPE_KEYS = {
  LOCATION: 'location',
  STAR_UNIT: 'orgStructure'
}

export const HR_DASHBOARD_GROUP_BY_TYPES_INFO = [
  {
    label: 'Location',
    key: HR_DASHBOARD_GROUP_BY_TYPE_KEYS.LOCATION
  },
  {
    label: 'Star Unit',
    key: HR_DASHBOARD_GROUP_BY_TYPE_KEYS.STAR_UNIT
  }
]

export const HR_DASHBOARD_TENURE_FILTER_TYPES = {
  CURRENT: 'current',
  LEFT: 'left'
}

export const HR_DASHBOARD_CONTENT_TABS = {
  locations: {
    key: 'locations',
    label: 'LOCATIONS'
  },
  units: {
    key: 'units',
    label: 'STAR UNITS'
  },
  departments: {
    key: 'departments',
    label: 'DEPARTMENTS'
  }
}

export const HR_DASHBOARD_TYPE_VIEWS = { bar: 'Bar', line: 'Line' };

export const HR_DASHBOARD = {
  HR_DASHBOARD_BASE_URL: '/hr-dashboard',
  HR_DASHBOARD_DEFAULT_TAB_KEY: 'hc-snapshot',
  DEFAULT_CONTENT_TAB: HR_DASHBOARD_CONTENT_TABS.locations
}

export const HR_DASHBOARD_LEAVERS_TURNOVER_TYPES = {
  all: {
    key: 'all',
    label: 'All'
  },
  good: {
    key: 'Good',
    label: 'Good'
  },
  bad: {
    key: 'Bad',
    label: 'Bad'
  }
}

export const HR_DASHBOARD_LEAVERS_EMPLOYMENT_DURATION = {
  all: {
    key: 'all',
    label: 'All'
  },
  first_year: {
    key: 'first_year',
    label: '1st year'
  },
  over_one_year: {
    key: 'over_one_year',
    label: 'Over 1 year'
  }
}

export const CHART_COMMON = {
  devicePixelRatio: 2,
  barThickness: 12,
  categoryPercentage: 1.0,
  labels: {
    good: 'Good',
    bad: 'Bad',
    fontFamily: 'Source Sans Pro',
    fontSize: 13,
    fontColor: '#768591',
    backgroundColor: '#55bbcc'
  },
  colors: {
    good: '#ffce6a',
    bad: '#ff6633',
    billable: '#57d07d',
    nonBillable: '#ffae00',
    billableOpacity: 'rgba(87, 208, 125, 0.9)',
    nonBillableOpacity: 'rgba(255, 174, 0, 0.9)',
    goodOpacity: 'rgba(1,220,186,0.5)',
    badOpacity: 'rgba(254,132,142,0.5)',
    left: '#eb430f',
    leftOpacity: 'rgba(235, 67, 15, 1)',
    joined: '#55bbcc',
    joinedOpacity: 'rgba(85, 187, 204, 1)'
  },
  border: {
    width: 0,
    color: 'white'
  }
}