import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import { DateRangePickerField } from '../../../Forms/DateRangePickerField';
import { getDictionaryCountries, getDictionaryReportFilters } from '../../../../store/dictionary/actions';
import { TypeaheadField } from '../../../Forms/TypeaheadField';

import './turnover-control.scss'

interface Props {
  activeTab: string,
  activePeriod: string,
  country: string,
  setActivePeriod: Function,
  setCountry: Function,
  setPeriodRange: Function,
  setFromDate: Function,
  setToDate: Function
}

export const TurnoverControl: React.FC<Props> = (props: Props) => {
  const {activeTab, activePeriod, country, setActivePeriod, setCountry, setPeriodRange, setFromDate, setToDate} = props;
  const [countryList, setCountryList] = useState([]);

  const dispatch = useDispatch();
  const {reportFilters, countries} = useSelector((store: any) => store.dictionary);
  const periodFilter = reportFilters?.headcount_dynamic_report?.period_type;

  useEffect(() => {
    dispatch(getDictionaryReportFilters())
  }, []);

  useEffect(() => {
    dispatch(getDictionaryCountries())
  }, []);

  useEffect(() => {
    if (countries?.length > 0) {
      setCountryList(countries);
    }
  }, [countries]);

  return (
    <div className="turnover-control">
      <div className="row">
        <div className="col-auto mr-auto">
          <div className="filter-group">
            <DateRangePickerField
              setPeriodRange={setPeriodRange}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
            <div>
              <span className="custom-label">Group by</span>
              <span className="buttons-list">
                {periodFilter && Object.entries(periodFilter).map((value: any, i: number) => {
                  const period = value[0];
                  const periodTitle = value[1].slice(3);
                  return (
                    <span
                      className={`button ${period === activePeriod ? ' active' : ''}`}
                      onClick={() => setActivePeriod(period)}
                      key={i}>
                    {periodTitle}
                  </span>
                  )
                })}
              </span>
            </div>
            {activeTab !== 'locations' && (<div className="country-wrapper">
              <span className="custom-label">Country</span>
              <Formik initialValues={{ country: country }} onSubmit={() => {}}>
                {
                  ({ values, setFieldValue }) => (
                    <Form className="edb-form">
                      <TypeaheadField
                        className="form-control"
                        id="country"
                        name="country"
                        labelKey="name"
                        multiple={ false }
                        options={ countryList }
                        onChange={ (selected: any) => {
                          if (selected && selected[0]) {
                            setFieldValue('country', selected[0]);
                            setCountry(selected[0].id);
                          } else {
                            setFieldValue('country', '');
                            setCountry('');
                          }
          
                        } }
                        clearButton={ !!values.country }
                      />
                    </Form>
                  )
                }
              </Formik>
            </div>)}
          </div>
        </div>
      </div>
    </div>
  );
}
