import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'

import './text-column.scss'

interface TextColumnFilterProps {
  column: {
    filterValue: string
    setFilter: Function
  }
}

export const TextColumnFilter: React.FC<TextColumnFilterProps> = (props: TextColumnFilterProps) => {
  const column = props.column
  const { filterValue, setFilter } = { ...column }
  const applyValue = useAsyncDebounce(value => {
    setFilter(value);
  }, 50)

  return (
    <input
      className="input-filter-input"
      value={ filterValue || '' }
      onChange={event => {
        applyValue(event.target.value || undefined)
      }}
      placeholder="Start typing"
    />
  )
}
