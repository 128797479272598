import React, {cloneElement, Fragment, FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {PopupIndexedCloserProps} from './PopupPropsInterface';
import {dispatchIndexedPopupState} from '../../store/popup/types';

export const PopupIndexedCloser: FunctionComponent<PopupIndexedCloserProps> =
  (props: PropsWithChildren<PopupIndexedCloserProps>) => {
    const handleClose = () => {
      dispatchIndexedPopupState(props.popupName, props.popupIndex, false);
    };

    return (
      <Fragment>
        {cloneElement(props.children as ReactElement, {onClick: handleClose})}
      </Fragment>
    );
  }
