import React, {FunctionComponent} from 'react';
import {HeadcountDynamicValue} from '../HeadcountDynamicValue/HeadcountDynamicValue';
import './headcount-detail-item.scss';
import {ChartLine} from "../ChartLine/ChartLine";
import {HR_DASHBOARD_TYPE_VIEWS} from "../../../../constants/HR_DASHBOARD";
import {ChartBar} from "../ChartBar/ChartBar";
import {ChartBarNotStacked} from "../ChartBarNotStacked/ChartBarNotStacked";

interface Props {
  activePeriod: string,
  data: any,
  overview: any,
  chartType: string
}

export const HeadcountDetailItem: FunctionComponent<Props> = (props: Props) => {
  const { activePeriod, data, overview, chartType } = { ...props };

  const defineEndDate = (data: any, overview: any, activePeriod: string): string => {
    if (activePeriod === 'M') {
      const endDateMonth = new Date(data.to_date).toLocaleString('default', { month: 'short' });
      const endDateYear = new Date(data.to_date).getFullYear();

      return `${endDateMonth} ${endDateYear}`;
    } else {
      return overview.data[overview.data.length - 1].label;
    }
  }

  const dataSet = data.data;
  const isData = !!dataSet;

  return (
    <div className="headcount-dynamic-detail-item">
      <div className="headcount-dynamic-detail-item-container">
        {
          (data.headcount > 0)
            ? (
              <div className="row customClearfix">
                <div className="commonStat">
                  <HeadcountDynamicValue
                    mb={ false }
                    balance={ false }
                    itemData={ data }
                    headcount={ data.headcount }
                    label={ data.label }
                    endDate={ defineEndDate(data, overview, activePeriod) }
                  />
                  <HeadcountDynamicValue mb={ true } balance={ true } itemData={ data } />
                </div>
                <div className="chartWrapper">
                  {
                    isData
                      ? (
                        <div className="multiple-chart-wrapper long">
                          {
                            chartType === HR_DASHBOARD_TYPE_VIEWS.line
                              ? (
                                <>
                                  <ChartLine
                                    data={ dataSet }
                                    joinedStat={ false }
                                    detailUrls={ ['not_billable_detail_url', 'billable_detail_url'] }
                                  />
                                  <ChartLine
                                    data={ dataSet }
                                    joinedStat={ true }
                                    detailUrls={['left_detail_url', 'joined_detail_url']}
                                  />
                                </>
                              )
                              : <></>
                          }
                          {
                            chartType === HR_DASHBOARD_TYPE_VIEWS.bar
                              ? (
                                <>
                                  <ChartBar
                                    data={ dataSet }
                                    detailUrls={ ['not_billable_detail_url', 'billable_detail_url'] }
                                  />
                                  <ChartBarNotStacked
                                    data={ dataSet }
                                    detailUrls={ ['left_detail_url', 'joined_detail_url'] }
                                  />
                                </>
                              )
                              : <></>
                          }
                        </div>
                      )
                      : <div className="no-data-wrapper">No data available</div>
                  }
                </div>
              </div>
            )
            : <div className="row empty">No data available</div>
        }
      </div>
    </div>
  );
}
