import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART_COMMON } from '../../../../constants';

interface Props {
  data: Array<any>,
  detailMap: Array<any>
}

export const ChartBar: React.FC<Props> = (props: Props) => {
  const { data, detailMap } = { ...props };
  
  const [width, setWidth] = useState<number>(0);
  const [labels, setLabels] = useState<Array<any>>([]);
  const [datasets, setDatasets] = useState<Array<any>>([]);
  
  const ref = useRef(null);
  const refChart = useRef(null);
  
  useEffect(() => {
    const labelsData: Array<any> = [];
    const goodList: Array<number> = [];
    const badList: Array<number> = [];
    data.map((item: any) => {
      goodList.push(item.good);
      badList.push(item.bad);
      const sum = item.good + item.bad;
      labelsData.push([sum, '', item.label]);
    });
    const datasetsData: Array<any> = [
      {
        data: badList,
        fill: true,
        borderColor: CHART_COMMON.border.color,
        borderWidth: CHART_COMMON.border.width,
        backgroundColor: CHART_COMMON.colors.bad,
        borderSkipped: false,
        barThickness: CHART_COMMON.barThickness,
        categoryPercentage: CHART_COMMON.categoryPercentage
      },
      {
        data: goodList,
        fill: true,
        borderColor: CHART_COMMON.border.color,
        borderWidth: CHART_COMMON.border.width,
        backgroundColor: CHART_COMMON.colors.good,
        barThickness: CHART_COMMON.barThickness,
        categoryPercentage: CHART_COMMON.categoryPercentage
      }
    ];
    const currentElement = ref.current as unknown as HTMLElement;
    const wrapperElement = currentElement.parentElement as HTMLElement;
    const wrapperWidth = wrapperElement.clientWidth;
    let factor = Math.ceil(labelsData.length / 12);
    if (window.innerWidth < 1150 && labelsData.length >= 12) {
      factor *= 1.3;
    }
    setLabels(labelsData);
    setDatasets(datasetsData);
    setWidth(wrapperWidth * factor);
    
  }, [data]);
  
  useEffect(() => {
    const chartElement = refChart.current as unknown as Bar;
    if (chartElement) {
      chartElement.chartInstance.resize();
    }
  }, [width])

  const handleClickLabel = (event: any) => {
    let reportKey = '';
    if (event.chart) {
      let index = event.dataIndex;
      let datasetIndex = event.datasetIndex;
      reportKey = data[index][detailMap[datasetIndex]];
    }
    if (reportKey) {
      window.open('/report/' + reportKey, '_blank');
    }
  }

  const handleEnterLabel = (event: any) => {
    labelHover(event, true);
  }

  const handleLeaveLabel = (event: any) => {
    labelHover(event, false);
  }

  const labelHover = (event: any, isHover: boolean) => {
    const chart = event.chart;
    if (!chart) { return; }
    const canvas = chart.canvas;
    if (isHover) {
      canvas.classList.add('hovered');
    } else {
      canvas.classList.remove('hovered');
    }
  }

  const handleHover = (event: any, activeElements: Array<any>) => {
    if (activeElements.length) {
      event.target.style.cursor = 'pointer';
    } else {
      event.target.style.cursor = 'default';
    }
  }

  const handleClick = (event: any, activeElements: Array<any>) => {
    let reportKey = '';
    if (activeElements.length) {
      const activeElement = activeElements[0];
      const index = activeElement._index;
      const datasetIndex = activeElement._datasetIndex;
      reportKey = data[index][detailMap[datasetIndex]];
    }

    if (reportKey) {
      window.open('/report/' + reportKey, '_blank');
    }
  }

  const datasetKeyProvider = () => {
    return btoa(String(Math.random())).substring(0, 12);
  }

  return (
    <div className="chart-bar-wrapper" ref={ ref } style={ { width } }>
      <Bar
        ref={ refChart }
        data={{ labels, datasets }}
        datasetKeyProvider={ datasetKeyProvider }
        options={{
          devicePixelRatio: CHART_COMMON.devicePixelRatio,
          maintainAspectRatio: false,
          tooltips: { enabled: false },
          hover: {mode: 'point'},
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: {display: false},
                ticks: {
                  display: true,
                  fontFamily: CHART_COMMON.labels.fontFamily,
                  fontSize: CHART_COMMON.labels.fontSize,
                  fontColor: CHART_COMMON.labels.fontColor,
                  fontStyle: 'bold'
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                display: false
              }
            ]
          },
          legend: {
            display: false
          },
          plugins: {
            datalabels: {
              display: (context: any) => {
                return context.dataset.data[context.dataIndex] !== 0;
              },
              clamp: true,
              anchor: 'center',
              align: 'left',
              color: 'black',
              font: {
                family: CHART_COMMON.labels.fontFamily,
                size: CHART_COMMON.labels.fontSize
              },
              padding: {left: 15},
              listeners: {
                click: handleClickLabel,
                enter: handleEnterLabel,
                leave: handleLeaveLabel
              }
            }
          },
          onClick: handleClick,
          onHover: handleHover
        }}
        plugins={ [ChartDataLabels] }
      />
    </div>
  );
}
