import { ComplianceItemI } from './../interfaces/DocumentItem';

export default class DocumentItem implements ComplianceItemI {
  id: number = NaN;
  createdAt: string = '';
  document: string = '';
  documentId: number = NaN;
  documentLink: string = '';
  documentPath: string = '';
  dueDate: string = '';
  date: string = '';
  peoplePartnerId: number = NaN;
  peoplePartnerName: string = '';
  status: number = NaN;
  statusName: string = '';
  staffId: number = NaN;
  fullName: string = '';
}
