import React, {Fragment, FunctionComponent, ReactElement, useEffect, useState} from 'react'
import {Value} from '../../../interfaces/Value';

import './wr-attribute.scss';

interface WRAttributeBooleanProps {
  name: string;
  value: Value<boolean>;
  tooltip?: ReactElement;
}

export const WRAttributeBoolean: FunctionComponent<WRAttributeBooleanProps> = (props: WRAttributeBooleanProps) => {
  const [name] = useState<string>(props.name);
  const [value, setValue] = useState<Value<boolean>>(props.value);

  useEffect(() => {
    setValue(props.value)
  }, [props]);

  if (value) {
    return (
      <div className="work-record-attribute">
        <div className="work-record-attribute__name">
          {name}
        </div>
        <div className="work-record-attribute__value">
          {(value.value) ? 'Yes' : 'No'}
          {(props.tooltip) ? (props.tooltip) : ''}
        </div>
      </div>
    );
  }

  return <Fragment/>;
}
