import {Staff as StaffInterface} from '../interfaces/Staff';
import {WorkRecord} from '../interfaces/WorkRecord';
import {Permission as PermissionInterface} from '../interfaces/security/Permission';
import ValueString from './ValueString';
import {Value} from '../interfaces/Value';
import DateHelper from '../utils/date/DateHelper';
import ValuePhysicalLocation from "./ValuePhysicalLocation";

export default class Staff implements StaffInterface {
  id: number = NaN;
  code: string = '';
  status: string = '';
  username: string = '';
  fullName: string = '';
  avatar: string = '';
  alumni: boolean = false;
  companyStartDate: Date | null = null;

  workRecords: Array<WorkRecord> = [];
  activeWRs: Array<WorkRecord> = [];
  inactiveWRs: Array<WorkRecord> = [];

  attributes: Map<string, any> = new Map();
  subordinates: Array<number> = [];
  permissions: PermissionInterface = {attributes: [], roles: []};

  email: Value<string> = new ValueString();
  skype: Value<string> = new ValueString();
  phone1: Value<string> = new ValueString();
  phone2: Value<string> = new ValueString();
  linkedIn: Value<string> = new ValueString();
  facebook: Value<string> = new ValueString();
  wiki: Value<string> = new ValueString();
  aboutMe: Value<string> = new ValueString();
  firstName: Value<string> = new ValueString();
  lastName: Value<string> = new ValueString();
  middleName: Value<string> = new ValueString();
  dateOfBirth: Value<Date> | null = null;
  gender: Value<string> = new ValueString();
  emergContactName: Value<string> = new ValueString();
  emergContactPhone: Value<string> = new ValueString();
  physicalLocation: ValuePhysicalLocation = new ValuePhysicalLocation();

  getDateOfBirth(): string {
    if (this.dateOfBirth && this.dateOfBirth.value) {
      return DateHelper.getFormatted2Date(this.dateOfBirth.value);
    }
    return ''
  }
}
