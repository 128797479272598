import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';
import {CVStatusType} from "../../store/cv/types";

class CVClient {

  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getByStatus(wrId: number, status: CVStatusType) {
    return this.http.getAction(ENDPOINTS.CV_STATUS(wrId, status));
  }

  public getConfig() {
    return this.http.get(ENDPOINTS.CV_CONFIG);
  }

  public create(body: any, wrId: number) {
    return this.http.postAction(ENDPOINTS.CV_CREATE(wrId), body);
  }

  public update(body: any, wrId: number) {
    return this.http.putAction(ENDPOINTS.CV_UPDATE(wrId), body);
  }

  public approve(wrId: number) {
    return this.http.putAction(ENDPOINTS.CV_APPROVE(wrId), {});
  }
  
  public clone(wrId: number, attributeId: number) {
    return this.http.getAction(ENDPOINTS.CV_CLONE(wrId, attributeId));
  }

  public request(wrId: number) {
    return this.http.postAction(ENDPOINTS.CV_REQUEST(wrId), {});
  }
  
  public requestUpdate(wrId: number) {
    return this.http.postAction(ENDPOINTS.CV_REQUEST_UPDATE(wrId), {});
  }
  
  public requestApproval(wrId: number) {
    return this.http.postAction(ENDPOINTS.CV_REQUEST_APPROVAL(wrId), {});
  }

  public checkIsApproved(wrId: number) {
    return this.http.postAction(ENDPOINTS.CV_CHECK_APPROVED(wrId), {});
  }
  
  public checkIsPending(wrId: number) {
    return this.http.postAction(ENDPOINTS.CV_CHECK_PENDING(wrId), {});
  }

  public getPdfContent(id: number, status: CVStatusType) {
    this.http.setResponseType('arraybuffer');
    return this.http.getAction(`${ENDPOINTS.CV_GET_PDF(id, status)}`);
  }

  public getDocxContent(id: number, status: CVStatusType) {
    this.http.setResponseType('arraybuffer');
    return this.http.getAction(`${ENDPOINTS.CV_GET_DOCX(id, status)}`);
  }
  
  public getList(staffId: number) {
    return this.http.getAction(ENDPOINTS.CV_GET_LIST(staffId));
  }
}

export default new CVClient(http);
