import React, { createContext } from 'react';
import { useParams } from 'react-router-dom';

import { EducationList } from '../../Education/EducationList/EducationList';
import { AddEducationFormWrapper } from '../../Education/Forms/EducationItem/AddEducationFormWrapper';
import { Popup } from '../../Popup/Popup';
import { PopupTrigger } from '../../Popup/PopupTrigger';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';

import { ROLES } from '../../../constants';

const context = {updateList: () => {}};
export const EducationListContext = createContext(context);

type Params = {
  staffId: string,
  wrId: string
}

export const WREducation: React.FC = () => {
  const params = useParams<Params>();
  const cParams = {
    wrId: Number(params.wrId)
  };

  return <EducationListContext.Provider value={context}>
    <div className="work-record-main">
      <div className="work-record-main__header"></div>
      <div className="work-record-main__body">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <p className="card-title">Education</p>
                <EducationList />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="work-record-main__footer">
        <ElementWithPermission element="addEducation" staffToCheck={{role: ROLES.LM, wrId: cParams.wrId}}>
          <PopupTrigger popupName="addEducation">
            <span className="edb-btn edb-btn--lightblue">+Add education</span>
          </PopupTrigger>
          <Popup popupName="addEducation" id="addEducation" title="Education item" size="lg">
            <AddEducationFormWrapper popupName="addEducation"/>
          </Popup>
        </ElementWithPermission>
      </div>
    </div>
  </EducationListContext.Provider>;
}
