import * as Yup from 'yup';

export const schema = Yup.object()
  .shape({
    startDate: Yup.date()
      .typeError('State Date should be set')
      .required('State Date is required'),
    endDate: Yup.date()
      .nullable(),
  })
