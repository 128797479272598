import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {GlobalState} from "../../store/types";
import {PERMISSION_STATE, STAFF_STATE} from "../../store/reducers";
import PermissionElementService from "../../services/security/PermissionElementService";
import PermissionWRService from "../../services/security/PermissionWRService";
import {useParams} from "react-router-dom";

type PropsType = {
  element: string,
  staffToCheck?: any
}

export const ElementWithPermission: FC<PropsType> = (props: PropsWithChildren<PropsType>) => {
  const {element, staffToCheck} = {...props};

  const {myStaff} = useSelector((state: GlobalState) => state[STAFF_STATE]);
  const {elements} = useSelector((state: GlobalState) => state[PERMISSION_STATE]);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  PermissionWRService.setParams(useParams());

  useEffect(() => {
    if (myStaff && elements) {
      PermissionWRService.setUser(myStaff);
      setIsVisible(PermissionElementService.isUIShown(element, myStaff, elements, staffToCheck, PermissionWRService.isOwner()));
    }
  }, [myStaff, elements, element]);

  if (isVisible) {
    return <>{props.children}</>
  }

  return <></>;
}