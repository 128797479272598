import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';
import {AdaptedCredentials} from '../../interfaces/authorization/AdaptedCredentials';
import {LoginResponse} from '../../interfaces/http/LoginResponse';
import {ITokenResponse} from '../../interfaces/authorization/ITokenResponse';

export class AuthClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public login(credentials: AdaptedCredentials): Promise<string|null> {
    return this.http.post<LoginResponse>(ENDPOINTS.SECURITY_LOGIN_CHECK, credentials).then((data) => {
      if (data && data.token) {
        return data.token;
      } else {
        return null;
      }
    });
  }

  public logout(): Promise<string|null> {
    return this.http.postAction(ENDPOINTS.SECURITY_LOGOUT, {});
  }

  public tokenValidate(token: ITokenResponse) {
    return this.http.postAction(ENDPOINTS.TOKEN_VALIDATE, { token })
    .then((data) => {
      if (data) {
        return data;
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  public setToken(token: string) {
    this.http.setToken(token);
  }

  public unsetToken() {
    this.http.unsetToken()
  }
}

export default new AuthClient(http);
