import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';
import {StaffResponse} from '../../interfaces/http/staff/StaffResponse';
import DateHelper from "../../utils/date/DateHelper";

class StaffClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getMy() {
    return this.http.getList(ENDPOINTS.STAFF_MY);
  }

  public get(id: number) {
    return this.http.get<StaffResponse>(`${ENDPOINTS.STAFF_GET}/${id}`);
  }

  public validate(body: any) {
    return this.http.post(ENDPOINTS.STAFF_VALIDATE, body);
  }

  public search(q: string) {
    return this.http.get(ENDPOINTS.STAFF_SEARCH(q));
  }

  public getParentLMList(staffId: number, wrId: number) {
    return this.http.getList(ENDPOINTS.STAFF_PARENT_LM_LIST(staffId, wrId));
  }

  public getLmList() {
    return this.http.getList(ENDPOINTS.STAFF_LM_LIST);
  }

  public getPpList() {
    return this.http.getList(ENDPOINTS.STAFF_PP_LIST);
  }

  public getLmTreeCsv(date: Date) {
    const formattedDate = DateHelper.getFormatted2Date(date);

    this.http.setResponseType('json');
    return this.http.get(ENDPOINTS.STAFF_GET_LM_TREE_CSV(formattedDate));
  }
}

export default new StaffClient(http);
