export interface FeedbackProject {
  id: string;
  name: string;
  pmWrId: string;
}

export const defaultFeedbackProjectValue: FeedbackProject = {
  id: '',
  name: '',
  pmWrId: ''
};
