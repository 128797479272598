import { useEffect, useRef, useState } from 'react';

export default function useOutsideClick(initialIsVisible: boolean) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (e: MouseEvent) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(e.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', (e) => handleClickOutside(e), true);
    return () => {
      document.removeEventListener('click', (e) => handleClickOutside(e), true);
    }
  }, []);

  return { ref, isVisible, setIsVisible }
}