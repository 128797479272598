import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Accordion, Button} from 'react-bootstrap';
import {Staff} from '../../../interfaces/Staff';
import {WorkRecord} from '../../../interfaces/WorkRecord';
import {GlobalState} from '../../../store/types';
import {STAFF_STATE} from '../../../store/reducers';
import {WRNavigationLinkList} from './WRNavigationLinkList';

import './wr-navigation-list.scss';

interface Params {
  staffId: string;
  wrId: string;
}

export const WRNavigationList: FunctionComponent = () => {
  const params = useParams<Params>();
  const cParams = {
    staffId: Number(params.staffId),
    wrId: Number(params.wrId)
  };

  const staff: Staff = useSelector((state: GlobalState) => state[STAFF_STATE].staff);
  const [activeKey, setActiveKey] = useState<number | undefined>();
  const [activeId, setActiveId] = useState<number>(NaN);

  const history = useHistory();

  useEffect(() => {
    if (cParams?.wrId !== activeId) {
      setActiveId(cParams.wrId);
      setActiveKey(cParams.wrId);
    }
  }, [cParams.wrId, staff]);

  const handleClick = (event: React.SyntheticEvent<Element, Event> | undefined, id: number) => {
    setActiveKey((activeKey === id) ? undefined : id);
    history.replace(`/staff/${staff.id}/work-records/${id}/main`);
  }

  if (staff && activeId) {
    const activeList: Array<WorkRecord> = staff.activeWRs;
    const inactiveList: Array<WorkRecord> = staff.inactiveWRs;

    return (
      <Accordion className="work-record-navigation-list" defaultActiveKey={(activeKey !== undefined) ? String(activeKey) : activeKey} activeKey={(activeKey !== undefined) ? String(activeKey) : activeKey}>
        {
          (activeList.length)
            ? <div className="work-record-navigation-list__title">
              Active work records
            </div>
            : <Fragment/>
        }
        {
          activeList.map(
            (wr: WorkRecord) => {
              return <div className={`work-record-navigation-item ${activeId === wr.id ? "active" : ""}`} key={String(wr.id)}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={String(wr.id)}
                  className="work-record-navigation-item-button"
                  onClick={(event) => handleClick(event, wr.id)}
                >
                  <div className="work-record-navigation-item-button__position">
                    {wr.position.value}
                  </div>
                  <div className="work-record-navigation-item-button__dates">
                    {wr.getDates().value}
                  </div>
                  <div className="work-record-navigation-item-button__employment">
                    {wr.employment.value}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={String(wr.id)}>
                  <WRNavigationLinkList wr={wr}/>
                </Accordion.Collapse>
              </div>
            }
          )
        }
        {
          (inactiveList.length)
            ? <div className="work-record-navigation-list__title">
              Inactive work records
            </div>
            : <Fragment/>
        }
        {
          inactiveList.map(
            (wr: WorkRecord) => {
              return <div className={`work-record-navigation-item ${activeId === wr.id ? "active" : ""}`} key={String(wr.id)}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={String(wr.id)}
                  className="work-record-navigation-item-button"
                  onClick={(event) => handleClick(event, wr.id)}
                >
                  <div className="work-record-navigation-item-button__position">
                    {wr.position.value}
                  </div>
                  <div className="work-record-navigation-item-button__dates">
                    {wr.getDates().value}
                  </div>
                  <div className="work-record-navigation-item-button__employment">
                    {wr.employment.value}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={String(wr.id)}>
                  <WRNavigationLinkList wr={wr}/>
                </Accordion.Collapse>
              </div>
            }
          )
        }
      </Accordion>
    );
  }

  return <Fragment/>;
}
