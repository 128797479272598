import React, { FunctionComponent } from 'react';
import { HRDashboardSidebarTab, HRDashboardSidebarTabInfoInterface } from './HRDashboardSidebarTab';
import { SIDEBAR_TABS } from '../../../constants';

import './hr-dashboard-sidebar.scss'

export const HRDashboardSidebar: FunctionComponent = () => {
  return (
    <div className="edb-hr-dashboard-sidebar">
      <nav className='menu'>
        {
          SIDEBAR_TABS.map((item: HRDashboardSidebarTabInfoInterface) => (
            <HRDashboardSidebarTab tabInfo={ item } key={ item.key } />
          ))
        }
      </nav>
    </div>
  );
}
