import { ColumnT } from './ComplianceTableTypes';

interface ComplianceTable {
  all: Array<ColumnT>;
  team: Array<ColumnT>;
}

export const COMPLIANCE_TABLE_COLUMNS: ComplianceTable = {
  all: [
    {
      header: 'Document/Policy',
      accessor: 'document',
      sortable: false,
      filterable: true,
      backend: 'document',
      backendKey: 'id'
    },
    {
      header: 'Employee',
      accessor: 'fullName',
      sortable: false,
      filterable: false,
      backend: 'fullName',
      backendKey: 'id'
    },
    {
      header: 'Acceptance request created at',
      accessor: 'createdAt',
      sortable: false,
      filterable: false,
      backend: 'createdAt'
    },
    {
      header: 'Due By',
      accessor: 'dueDate',
      sortable: true,
      filterable: false,
      backend: 'dueDate'
    },
    {
      header: 'Acceptance Status',
      accessor: 'statusName',
      sortable: false,
      filterable: true,
      backend: 'status',
      backendKey: 'id'
    },
    {
      header: 'Acceptance Date',
      accessor: 'date',
      sortable: true,
      filterable: false,
      backend: 'date'
    },
    {
      header: 'People Partner',
      accessor: 'peoplePartnerName',
      sortable: false,
      filterable: true,
      backend: 'peoplePartner',
      backendKey: 'id'
    }
  ],
  team: [
    {
      header: 'Document/Policy',
      accessor: 'document',
      sortable: false,
      filterable: true,
      backend: 'document',
      backendKey: 'id'
    },
    {
      header: 'Employee',
      accessor: 'fullName',
      sortable: false,
      filterable: false,
      backend: 'fullName',
      backendKey: 'id'
    },
    {
      header: 'Acceptance request created at',
      accessor: 'createdAt',
      sortable: false,
      filterable: false,
      backend: 'createdAt'
    },
    {
      header: 'Due By',
      accessor: 'dueDate',
      sortable: true,
      filterable: false,
      backend: 'dueDate'
    },
    {
      header: 'Acceptance Status',
      accessor: 'statusName',
      sortable: false,
      filterable: true,
      backend: 'status',
      backendKey: 'id'
    },
    {
      header: 'Acceptance Date',
      accessor: 'date',
      sortable: true,
      filterable: false,
      backend: 'date'
    }
  ]
}