import {
  POPUP_SET_IS_ACTIVATE_WORK_RECORD_SHOWN,
  POPUP_SET_IS_ADD_EDUCATION_SHOWN,
  POPUP_SET_IS_ADD_FEEDBACK_SHOWN,
  POPUP_SET_IS_ADD_NEW_WORK_RECORD_SHOWN,
  POPUP_SET_IS_CLOSE_WORK_RECORD_SHOWN,
  POPUP_SET_IS_DOCUMENT_SHOWN,
  POPUP_SET_IS_EDIT_EDUCATION_SHOWN,
  POPUP_SET_IS_EDIT_FEEDBACK_SHOWN,
  POPUP_SET_IS_EDIT_PROFILE_SHOWN,
  POPUP_SET_IS_EDIT_SKILL_SHOWN,
  POPUP_SET_IS_NEXT_EMPLOYER_SHOWN,
  POPUP_SET_IS_POSITION_DESCRIPTION_SHOWN,
  POPUP_SET_IS_REASON_TO_LEAVE_SHOWN,
  POPUP_SET_IS_TRIAL_STATUS_SHOWN,
  POPUP_SET_IS_TURNOVER_SHOWN,
  PopupState,
  PopupTypes
} from './types';

const defaultState: PopupState = {
  editProfile: false,
  positionDescription: false,
  addNewWorkRecord: false,
  activateWorkRecord: false,
  closeWorkRecord: false,
  turnover: false,
  trialStatus: false,
  reasonToLeave: false,
  nextEmployer: false,
  addEducation: false,
  editEducation: new Map<string, boolean>(),
  addFeedback: false,
  editFeedback: new Map<string, boolean>(),
  editSkill: new Map<string, boolean>(),
  document: new Map<string, boolean>()
};

export const popupReducer = (state: PopupState = defaultState, action: PopupTypes): PopupState => {
  switch (action.type) {
    case POPUP_SET_IS_EDIT_PROFILE_SHOWN:
      return {...state, editProfile: action.payload}
    case POPUP_SET_IS_POSITION_DESCRIPTION_SHOWN:
      return {...state, positionDescription: action.payload}
    case POPUP_SET_IS_ADD_NEW_WORK_RECORD_SHOWN:
      return {...state, addNewWorkRecord: action.payload}
    case POPUP_SET_IS_ACTIVATE_WORK_RECORD_SHOWN:
      return {...state, activateWorkRecord: action.payload}
    case POPUP_SET_IS_CLOSE_WORK_RECORD_SHOWN:
      return {...state, closeWorkRecord: action.payload}
    case POPUP_SET_IS_TURNOVER_SHOWN:
      return {...state, turnover: action.payload}
    case POPUP_SET_IS_TRIAL_STATUS_SHOWN:
      return {...state, trialStatus: action.payload}
    case POPUP_SET_IS_REASON_TO_LEAVE_SHOWN:
      return {...state, reasonToLeave: action.payload}
    case POPUP_SET_IS_NEXT_EMPLOYER_SHOWN:
      return {...state, nextEmployer: action.payload}
    case POPUP_SET_IS_ADD_EDUCATION_SHOWN:
      return {...state, addEducation: action.payload}
    case POPUP_SET_IS_EDIT_EDUCATION_SHOWN:
      return {...state, editEducation: action.payload}
    case POPUP_SET_IS_ADD_FEEDBACK_SHOWN:
      return {...state, addFeedback: action.payload}
    case POPUP_SET_IS_EDIT_FEEDBACK_SHOWN:
      return {...state, editFeedback: action.payload}
    case POPUP_SET_IS_EDIT_SKILL_SHOWN:
      return {...state, editSkill: action.payload}
    case POPUP_SET_IS_DOCUMENT_SHOWN:
      return {...state, document: action.payload}
    default:
      return state;
  }
};
