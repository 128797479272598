import {Staff} from '../../../../interfaces/Staff';

export interface ProfileStateInterface {
  username: string;
  photo: string;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: Date | null;
  gender: string;
  email: string;
  skype: string;
  phone1: string;
  phone2: string;
  emergContactName: string;
  emergContactPhone: string;
  facebook: string;
  linkedIn: string;
  wiki: string;
  companyStartDate: Date | null;
  alumni: boolean;
  country: string;
  city: string;

  applyChanges: Date;
}

export const initialState: ProfileStateInterface = {
  username: '',
  photo: '',
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: null,
  gender: '',
  email: '',
  skype: '',
  phone1: '',
  phone2: '',
  emergContactName: '',
  emergContactPhone: '',
  facebook: '',
  linkedIn: '',
  wiki: '',
  companyStartDate: null,
  alumni: false,
  country: '',
  city: '',
  applyChanges: new Date(),
};

export default class ProfileState {
  public static generateFromStaff(staff?: Staff): ProfileStateInterface {
    let state = initialState;
    if (staff) {
      state.username = staff.username;
      state.photo = staff.avatar;
      state.companyStartDate = staff.companyStartDate;
      state.alumni = staff.alumni;

      state.firstName = staff.firstName.value;
      state.lastName = staff.lastName.value;
      state.middleName = staff.middleName.value;
      state.dateOfBirth = (staff.dateOfBirth) ? staff.dateOfBirth.value : null;
      state.gender = staff.gender.value;
      state.email = staff.email.value;
      state.skype = staff.skype.value;
      state.phone1 = staff.phone1.value;
      state.phone2 = staff.phone2.value;
      state.emergContactName = staff.emergContactName.value;
      state.emergContactPhone = staff.emergContactPhone.value;
      state.facebook = staff.facebook.value;
      state.linkedIn = staff.linkedIn.value;
      state.wiki = staff.wiki.value;
      state.country = staff.physicalLocation.countryName;
      state.city = staff.physicalLocation.cityName
    }
    return state;
  }
}
