import React, {FunctionComponent} from 'react';

import './no-permissions.scss';

export const NoPermissions: FunctionComponent = () => {
  return (
    <div className="no-permissions-error">
      <div className="message">No permissions to view this data</div>
    </div>
  );
}
