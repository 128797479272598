import React, { useEffect, useState } from 'react';

import { LeaversChartDoughnut } from '../LeaversChartDoughnut/LeaversChartDoughnut';

import './leavers-detail-item.scss'

interface Props {
  label: string,
  data: any,
  reasonsToLeave: Array<any>
}

export const LeaversDetailItem: React.FC<Props> = (props: Props) => {
  const { label, data, reasonsToLeave } = { ...props };

  const [isData, setIsData] = useState<boolean>(false);
  const [dataSet, setDataSet] = useState<Array<any>>([]);
  const [reasons, setReasons] = useState<Array<any>>([]);

  useEffect(() => {
    const activeReasons = data.data.filter((item: any) => {
      return item.amount > 0;
    });
    const reasonsList = activeReasons.map((item: any) => {
      for(let i = 0; i < reasonsToLeave.length; i++){
        if(item.reason_to_leave_id === reasonsToLeave[i].id){
          return { ...reasonsToLeave[i], amount:item.amount, detail_url: item.detail_url}
        }
      }
    });
    setReasons(reasonsList);
    setDataSet(data.data);
    setIsData(!!dataSet);
  }, [data, reasonsToLeave]);

  return (
    <div className="leavers-detail-item">
      <div className="leavers-detail-item-container">
        <div className="row">
          <div className="col-5">
            <div className='header-title'>{ label }</div>
            {
              isData && (
                <div className="multiple-chart-wrapper long">
                  <LeaversChartDoughnut
                    data={ dataSet }
                    reasonsData={ reasons }
                  />
                </div>
              )
            }
          </div>
          <div className="col-5 reasons-wrapper">
            <div>
              {
                reasons.map((reason: any, i: number) => {
                  return (
                    <div className="title-wrapper" key={i}>
                      <span
                        className="marker"
                        style={{backgroundColor: reason.color}}
                        onClick={() => window.open('/report/' + reason.detail_url, "_blank")}>
                      </span>
                      <span>{reason.title}</span>
                      <span className="dash">-</span>
                      <span>{reason.amount}</span>
                    </div>
                  )
                })
              }
            </div>
            {
              (!isData) && <div className="no-data-wrapper">No data available</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
