import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import store from '../../../store/store';
import { GlobalState } from '../../../store/types';
import { CV_STATUS_APPROVED, CV_STATUS_PENDING, CVStatusType } from '../../../store/cv/types';
import { cvActions, setCVPendingLatestVersionThunk } from '../../../store/cv/actions';

import PermissionWRService, {
  CV_PERMISSION_APPROVE,
  CV_PERMISSION_CREATE,
  CV_PERMISSION_DOWNLOAD_APPROVED,
  CV_PERMISSION_DOWNLOAD_PENDING,
  CV_PERMISSION_EDIT,
  CV_PERMISSION_REQUEST_UPDATE
} from '../../../services/security/PermissionWRService';
import CVHelper from '../../../services/data/CVHelper';
import CVClient from '../../../services/http/CVClient';

import { WorkRecord as WorkRecordInterface } from '../../../interfaces/WorkRecord';
import WorkRecord from '../../../models/WorkRecord';
import CVMapper from '../../../mappers/response/CVMapper';

import { WRCvInfo } from './WRCvInfo';
import { WRCvData } from './WRCvData';
import { PopoverCvRequest } from '../../Popovers/PopoverCvRequest/PopoverCvRequest';

type Params = {
  staffId: string,
  wrId: string
}

export const WRCvRead: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<Params>();
  const cParams = {
    wrId: Number(params.wrId),
    staffId: Number(params.staffId)
  };

  const {
    isPending,
    isApproved,
    data,
    hasLatestPendingVersion,
    hasLatestApprovedVersion,
    requestLatestPendingVersion
  } = useSelector((state: GlobalState) => state.cv);

  const { staff, myStaff } = useSelector((state: GlobalState) => state.staff);
  PermissionWRService.setUser(myStaff);

  const [workRecord, setWorkRecord] = useState<WorkRecordInterface | null>(
    useSelector((state: GlobalState) => state.workRecord.workRecord)
  );

  useEffect(() => {
    if (staff && !workRecord) {
      let wr: WorkRecord = staff.workRecords.filter((wr: WorkRecordInterface) => wr.id === cParams.wrId)[0];
      setWorkRecord(wr);
    }
  }, [cParams.wrId, cParams.staffId, staff]);
  
  useEffect(() => {
    if (isPending && isApproved && !data) {
      dispatch(setCVPendingLatestVersionThunk(cParams.wrId));
    }
  }, [isPending, isApproved, data]);

  const history = useHistory();
  const [requestBtn, setRequestBtn] = useState<boolean>(false);

  useEffect(() => {
    if (isPending && isApproved && !data && requestLatestPendingVersion && !hasLatestPendingVersion) {
      if (workRecord && PermissionWRService.isGrantedCv(CV_PERMISSION_CREATE, workRecord)) {
        history.push(`/staff/${cParams.staffId}/work-records/${cParams.wrId}/cv/create`);
      } else {
        setRequestBtn(true);
      }
    }
  }, [isPending, isApproved, data, hasLatestPendingVersion, requestLatestPendingVersion, workRecord]);

  const downloadPdf = (event: React.MouseEvent<HTMLAnchorElement>, status: CVStatusType) => {
    event.preventDefault();
    if (!workRecord) {
      return;
    }
    CVHelper.getFile(cParams.wrId, {
      format: 'pdf',
      firstName: staff.firstName.value,
      lastName: staff.lastName.value,
      position: workRecord.position.value,
      date: data?.updatedAt,
      status
    });
  }

  const downloadDocx = (event: React.MouseEvent<HTMLAnchorElement>, status: CVStatusType) => {
    event.preventDefault();
    if (!workRecord) {
      return;
    }
    CVHelper.getFile(cParams.wrId, {
      format: 'docx',
      firstName: staff.firstName.value,
      lastName: staff.lastName.value,
      position: workRecord.position.value,
      date: data?.updatedAt,
      status
    });
  }

  const handleRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    CVClient.request(cParams.wrId)
      .then((response: any) => {
        if (response.status === 201) {
          toastr.success('', 'CV request was successfully sent!');
        }
      })
      .catch((e: any) => {
        console.error(e.statusText);
        toastr.error('', 'Something went wrong!');
      });
  }
  
  const handleRequestUpdate = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    CVClient.requestUpdate(cParams.wrId)
      .then((response: any) => {
        if (response.status === 201) {
          toastr.success('', 'CV update request was successfully sent!');
        }
      })
      .catch((e: any) => {
        console.error(e.statusText);
        toastr.error('', 'Something went wrong!');
      });
  }
  
  const handleRequestApproval = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    CVClient.requestApproval(cParams.wrId)
      .then((response: any) => {
        if (response.status === 201) {
          toastr.success('', 'CV request approval was successfully sent!');
        }
      })
      .catch((e: any) => {
        console.error(e.statusText);
        toastr.error('', 'Something went wrong!');
      });
  }

  const handleApprove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    CVClient.approve(cParams.wrId)
      .then((response: any) => {
        if (response.status === 200) {
          const data = CVMapper.createCVFromResponse(response.data);
          store.dispatch(cvActions.setData(data));
          toastr.success('', 'CV has been approved successfully');
        }
      })
      .catch((e) => {
        toastr.error('','Something went wrong!');
      });
  }

  return (
    <>
      <div className="work-record-cv__body">
        <div className="row no-gutters">
          <div className="col-12">
            <div className="position-relative bg-white">
              {
                (!requestBtn && data)
                  ? (
                    <p className={ `work-record-cv__status ${data.status === CV_STATUS_APPROVED ? 'work-record-cv__status-approved' : ''}` }>
                      { data.status } <br/> { data.updatedAt }
                    </p>
                  )
                  : <></>
              }
              { staff && workRecord && <WRCvInfo staff={ staff } workRecord={ workRecord } cv={ data } /> }
              {
                (requestBtn || hasLatestPendingVersion)
                  ? (
                    <div className="work-record-cv__no-data text-center">
                      <p className="work-record-cv__no-data-title">No approved CV data available</p>
                      {
                        (hasLatestPendingVersion)
                          ? (
                            <button
                              style={{ width: 'auto'}}
                              className="edb-btn edb-btn--primary"
                              onClick={(e) => handleRequestApproval(e)}
                            >
                              Request CV approval
                            </button>
                          )
                          : (
                            <button
                              className="edb-btn edb-btn--primary"
                              onClick={(e) => handleRequest(e)}
                            >
                              Request CV
                            </button>
                          )
                      }
                    </div>
                  )
                  : <></>
              }
              { staff && workRecord && data && <WRCvData data={ data } staff={ staff } workRecord={ workRecord } /> }
            </div>
          </div>
        </div>
      </div>
      <div className="work-record-cv__footer">
        <div className="row no-gutters">
          <div className="col px-4">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <section>
                {
                  (workRecord && PermissionWRService.isGrantedCv(CV_PERMISSION_DOWNLOAD_APPROVED, workRecord))
                    ? (
                      <div style={{ marginBottom: '10px' }}>
                        <span className="mr-1 text-muted">Download Latest Approved Version</span>
                        {
                          (!hasLatestApprovedVersion)
                            ? (
                              <>
                                <PopoverCvRequest wrId={ cParams.wrId } btnClass={"text-muted"} btnText={"PDF"} request={"PDF"} />
                                <span> | </span>
                                <PopoverCvRequest wrId={ cParams.wrId } btnClass={"text-muted"} btnText={"DOCX"} request={"DOCX"} />
                              </>
                            )
                            : (
                              <>
                                <a className="link-download" href="#" onClick={(e) => downloadPdf(e, CV_STATUS_APPROVED)}>PDF</a>
                                <span> | </span>
                                <a className="link-download" href="#" onClick={(e) => downloadDocx(e, CV_STATUS_APPROVED)}>DOCX</a>
                              </>
                            )
                        }
                      </div>
                    )
                    : <></>
                }
                {
                  (workRecord && PermissionWRService.isGrantedCv(CV_PERMISSION_DOWNLOAD_PENDING, workRecord) && data?.status === CV_STATUS_PENDING)
                    ? (
                      <div style={{ marginBottom: '10px' }}>
                        <span className="mr-1 text-muted">Download Latest Draft Version</span>
                        <a className="link-download" href="#" onClick={(e) => downloadDocx(e, CV_STATUS_PENDING)}>DOCX</a>
                      </div>
                    )
                    : <></>
                }
              </section>

              <section>
                {
                  (workRecord && PermissionWRService.isGrantedCv(CV_PERMISSION_REQUEST_UPDATE, workRecord) && data?.status === CV_STATUS_APPROVED)
                    ? (
                      <button
                        style={{ width: 'auto'}}
                        className="edb-btn edb-btn--primary"
                        onClick={ (e) => handleRequestUpdate(e) }
                      >
                        Request CV update
                      </button>
                    )
                    : <></>
                }
                {
                  (workRecord && PermissionWRService.isGrantedCv(CV_PERMISSION_APPROVE, workRecord) && data?.status === CV_STATUS_PENDING)
                    ? (
                      <button
                        className="edb-btn edb-btn--primary ml-2"
                        onClick={ (e) => handleApprove(e) }
                      >
                        Approve
                      </button>
                    )
                    : <></>
                }
                {
                  (workRecord && PermissionWRService.isGrantedCv(CV_PERMISSION_EDIT, workRecord))
                    ? (
                      <NavLink
                        className="edb-btn edb-btn--transparent ml-2"
                        to={`/staff/${cParams.staffId}/work-records/${cParams.wrId}/cv/edit`}
                      >
                        Edit
                      </NavLink>
                    )
                    : <></>
                }
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
