import React, {FC} from "react";

import './popover-cv-request.scss';
import {PopoverBase} from "../PopoverBase";
import CVClient from "./../../../services/http/CVClient";
import {toastr} from "react-redux-toastr";

const ID = "popover-cvRequest";
const TITLE = "No CV in 'approved' status available.";

type PropsType = {
  btnText: string,
  btnClass: string,
  request: 'PDF' | 'DOCX',
  wrId: number
}

export const PopoverCvRequest: FC<PropsType> = (props: PropsType) => {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    CVClient.request(props.wrId)
      .then((response: any) => {
        if (response.status === 201) {
          toastr.success('', 'CV request was successfully sent!');
        }
      })
      .catch((e: any) => {
        console.error(e.statusText);
        toastr.error('', 'Something went wrong!');
      })
  }

  return (
    <PopoverBase id={ ID } title={ TITLE } btn={ <span className={ props.btnClass }>{ props.btnText }</span> }>
      <button className="edb-btn edb-btn--primary w-auto" onClick={ (e) => handleClick(e) }>
        Request CV approval
      </button>
    </PopoverBase>
  )
}