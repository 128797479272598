import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { tokenValidateThunk } from '../../store/authorization/actions';
import { useGoogleOAuth } from '../../hooks';
import { ITokenResponse } from '../../interfaces/authorization/ITokenResponse';

export const GoogleSigninBtn = () => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const { clientId, scriptLoadedSuccessfully } = useGoogleOAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!scriptLoadedSuccessfully) return;
    // @ts-ignore
    btnRef.current = window.google?.accounts.oauth2.initTokenClient({
      client_id: clientId,
      scope: 'openid profile email',
      callback: (response: ITokenResponse) => {
        if (response.error) {
          toastr.error('', `Can't sign in: ${response.error}`);
          return;
        }
        dispatch(tokenValidateThunk(response));
      }
    });
  }, [clientId, scriptLoadedSuccessfully]);

  const handleClick = () => {
    // @ts-ignore
    btnRef.current.requestAccessToken();
  };

  return (
    <button className="btn btn-block btn-dark mt-0" ref={ btnRef } onClick={ handleClick }>
      Sign in with Google
    </button>
  )
}