import {Staff} from '../../interfaces/Staff';

export const STAFF_SET_MY_STAFF = 'STAFF_SET_MY_STAFF';
export const STAFF_SET_STAFF = 'STAFF_SET_STAFF';
export const STAFF_GET_MY_LOADING = 'STAFF_GET_MY_LOADING';
export const STAFF_GET_MY_SUCCESS = 'STAFF_GET_MY_SUCCESS';
export const STAFF_GET_MY_FAILURE = 'STAFF_GET_MY_FAILURE';
export const STAFF_SET_SHOULD_UPDATE = 'STAFF_SET_SHOULD_UPDATE';
export const STAFF_SET_LOADING = 'STAFF_SET_LOADING';

export interface StaffState {
  myStaff: Staff | null
  staff: Staff | null
  staffMyData: Staff | null,
  shouldUpdate: boolean,
  loading: boolean
}

interface SetMyStaffAction {
  type: typeof STAFF_SET_MY_STAFF
  payload: Staff | null
}

interface SetStaffAction {
  type: typeof STAFF_SET_STAFF
  payload: Staff
}

interface GetStaffMyLoading {
  type: typeof STAFF_GET_MY_LOADING
  payload: object
}

interface GetStaffMySuccess {
  type: typeof STAFF_GET_MY_SUCCESS
  payload: object
}

interface GetStaffMyFailure {
  type: typeof STAFF_GET_MY_FAILURE
  payload: boolean
}

interface SetShouldUpdateAction {
  type: typeof STAFF_SET_SHOULD_UPDATE
  payload: boolean
}

interface SetStaffLoadingAction {
  type: typeof STAFF_SET_LOADING
  payload: boolean
}

export type StaffTypes = SetMyStaffAction
    | SetStaffAction
    | GetStaffMyLoading
    | GetStaffMySuccess
    | GetStaffMyFailure
    | SetShouldUpdateAction
    | SetStaffLoadingAction
