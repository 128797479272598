import React, { useEffect, useState } from 'react';

// store
import store from '../../../store/store';
import { loaderActions } from '../../../store/loader/actions';

import client from './../../../services/http/StructureClient';

// components
import { TreeChart } from '../TreeChart/TreeChart';

const ID = 'lmTree';

export const LmTree: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [tree, setTree] = useState<Array<any>>([]);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);

  useEffect(() => {
    if (isLoaded) {
        return;
    }
    store.dispatch(loaderActions.showLoader());
    client.getLMTree()
      .then((data: any) => {
        if (data.data) {
          setTree([data.data]);
        }
        setIsLoaded(true);
      })
      .catch((error: any) => {
        if (error.status === 403) {
            setNoPermissions(true);
        }
        setIsLoaded(true);
      })
      .finally(() => {
        store.dispatch(loaderActions.hideLoader());
      });
  }, [tree])

  if (!isLoaded) {
    return <></>
  }

  return (
    <>
      {
        noPermissions
          ? <div className="text-center">No permissions to view this data</div>
          : (
            tree.length
              ? (
                <div className="lm-tree" id={ ID }>
                  <TreeChart tree={ tree } />
                </div>
              )
              : <></>
          )
      }
    </>
  );
}
