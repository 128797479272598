import React, {FC} from "react";
import {Staff} from "../../../interfaces/Staff";

import './staff-avatar-preview.scss';

type PropsType = {
  staff: Staff,
  visible: boolean
}

export const StaffAvatarPreview: FC<PropsType> = (props: PropsType) => {

  const getAvatarByStaff = () => {
    return props.staff.avatar ?? `/api/v1/media/avatar/${props.staff.id}`;
  }

  return (
    <div className={`edb-employee-photo ${props.visible ? "d-block" : "d-none"}`}>
      <div className="edb-employee-photo__popup-content">
        <img className="img-thumbnail" src={getAvatarByStaff()}/>
      </div>
    </div>
  );
};