export const MT_SET_SUBORDINATES_ATTRIBUTES = 'MT_SET_SUBORDINATES_ATTRIBUTES';
export const MT_SET_SUBORDINATES_ATTRIBUTES_DEFAULT = 'MT_SET_SUBORDINATES_ATTRIBUTES_DEFAULT';
export const MT_SET_DIRECT_ATTRIBUTES = 'MT_SET_DIRECT_ATTRIBUTES';
export const MT_SET_DIRECT_ATTRIBUTES_DEFAULT = 'MT_SET_DIRECT_ATTRIBUTES_DEFAULT';

export interface MtState {
  subordinatesAttributes: Array<any>,
  subordinatesAttributesDefault: Array<any>,
  directAttributes: Array<any>,
  directAttributesDefault: Array<any>
}

interface SetMtSubordinatesAttributesAction {
  type: typeof MT_SET_SUBORDINATES_ATTRIBUTES,
  payload: {
    subordinatesAttributes: Array<any>
  }
}

interface SetMtSubordinatesAttributesDefaultsAction {
  type: typeof MT_SET_SUBORDINATES_ATTRIBUTES_DEFAULT,
  payload: {
    subordinatesAttributesDefault: Array<any>
  }
}

interface SetMtDirectAttributesAction {
  type: typeof MT_SET_DIRECT_ATTRIBUTES,
  payload: {
    directAttributes: Array<any>
  }
}

interface SetMtDirectAttributesDefaultsAction {
  type: typeof MT_SET_DIRECT_ATTRIBUTES_DEFAULT,
  payload: {
    directAttributesDefault: Array<any>
  }
}

export type MtTypes = SetMtSubordinatesAttributesAction
  | SetMtSubordinatesAttributesDefaultsAction
  | SetMtDirectAttributesAction
  | SetMtDirectAttributesDefaultsAction;

