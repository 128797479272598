import {WorkRecord} from "../../../../interfaces/WorkRecord";

export interface TrialStatusStateInterface {
  trialStatus: string
  trialEndDate: Date | null
}

const initialState: TrialStatusStateInterface = {
  trialStatus: '',
  trialEndDate: new Date()
};

export default class TrialStatusState {

  public static generateFromWorkRecord(workRecord?: WorkRecord): TrialStatusStateInterface {
    let state = initialState;
    if (workRecord) {
      state.trialStatus = workRecord.trialStatus;
      state.trialEndDate = workRecord.trialEndDate;
    }
    return state;
  }
}