import {Attribute as AttributeInterface} from '../interfaces/Attribute';
import {Value} from '../interfaces/Value';
import ValueNull from './ValueNull';

export default class Attribute implements AttributeInterface {
  alias: string = '';
  name: string = '';
  value: Value<any> = new ValueNull();
  future: Array<any> = [];
}
