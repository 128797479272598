import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import Select from 'react-select';

import store from '../../../../store/store';
import { dispatchPopupState } from '../../../../store/popup/types';
import { setShouldUpdate } from '../../../../store/workRecord/actions';
import { loaderActions } from '../../../../store/loader/actions';

import dictionaryStore from '../../../../services/dictionary/DictionaryStore';
import wrHelper from '../../../../services/data/WorkRecordHelper';
import { WorkRecord } from '../../../../interfaces/WorkRecord';

import { PopupCloser } from '../../../Popup/PopupCloser';

import ReasonToLeaveState, { ReasonToLeaveStateInterface } from './ReasonToLeaveState';
import { schema } from './ReasonToLeaveFormSchema';

import './reason-to-leave-form.scss';

interface ReasonToLeaveFormProps {
  workRecord?: WorkRecord
}

export const ReasonToLeaveForm: React.FC<ReasonToLeaveFormProps> = (props: ReasonToLeaveFormProps) => {
  const [initialState, setInitialState] = useState<ReasonToLeaveStateInterface | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [reasonToLeaveList, setReasonToLeaveList] = useState<Array<any>>([]);

  const submission = (values: ReasonToLeaveStateInterface) => {
    setIsSaving(true);
    store.dispatch(loaderActions.showLoader());
    const workRecord = props.workRecord;
    if (workRecord) {
      wrHelper.updateReasonToLeave(workRecord, values.reasonToLeave as number)
        .then((data: any) => {
          store.dispatch(setShouldUpdate(true));
          dispatchPopupState('reasonToLeave', false);
        })
        .finally(() => {
          setIsSaving(false);
          store.dispatch(loaderActions.hideLoader());
        });
    }
  }

  useEffect(() => {
    if (initialState == null) {
      setInitialState(ReasonToLeaveState.generateFromWorkRecord(props.workRecord));
    }
    if (!isLoaded) {
      dictionaryStore.getByAlias('reason_to_leave').then((data: any) => {
        if (typeof data !== 'undefined') {
          setReasonToLeaveList(data);
        }
      });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded])

  if (initialState) {
    return (
      <Formik
        initialValues={ initialState }
        validationSchema={ schema }
        onSubmit={values => { submission(values) }}>
        {({ values, setFieldValue }) => (
          <Form className="edb-form form-reason-to-leave">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="reasonToLeave">Set Data</label>
                  <Select
                    id="reasonToLeave"
                    name="reasonToLeave"
                    value={ reasonToLeaveList.filter((item) => item.id === values.reasonToLeave) }
                    options={ reasonToLeaveList }
                    getOptionLabel={ (option) => option.title }
                    getOptionValue={ (option) => option.id }
                    isSearchable={ reasonToLeaveList.length > 10 }
                    isClearable={ false }
                    maxMenuHeight={ 200 }
                    menuPlacement={ 'bottom' }
                    onChange={ (selected) => setFieldValue('reasonToLeave', selected ? selected.id : '') }
                    classNamePrefix={ 'select-field-custom' }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-center form-buttons">
                <PopupCloser popupName="reasonToLeave">
                  <button className="edb-btn edb-btn--secondary" type="reset">
                    Cancel
                  </button>
                </PopupCloser>
                <button type="submit" className="edb-btn edb-btn--primary" disabled={ isSaving }>
                  { isSaving ? 'Saving' : 'Save' }
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  return <></>;
}
