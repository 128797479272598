import React, {FunctionComponent} from 'react';

import {DetailsInterface} from '../../../../interfaces/dashboard/DetailsInterface';
import {TurnoverHorizontalBars} from '../TurnoverHorizontalBars/TurnoverHorizontalBars';
import './turnover-value.scss'

interface Props {
  value: number,
  label: string,
  dateStart: string,
  dateEnd: string,
  data: Array<any>,
  details: DetailsInterface
}

export const TurnoverValue: FunctionComponent<Props> = (props: Props) => {
  const {value, label, dateStart, dateEnd, data, details} = props;

  const convertDate = (dateStart: string, dateEnd: string): string => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateFrom = new Date(dateStart.trim());
    const dateTo = new Date(dateEnd.trim());
    const formattedDateFrom = `${months[dateFrom.getUTCMonth()]} ${dateFrom.getUTCFullYear()}`;
    const formattedDateTo = `${months[dateTo.getUTCMonth()]} ${dateTo.getUTCFullYear()}`;

    return `${formattedDateFrom} - ${formattedDateTo}`;
  }

  return (
    <div className="turnover-value-wrapper">
      <div className="turnover-value-label">{ label }</div>
      <div className="turnover-value-range">{ convertDate(dateStart, dateEnd) }</div>
      <div className="turnover-value">{ value.toFixed(1) }%</div>
      <TurnoverHorizontalBars
        data={ data }
        value={ value }
        details={ details }
      />
    </div>
  );
}
