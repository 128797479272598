import React, {FC, Fragment} from 'react';
import {useSelector} from 'react-redux';
import {GlobalState} from '../store/types';
import {Redirect} from 'react-router-dom';

export const MyPage: FC = () => {

  const myStaff = useSelector((state: GlobalState) => state.staff.myStaff)

  if (myStaff && myStaff.id) {
    return <Redirect to={`/staff/${myStaff.id}/work-records`}/>
  }

  return <Fragment />

}