import React, {FunctionComponent} from 'react';

import './employee-table-settings.scss';
import {DownloadIcon} from "../../Icon/DownloadIcon";
import StaffClient from "../../../services/http/StaffClient";
import DateHelper from "../../../utils/date/DateHelper";
import {toastr} from "react-redux-toastr";
import FileSaver from "file-saver";

interface EmployeeTableExportProps {
  columns: Array<any>;
  data: Array<any>;
  asOfDate: Date;
  setIsLoadingShared: Function;
  csvDataFilePrefix: string;
}

export const EmployeeTableExport: FunctionComponent<EmployeeTableExportProps> = (props: EmployeeTableExportProps) => {
  const { columns, data, asOfDate, setIsLoadingShared, csvDataFilePrefix } = { ...props };

  const isObjEmpty = (obj: {}): boolean => {
    for(var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  const getCsvHeaders = (allAttributes: Array<any>): Array<any> => {
    var visibleAttributes: Array<any> = allAttributes.filter( attribute => attribute.isVisible),
      csvHeaders: Array<any> = [];

    visibleAttributes.forEach(attribute => {
      if (attribute.id !== 'image_url') {
        csvHeaders.push(
          {
            alias: attribute.id,
            name: attribute.Header
          }
        );
      }
    });

    return csvHeaders;
  }

  const convertToCSV = (delimiter: string, headerNames: Array<any>, displayedData: Array<any>): string => {
    let csvString = '', i, j;

    headerNames.map(headerName => {
      csvString += `"${headerName.name}"${delimiter}`;
    });

    for (i = 0; i < displayedData.length; i++) {
      let dataRow = displayedData[i].values;
      csvString += '\n';

      for (j = 0; j < headerNames.length; j++) {
        let tableCellValue = dataRow[headerNames[j].alias] !== undefined ? dataRow[headerNames[j].alias] : '';
        csvString += tableCellValue ? '"' : '';
        if (headerNames[j].alias === 'skill') {
          if (!isObjEmpty(tableCellValue)) {
            tableCellValue.map((value: any) => {
              csvString += value + ', ';
            });
            csvString = csvString.slice(0, -2);
          }
        } else {
          csvString += tableCellValue;
        }

        csvString += tableCellValue ? '"' + delimiter : delimiter;
      }
    }

    return csvString;
  }

  const exportToCSV = () => {
    setIsLoadingShared(true);
    const csvColumns = getCsvHeaders(columns);
    const convertedData = convertToCSV(',', csvColumns, data);

    const filename = csvDataFilePrefix + '-' + DateHelper.getFormatted2Date(asOfDate) + '.csv';
    const dataToWriteInFile = new Blob([convertedData], {type: 'text/csv; charset = utf-8'});

    FileSaver.saveAs(dataToWriteInFile, filename, true);
    setIsLoadingShared(false);
  };

  const downloadLmCsv = () => {
    setIsLoadingShared(true);
    StaffClient.getLmTreeCsv(asOfDate).then((response: any) => {
      if (response) {
        const fileName = 'Star_Lm_Hierarchy_Report_' + DateHelper.getFormatted2Date(asOfDate) + '.' + 'csv';
        const file = new Blob([response], {
          type: 'text/csv'
        });

        FileSaver.saveAs(file, fileName);
        setIsLoadingShared(false);
      }
    }).catch(e => {
      toastr.error('', `Request failed with status: ${e}`);
    });
  };

  return (
    <div className="export">
      <span className="table-settings__control-icon">
        <DownloadIcon />{' '}
        Export to CSV
      </span>
      <div className="export-content">
        <p onClick={ exportToCSV }>Export Table</p>
        <p onClick={ downloadLmCsv }>Export LM Hierarchy</p>
      </div>
    </div>
  );
}
