import { ReportStateI, reportTypes } from './types';

const defaultState: ReportStateI = {
  config: []
}

export const reportReducer = (state: ReportStateI = defaultState, action: any): ReportStateI => {
  switch (action.type) {
    case reportTypes.SET_CONFIG:
      return { ...state, config: action.payload };
    default:
      return state;
  }
}