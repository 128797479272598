import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART_COMMON } from '../../../../constants';

interface Props {
  data: Array<any>,
  overrideLabels: Array<any>,
  details: Array<string>
}

export const ChartBarHorizontal: React.FC<Props> = (props: Props) => {
  const { data, overrideLabels, details } = { ...props };

  let labels = [CHART_COMMON.labels.good, CHART_COMMON.labels.bad];
  if (overrideLabels.length > 0) {
    labels = overrideLabels;
  } else if (data.length === 2 && overrideLabels.length === 0) {
    labels = data;
  }

  let maxScale = 0;
  if (data.length === 2) {
    maxScale = data[0] + data[1];
  }

  const datasets: Array<any> = [
    {
      data: data,
      fill: true,
      borderColor: CHART_COMMON.border.color,
      borderWidth: CHART_COMMON.border.width,
      barThickness: CHART_COMMON.barThickness - 4,
      backgroundColor: [CHART_COMMON.colors.good, CHART_COMMON.colors.bad]
    }
  ];

  const handleHover = (event: any, activeElements: Array<any>) => {
    if (activeElements.length) {
      event.target.style.cursor = 'pointer';
    } else {
      event.target.style.cursor = 'default';
    }
  }

  const handleClick = (event: any, activeElements: Array<any>) => {
    let reportKey = '';
    if (activeElements.length) {
      const activeElement = activeElements[0];
      const index = activeElement._index;
      reportKey = details[index];
    }

    if (reportKey) {
      window.open('/report/' + reportKey, '_blank');
    }
  }

  return (
    <div className="chart-bar-wrapper" style={ { width: '100%' } }>
      <HorizontalBar
        data={{
          labels: labels,
          datasets: datasets
        }}
        options={{
          responsive: true,
          devicePixelRatio: CHART_COMMON.devicePixelRatio,
          maintainAspectRatio: false,
          tooltips: { enabled: false },
          hover: { mode: 'point' },
          scales: {
            xAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: maxScale !== 0 ? maxScale : 100,
                }
              }
            ],
            yAxes: [
              {
                position: 'right',
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: true,
                  fontFamily: CHART_COMMON.labels.fontFamily,
                  fontSize: CHART_COMMON.labels.fontSize,
                  fontColor: CHART_COMMON.labels.fontColor,
                }
              }
            ]
          },
          legend: {
            display: false
          },
          plugins: { datalabels: false },
          onClick: handleClick,
          onHover: handleHover
        }}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
}
