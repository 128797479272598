import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../store/types';
import { dispatchIndexedPopupState } from '../../../store/popup/types';

import DocumentClient from '../../../services/http/DocumentClient';
import PermissionWRService from '../../../services/security/PermissionWRService';

import { ComplianceItemI } from '../../../interfaces/DocumentItem';

import { Spinner } from '../../Spinner/Spinner';
import { DocumentListContext } from '../../WR/WRDocument/WRDocument';

import './document.scss';

type PropsType = {
  popupName: string;
  document: ComplianceItemI;
};

const STATUS_ACCEPT = 1;

export const Document: React.FC<PropsType> = (props: PropsType) => {
  const {popupName, document} = {...props};

  const [urlDocument, setUrlDocument] = useState<string | undefined>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const context = useContext(DocumentListContext);

  const {myStaff} = useSelector((state: GlobalState) => state.staff);

  useEffect(() => {
    DocumentClient.getPdfContent(document.documentId)
      .then((response: any) => {
        if (response) {
          const file = new Blob([response.data], {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          setUrlDocument(fileURL);
        }
      })
      .catch((error) => {
        setUrlDocument(undefined);
      })
      .finally(() => {
        DocumentClient.http.unsetResponseType();
        setIsLoaded(true);
      });
  }, [document]);

  useEffect(() => {
    if (myStaff && document) {
      PermissionWRService.setUser(myStaff);
      if (PermissionWRService.isOwner() && (document.status === 0) && !document.staffId && !document.fullName) {
        setReadOnly(false);
        return;
      }
    }
    setReadOnly(true);
  }, [myStaff, document]);

  const handleClose = () => {
    dispatchIndexedPopupState(popupName, String(document.id), false);
  };

  const handleAccept = () => {
    DocumentClient.sign(document.id, STATUS_ACCEPT)
      .then((response: any) => {
        let data = response.data;
        if (data) {
          context.updateList();
        }
        dispatchIndexedPopupState(popupName, String(document.id), false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="document-content">
        <h3 className="document-name text-center">{document.document}</h3>
        {
          (document.documentLink)
            ? (
              <a
                className="edb-btn edb-btn--primary w-100"
                href={document.documentLink}
                target="_blank">
                Show content from link
              </a>
            )
            : <></>
        }
        {
          (isLoaded)
          ? (urlDocument)
            ? <div className="embed-responsive embed-responsive-16by9">
                <object className="embed-responsive-item" data={ urlDocument } type="application/pdf"></object>
              </div>
            : <></>
          : <Spinner/>
        }
      </div>
      <div className="document-footer">
        {
          !readOnly
          ? <>
              <button className="edb-btn edb-btn--secondary" onClick={ handleClose }>
                Read Later
              </button>
              <button className="edb-btn edb-btn--primary" onClick={ handleAccept }>
                I Accept
              </button>
            </>
          : <button className="edb-btn edb-btn--primary" onClick={ handleClose }>
              Close
            </button>
        }
      </div>
    </>
  );
};