import React, {FunctionComponent, useEffect, useState} from 'react';
import {SorterIcon} from '../../Icon/SorterIcon';
import './report-header-sorter.scss';

interface Props {
  label: string,
  fieldName: string,
  fieldType: string,
  list: Array<any>,
  setList: Function
}

const sortTypes = {
  DEFAULT: 'DEFAULT',
  ACS: 'ACS',
  DESC: 'DESC'
}

export const ReportHeaderSorter: FunctionComponent<Props> = (props: Props) => {
  const {label, fieldName, fieldType, list, setList} = {...props};
  const [sortType, setSortType] = useState<string>(sortTypes.DEFAULT);

  const callSort = (sort: string) => {
    switch (sort) {
      case sortTypes.DEFAULT:
        setSortType(sortTypes.ACS);
        break;
      case sortTypes.ACS:
        setSortType(sortTypes.DESC);
        break;
      case sortTypes.DESC:
        setSortType(sortTypes.DEFAULT);
        break;
    }
  }

  const sort = (list: Array<any>, setList: Function, fieldName: string, fieldType: string, type: string) => {
    const newList = [...list];
    if (type === sortTypes.DEFAULT) {
      return newList;
    }
    newList.sort((wr1, wr2) => {
      if (fieldType === 'string') {
        const wr1S = (wr1[fieldName]) ? wr1[fieldName] : '';
        const wr2S = (wr2[fieldName]) ? wr2[fieldName] : '';
        return  wr1S.localeCompare(wr2S);
      } else if (fieldType === 'date') {
        const wr1T = (wr1[fieldName]) ? wr1[fieldName].getTime() : 0;
        const wr2T = (wr2[fieldName]) ? wr2[fieldName].getTime() : 0;
        if (wr1T > wr2T ) { return 1;}
        if (wr1T < wr2T ) { return -1;}
        return 0;
      } else {
        if (wr1[fieldName] > wr2[fieldName]) { return 1;}
        if (wr1[fieldName] < wr2[fieldName]) { return -1;}
        return 0;
      }
    });
    if (type === sortTypes.DESC) {
      newList.reverse();
    }
    setList(newList);
  }

  useEffect(() => {
    sort(list, setList, fieldName, fieldType, sortType);
  }, [sortType]);

  const getSortClass = (sort: string) => {
    switch (sort) {
      case sortTypes.ACS:
        return 'sort-asc';
      case sortTypes.DESC:
        return 'sort-desc';
      default:
        return 'sort-no';
    }
  }

  return (
    <span className="report-header-sorter" onClick={() => callSort(sortType)}>
      <strong>{label}</strong>
      <span className={`sort ${getSortClass(sortType)}`}>
        <SorterIcon/>
      </span>
    </span>
  );
}