import React from 'react'
import { Value } from '../../../interfaces/Value';

import './staff-attribute.scss';

interface StaffAttributeLinkProps {
  name: string;
  value: Value<string>;
  text?: string;
}

export const StaffAttributeLink: React.FC<StaffAttributeLinkProps> = (props: StaffAttributeLinkProps) => {
  const [name] = React.useState(props.name);
  const [value, setValue] = React.useState(props.value);
  
  React.useEffect(() => {
    setValue(props.value)
  }, [props]);

  return (
    <div className="staff-attribute">
      <div className="staff-attribute__name">
        {name}
      </div>
      <div className="staff-attribute__value">
        {
          (value.value)
            ? (
              <a href={`${value.value}`} target="_blank" rel="noopener noreferrer">
                {props.text ?? value.value}
              </a>
            )
            : (
              <></>
            )
        }
      </div>
    </div>
  );
}
