import React, {FunctionComponent} from 'react';

import './my-team-type.scss';
import {MY_TEAM_TYPES} from "../../../constants/WORK_RECORD";

interface MyTeamTypeProps {
  value: string,
  setter: Function,
  myTeamEmployCount: number,
  directReportsCount: number,
}

const myTeamTypes = [
  {value: MY_TEAM_TYPES.SUBORDINATE, label: MY_TEAM_TYPES.SUBORDINATE_LABEL},
  {value: MY_TEAM_TYPES.DIRECT_TEAM, label: MY_TEAM_TYPES.DIRECT_TEAM_LABEL}
]

export const MyTeamType: FunctionComponent<MyTeamTypeProps> = (props: MyTeamTypeProps) => {
  const { value, setter, myTeamEmployCount, directReportsCount } = { ...props };

  const selectType = (value: string) => {
    setter(value);
  };

  return (
    <div className="my-team-button-container">
      {
        myTeamTypes.map((item) => (
            <div
              className={`my-team-button ${(item.value === value) ? 'active': ''}`}
              key={item.value}
              onClick={() => selectType(item.value)}>
              {item.label} ({item.value === MY_TEAM_TYPES.SUBORDINATE ? myTeamEmployCount: directReportsCount})
            </div>
          )
        )
      }
    </div>
  );
};
