import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {toastr} from 'react-redux-toastr';
import {GlobalState} from '../../../store/types';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {DOCUMENT_ITEM_LIMIT, DocumentListContext} from "../../WR/WRDocument/WRDocument";
import { ComplianceItemI } from "../../../interfaces/DocumentItem";
import client from "../../../services/http/DocumentClient";
import mapper from "../../../mappers/response/DocumentMapper";
import {DocumentItemResponse} from "../../../interfaces/http/document/DocumentItemResponse";
import {DocumentItem} from "../DocumentItem/DocumentItem";

interface DocumentListParams {
  staffId: string
}

type PropsType = {
  start: number,
  setPages: Function,
  setCountItems: Function
}

export const DocumentList: FunctionComponent<PropsType> = (props: PropsType) => {
  const params = useParams<DocumentListParams>();
  const cParams = {
    staffId: Number(params.staffId)
  };

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [items, setItems] = useState<Array<ComplianceItemI>>([]);
  const [itemsSliced, setItemsSliced] = useState<Array<ComplianceItemI>>([])
  const myStaff = useSelector((state: GlobalState) => state.staff.myStaff);

  const context = useContext(DocumentListContext);
  context.updateList = () => {setIsLoaded(false);}

  useEffect(() => {
    if (!isLoaded) {
      const itemsPromise = myStaff.id === cParams.staffId ?
        client.getMyDocumentItems() : client.getDocumentItems(cParams.staffId);

      itemsPromise.then((data: any) => {
        const items: Array<ComplianceItemI> = [];
        (data as Array<DocumentItemResponse>).forEach((item: DocumentItemResponse) => {
          items.push(mapper.creatDocumentItemFromResponse(item));
        });
        setItems(items);
        props.setCountItems(items.length);
        const pages = Math.ceil(items.length / DOCUMENT_ITEM_LIMIT);
        props.setPages(pages);
        setIsLoaded(true);
      }).catch((error: any) => {
        toastr.error('', 'This data is unavailable');
        setIsLoaded(true);
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (items.length) {
      const part = items.slice(props.start, props.start + DOCUMENT_ITEM_LIMIT);
      setItemsSliced(part);
    }
  }, [items, props.start]);

  if (isLoaded) {
    return <>
      {(itemsSliced.length)
          ? itemsSliced.map((item: ComplianceItemI) =>
              <DocumentItem documentItem={item} key={item.id}/>
          )
          : <tr><td colSpan={4}><span>Documents/Policies will be shown here.</span></td></tr>
      }
    </>;
  }

  return <tr>
      <td colSpan={4}><span>Documents/Policies will be shown here.</span></td>
    </tr>;
}
