import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { DateRangePickerField } from '../../../Forms/DateRangePickerField';
import { getDictionaryCountries } from '../../../../store/dictionary/actions';
import { TypeaheadField } from '../../../Forms/TypeaheadField';

import {
  HR_DASHBOARD_CONTENT_TABS,
  HR_DASHBOARD_LEAVERS_EMPLOYMENT_DURATION,
  HR_DASHBOARD_LEAVERS_TURNOVER_TYPES
} from '../../../../constants';

import './leavers-control.scss';

interface Props {
  activeTab: string,
  country: string,
  setCountry: Function,
  turnover: string,
  setTurnover: Function,
  employmentDuration: string,
  setEmploymentDuration: Function,
  setPeriodRange: Function,
  setFromDate: Function,
  setToDate: Function
}

export const LeaversControl: React.FC<Props> = (props: Props) => {
  const {
    activeTab,
    country,
    setCountry,
    turnover,
    setTurnover,
    employmentDuration,
    setEmploymentDuration,
    setPeriodRange,
    setFromDate,
    setToDate
  } = {...props};
  const [countryList, setCountryList] = useState([]);

  const turnoverTypes: any = HR_DASHBOARD_LEAVERS_TURNOVER_TYPES;
  const employmentDurations: any = HR_DASHBOARD_LEAVERS_EMPLOYMENT_DURATION;

  const dispatch = useDispatch();
  const { countries } = useSelector((store: any) => store.dictionary);

  useEffect(() => {
    dispatch(getDictionaryCountries());
  }, []);

  useEffect(() => {
    if (countries?.length > 0) {
      setCountryList(countries);
    }
  }, [countries]);

  return (
    <div className="leavers-control">
      <div className="row">
        <div className="col-auto mr-auto">
          <div className="filter-group">
            <DateRangePickerField
              setPeriodRange={setPeriodRange}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
            {
              (activeTab !== HR_DASHBOARD_CONTENT_TABS.locations.key) && (
                <div className="country-wrapper">
                  <span className="custom-label">Country</span>
                  <Formik initialValues={{ country: country }} onSubmit={() => {}}>
                    {
                      ({ values, setFieldValue }) => (
                        <Form className="edb-form">
                          <TypeaheadField
                            className="form-control"
                            id="country"
                            name="country"
                            labelKey="name"
                            multiple={ false }
                            options={ countryList }
                            onChange={ (selected: any) => {
                              if (selected && selected[0]) {
                                setFieldValue('country', selected[0]);
                                setCountry(selected[0].id);
                              } else {
                                setFieldValue('country', '');
                                setCountry('');
                              }
                
                            } }
                            clearButton={ !!values.country }
                          />
                        </Form>
                      )
                    }
                  </Formik>
                </div>
              )
            }
            <div>
              <span className="custom-label">Turnover</span>
              <span className="buttons-list">
                {Object.keys(turnoverTypes).map((key: string, i: number) => {
                  return (
                    <span
                      key={i}
                      className={`button btn-small${turnoverTypes[key].key === turnover ? ' active' : ''}`}
                      onClick={() => setTurnover(turnoverTypes[key].key)}>
                      {turnoverTypes[key].label}
                    </span>
                  )
                })}
              </span>
            </div>
            <div>
              <span className="custom-label">Employment duration</span>
              <span className="buttons-list">
                {Object.keys(employmentDurations).map((key: string, i: number) => {
                  return (
                    <span
                      key={i}
                      className={`button no-capitalize${employmentDurations[key].key === employmentDuration ? ' active' : ''}`}
                      onClick={() => setEmploymentDuration(employmentDurations[key].key)}>
                      {employmentDurations[key].label}
                    </span>
                  )
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
