import { CVInterface } from '../../interfaces/CVInterface';

export const cvTypes = Object.freeze({
  CV_SET_LOADING: 'CV_SET_LOADING',
  CV_SET_DATA: 'CV_SET_DATA',
  CV_SET_SUCCESS_PENDING: 'CV_SET_SUCCESS_PENDING',
  CV_SET_SUCCESS_APPROVED: 'CV_SET_SUCCESS_APPROVED',
  CV_SET_NOT_FOUND: 'CV_SET_NOT_FOUND',
  CV_SET_DENY: 'CV_SET_DENY',
  CV_SET_STATUS: 'CV_SET_STATUS',
  CV_SET_REQUEST_CLEAR: 'CV_SET_REQUEST_CLEAR',
  CV_SET_IS_PENDING: 'CV_SET_IS_PENDING',
  CV_SET_IS_APPROVED: 'CV_SET_IS_APPROVED',
  CV_SET_ACTION: 'CV_SET_ACTION',
  CV_SET_HAS_LATEST_APPROVED_VERSION: 'CV_SET_HAS_LATEST_APPROVED_VERSION',
  CV_SET_HAS_LATEST_PENDING_VERSION: 'CV_SET_HAS_LATEST_PENDING_VERSION'
});

export const CV_STATUS_PENDING = 'pending';
export const CV_STATUS_APPROVED = 'approved';

export type CVStatusType = typeof CV_STATUS_PENDING | typeof CV_STATUS_APPROVED;

export interface CVState {
  data: CVInterface | null,
  hasAccess: boolean,
  isPending: boolean,
  isApproved: boolean,
  hasLatestApprovedVersion: boolean,
  hasLatestPendingVersion: boolean,
  requestLatestPendingVersion: boolean
}

export interface SetLoadingAction {
  type: typeof cvTypes.CV_SET_LOADING
}

export interface SetDataAction {
  type: typeof cvTypes.CV_SET_DATA
  payload: CVInterface
}

export interface SetSuccessPendingAction {
  type: typeof cvTypes.CV_SET_SUCCESS_PENDING
  payload: CVInterface
}

export interface SetSuccessApprovedAction {
  type: typeof cvTypes.CV_SET_SUCCESS_APPROVED
  payload: CVInterface
}

export interface SetDenyAction {
  type: typeof cvTypes.CV_SET_DENY
}

export interface SetNotFoundAction {
  type: typeof cvTypes.CV_SET_NOT_FOUND
}

export interface SetIsPendingAction {
  type: typeof cvTypes.CV_SET_IS_PENDING
}

export interface SetIsApprovedAction {
  type: typeof cvTypes.CV_SET_IS_APPROVED
}

export interface SetHasLatestApprovedVersion {
  type: typeof cvTypes.CV_SET_HAS_LATEST_APPROVED_VERSION
  payload: boolean
}

export interface SetHasLatestPendingVersion {
  type: typeof cvTypes.CV_SET_HAS_LATEST_PENDING_VERSION
  payload: boolean
}

export type CVTypes =
  SetLoadingAction |
  SetDataAction |
  SetSuccessPendingAction |
  SetSuccessApprovedAction |
  SetDenyAction |
  SetNotFoundAction |
  SetIsPendingAction |
  SetIsApprovedAction |
  SetHasLatestApprovedVersion |
  SetHasLatestPendingVersion;