import {
  AUTHORIZATION_CHANGE_IS_CAPTCHA_NEEDED,
  AUTHORIZATION_CHANGE_IS_LOGGED_IN,
  AUTHORIZATION_CHANGE_IS_TOKEN_VALID,
  AuthorizationTypes
} from './types';
import AuthClient from '../../services/http/AuthClient';
import {toastr} from 'react-redux-toastr';
import {Authorization} from '../../services/authorization/Authorization';
import {loaderActions} from '../loader/actions';

export function changeIsLoggedIn(isLoggedIn: boolean): AuthorizationTypes {
  return {
    type: AUTHORIZATION_CHANGE_IS_LOGGED_IN,
    payload: isLoggedIn
  }
}

export function changeIsTokenValid(isTokenValid: boolean): AuthorizationTypes {
  return {
    type: AUTHORIZATION_CHANGE_IS_TOKEN_VALID,
    payload: isTokenValid
  }
}

export function changeIsCaptchaNeeded(isCaptchaNeeded: boolean): AuthorizationTypes {
  return {
    type: AUTHORIZATION_CHANGE_IS_CAPTCHA_NEEDED,
    payload: isCaptchaNeeded
  }
}

export function tokenValidateThunk(token: any) {
  return async (dispatch: any) => {
    dispatch(loaderActions.showLoader());
    try {
      const { data }: any = await AuthClient.tokenValidate(token);
      if (data?.token) {
        AuthClient.setToken(data.token);
        Authorization.saveToken(data.token);
        dispatch(changeIsLoggedIn(true))
      }
    } catch (error: any) {
      toastr.error('', `Can't sign in: ${error?.message}`);
      AuthClient.unsetToken();
      Authorization.saveToken('');
      dispatch(changeIsLoggedIn(false));
    } finally {
      dispatch(loaderActions.hideLoader());
    }
  }
}