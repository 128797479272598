import React, {FunctionComponent, HTMLAttributes} from 'react';
import {useField} from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './phone.scss';

interface PhoneFieldProps {
  id: string;
  name: string;
  value: string;
  disabled?: boolean;
}

export const PhoneField: FunctionComponent<PhoneFieldProps & HTMLAttributes<HTMLDivElement>> =
  (props: PhoneFieldProps & HTMLAttributes<HTMLDivElement>) => {
    const {id, name, placeholder, value, disabled} = {...props};
    const [, , {setValue}] = useField(props as any);

    return (
      <PhoneInput
        containerClass="phone-field"
        inputProps={{id: id, name: name, placeholder: placeholder}}
        disabled={disabled}
        value={value}
        onChange={value => {
          setValue(value)
        }}
        onBlur={(event, data) => {
          if (value && !event.target.value) {
            setValue(event.target.value);
          }
        }}
      />
    );
  };