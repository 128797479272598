import React, {FunctionComponent} from 'react';
import {Status} from '../../WR/Common/Status';

interface StatusCellProps {
  cell: {
    value: string;
  };
}

export const StatusCell: FunctionComponent<StatusCellProps> = (props: StatusCellProps) => {
  const cell = props.cell;
  const value:string = cell.value;

  return (
    <Status value={value}/>
  )
}
