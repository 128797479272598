import {Value as ValueInterface} from '../interfaces/Value';

export default class ValueNull implements ValueInterface<null> {
  valueId: number | null = null;
  value: null = null;

  getValue(): null {
    return null;
  }
}
