import React, {FunctionComponent, useEffect, useRef} from 'react';
import layoutGalaxy from './../../../../utils/d3/GalaxyLayout';
import useResizeHeightForChartHook from "../../../../hooks/resizeHeightForChartHook";
import './unit-tree-chart.scss';
import iconPeople from './../../../../assets/images/org-tree/icon-people.svg';
import iconPeopleWhite from './../../../../assets/images/org-tree/icon-people-white.svg';
import iconTree from './../../../../assets/images/org-tree/icon-tree.svg';
import iconTreeWhite from './../../../../assets/images/org-tree/icon-tree-white.svg';

const d3 = require('d3v3');

interface UnitTreeChartProps {
  tree: any;
  setSelectedUnit: Function;
  setShowUnitPreview: Function;
}

const TYPE_COMPANY = 'Company';

export const UnitTreeChart: FunctionComponent<UnitTreeChartProps> = (props: UnitTreeChartProps) => {
  const {tree, setSelectedUnit, setShowUnitPreview} = {...props};

  const dimensions = useResizeHeightForChartHook();

  const svgRef = useRef(null);

  useEffect(() => {
    let i: number = 0;
    let prevActiveNode: any;

    const root = tree[0];
    const zoom = d3.behavior.zoom()
      .scaleExtent([0.2, 3])
      .on('zoom', redraw);
    const diagonal = d3.svg.diagonal.radial();
    const galaxy = layoutGalaxy()
      .size([dimensions.width, dimensions.height])
      .spread(10)
      .sort(false)
      .value(function (d: any) {
        return d.depth + 1;
      });
    const svg = d3.select(svgRef.current).call(zoom);

    root.x0 = 0;
    root.y0 = 0;

    setInitialStates(root);

    root.children.forEach(collapse);

    update(root);

    function setInitialStates(root: any) {
      const childrenList = root.children || root._children;
      root.state = {};
      root.state.active = false;
      childrenList.forEach((node: any) => {
        setInitialStates(node);
      });
    }

    function update(source: any) {
      const nodes = galaxy.nodes(root);
      const links = galaxy.links(nodes);
      const node = svg.select('g')
        .selectAll('g.node')
        .data(nodes, function(d: any) {
          return d.id || (d.id = ++i);
        });

      const nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', () => `translate(${source.x0},${source.y0})`)
        .on('click', clickNode);
      nodeEnter.append('circle')
        .attr('r', getRadius)
        .attr('stroke', '#f2f2f2')
        .attr('stroke-width', 1)
        .style('fill', getColorBackground);
      nodeEnter.append('foreignObject')
        .attr('x', function (d: any) {
          let r = getRadius(d);
          return -r;
        })
        .attr('y', function (d: any) {
          let r = getRadius(d);
          return -r;
        })
        .attr('width', function (d: any) {
          let r = getRadius(d);
          return 2 * r;
        })
        .attr('height', function (d: any) {
          let r = getRadius(d);
          return 2 * r;
        })
        .append('xhtml:body')
        .style('color', colorText)
        .style('font-size', getFontSize);
      nodeEnter.selectAll('body')
        .insert('div')
        .attr('class', 'block-people');
      nodeEnter.selectAll('div.block-people')
        .insert('img')
        .attr('src', getIconPeople);
      nodeEnter.selectAll('div.block-people')
        .insert('span')
        .text(function(d: any) {
          return d.total_count;
        });
      nodeEnter.selectAll('body')
        .insert('span')
        .attr('class', 'name')
        .text((d: any) => d.full_name ? d.full_name : d.name);
      nodeEnter.selectAll('body')
        .insert('div')
        .attr('class', 'block-tree');
      nodeEnter.selectAll('div.block-tree')
        .insert('img')
        .attr('src', getIconTree)
        .style('display', function (d: any) {
          return d.children
            ? (d.children.length > 0 ? 'block' : 'none')
            : d._children
              ? (d._children.length > 0 ? 'block' : 'none')
              : 'none';
        });
      nodeEnter.selectAll('div.block-tree')
        .insert('span')
        .text(function(d: any) {
          return d.children
            ? (d.children.length > 0 ? d.children.length : '')
            : d._children
              ? (d._children.length > 0 ? d._children.length : '')
              : '';
        });

      const nodeUpdate = node.transition()
        .attr('transform', (d: any) => `translate(${d.x},${d.y})`);
      nodeUpdate.select('circle')
        .attr('r', getRadius)
        .attr('stroke', '#f2f2f2')
        .attr('stroke-width', 1)
        .style('fill', getColorBackground);
      nodeUpdate.selectAll('text')
        .style('fill-opacity', 1);

      const nodeExit = node.exit().transition()
        .attr('transform', function () {
          return `translate(${source.x},${source.y})`;
        })
        .remove();
      nodeExit.select('circle')
        .attr('r', getRadius)
        .attr('stroke', '#f2f2f2')
        .attr('stroke-width', 1);
      nodeExit.selectAll('text');

      const link = svg.select('g')
        .selectAll('.link')
        .data(links);
      link.enter()
        .insert('line', '.node')
        .attr('class', 'link')
        .style('stroke-dasharray', ('3, 3'))
        .call(getLine);
      link.transition().call(getLine);
      link.exit().transition().remove();

      nodes.forEach(function (d: any) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    function clickNode(d: any) {
      if (d.type !== TYPE_COMPANY) {
        setSelectedUnit(d);
        setShowUnitPreview(true);
      } else {
        setSelectedUnit([]);
        setShowUnitPreview(false);
      }
      d3.selectAll('.bordered').classed('bordered', false);
      // @ts-ignore
      d3.select(this.children[0]).classed('bordered', true);
      click(d);
    }

    function click(d: any) {
      if (prevActiveNode) {
        prevActiveNode.state.active = false;
        update(prevActiveNode);
      }
      prevActiveNode = d;
      d.state.active = true;
      highlightThePath(d);
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      update(d);
      centerNode(d);
    }

    function collapse(d: any) {
      if (d.children) {
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
      }
    }

    function centerNode(source: any) {
      const previewElement = document.getElementsByClassName('unit-tree-preview')[0] as HTMLElement;
      const previewElementWidth = previewElement?.clientWidth || 0;
      let x, y;
      const scale = zoom.scale();
      x = -source.x0;
      y = -source.y0;
      x = x * scale + (dimensions.width - previewElementWidth) / 2;
      y = y * scale + dimensions.height / 2;
      d3.select('g').transition()
        .attr('transform', `translate(${x},${y})scale(${scale})`);
      zoom.scale(scale);
      zoom.translate([x, y]);
    }

    function getLine(l: any) {
      l.attr('x1', (d: any) => d.source.x);
      l.attr('y1', (d: any) => d.source.y);
      l.attr('x2', (d: any) => d.target.x);
      l.attr('y2', (d: any) => d.target.y);
    }

    function colorText(d: any) {
      return (d.level === 1 || d.level === 2) ? '#ffffff' : '#313942';
    }

    function getIconPeople(d: any) {
      return (d.level === 1 || d.level === 2) ? iconPeopleWhite : iconPeople;
    }

    function getIconTree(d: any) {
      return (d.level === 1 || d.level === 2) ? iconTreeWhite : iconTree;
    }

    function getColorBackground(d: any) {
      switch (d.level) {
        case 1: return '#000';
        case 2: return '#313942';
        case 3: return '#b8c7d5';
        default: return '#ffffff';
      }
    }

    function getFontSize(d: any) {
      return (d.level <= 3) ? '14px' : '12px';
    }

    function getRadius(d: any) {
      return (d.level <= 3) ? 65 : 52;
    }

    function redraw() {
      svg.select('g').attr('transform', `translate(${d3.event.translate}) scale(${d3.event.scale})`);
    }

    function highlightThePath(d: any) {
      let ancestors: any = [],
        parent = d,
        links: any;
      while (parent) {
        ancestors.push({
          name: parent.name,
          type: parent.type
        });
        parent = parent.parent;
      }
      links = d3.selectAll('.link');
      links.attr('class', 'link').style('stroke-dasharray', ('3, 3'));
      links.each((p: any, i: number) => {
        let matchedLinks = ancestors.some( (ancestor: any) => {
          return ancestor.name === p.target.name && ancestor.type === p.target.type;
        });
        if (matchedLinks) {
          d3.select(links[0][i])
            .style('stroke-dasharray', '0')
            .attr('class', 'link link--active');
        }
      });
    }
  }, [dimensions]);

  return (
      <div className="unit-tree-chart" style={{height: dimensions.height}}>
        <svg height={dimensions.height} ref={svgRef}><g></g></svg>
      </div>
  );
};