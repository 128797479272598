import React from 'react';
import { VersionComponent } from "../components/Version/Version";

export const VersionPage: React.FC = () => {
  return (
    <div className="p-3">
      <VersionComponent />
    </div>
  )
}
