import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { ErrorMessage, Field, FormikErrors, FormikTouched, FormikValues } from 'formik';

import WorkRecordClient from '../../../../services/http/WorkRecordClient';
import dictionaryStore from '../../../../services/dictionary/DictionaryStore';

import { FeedbackType } from '../../../../interfaces/entity/FeedbackType';
import { WorkRecord } from '../../../../interfaces/WorkRecord';
import { Staff } from '../../../../interfaces/Staff';
import { notSpecifiedSelectValue } from '../../../../interfaces/form/SelectValueInterface';

import FeedbackItemState from './FeedbackItemState';

import { GlobalState } from '../../../../store/types';
import { STAFF_STATE, WORK_RECORD_STATE } from '../../../../store/reducers';

import { DateField } from '../../../Forms/DateField';

import { ATTRIBUTE_NAMES } from '../../../../constants';

const labels = ATTRIBUTE_NAMES['feedbackItem'];

interface FeedbackItemFormFieldsProps {
  values: any;
  feedback: any;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: Function;
  setFieldValue: Function;
}

export const FeedbackItemFormFields: React.FC<FeedbackItemFormFieldsProps> = (props: FeedbackItemFormFieldsProps) => {
  const { values, setFieldValue } = { ...props };
  
  const [isLoaded, setIsLoaded] = useState(false);
  const [types, setTypes] = useState<Array<any>>([]);
  const [typesMap, setTypesMap] = useState<any>([]);
  const [activeStaff, setActiveStaff] = useState([]);
  const [projects, setProjects] = useState<Array<any>>([]);

  useEffect(() => {
    if (!isLoaded) {
      Promise
        .all([
          dictionaryStore.getByAlias('feedback_type'),
          dictionaryStore.getActiveStaff(),
          dictionaryStore.getProjects()
        ])
        .then((results: Array<any>) => {
          if (results[0]) {
            setTypes(results[0].map((item: FeedbackType) => ({ id: item.alias, name: item.title })));
            setTypesMap(results[0].reduce((acc: any, el: any) => {
              acc[el.alias] = el;
              return acc;
            }, {}));
          }
          let staff = results[1].map((staff: any) => ({ id: staff.id, name: staff.full_name }));
          staff.unshift(notSpecifiedSelectValue);
          setActiveStaff(staff);
          setProjects(results[2]);
          setIsLoaded(true);
        });
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  const workRecord: WorkRecord = useSelector((state: GlobalState) => state[WORK_RECORD_STATE].workRecord);
  const myStaff: Staff = useSelector((state: GlobalState) => state[STAFF_STATE].myStaff);

  if (isLoaded) {
    return (
      <>
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="type">{ labels['type'] }</label>
              <Select
                id="type"
                name="type"
                value={ values.type }
                options={ types }
                getOptionLabel={ (option) => option.name }
                getOptionValue={ (option) => option.id }
                isSearchable={ false }
                maxMenuHeight={ 200 }
                onChange={ (selected) => {
                  setFieldValue('type', selected);
                  const value = selected ? selected.id: '';
                  const reviewer = FeedbackItemState.getReviewerByFeedbackType(value, workRecord, myStaff);
                  setFieldValue('reviewer', reviewer);
                } }
                classNamePrefix={ 'select-field-custom' }
                className={ props.errors.type && props.touched.type ? 'invalid-field' : '' }
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="reviewer">{ labels['reviewer'] }</label>
              <Select
                id="reviewer"
                name="reviewer"
                value={ values.reviewer }
                options={ activeStaff }
                getOptionLabel={ (option) => option.name }
                getOptionValue={ (option) => option.id }
                maxMenuHeight={ 200 }
                onChange={ (selected) => setFieldValue('reviewer', selected) }
                classNamePrefix={ 'select-field-custom' }
                className={ props.errors.reviewer && props.touched.reviewer ? 'invalid-field' : '' }
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="periodStart">{labels['periodStart']}</label>
              <DateField
                autoComplete='off'
                id="periodStart"
                name="periodStart"
                className={`form-control ${props.errors.periodStart && props.touched.periodStart ? 'invalid-field' : ''}`}
                onChange={() => {
                }}/>
              <ErrorMessage name="periodStart">{msg => <div className="text-danger">{msg}</div>}</ErrorMessage>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="periodEnd">{labels['periodEnd']}</label>
              <DateField
                autoComplete='off'
                id="periodEnd"
                name="periodEnd"
                className={`form-control ${props.errors.periodEnd && props.touched.periodEnd ? 'invalid-field' : ''}`}
                onChange={() => {
                }}/>
              <ErrorMessage name="periodEnd">{msg => <div className="text-danger">{msg}</div>}</ErrorMessage>
            </div>
          </div>
        </div>
        {
          (values.type && typesMap[values.type.id] && typesMap[values.type.id].configuration?.isProject)
            ? (
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="project">{ labels['project'] }</label>
                    <Select
                      id="project"
                      name="project"
                      value={ values.project }
                      options={ projects }
                      getOptionLabel={ (option) => option.name }
                      getOptionValue={ (option) => option.id }
                      maxMenuHeight={ 200 }
                      onChange={ (selected) => {
                        setFieldValue('project', selected);
                        if (selected?.pm_wr_id) {
                          WorkRecordClient.getProjectManager(selected.pm_wr_id).then((response: any) => {
                            const pm = activeStaff.filter((pm: any) => pm.id === response.staff_id)[0];
                            setFieldValue('projectManager', pm ? pm : notSpecifiedSelectValue);
                          });
                        }
                      } }
                      classNamePrefix={ 'select-field-custom' }
                    />
                    <ErrorMessage name="project" />
                  </div>
                </div>
                <div className="col-3 offset-3">
                  <div className="form-group">
                    <label htmlFor="projectManager">{ labels['projectManager'] }</label>
                    <Select
                      id="projectManager"
                      name="projectManager"
                      value={ values.projectManager }
                      options={ activeStaff }
                      getOptionLabel={ (option) => option.name }
                      getOptionValue={ (option) => option.id }
                      maxMenuHeight={ 200 }
                      onChange={ (selected) => setFieldValue('projectManager', selected) }
                      classNamePrefix={ 'select-field-custom' }
                    />
                    <ErrorMessage name="projectManager" />
                  </div>
                </div>
              </div>
            )
            : <></>
        }
        <div className="row">
          <div className="col">
            <div className="form-group">
              <Field
                className="form-control"
                as="textarea"
                id="value"
                name="value"/>
              <ErrorMessage name="value"/>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="spinner__centered-wrapper">
      <div className="spinner__transform-wrapper">
        <div className="spinner__outer">
          <div className="spinner__inner">
            <div className="spinner__left-half">
              <div className="spinner__circle--left"></div>
            </div>
            <div className="spinner__right-half">
              <div className="spinner__circle--right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
