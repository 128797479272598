import { toastr } from "react-redux-toastr";

import client, { AuthClient } from '../../services/http/AuthClient';

import store from './../../store/store';
import { changeIsCaptchaNeeded, changeIsLoggedIn } from '../../store/authorization/actions';
import { AUTHORIZATION_TOKEN_STATE } from '../../store/reducers';
import { setMyStaff } from '../../store/staff/actions';

import { Credentials } from '../../interfaces/authorization/Credentials';
import { AdaptedCredentials } from '../../interfaces/authorization/AdaptedCredentials';

const TOKEN_KEY = 'jwt-token';

export class Authorization {
  public client: AuthClient;

  constructor(client: AuthClient) {
    this.client = client;
  }

  public checkStatus() {
    let needToReAuthorize: boolean;
    const currentToken = Authorization.getToken();
    const isTokenValid = store.getState()[AUTHORIZATION_TOKEN_STATE].isTokenValid;
    if (currentToken && isTokenValid) {
      store.dispatch(changeIsLoggedIn(true));
      this.client.setToken(currentToken);
      needToReAuthorize = false;
    } else {
      Authorization.saveToken('');
      needToReAuthorize = true;
    }

    return needToReAuthorize;
  }

  public login(credentials: Credentials) {
    if (!this.checkStatus()) {
      return true;
    }

    const adaptedCredentials: AdaptedCredentials = {
      login: credentials.username,
      password: credentials.password,
      keepSigned: credentials.keepSigned,
      captcha: credentials.captcha
    }

    store.dispatch(changeIsCaptchaNeeded(false))

    this.client.login(adaptedCredentials)
      .then((token) => {
        if (token) {
          this.client.setToken(token);
          Authorization.saveToken(token);
          store.dispatch(changeIsLoggedIn(true));
        } else {
          toastr.error('', `Can't sign in:`);
        }
      })
      .catch(e => {
        if (e.data.captcha) {
          store.dispatch(changeIsCaptchaNeeded(true))
        }
        toastr.error('', `Can't sign in: ${e.data.message}`);
      });

    return true;
  }

  public logout() {
    this.client.logout()
      .then((data) => {
        this.client.unsetToken();
      })
      .catch(e => {
        toastr.error('', `Can't sign out: ${e?.data?.message}`);
      });

    Authorization.saveToken('');
    store.dispatch(changeIsLoggedIn(false));
    store.dispatch(setMyStaff(null));
  }

  public static saveToken(token: string) {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(TOKEN_KEY, token);
    }
  }

  public static getToken():string {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(TOKEN_KEY) as string;
    }

    return '';
  }
}

export default new Authorization(client);