import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../store/types';
import { LOADER_STATE } from '../../store/reducers';

import './spinner.scss';

export const Spinner: FunctionComponent = () => {
  const { isShown } = useSelector((state: GlobalState) => state[LOADER_STATE]);
  
  if (isShown) {
    return (
      <div className="spinner__centered-wrapper">
        <div className="spinner__transform-wrapper">
          <div className="spinner__outer">
            <div className="spinner__inner">
              <div className="spinner__left-half">
                <div className="spinner__circle--left"></div>
              </div>
              <div className="spinner__right-half">
                <div className="spinner__circle--right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
