import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../store/types';

import WorkRecord from '../../../models/WorkRecord';
import { WorkRecord as WorkRecordInterface } from '../../../interfaces/WorkRecord';
import PermissionWRService, { CV_PERMISSION_CREATE } from '../../../services/security/PermissionWRService';

import { CvForm, MODE_CREATE } from '../../Cv/Forms/CvForm';

import { COMMON } from '../../../constants';

type Params = {
  staffId: string,
  wrId: string
}

export const WRCvCreate: React.FC = () => {
  const params = useParams<Params>();
  const cParams = {
    wrId: Number(params.wrId),
    staffId: Number(params.staffId)
  };

  const { isPending, isApproved, data } = useSelector((state: GlobalState) => state.cv);

  const { staff, myStaff } = useSelector((state: GlobalState) => state.staff);
  PermissionWRService.setUser(myStaff);

  const [workRecord, setWorkRecord] = useState<WorkRecordInterface | null>(
    useSelector((state: GlobalState) => state.workRecord.workRecord)
  );
  
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (staff && !workRecord) {
      let wr: WorkRecord = staff.workRecords.filter((wr: WorkRecordInterface) => wr.id === cParams.wrId)[0];
      setWorkRecord(wr);
    }
  }, [cParams.wrId, cParams.staffId, staff]);

  const history = useHistory();

  useEffect(() => {
    if (data || (!data && isPending && isApproved && workRecord && !PermissionWRService.isGrantedCv(CV_PERMISSION_CREATE, workRecord))) {
      history.push(`/staff/${cParams.staffId}/work-records/${cParams.wrId}/cv/show`);
    }
  }, [isPending, isApproved, data, workRecord]);

  return (
    <>
      <div className="work-record-cv__body">
        <div className="row no-gutters">
          <div className="col-12 p-3">
            <div className="position-relative bg-white">
              <div className="cv-terms">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16">
                  <path fill="#FE8D08" fillRule="nonzero"
                        d="M9.5.506l8.741 14.985H.759L9.5.506zm0 1.985l-7 12h14l-7-12zM9 5h1v6H9V5zm0 7h1v1H9v-1z"/>
                </svg>
                <span>Please make sure that you’ve read and agree to <a href={ COMMON.LINKS.WIKI.CV_TERMS } target="_blank" rel="noreferrer">CV Creating Terms.</a></span>
              </div>
              <CvForm
                cv={ null }
                staffId={ cParams.staffId }
                wrId={ cParams.wrId }
                staff={ staff }
                workRecord={ workRecord }
                cvListShow={ true }
                setIsSaving={ setIsSaving }
                mode={ MODE_CREATE }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="work-record-cv__footer">
        <div className="row no-gutters">
          <div className="col px-4">
            <div className="d-flex flex-row justify-content-end align-items-center">
              <section>
                <button type="submit" form="form-cv" className="edb-btn edb-btn--primary" disabled={ isSaving }>
                  Save
                </button>
                {/*<button className="edb-btn edb-btn--transparent ml-2" onClick={(e) => cancel(e)}>*/}
                {/*  Cancel*/}
                {/*</button>*/}
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
