export const LOCATION_TYPE = 'location';
export const ORG_STRUCTURE_TYPE = 'orgStructure';
export const DEPARTMENT_TYPE = 'department';
export const UNIT_TYPE = 'unit';

type SettingsType = typeof LOCATION_TYPE | typeof ORG_STRUCTURE_TYPE | typeof DEPARTMENT_TYPE | typeof UNIT_TYPE;

export interface HCSnapshotSettings {
  id: number,
  parentId: number | null,
  name: string,
  expand: boolean,
  type: SettingsType
}

export interface HCSnapshotLocationSettings extends HCSnapshotSettings {
  locationId?: number
}

export interface HCSnapshotOrgStructureSettings extends HCSnapshotSettings {
  orgStructureId?: number
}