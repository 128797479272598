import React, {Fragment, FunctionComponent, useContext, useEffect, useState} from 'react';

import {Skill, Skill as SkillInterface} from '../../../../interfaces/entity/Skill';
import {SkillEditFormContext, SkillEditFormContextInterface} from './SkillEditForm';

import './skill-edit-wrapper.scss';


export const SkillSidebar: FunctionComponent = () => {
  const {skills, setFilteredSkills, formSkills} = useContext<SkillEditFormContextInterface>(SkillEditFormContext);
  const [filters, setFilters] = useState<Array<{ skill: SkillInterface, amount: number }>>([]);

  const reset = () => {
    setFilteredSkills(skills);
  }

  const filter = (id: number) => {
    const filtered = skills.filter((skill: SkillInterface) =>
      (skill.id === id || skill.parents.indexOf(id) !== -1)
    );
    setFilteredSkills(filtered);
  }

  useEffect(() => {
    const f: Array<{ skill: SkillInterface, amount: number }> = [];
    skills.forEach((skill: Skill) => {
      if (skill.hierarchyLevel === 1) {
        const amount = formSkills.filter(
          (s: SkillInterface) => s.parents.indexOf(skill.id) !== -1 || s.id === skill.id
        ).length
        f.push({skill: skill, amount: amount});
      }
    });
    setFilters(f);
  }, [formSkills]);

  if (filters.length) {
    return (
      <Fragment>
        <div className="filter" onClick={() => reset()}>
          All <span className="filter__amount">{formSkills.length}</span>
        </div>
        {filters.map((item: { skill: SkillInterface, amount: number }) => (
          <div key={`filter${item.skill.id}`} className="filter" onClick={() => filter(item.skill.id)}>
            {item.skill.originalName}
            {(item.amount) ? <span className="filter__amount">{item.amount}</span> : ''}
          </div>
        ))}
      </Fragment>
    );
  }

  return <Fragment/>;
}
