import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

// Services
import HRDashboardClient from '../../../services/http/HRDashboardClient';

// Store
import store from '../../../store/store';
import { setOverlayIsVisible } from '../../../store/overlay/actions';
import { loaderActions } from '../../../store/loader/actions';

// Components
import { TenureFilter } from './TenureFilter';
import { TenureList } from './TenureList';
import { NoPermissions } from '../../Security/NoPermissions/NoPermissions';

import { HR_DASHBOARD_TENURE_FILTER_TYPES } from '../../../constants';

// Styles
import './tenure.scss'

const pageSize = 50;

export const Tenure: FunctionComponent = () => {
  const [filter, setFilter] = useState<string>(HR_DASHBOARD_TENURE_FILTER_TYPES.CURRENT);
  const [asForDate, setAsForDate] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>(new Date(2008, 3, 1));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [lineManager, setLineManager] = useState<string>('');
  const [seniority, setSeniority] = useState<string>('');
  const [average, setAverage] = useState<string>('');
  const [records, setRecords] = useState<Array<any>>([]);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);
  const [pages, setPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loadedPages, setLoadedPages] = useState<Array<number>>([]);

  const refTenure = useRef<HTMLDivElement>(null);

  const getRecords = (page: number, pageCount: number | null) => {
    if ((pageCount !== null && page > pageCount) || loadedPages.includes(page)) {
      return;
    }
  
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());

    const fromDateResult = filter === HR_DASHBOARD_TENURE_FILTER_TYPES.CURRENT ? asForDate : fromDate;

    HRDashboardClient.getTenureData(page, pageSize, filter, fromDateResult, toDate, lineManager, seniority)
      .then((data: any) => {
        if (page === 1) {
          setRecords(data.items);
        } else {
          records.push(...data.items);
        }
        setAverage(data.average);
        setPages(data.pages);
        if (!loadedPages.includes(page)) {
          loadedPages.push(page);
        }
        setCurrentPage(page + 1);
      })
      .catch((error: any) => {
        if (error.status === 403) {
          setNoPermissions(true);
          toastr.error('', 'No permissions to view this data');
        } else {
          toastr.error('', 'This data is unavailable');
        }
      })
      .finally(() => {
        store.dispatch(setOverlayIsVisible(false));
        store.dispatch(loaderActions.hideLoader());
      });
  }

  useEffect(() => {
    setLoadedPages([]);
    getRecords(1, null);
  }, [filter, asForDate, fromDate, toDate, lineManager, seniority]);

  const trackScroll = (scrollTop: number, scrollHeight: number) => {
    if (scrollTop === scrollHeight) {
      getRecords(currentPage, pages);
    }
  }

  return (
    <section className="edb-tenure-wrapper">
      {
        noPermissions
          ? <NoPermissions />
          : (
            <div className="tenure-view">
              <TenureFilter
                average={ average }
                filter={ filter }
                asForDate={ asForDate }
                fromDate={ fromDate }
                toDate={ toDate }
                lineManager={ lineManager }
                seniority={ seniority }
                setFilter={ setFilter }
                setAsForDate={ setAsForDate }
                setFromDate={ setFromDate }
                setToDate={ setToDate }
                setLineManager={ setLineManager }
                setSeniority={ setSeniority }
              />
              <TenureList
                records={ records }
                refTenure={ refTenure }
                trackScroll={ trackScroll }
              />
            </div>
          )
      }
    </section>
  );
}
