import React, {FunctionComponent, useEffect, useLayoutEffect, useRef, useState} from 'react';

import {DIRECTION_COLUMN, MODE_VIEW, WRNavigationStaff} from '../../Staff/WRNavigationStaff/WRNavigationStaff';
import {WRNavigationList} from '../WRNavigationList/WRNavigationList';
import {PopupTrigger} from '../../Popup/PopupTrigger';
import {Popup} from '../../Popup/Popup';
import {AddWorkRecordForm} from '../Forms/AddWorkRecord/AddWorkRecordForm';
import {EditProfileWrapper} from '../../Staff/Forms/EditProfile/EditProfileWrapper';
import {WRNavigationStaffAttributes} from "../../Staff/WRNavigationStaffAttributes/WRNavigationStaffAttributes";
import {ElementWithPermission} from "./../../ElementWithPermission/ElementWithPermission";

import './wr-navigation.scss';
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";

export const WRNavigation: FunctionComponent = () => {
  const [isHeightSet, setIsHeightSet] = useState<boolean>(false);
  const [style, setStyle] = useState({});
  const refHeader = useRef<HTMLDivElement>(null);
  const refBody = useRef<HTMLDivElement>(null);
  const refFooter = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isHeightSet) {
      setTimeout(() => {
        if (refHeader.current && refBody.current && refFooter.current) {
          const maxHeight = window.document.body.offsetHeight - 60
            - refHeader.current.offsetHeight - refFooter.current.offsetHeight;
          setStyle({height: `${maxHeight}px`});
          setIsHeightSet(true);
        }
      }, 100);
    }
  }, [isHeightSet]);

  useEffect(() => {
    const handleResize = () => {
      setIsHeightSet(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const [open, setOpen] = useState<boolean>(false);

  return (
    <nav className="work-record-navigation h-100">
      <div className="work-record-navigation__header" ref={ refHeader }>
        <WRNavigationStaff mode={ MODE_VIEW } direction={ DIRECTION_COLUMN } />
        <ElementWithPermission element="editProfile" staffToCheck={{}}>
          <div className="edit-profile-button">
            <PopupTrigger popupName="editProfile">
              <span className="popup-button">
                <FA icon={['fas', 'pen']}/>
              </span>
            </PopupTrigger>
          </div>
          <Popup popupName="editProfile" id="editProfile" title="Edit Profile" size="lg">
            <EditProfileWrapper popupName={'editProfile'} />
          </Popup>
        </ElementWithPermission>
        <WRNavigationStaffAttributes open={ open } />
        <div
          className="edb-btn edb-btn--dark w-100"
          onClick={ () => setOpen(!open) }
          aria-controls="contactInfo"
          aria-expanded={ open }
        >
          {(open) ? <FA icon={['fas', 'angle-up']} /> : <FA icon={['fas', 'angle-down']} />}
          {(open) ? <span className="pl-2">Show Less</span> : <span className="pl-2">Show More</span>}
        </div>
      </div>
      <div className="work-record-navigation__body">
        <div className="h-100">
          <WRNavigationList />
          <ElementWithPermission element="addWorkRecord" staffToCheck={{}}>
            <div className="add-wr-button">
              <PopupTrigger popupName="addNewWorkRecord">
                <span className="popup-button">Add work record</span>
              </PopupTrigger>
            </div>
            <Popup popupName="addNewWorkRecord" id="addNewWorkRecord" title="Add New Work Record" size="lg">
              <AddWorkRecordForm popupName={'addNewWorkRecord'}/>
            </Popup>
          </ElementWithPermission>
        </div>
      </div>
      <div className="work-record-navigation__footer position-fixed" ref={ refFooter }></div>
    </nav>
  );
}
