import React, {FC} from 'react';
import './notification-empty.scss';

export const NotificationEmpty: FC = () => {
  return (
    <div className="notification-empty">
      <div className="notification-empty--img"></div>
      <p className="notification-empty--p">Houston, all clear!</p>
    </div>
  )
}