export const DICTIONARY_SET_ALL = 'DICTIONARY_SET_ALL';
export const DICTIONARY_SET_ACTIVE_STAFF = 'DICTIONARY_SET_ACTIVE_STAFF';
export const DICTIONARY_SET_USERS = 'DICTIONARY_SET_USERS';
export const DICTIONARY_SET_DEGREES = 'DICTIONARY_SET_DEGREES';
export const DICTIONARY_SET_SCHOOLS = 'DICTIONARY_SET_SCHOOLS';
export const DICTIONARY_SET_PROJECTS = 'DICTIONARY_SET_PROJECTS';
export const DICTIONARY_GET_REPORT_FILTERS_LOADING = 'DICTIONARY_GET_REPORT_FILTERS_LOADING';
export const DICTIONARY_GET_REPORT_FILTERS_SUCCESS = 'DICTIONARY_GET_REPORT_FILTERS_SUCCESS';
export const DICTIONARY_GET_REPORT_FILTERS_FAILURE = 'DICTIONARY_GET_REPORT_FILTERS_FAILURE';
export const DICTIONARY_GET_COUNTRIES_SUCCESS = 'DICTIONARY_GET_COUNTRIES_SUCCESS';
export const DICTIONARY_GET_COUNTRIES_LOADING = 'DICTIONARY_GET_COUNTRIES_LOADING';
export const DICTIONARY_GET_COUNTRIES_FAILURE = 'DICTIONARY_GET_COUNTRIES_FAILURE';

export interface DictionaryState {
  all: any,
  activeStaff: any,
  users: any,
  degrees: Array<string>,
  schools: Array<string>,
  projects: Array<string>,
  reportFilters: object | null,
  loading: boolean,
  countries: Array<any>
}

interface SetDictionaryAllAction {
  type: typeof DICTIONARY_SET_ALL
  payload: {
    all: any
  }
}

interface SetDictionaryActiveStaffAction {
  type: typeof DICTIONARY_SET_ACTIVE_STAFF
  payload: {
    activeStaff: any
  }
}

interface SetDictionaryUsersAction {
  type: typeof DICTIONARY_SET_USERS
  payload: {
    users: any
  }
}

interface SetDictionaryDegreesAction {
  type: typeof DICTIONARY_SET_DEGREES
  payload: {
    degrees: any
  }
}

interface SetDictionarySchoolsAction {
  type: typeof DICTIONARY_SET_SCHOOLS
  payload: {
    schools: any
  }
}

interface SetDictionaryProjectsAction {
  type: typeof DICTIONARY_SET_PROJECTS
  payload: {
    projects: any
  }
}

interface GetDictionaryReportFiltersLoading {
  type: typeof DICTIONARY_GET_REPORT_FILTERS_LOADING
  payload: object
}

interface GetDictionaryReportFiltersSuccess {
  type: typeof DICTIONARY_GET_REPORT_FILTERS_SUCCESS
  payload: object
}

interface GetDictionaryReportFiltersFailure {
  type: typeof DICTIONARY_GET_REPORT_FILTERS_FAILURE
  payload: boolean
}

interface GetDictionaryCountriesLoading {
  type: typeof DICTIONARY_GET_COUNTRIES_LOADING
  payload: object
}

interface GetDictionaryCountriesSuccess {
  type: typeof DICTIONARY_GET_COUNTRIES_SUCCESS
  payload: Array<any>
}

interface GetDictionaryCountriesFailure {
  type: typeof DICTIONARY_GET_COUNTRIES_FAILURE
  payload: boolean
}

export type DictionaryTypes = SetDictionaryAllAction
  | GetDictionaryCountriesLoading
  | GetDictionaryCountriesSuccess
  | GetDictionaryCountriesFailure
  | GetDictionaryReportFiltersLoading
  | GetDictionaryReportFiltersSuccess
  | GetDictionaryReportFiltersFailure
  | SetDictionaryActiveStaffAction
  | SetDictionaryUsersAction
  | SetDictionaryDegreesAction
  | SetDictionarySchoolsAction
  | SetDictionaryProjectsAction
