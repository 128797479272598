import React, {FunctionComponent} from 'react';
import {HCSnapshotOrgStructureResponse} from "../../../interfaces/http/hrDashboard/hcSnapshot/HCSnapshotOrgStructureResponse";
import {HCSnapshotHeadcountData} from "./HCSnapshotHeadcountData";
import {OrgStructureResponse} from "../../../interfaces/http/hrDashboard/hcSnapshot/OrgStructureResponse";
import {LocationForOrgStructureResponse} from "../../../interfaces/http/hrDashboard/hcSnapshot/LocationResponse";
import {UnitReportItemResponse} from "../../../interfaces/http/hrDashboard/hcSnapshot/UnitReportItemResponse";

import {
  HCSnapshotOrgStructureSettings, LOCATION_TYPE,
  ORG_STRUCTURE_TYPE,
  DEPARTMENT_TYPE, UNIT_TYPE
} from "../../../interfaces/entity/hrDashboard/HCSnapshotSettings";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

import './hc-snapshot-report.scss';

interface Props {
  data: HCSnapshotOrgStructureResponse,
  settings: Array<HCSnapshotOrgStructureSettings>,
  setSettings: Function,
  showDiff: boolean
}

export const HCSnapshotOrgStructureReport: FunctionComponent<Props> = (props: Props) => {

  const {data, settings, setSettings} = {...props};

  const changeDataSettings = (event: React.MouseEvent<HTMLParagraphElement>) => {
    let element = event.currentTarget,
      itemType = element.dataset.itemType,
      itemId = element.dataset.itemId ? parseInt(element.dataset.itemId) : null,
      itemParentId = element.dataset.itemParentId ? parseInt(element.dataset.itemParentId) : null,
      itemOrgStructureId = element.dataset.itemOrgStructureId ? parseInt(element.dataset.itemOrgStructureId) : null;

    const copyOfSettings = Array.from(settings);

    copyOfSettings.forEach((item, index, array) => {
      let cond = item.type === itemType && item.id === itemId;
      if (itemType !== ORG_STRUCTURE_TYPE) {
        if (itemParentId) {
          cond = cond && item.parentId === itemParentId;
        }
        if (itemOrgStructureId) {
          cond = cond && item.orgStructureId === itemOrgStructureId;
        }
      }
      if (cond) {
        array[index].expand = !item.expand;
      }
    });
    setSettings(copyOfSettings);
  }

  const getExpanded = (id: number,
                       type: string = ORG_STRUCTURE_TYPE,
                       parentId: number | null = null,
                       orgStructureId: number | null = null) => {
    if (settings?.length) {
      let element = settings.find((element) => {
        let cond = element.id === id && element.type === type;
        if (type !== ORG_STRUCTURE_TYPE) {
          if (parentId) {
            cond = cond && element.parentId === parentId;
          }
          if (orgStructureId) {
            cond = cond && element.orgStructureId === orgStructureId;
          }
        }
        return cond;
      });
      return element ? element.expand : false;
    }
    return false;
  }

  const getIcon = (id: string | number,
                   type: string = ORG_STRUCTURE_TYPE,
                   parentId: string | number | null = null,
                   orgStructureId: string | number | null = null): IconProp => {
    if (typeof id === "string") {
      id = parseInt(id);
    }
    if (typeof parentId === "string") {
      parentId = parseInt(parentId);
    }
    if (typeof orgStructureId === "string") {
      orgStructureId = parseInt(orgStructureId);
    }
    return getExpanded(id, type, parentId, orgStructureId) ? ['fas', 'caret-down'] : ['fas', 'caret-right'];
  }

  return (
    <div className="headcount-report">
      <div id="headcount-report-org-wrapper">
        <div className="headcount-report--header">
          <div className="row headcount-report--header-title">
            <div className="col">Total</div>
            <div className="col-4">
              <div className="row">
                <div className="col border border-bottom-0">
                  Global Headcount
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col border border-bottom-0">
                  Headcount FTE
                </div>
              </div>
            </div>

          </div>
          <div className="row headcount-report--header-data">
            <div className="col"/>
            <div className="col-4">
              <div className="row">
                <HCSnapshotHeadcountData
                  classValue="col border-left"
                  data={data.head_count_billable}
                  diff={data.head_count_metric_diff.head_count_billable}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col"
                  data={data.head_count_non_billable}
                  diff={data.head_count_metric_diff.head_count_non_billable}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col border-right"
                  data={data.head_count_total}
                  diff={data.head_count_metric_diff.head_count_total}
                  showDiff={props.showDiff}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <HCSnapshotHeadcountData
                  classValue="col border-left"
                  data={data.billable_fte}
                  diff={data.head_count_metric_diff.billable_fte}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col"
                  data={data.non_billable_fte}
                  diff={data.head_count_metric_diff.non_billable_fte}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col"
                  data={data.fte_total}
                  diff={data.head_count_metric_diff.fte_total}
                  showDiff={props.showDiff}
                />
                <HCSnapshotHeadcountData
                  classValue="col border-right"
                  data={data.fte_ratio}
                  diff={data.head_count_metric_diff.fte_ratio}
                  showDiff={props.showDiff}
                  isNotClickable={true}
                />
              </div>
            </div>
          </div>
          <div className="row headcount-report--header-subtitle">
            <div className="col">Star Unit, Location</div>
            <div className="col-4">
              <div className="row">
                <div className="col border-bottom border-left">Billable</div>
                <div className="col border-bottom">NB</div>
                <div className="col border-bottom border-right">Total</div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col border-bottom border-left">Billable</div>
                <div className="col border-bottom">NB</div>
                <div className="col border-bottom">Total</div>
                <div className="col border-bottom border-right">B/NB</div>
              </div>
            </div>
          </div>
        </div>
        <div className="headcount-report--body">
          {
            (data.org_structures)
              ? (
                <div className="accordion">
          
                  {
                    (data.org_structures as Array<any>).map((orgStructure: OrgStructureResponse) => (
                      <div className="card" key={ `orgStructure-id-${orgStructure.id}` }>
                
                        <div className="card-header p-0" id={`orgStructure-${orgStructure.id}`}>
                  
                          <div className="row headcount-report-row--location">
                            <div className="col">
                              <p
                                className="m-0 py-0 position-relative collapse-toggle"
                                style={{ fontSize: '18px' }}
                                data-toggle="collapse"
                                data-target={`#collapse-orgStructure-${orgStructure.id}`}
                                data-item-id={orgStructure.id}
                                data-item-type="orgStructure"
                                aria-expanded="true"
                                aria-controls={`collapse-${orgStructure.id}`}
                                onClick={e => changeDataSettings(e)}
                              >
                                {
                                  (orgStructure.locations.length)
                                    ? (
                                      <FontAwesomeIcon
                                        icon={getIcon(orgStructure.id, ORG_STRUCTURE_TYPE)}
                                        className="position-absolute top-5 font-size--14"
                                        data-item-id={orgStructure.id}
                                        data-item-type={ORG_STRUCTURE_TYPE}
                                      />
                                    ) : <></>
                                }
                                <span
                                  className="location-name"
                                  data-item-id={orgStructure.id}
                                  data-item-type="orgStructure">{orgStructure.name}</span>
                              </p>
                            </div>
                            <div className="col-4">
                              <div className="row">
                                <HCSnapshotHeadcountData
                                  classValue="col"
                                  data={orgStructure.head_count_billable}
                                  diff={orgStructure.head_count_metric_diff.head_count_billable}
                                  showDiff={props.showDiff}
                                  type={orgStructure.type}
                                  orgStructure={orgStructure.name}
                                  isBillable={true}
                                />
                                <HCSnapshotHeadcountData
                                  classValue="col"
                                  data={orgStructure.head_count_non_billable}
                                  diff={orgStructure.head_count_metric_diff.head_count_non_billable}
                                  showDiff={props.showDiff}
                                  type={orgStructure.type}
                                  orgStructure={orgStructure.name}
                                  isBillable={false}
                                />
                                <HCSnapshotHeadcountData
                                  classValue="col"
                                  data={orgStructure.head_count_total}
                                  diff={orgStructure.head_count_metric_diff.head_count_total}
                                  showDiff={props.showDiff}
                                  type={orgStructure.type}
                                  orgStructure={orgStructure.name}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="row">
                                <HCSnapshotHeadcountData
                                  classValue="col"
                                  data={orgStructure.billable_fte}
                                  diff={orgStructure.head_count_metric_diff.billable_fte}
                                  showDiff={props.showDiff}
                                  type={orgStructure.type}
                                  orgStructure={orgStructure.name}
                                  isBillable={true}
                                />
                                <HCSnapshotHeadcountData
                                  classValue="col"
                                  data={orgStructure.non_billable_fte}
                                  diff={orgStructure.head_count_metric_diff.non_billable_fte}
                                  showDiff={props.showDiff}
                                  type={orgStructure.type}
                                  orgStructure={orgStructure.name}
                                  isBillable={false}
                                />
                                <HCSnapshotHeadcountData
                                  classValue="col"
                                  data={orgStructure.fte_total}
                                  diff={orgStructure.head_count_metric_diff.fte_total}
                                  showDiff={props.showDiff}
                                  type={orgStructure.type}
                                  orgStructure={orgStructure.name}
                                />
                                <HCSnapshotHeadcountData
                                  classValue="col"
                                  data={orgStructure.fte_ratio}
                                  diff={orgStructure.head_count_metric_diff.fte_ratio}
                                  showDiff={props.showDiff}
                                  type={orgStructure.type}
                                  orgStructure={orgStructure.name}
                                  isNotClickable={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                
                        <div
                          id={`collapse-orgStructure-${orgStructure.id}`}
                          className={`collapse ${getExpanded(parseInt(orgStructure.id), ORG_STRUCTURE_TYPE) === true ? 'show' : ''}`}
                          aria-labelledby={`orgStructure-${orgStructure.id}`}>
                  
                          <div className="card-body p-0 font-size--14">
                    
                            <div className="accordion">
                              {
                                (orgStructure.locations.map((location: LocationForOrgStructureResponse) => (
                                  <div className="card border-0" key={ `location-id-${location.id}` }>
                            
                                    <div className="card-header p-0"
                                         id={`orgStructure-${orgStructure.id}-location-${location.id}`}>
                              
                                      <div
                                        className="row border-bottom headcount-report-row--org-structure">
                                        <div className="col">
                                          <p
                                            className="m-0 py-0 position-relative collapse-toggle"
                                            data-toggle="collapse"
                                            data-target={`#collapse-orgStructure-${orgStructure.id}-location-${location.id}`}
                                            data-item-id={location.id}
                                            data-item-type="location"
                                            data-item-orgStructure-id={orgStructure.id}
                                            aria-expanded="true"
                                            aria-controls={`collapse-${location.id}`}
                                            onClick={e => changeDataSettings(e)}
                                          >
                                            {
                                              (location.units.length)
                                                ? (
                                                  <FontAwesomeIcon
                                                    icon={getIcon(location.id, LOCATION_TYPE, null, orgStructure.id)}
                                                    className="position-absolute top-3 font-size--14"
                                                    data-item-id={location.id}
                                                    data-item-type="location"
                                                    data-item-orgStructure-id={orgStructure.id}
                                                  />
                                                ) : <></>
                                            }
                                            <span
                                              className="location-name"
                                              data-item-id={location.id}
                                              data-item-type="location"
                                              data-item-orgStructure-id={orgStructure.id}>{location.name}</span>
                                          </p>
                                        </div>
                                        <div className="col-4">
                                          <div className="row">
                                            <HCSnapshotHeadcountData
                                              classValue="col"
                                              data={location.head_count_billable}
                                              diff={location.head_count_metric_diff.head_count_billable}
                                              showDiff={props.showDiff}
                                              location={location.name}
                                              type={orgStructure.type}
                                              orgStructure={orgStructure.name}
                                              isBillable={true}
                                            />
                                            <HCSnapshotHeadcountData
                                              classValue="col"
                                              data={location.head_count_non_billable}
                                              diff={location.head_count_metric_diff.head_count_non_billable}
                                              showDiff={props.showDiff}
                                              location={location.name}
                                              type={orgStructure.type}
                                              orgStructure={orgStructure.name}
                                              isBillable={false}
                                            />
                                            <HCSnapshotHeadcountData
                                              classValue="col"
                                              data={location.head_count_total}
                                              diff={location.head_count_metric_diff.head_count_total}
                                              showDiff={props.showDiff}
                                              location={location.name}
                                              type={orgStructure.type}
                                              orgStructure={orgStructure.name}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="row">
                                            <HCSnapshotHeadcountData
                                              classValue="col"
                                              data={location.billable_fte}
                                              diff={location.head_count_metric_diff.billable_fte}
                                              showDiff={props.showDiff}
                                              location={location.name}
                                              type={orgStructure.type}
                                              orgStructure={orgStructure.name}
                                              isBillable={true}
                                            />
                                            <HCSnapshotHeadcountData
                                              classValue="col"
                                              data={location.non_billable_fte}
                                              diff={location.head_count_metric_diff.non_billable_fte}
                                              showDiff={props.showDiff}
                                              location={location.name}
                                              type={orgStructure.type}
                                              orgStructure={orgStructure.name}
                                              isBillable={false}
                                            />
                                            <HCSnapshotHeadcountData
                                              classValue="col"
                                              data={location.fte_total}
                                              diff={location.head_count_metric_diff.fte_total}
                                              showDiff={props.showDiff}
                                              location={location.name}
                                              type={orgStructure.type}
                                              orgStructure={orgStructure.name}
                                            />
                                            <HCSnapshotHeadcountData
                                              classValue="col"
                                              data={location.fte_ratio}
                                              diff={location.head_count_metric_diff.fte_ratio}
                                              showDiff={props.showDiff}
                                              location={location.name}
                                              type={orgStructure.type}
                                              orgStructure={orgStructure.name}
                                              isNotClickable={true}
                                            />
                                          </div>
                                        </div>
                                      </div>
                            
                                    </div>
                            
                                    <div
                                      id={`collapse-orgStructure-${orgStructure.id}-location-${location.id}`}
                                      className={`collapse ${getExpanded(parseInt(location.id), LOCATION_TYPE, null, parseInt(orgStructure.id)) === true ? 'show' : ''}`}
                                      aria-labelledby={`orgStructure-${orgStructure.id}-location-${location.id}`}>
                              
                                      <div className="card-body p-0 font-size--14">
                                
                                        <div className="accordion">
                                  
                                          {
                                            (location.units.map((department: UnitReportItemResponse) => (
                                              <div className="card border-0" key={ `department-id-${department.id}` }>
                                        
                                                <div className="card-header p-0"
                                                     id={`orgStructure-${orgStructure.id}-department-${department.id}`}>
                                          
                                                  <div
                                                    className="row border-bottom headcount-report-row--department">
                                                    <div className="col">
                                                      <p
                                                        className="m-0 py-0 position-relative collapse-toggle"
                                                        data-toggle="collapse"
                                                        data-target={`#collapse-orgStructure-${orgStructure.id}-department-${department.id}`}
                                                        data-item-id={department.id}
                                                        data-item-type="department"
                                                        data-item-parent-id={location.id}
                                                        data-item-orgStructure-id={orgStructure.id}
                                                        aria-expanded="true"
                                                        aria-controls={`collapse-${department.id}`}
                                                        onClick={e => changeDataSettings(e)}
                                                      >
                                                        {
                                                          (department.units.length)
                                                            ? (
                                                              <FontAwesomeIcon
                                                                icon={getIcon(department.id, DEPARTMENT_TYPE, location.id, orgStructure.id)}
                                                                className="position-absolute top-5 font-size--14"
                                                                data-item-id={department.id}
                                                                data-item-type={DEPARTMENT_TYPE}
                                                                data-item-parent-id={location.id}
                                                                data-item-orgStructure-id={orgStructure.id}
                                                              />
                                                            )
                                                            : <></>
                                                        }
                                                        <span className="location-name"
                                                              data-item-id={department.id}
                                                              data-item-type={DEPARTMENT_TYPE}
                                                              data-item-parent-id={location.id}
                                                              data-item-orgStructure-id={orgStructure.id}>{department.unit_name}</span>
                                                      </p>
                                                    </div>
                                                    <div className="col-4">
                                                      <div className="row">
                                                        <HCSnapshotHeadcountData
                                                          classValue="col"
                                                          data={department.head_count_billable}
                                                          diff={department.head_count_metric_diff.head_count_billable}
                                                          showDiff={props.showDiff}
                                                          location={location.name}
                                                          department={department.unit_code_name}
                                                          isBillable={true}
                                                        />
                                                        <HCSnapshotHeadcountData
                                                          classValue="col"
                                                          data={department.head_count_non_billable}
                                                          diff={department.head_count_metric_diff.head_count_non_billable}
                                                          showDiff={props.showDiff}
                                                          location={location.name}
                                                          department={department.unit_code_name}
                                                          isBillable={false}
                                                        />
                                                        <HCSnapshotHeadcountData
                                                          classValue="col"
                                                          data={department.head_count_total}
                                                          diff={department.head_count_metric_diff.head_count_total}
                                                          showDiff={props.showDiff}
                                                          location={location.name}
                                                          department={department.unit_code_name}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-6">
                                                      <div className="row">
                                                        <HCSnapshotHeadcountData
                                                          classValue="col"
                                                          data={department.billable_fte}
                                                          diff={department.head_count_metric_diff.billable_fte}
                                                          showDiff={props.showDiff}
                                                          location={location.name}
                                                          department={department.unit_code_name}
                                                          isBillable={true}
                                                        />
                                                        <HCSnapshotHeadcountData
                                                          classValue="col"
                                                          data={department.non_billable_fte}
                                                          diff={department.head_count_metric_diff.non_billable_fte}
                                                          showDiff={props.showDiff}
                                                          location={location.name}
                                                          department={department.unit_code_name}
                                                          isBillable={false}
                                                        />
                                                        <HCSnapshotHeadcountData
                                                          classValue="col"
                                                          data={department.fte_total}
                                                          diff={department.head_count_metric_diff.fte_total}
                                                          showDiff={props.showDiff}
                                                          location={location.name}
                                                          department={department.unit_code_name}
                                                        />
                                                        <HCSnapshotHeadcountData
                                                          classValue="col"
                                                          data={department.fte_ratio}
                                                          diff={department.head_count_metric_diff.fte_ratio}
                                                          showDiff={props.showDiff}
                                                          location={location.name}
                                                          department={department.unit_code_name}
                                                          isNotClickable={true}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                        
                                                <div
                                                  id={`collapse-orgStructure-${orgStructure.id}-department-${department.id}`}
                                                  className={`collapse ${getExpanded(department.id, DEPARTMENT_TYPE, parseInt(location.id), parseInt(orgStructure.id)) === true ? 'show' : ''}`}
                                                  aria-labelledby={`orgStructure-${orgStructure.id}-department-${department.id}`}>
                                                  <div className="card-body p-0 font-size--14">
                                                    <div className="accordion">
                                                      {
                                                        ((department.units as Array<any>).map((unit: UnitReportItemResponse) => (
                                                          <div className="card border-0" key={ `unit-id-${unit.id}` }>
                                                    
                                                            <div className="card-header p-0"
                                                                 id={`orgStructure-${orgStructure.id}-unit-${unit.id}`}>
                                                      
                                                              <div className="row border-bottom headcount-report-row--unit">
                                                                <div className="col">
                                                                  <p
                                                                    className="m-0 py-0 position-relative collapse-toggle"
                                                                    data-toggle="collapse"
                                                                    data-target={`#collapse-orgStructure-${orgStructure.id}-unit-${unit.id}`}
                                                                    data-item-id={unit.id}
                                                                    data-item-type={UNIT_TYPE}
                                                                    data-item-parent-id={department.id}
                                                                    data-item-orgStructure-id={orgStructure.id}
                                                                    aria-expanded="true"
                                                                    aria-controls={`collapse-${unit.id}`}
                                                                    onClick={e => changeDataSettings(e)}
                                                                  >
                                                                    {
                                                                      (unit.units.length)
                                                                        ? (
                                                                          <FontAwesomeIcon
                                                                            icon={getIcon(unit.id, UNIT_TYPE, department.id, orgStructure.id)}
                                                                            className="position-absolute top-3 font-size--14"
                                                                            data-item-id={unit.id}
                                                                            data-item-type={UNIT_TYPE}
                                                                            data-item-parent-id={department.id}
                                                                            data-item-orgStructure-id={orgStructure.id}
                                                                          />
                                                                        )
                                                                        : <></>
                                                                    }
                                                                    <span
                                                                      className="location-name"
                                                                      data-item-id={unit.id}
                                                                      data-item-type="unit"
                                                                      data-item-parent-id={department.id}
                                                                      data-item-orgStructure-id={orgStructure.id}
                                                                    >{unit.unit_name}</span>
                                                                  </p>
                                                                </div>
                                                                <div className="col-4">
                                                                  <div className="row">
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={unit.head_count_billable}
                                                                      diff={unit.head_count_metric_diff.head_count_billable}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      unit={unit.unit_code_name}
                                                                      isBillable={true}
                                                                    />
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={unit.head_count_non_billable}
                                                                      diff={unit.head_count_metric_diff.head_count_non_billable}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      unit={unit.unit_code_name}
                                                                      isBillable={false}
                                                                    />
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={unit.head_count_total}
                                                                      diff={unit.head_count_metric_diff.head_count_total}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      unit={unit.unit_code_name}
                                                                    />
                                                                  </div>
                                                                </div>
                                                                <div className="col-6">
                                                                  <div className="row">
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={unit.billable_fte}
                                                                      diff={unit.head_count_metric_diff.billable_fte}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      unit={unit.unit_code_name}
                                                                      isBillable={true}
                                                                    />
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={unit.non_billable_fte}
                                                                      diff={unit.head_count_metric_diff.non_billable_fte}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      unit={unit.unit_code_name}
                                                                      isBillable={false}
                                                                    />
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={unit.fte_total}
                                                                      diff={unit.head_count_metric_diff.fte_total}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      unit={unit.unit_code_name}
                                                                    />
                                                                    <HCSnapshotHeadcountData
                                                                      classValue="col"
                                                                      data={unit.fte_ratio}
                                                                      diff={unit.head_count_metric_diff.fte_ratio}
                                                                      showDiff={props.showDiff}
                                                                      location={location.name}
                                                                      unit={unit.unit_code_name}
                                                                      isNotClickable={true}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                    
                                                            </div>
                                                    
                                                            <div
                                                              id={`collapse-orgStructure-${orgStructure.id}-unit-${unit.id}`}
                                                              className={`collapse ${getExpanded(unit.id, UNIT_TYPE, department.id, parseInt(orgStructure.id)) === true ? 'show' : ''}`}
                                                              aria-labelledby={`orgStructure-${orgStructure.id}-unit-${unit.id}`}>
                                                              <div className="card-body p-0 font-size--14">
                                                                {
                                                                  ((unit.units as Array<any>).map((team: UnitReportItemResponse) => (
                                                                    <div key={ `team-id-${ team.id }` }>
                                                                      <div className="row border-bottom headcount-report-row--team">
                                                                        <div className="col">{ team.unit_name }</div>
                                                                        <div className="col-4">
                                                                          <div className="row">
                                                                            <HCSnapshotHeadcountData
                                                                              classValue="col"
                                                                              data={ team.head_count_billable }
                                                                              diff={ team.head_count_metric_diff.head_count_billable }
                                                                              showDiff={ props.showDiff }
                                                                              location={ location.name }
                                                                              subunit={ team.unit_code_name }
                                                                              isBillable={ true }
                                                                            />
                                                                    
                                                                            <HCSnapshotHeadcountData
                                                                              classValue="col"
                                                                              data={team.head_count_non_billable}
                                                                              diff={team.head_count_metric_diff.head_count_non_billable}
                                                                              showDiff={props.showDiff}
                                                                              location={location.name}
                                                                              subunit={team.unit_code_name}
                                                                              isBillable={false}
                                                                            />
                                                                    
                                                                            <HCSnapshotHeadcountData
                                                                              classValue="col"
                                                                              data={team.head_count_total}
                                                                              diff={team.head_count_metric_diff.head_count_total}
                                                                              showDiff={props.showDiff}
                                                                              location={location.name}
                                                                              subunit={team.unit_code_name}
                                                                            />
                                                                  
                                                                          </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                          <div className="row">
                                                                            <HCSnapshotHeadcountData
                                                                              classValue="col"
                                                                              data={team.billable_fte}
                                                                              diff={team.head_count_metric_diff.billable_fte}
                                                                              showDiff={props.showDiff}
                                                                              location={location.name}
                                                                              subunit={team.unit_code_name}
                                                                              isBillable={true}
                                                                            />
                                                                    
                                                                            <HCSnapshotHeadcountData
                                                                              classValue="col"
                                                                              data={team.non_billable_fte}
                                                                              diff={team.head_count_metric_diff.non_billable_fte}
                                                                              showDiff={props.showDiff}
                                                                              location={location.name}
                                                                              subunit={team.unit_code_name}
                                                                              isBillable={false}
                                                                            />
                                                                    
                                                                            <HCSnapshotHeadcountData
                                                                              classValue="col"
                                                                              data={team.fte_total}
                                                                              diff={team.head_count_metric_diff.fte_total}
                                                                              showDiff={props.showDiff}
                                                                              location={location.name}
                                                                              subunit={team.unit_code_name}
                                                                            />
                                                                    
                                                                            <HCSnapshotHeadcountData
                                                                              classValue="col"
                                                                              data={team.fte_ratio}
                                                                              diff={team.head_count_metric_diff.fte_ratio}
                                                                              showDiff={props.showDiff}
                                                                              location={location.name}
                                                                              subunit={team.unit_code_name}
                                                                              isNotClickable={true}
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )))
                                                                }
                                                              </div>
                                                    
                                                            </div>
                                                  
                                                          </div>
                                                        )))
                                                      }
                                            
                                                    </div>
                                          
                                                  </div>
                                                </div>
                                      
                                              </div>
                                            )))
                                          }
                                
                                        </div>
                              
                                      </div>
                                    </div>
                          
                                  </div>
                                )))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
        
                </div>
              )
              : <></>
          }
        </div>
      </div>
    </div>
  );
}
