import http, { Http } from './Http';
import { ENDPOINTS } from '../../constants';

class ReportClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getReport(reportId: string) {
    return this.http.get(ENDPOINTS.DETAILS(reportId));
  }

  public getReportCSV(reportId: string) {
    return this.http.get(ENDPOINTS.DETAILS_CSV(reportId));
  }
  
  public getConfig() {
    return this.http.getAction(ENDPOINTS.DETAILS_CONFIG);
  }
}

export default new ReportClient(http);
