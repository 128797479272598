import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import FileSaver from 'file-saver';

import dictionaryStore from '../../../services/dictionary/DictionaryStore';
import StaffClient from '../../../services/http/StaffClient';
import client from '../../../services/http/HRDashboardClient';

import DateHelper from '../../../utils/date/DateHelper';

import { DownloadIcon } from '../../Icon/DownloadIcon';
import { DatePickerField } from '../../Forms/DatePickerField';
import { TypeaheadField } from '../../Forms/TypeaheadField';

import { HR_DASHBOARD_TENURE_FILTER_TYPES, COMMON } from '../../../constants';

interface Props {
  average: string,
  filter: string,
  asForDate: Date,
  fromDate: Date,
  toDate: Date,
  lineManager: string,
  seniority: string,
  setFilter: Function,
  setAsForDate: Function,
  setFromDate: Function,
  setToDate: Function,
  setLineManager: Function,
  setSeniority: Function
}

export const TenureFilter: React.FC<Props> = (props: Props) => {
  const {
    average, filter, asForDate, fromDate, toDate, lineManager, seniority,
    setFilter, setAsForDate, setFromDate, setToDate, setLineManager, setSeniority
  } = { ...props };

  const [isLoaded, setIsLoaded] = useState(false);
  const [seniorityList, setSeniorityList] = useState([]);
  const [lmList, setLmList] = useState([]);

  useEffect(() => {
    if (!isLoaded) {
      StaffClient.getLmList().then((data: any) => {
        if (data?.length) {
          const lm = data.map((staff: any) => {
            return {
              id: staff.id,
              name: staff.fullName
            }
          });
          lm.unshift({id: 0, name: COMMON.NOT_SET});
          setLmList(lm);
        }
      });
      dictionaryStore.getByAlias('seniority').then((data: any) => {
        if (data) {
          const list = data.map((item: any, index: number) => {
            return {
              id: index,
              name: item
            };
          });
          list.unshift({id: 0, name: COMMON.NOT_SET});
          setSeniorityList(list);
        }
      });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  const exportCSV = () => {
    const fromDateResult = filter === HR_DASHBOARD_TENURE_FILTER_TYPES.CURRENT ? asForDate : fromDate;

    client.getTenureCSV(filter, fromDateResult, toDate, lineManager, seniority).then((data: any) => {
      if (data) {
        const file = new Blob([data], {
          type: 'text/csv'
        });
        FileSaver.saveAs(file, 'tenureRecordsExport.csv');
        client.http.unsetResponseType();
      }
    });
  }

  return (
    <div className="tenure-filter-form w-100 bg-white">
      <div className="row align-items-end">
        <div className="col-auto mr-auto">
          <div className="filter-group">
            <div className="filter-field mr-0">
              <label className="edb-label">Average Tenure</label>
              <p className="info font-size--18 font-weight--600 color--azure">{average}</p>
            </div>
            <Formik
              initialValues={{ asForDate, fromDate, toDate, lineManager, seniority }}
              onSubmit={() => {}}
            >
              {({ values, setFieldValue }) => (
                <Form className="edb-form" style={{display: "contents"}}>
                  { filter === HR_DASHBOARD_TENURE_FILTER_TYPES.CURRENT &&
                  <div className="filter-field mr-0 ml-20">
                      <label className="edb-label">As For</label>
                      <DatePickerField
                          name="asForDate"
                          value={ (asForDate) ? DateHelper.getFormattedDate(asForDate) : '' }
                          selected={ asForDate }
                          className="input-group ui-datepicker my-0"
                          onChange={ (val: Date) => setAsForDate(val) }
                      />
                  </div> }
                  { filter === HR_DASHBOARD_TENURE_FILTER_TYPES.LEFT &&
                  <div className="filter-field mr-0 ml-20">
                      <label className="edb-label">From</label>
                      <DatePickerField
                          name="fromDate"
                          value={ (fromDate) ? DateHelper.getFormattedDate(fromDate) : '' }
                          selected={ fromDate }
                          className="input-group ui-datepicker"
                          onChange={ (val: Date) => setFromDate(val) }
                      />
                  </div> }
                  { filter === HR_DASHBOARD_TENURE_FILTER_TYPES.LEFT &&
                  <div className="filter-field mr-0 ml-20">
                      <label className="edb-label">To</label>
                      <DatePickerField
                          name="toDate"
                          value={ (toDate) ? DateHelper.getFormattedDate(toDate) : '' }
                          selected={ toDate }
                          className="input-group ui-datepicker"
                          onChange={ (val: Date) => setToDate(val) }
                      />
                  </div> }
                  <div className="filter-field mr-0 ml-20">
                    <label htmlFor="lineManager" className="edb-label">Line Manager</label>
                    <TypeaheadField
                      className="form-control"
                      id="lineManager"
                      name="lineManager"
                      labelKey="name"
                      multiple={ false }
                      options={ lmList }
                      onChange={ (selected: any) => {
                        const value = selected && selected[0] ? selected[0] : '';
                        setFieldValue('lineManager', value);
                        setLineManager(value.id);
                      } }
                      clearButton={ !!values.lineManager }
                    />
                  </div>
                  <div className="filter-field mr-0 ml-20">
                    <label htmlFor="seniority" className="edb-label">Seniority</label>
                    <TypeaheadField
                      className="form-control"
                      id="seniority"
                      name="seniority"
                      labelKey="name"
                      multiple={ false }
                      options={ seniorityList }
                      onChange={ (selected: any) => {
                        if (selected && selected[0]) {
                          setFieldValue('seniority', selected[0]);
                          setSeniority(selected[0].name);
                        } else {
                          setFieldValue('seniority', '');
                          setSeniority('');
                        }
                        
                      } }
                      clearButton={ !!values.seniority }
                    />
                  </div>
                </Form>)}
            </Formik>
          </div>
        </div>
        <div className="col-auto ml-auto">
          <div className="filter-group align-items-end">
            <div className="filter-field tenure-radio-group">
              <input
                type="radio"
                id="typeCurrent"
                checked={ filter === HR_DASHBOARD_TENURE_FILTER_TYPES.CURRENT }
                name="filter_tenure"
                value={ HR_DASHBOARD_TENURE_FILTER_TYPES.CURRENT }
                onChange={ (event) => setFilter(event.target.value) }
              />
              <label className="first text-center text-uppercase font-weight-normal font-size--12" htmlFor="typeCurrent">
                Current
              </label>
              <input
                type="radio"
                id="typeLeft"
                checked={ filter === HR_DASHBOARD_TENURE_FILTER_TYPES.LEFT }
                name="filter_tenure"
                value={ HR_DASHBOARD_TENURE_FILTER_TYPES.LEFT }
                onChange={ (event) => setFilter(event.target.value) }
              />
              <label className="last text-center text-uppercase font-weight-normal font-size--12" htmlFor="typeLeft">
                Left
              </label>
            </div>
            <div className="export-link">
              <span className="table-settings__control-link" onClick={ exportCSV }>
                <span className="table-settings__control-icon">
                  <DownloadIcon />
                </span>
                Export to CSV
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
