export const ET_SET_ATTRIBUTES = 'ET_SET_ATTRIBUTES';
export const ET_SET_ATTRIBUTES_DEFAULT = 'ET_SET_ATTRIBUTES_DEFAULT';

export interface EtState {
  attributes: Array<any>,
  attributesDefault: Array<any>
}

interface SetEtAttributesAction {
  type: typeof ET_SET_ATTRIBUTES
  payload: {
    attributes: Array<any>
  }
}

interface SetEtAttributesDefaultsAction {
  type: typeof ET_SET_ATTRIBUTES_DEFAULT
  payload: {
    attributesDefault: Array<any>
  }
}

export type EtTypes = SetEtAttributesAction | SetEtAttributesDefaultsAction
