import React, {FormEvent, FunctionComponent, useEffect, useState} from 'react';
import {FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome';

interface InputProps {
  name: string
  value?: string
  placeholder?: string
  isClearable?: boolean
  changeEffect?: Function
}

export const TextInput: FunctionComponent<InputProps> = (props: InputProps) => {
  const {name, value, placeholder, isClearable, changeEffect} = {...props};
  const [currentValue, setCurrentValue] = useState<string>(value ?? '');
  const clear = () => {
    setCurrentValue('');
    if (changeEffect) {
      changeEffect('');
    }
  }
  const change = (event: any) => {
    const value = event.target.value;
    setCurrentValue(value);
    if (changeEffect) {
      changeEffect(value);
    }
  }

  useEffect(() => {
    setCurrentValue(value ?? '');
  }, [value]);

  return (
    <div className="edb-field input">
      <input
        type="text"
        name={name}
        placeholder={(placeholder) ? placeholder : ''}
        autoComplete="off"
        value={currentValue}
        onChange={(event => change(event))}/>
      {(isClearable && currentValue.length > 1)
        ? <span className="edb-field__clear" onClick={clear}>
          <FA icon={['fas', 'times']}/>
        </span>
        : ''
      }
    </div>
  );
};
