export const OVERLAY_SET_IS_VISIBLE = 'OVERLAY_SET_IS_VISIBLE';
export const OVERLAY_SET_PARAMS = 'OVERLAY_SET_PARAMS';

export interface OverlayParams {
  isExtra: boolean,
  isFullscreen: boolean
}

export interface OverlayState {
  isVisible: boolean,
  params: OverlayParams
}

export interface SetIsVisibleAction {
  type: typeof OVERLAY_SET_IS_VISIBLE
  payload: boolean
}

export interface SetParamsAction {
  type: typeof OVERLAY_SET_PARAMS
  payload: OverlayParams
}

export type OverlayTypes = SetIsVisibleAction | SetParamsAction;