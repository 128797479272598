import React from 'react';
import { MyTeamTableWrapper } from '../components/EmployeeList/MyTeam/MyTeamTableWrapper';

export const MyTeamPage: React.FC = () => {
  return (
    <>
      <MyTeamTableWrapper />
    </>
  );
}
