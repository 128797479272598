import React, {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useStore} from 'react-redux';
import {PopupIndexedProps} from './PopupPropsInterface';
import {POPUP_STATE} from '../../store/reducers';

const time = (new Date()).getTime();
const defaultProps: PopupIndexedProps = {
  popupName: 'none',
  popupIndex: time.toString(),
  id: `modal-${time}`,
  title: '',
  size: 'sm',
  centered: false
};

export const PopupIndexed: FunctionComponent<PopupIndexedProps> = (props: PropsWithChildren<PopupIndexedProps>) => {
  const {getState, subscribe} = useStore();
  const state = getState()[POPUP_STATE][props.popupName];
  const [show, setShow] = useState(state.has(props.popupIndex) ? state.get(props.popupIndex) : false);

  useEffect(() => {
    return subscribe(() => {
      const localState = getState()[POPUP_STATE][props.popupName];
      setShow(localState.has(props.popupIndex) ? localState.get(props.popupIndex) : false);
    });
  }, [show]);

  return (
    <Modal
      id={props.id ?? defaultProps.id}
      size={props.size ?? defaultProps.size}
      centered={props.centered ?? defaultProps.centered}
      show={show}
      backdropClassName={'extend-modal-backdrop'}
      onHide={() => {
      }}>
      {(props.title) ?
        <Modal.Header>
          <Modal.Title>
            {props.title}
          </Modal.Title>
        </Modal.Header>
        : ''
      }
      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  );
};
