import yup from '../../../utils/yup-extended';

export const schema = yup.object().shape({
  location: yup.string()
    .required('Location is required')
    .testSpecialChars()
    .testXSS(),
  summary: yup.string()
    .max(2000)
    .notRequired()
    .testSpecialChars()
    .testXSS(),
  coreskills: yup.array()
    .of(yup.string()
      .testSpecialChars()
      .testXSS()
    )
    .notRequired(),
  additionalskills: yup.string()
    .notRequired()
    .max(2000)
    .testSpecialChars()
    .testXSS(),
  work2: yup.array()
    .of(yup.object().shape({
      position3: yup.string()
        .notRequired()
        .testSpecialChars()
        .testXSS(),
      projectdescription: yup.string()
        .notRequired()
        .max(2000)
        .testSpecialChars()
        .testXSS(),
      projectname: yup.string()
        .notRequired()
        .testSpecialChars()
        .testXSS(),
      technologiesused2: yup.string()
        .notRequired()
        .max(1000)
        .testSpecialChars()
        .testXSS(),
      responsibilities2: yup.array()
        .of(yup.string()
          .notRequired()
          .testSpecialChars()
          .testXSS()
        )
        .notRequired()
    }))
    .notRequired(),
  workexperiencebeforeCogniance2: yup.array()
    .of(yup.object().shape({
      company2: yup.string()
        .notRequired()
        .testSpecialChars()
        .testXSS(),
      position2: yup.string()
        .notRequired()
        .testSpecialChars()
        .testXSS(),
      officelocation2: yup.string()
        .notRequired()
        .testSpecialChars()
        .testXSS(),
      projectdescription: yup.string()
        .notRequired()
        .max(2000)
        .testSpecialChars()
        .testXSS(),
      technologiesused: yup.string()
        .notRequired()
        .max(1000)
        .testSpecialChars()
        .testXSS(),
      responsibilities: yup.array()
        .of(yup.string()
          .notRequired()
          .testSpecialChars()
          .testXSS()
        )
        .notRequired()
    }))
    .notRequired(),
  certifications: yup.string()
    .notRequired()
    .max(2000)
    .testSpecialChars()
    .testXSS()
})