import {STAFF_SET_LOADING, STAFF_SET_MY_STAFF, STAFF_SET_SHOULD_UPDATE, STAFF_SET_STAFF, StaffTypes} from './types';
import {Staff} from '../../interfaces/Staff';
import {STAFF_GET_MY_FAILURE, STAFF_GET_MY_LOADING, STAFF_GET_MY_SUCCESS} from "../workRecord/types";
import StaffClient from "../../services/http/StaffClient";

export function setMyStaff(staff: Staff | null): StaffTypes {
  return {
    type: STAFF_SET_MY_STAFF,
    payload: staff
  }
}

export function setStaff(staff: Staff): StaffTypes {
  return {
    type: STAFF_SET_STAFF,
    payload: staff
  }
}

export function setLoading(loading: boolean): StaffTypes {
  return {
    type: STAFF_SET_LOADING,
    payload: loading
  }
}

export function getStaffMy(): any {
  return async (dispatch: any) => {
    dispatch({ type: STAFF_GET_MY_LOADING });
    try {
      const { status, data }: any = await StaffClient.getMy();
      if (status > 199 && status < 300) {
        dispatch({ type: STAFF_GET_MY_SUCCESS, payload: data });
      }
    } catch (e: any) {
      dispatch({ type: STAFF_GET_MY_FAILURE });
      console.error("error", e.response?.statusText);
    }
  };
}

export function setShouldUpdate(shouldUpdate: boolean): StaffTypes {
  return {
    type: STAFF_SET_SHOULD_UPDATE,
    payload: shouldUpdate
  }
}
