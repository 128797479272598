import React, { useState } from 'react';

import useOutsideClick from '../../../hooks/outsideClick';

import { NotificationEmpty } from './NotificationEmpty/NotificationEmpty';

import './notification.scss';

export const Notification: React.FC = () => {

  const [list, setList] = useState<Array<any>>([]);

  const { ref, isVisible, setIsVisible } = useOutsideClick(false);

  return (
    <div className={`nav-item--notification notification ${isVisible ? 'active' : ''}`}>
      <div className="notification--title"
           onClick={() => setIsVisible(!isVisible)}
           ref={ref}
           data-testid="testNotification">
      </div>
      <div className={`notification--results ${isVisible ? 'd-block' : 'd-none'}`}>
        <div className="notification--results_header">Notifications</div>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item" role="presentation">
            <a id="tabApproval"
               className="nav-link active"
               data-toggle="tab"
               // href="#contentApproval"
               aria-controls="contentApproval">Approval
            </a>
          </li>
          <li className="nav-item" role="presentation">
            <a id="tabUpdates"
               className="nav-link disabled"
               data-toggle="tab"
               // href="#contentUpdates"
               aria-controls="contentUpdates"
               tabIndex={-1}
               aria-disabled="true">Updates
            </a>
          </li>
        </ul>
        <div className="tab-content" id="tabNotification">
          <div className="tab-pane fade show active"
               id="contentApproval"
               role="tabpanel"
               aria-labelledby="tabApproval">
            {
              (list.length)
              ? <div></div>
              : <NotificationEmpty />
            }
          </div>
          <div className="tab-pane fade"
               id="contentUpdates"
               role="tabpanel"
               aria-labelledby="tabUpdates">
            {
              (list.length)
                ? <div></div>
                : <NotificationEmpty />
            }
          </div>
        </div>
      </div>
    </div>
  )
}