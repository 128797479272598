import {EducationItemResponse} from '../../interfaces/http/education/EducationItemResponse';
import {EducationItem as EducationItemInterface} from '../../interfaces/EducationItem';
import EducationItem from '../../models/EducationItem';

class EducationMapper {
  public creatEducationItemFromResponse(data: EducationItemResponse): EducationItemInterface {
    let educationItem: EducationItemInterface = new EducationItem();
    educationItem.id = data.id;
    educationItem.degree = data.degree;
    educationItem.fieldOfStudy = data.field_of_study;
    educationItem.location = data.location;
    educationItem.schoolName = data.school_name;
    educationItem.yearOfGraduation = data.year_of_graduation;
    educationItem.staffId = data.staff_id;

    return educationItem;
  }
}

export default new EducationMapper();
