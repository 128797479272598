import React from 'react';

import { TurnoverValue } from '../TurnoverValue/TurnoverValue';
import { ChartBar } from '../ChartBar/ChartBar';
import { ChartBarHorizontal } from '../ChartBarHorizontal/ChartBarHorizontal';

import './turnover-detail-item.scss'

interface Props {
  data: any
}

export const TurnoverDetailItem: React.FC<Props> = (props: Props) => {
  const { data } = { ...props };

  const details = {
    good_detail_url: data.good_detail_url,
    bad_detail_url: data.bad_detail_url
  };
  const turnover = data.turnover;
  const label = data.label;
  let dataSet = data.data;
  let dataSetDetails = ['', ''];
  const isData = dataSet && dataSet.length > 0;
  const isMultiple = dataSet && dataSet.length > 1;
  let overrideLabels: Array<any> = [];
  let dataForValueBars: Array<any> = [];

  if (!isMultiple && isData) {
    const first = dataSet[0];
    const sum = (first.good + first.bad) === 0 ? 1 : first.good + first.bad;
    const goodP = parseFloat((first.good / sum * turnover).toFixed(1));
    const badP = parseFloat((first.bad / sum * turnover).toFixed(1));
    dataSet = [goodP, badP];
    dataSetDetails = [first.good_detail_url, first.bad_detail_url];
    overrideLabels = [`${goodP}% (${first.good})`, `${badP}% (${first.bad})`];
    dataForValueBars = [{good: first.good, bad: first.bad}];
  }

  return (
    <div className="turnover-detail-item">
      <div className="turnover-detail-item-container">
        <div className="row customClearfix">
          <div className="commonStat">
            <TurnoverValue
              value={turnover}
              label={label}
              dateStart={data.from_date}
              dateEnd={data.to_date}
              data={isMultiple ? dataSet : dataForValueBars}
              details={details}
            />
          </div>
          <div className="chartWrapper">
            {
              isData
                ? (
                  isMultiple
                    ? (
                      <div className="multiple-chart-wrapper long">
                        <ChartBar
                          data={ dataSet }
                          detailMap={['bad_detail_url', 'good_detail_url']}
                        />
                      </div>
                    )
                    : (
                      <div className="single-chart-wrapper d-table">
                        <ChartBarHorizontal
                          data={ dataSet }
                          overrideLabels={ overrideLabels }
                          details={ dataSetDetails }
                        />
                      </div>
                    )
                )
                : <div className="no-data-wrapper">No data available</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
