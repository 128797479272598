import React, { useEffect, useState } from 'react';

// store
import store from '../../../store/store';
import { loaderActions } from '../../../store/loader/actions';

import client from './../../../services/http/StructureClient';

// components
import { UnitTreeChart } from './UnitTreeChart/UnitTreeChart';
import { UnitTreePreview } from './UnitTreePreview/UnitTreePreview';
import { WRPreview } from '../../WR/WRPreview/WRPreview';

import './unit-tree.scss';

const ID = 'unitTree';

export const UnitTree: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [tree, setTree] = useState<Array<any>>([]);
  const [selectedUnit, setSelectedUnit] = useState<Object>({});
  const [selectedWRId, setSelectedWRId] = useState<number | null>(null);
  const [showUnitPreview, setShowUnitPreview] = useState<boolean>(false);
  const [showWRPreview, setShowWRPreview] = useState<boolean>(false);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);

  useEffect(() => {
    if (isLoaded) {
      return;
    }
    store.dispatch(loaderActions.showLoader());
    client.getUnitTree()
      .then((data: any) => {
        if (data.data) {
          setTree([data.data]);
        }
      })
      .catch((error: any) => {
        if (error.status === 403) {
          setNoPermissions(true);
        }
      })
      .finally(() => {
        setIsLoaded(true);
        store.dispatch(loaderActions.hideLoader());
      });
  }, [tree]);

  if (!isLoaded) {
    return <></>;
  }

  return (
    <>
      {
        noPermissions
          ? <div className="text-center">No permissions to view this data</div>
          : (
            tree
              ? (
                <div className="unit-tree" id={ID}>
                  <UnitTreeChart
                    tree={tree}
                    setSelectedUnit={setSelectedUnit}
                    setShowUnitPreview={setShowUnitPreview}/>
                  <UnitTreePreview
                    selectedUnit={selectedUnit}
                    showUnitPreview={showUnitPreview}
                    setShowUnitPreview={setShowUnitPreview}
                    setSelectedWRId={setSelectedWRId}/>
                  <WRPreview
                    selectedWRId={selectedWRId}
                    setSelectedWRId={ setSelectedWRId }
                    showWRPreview={showWRPreview}
                    setShowWRPreview={setShowWRPreview}/>
                </div>
              )
              : <></>
          )
      }
    </>
  );
}
