import { FeedbackI } from '../../interfaces/entity/Feedback';
import client from '../http/FeedbackClient';
import { FeedbackItemStateInterface } from '../../components/Feedback/Forms/FeedbackItem/FeedbackItemState';
import DateHelper from '../../utils/date/DateHelper';

class FeedbackHelper {

  public create(feedback: FeedbackI, formValues: FeedbackItemStateInterface, wrId: number) {
    return client.create(this.createFeedbackBody(feedback, formValues), wrId);
  }

  public update(feedback: FeedbackI, formValues: FeedbackItemStateInterface, wrId: number) {
    return client.update(this.createFeedbackBody(feedback, formValues), wrId, feedback.id);
  }

  private createFeedbackBody(feedback: FeedbackI, formValues: FeedbackItemStateInterface): any {
    const body: any = {};
    body.author = formValues.reviewer.id;
    body.type = formValues.type.id;
    body.value = formValues.value;
    body.period_start = DateHelper.getFormatted2Date(formValues.periodStart);
    body.period_end = DateHelper.getFormatted2Date(formValues.periodEnd);
    if (formValues.project) {
      body.project = formValues.project.id;
    }
    if (formValues.projectManager) {
      body.project_manager = formValues.projectManager.id;
    }
    return body;
  }
}

export default new FeedbackHelper();
