import http, { Http } from './Http';
import { ENDPOINTS } from '../../constants';

class MyTeamClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getSubordinates(staffId: number) {
    return this.http.getList(`${ENDPOINTS.MY_SUBORDINATES}?staff=${staffId}`);
  }

  public getDirectMyTeam(staffId: number) {
    return this.http.getList(`${ENDPOINTS.MY_DIRECT_TEAM}?staff=${staffId}`);
  }
}

export default new MyTeamClient(http);
