import {EducationItem as EducationItemInterface} from './../interfaces/EducationItem';

export default class EducationItem implements EducationItemInterface {
  id: number = NaN;
  degree: number = 0;
  fieldOfStudy: string = '';
  location: string = '';
  schoolName: string = '';
  yearOfGraduation: string = '';
  staffId: number = NaN;
}
