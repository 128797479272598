import { Skill as SkillInterface } from '../../interfaces/entity/Skill';

export default class Skill implements SkillInterface {
  id = NaN;
  attributeId? = NaN;
  name = '';
  originalName = '';
  level?: 'Beginner' | 'Intermediate' | 'Advanced' | 'Native' = 'Intermediate';
  hierarchyLevel = 1;
  parent? = NaN;
  parents: Array<number> = [];
  hasChildren = false;

  getSeparator = (): string => '➞';
  getShortedName = (): string => {
    return (this.hierarchyLevel > 2)
      ? `${this.name.substr(0, this.name.indexOf(this.getSeparator()) + 5)}` +
      ` ... ${this.getSeparator()} ${this.originalName}`
      : this.name
  }
}
