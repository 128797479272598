import http, { Http } from './Http';
import { ENDPOINTS } from '../../constants';

export class StructureClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getUnitTree() {
    return this.http.getAction(ENDPOINTS.STRUCTURE_UNIT_TREE);
  }

  public getLMTree() {
    return this.http.getAction(ENDPOINTS.STRUCTURE_LM_TREE);
  }

  public getCMTree() {
    return this.http.getAction(ENDPOINTS.STRUCTURE_CM_TREE);
  }
}

export default new StructureClient(http);
