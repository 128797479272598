import React from 'react';

import './staff-attribute.scss';

interface StaffAttributePlainProps {
  name: string;
  value: string;
}

export const StaffAttributePlain: React.FC<StaffAttributePlainProps> = (props: StaffAttributePlainProps) => {
  const [name] = React.useState<string>(props.name);
  const [value, setValue] = React.useState(props.value);
  
  React.useEffect(() => {
    setValue(props.value)
  }, [props]);

  return (
    <div className="staff-attribute">
      <div className="staff-attribute__name">
        { name }
      </div>
      <div className="staff-attribute__value">
        { (value) ? value : '' }
      </div>
    </div>
  );
}
