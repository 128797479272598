import React from 'react'
import { Value } from '../../../interfaces/Value';

import './staff-attribute.scss';

interface StaffAttributeStringProps {
  name: string;
  value: Value<string>;
}

export const StaffAttributeString: React.FC<StaffAttributeStringProps> = (props: StaffAttributeStringProps) => {
  const [name] = React.useState(props.name);
  const [value, setValue] = React.useState<Value<string>>(props.value);
  React.useEffect(() => {
    setValue(props.value);
  }, [props]);

  return (
    <div className="staff-attribute">
      <div className="staff-attribute__name">
        {name}
      </div>
      <div className="staff-attribute__value">
        { (value.value ? value.value : <></>) }
      </div>
    </div>
  );
}
