import React, {Fragment, FunctionComponent, ReactElement} from 'react'
import {WorkRecord} from '../../../../interfaces/WorkRecord';
import permissionService from '../../../../services/security/PermissionWRService';
import {ATTRIBUTES_MAP} from '../../../../constants/ATTRIBUTES_MAP';
import {useSelector} from "react-redux";
import {GlobalState} from "../../../../store/types";
import {STAFF_STATE} from "../../../../store/reducers";

const names = {...ATTRIBUTES_MAP.workRecordBackend, ...ATTRIBUTES_MAP.workRecordProperties};

interface Props {
  wr: WorkRecord,
  name: string,
  permission?: string,
  attribute: ReactElement
}

export const AttributeWithPermission: FunctionComponent<Props> = (props: Props) => {
  const {wr, name, permission, attribute} = {...props};
  const action: string = (!permission) ? 'view' : permission;
  permissionService.setUser(useSelector((state: GlobalState) => state[STAFF_STATE].myStaff));
  if (permissionService.isGranted(names[name], action, wr)) {
    return (attribute);
  }

  return <Fragment/>;
};
