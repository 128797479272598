export interface ReportItemI {
  id: number | null,
  staffId: number | null,
  code: string,
  name: string,
  position: string,
  startDate: Date | null,
  endDate: Date | null,
  orgStructure: string,
  department: string,
  subDepartment: string,
  lineManager: string,
  location: string,
  isBillable: boolean,
  turnover: string,
  reasonToLeave: string,
  nextEmployer: string
}

export default class ReportItem implements ReportItemI {
  id: number | null = null;
  staffId: number | null = null;
  code = '';
  name = '';
  position = '';
  startDate: Date | null = null;
  endDate: Date | null = null;
  orgStructure = '';
  department = '';
  subDepartment = '';
  lineManager = '';
  location = '';
  isBillable = false;
  turnover = '';
  reasonToLeave = '';
  nextEmployer = '';
}
