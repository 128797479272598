import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';
import DateHelper from "../../utils/date/DateHelper";
import {ColumnT, FilterOptionsT} from "../../components/HRDashboard/Compliance/ComplianceTableTypes";

class HRDashboardClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getSnapshotCSV(asForDate: Date, compareDate: Date | null, groupBy: string) {
    let queryString = 'date=' + DateHelper.getFormatted2Date(asForDate);
    if (compareDate) {
      queryString += '&compare_date=' + DateHelper.getFormatted2Date(compareDate);
    }
    queryString += '&group_by=' + groupBy;

    this.http.setResponseType('arraybuffer');
    return this.http.get(ENDPOINTS.HR_DASHBOARD_HC_SNAPSHOT_EXPORT_CSV(queryString));
  }

  public getSnapshotNavision(asForDate: Date, compareDate: Date | null) {
    let queryString = 'date=' + DateHelper.getFormatted2Date(asForDate);
    if (compareDate) {
      queryString += '&compare_date=' + DateHelper.getFormatted2Date(compareDate);
    }

    this.http.setResponseType('arraybuffer');
    return this.http.get(ENDPOINTS.HR_DASHBOARD_HC_SNAPSHOT_EXPORT_NAVISION(queryString));
  }

  public getSnapshotHeadcountData(groupBy: string, asForDate: Date, compareDate: Date | null) {
    const params = [];
    if (asForDate) {
      params.push('date=' + DateHelper.getFormatted2Date(asForDate));
    }
    if (compareDate) {
      params.push('compare_date=' + DateHelper.getFormatted2Date(compareDate));
    }
    params.push('group_by=' + groupBy);
    const queryString = params.join('&');
    return this.http.get(ENDPOINTS.HR_DASHBOARD_HC_SNAPSHOT_GET_HEADCOUNT_DATA(queryString));
  }

  public getHeadcountData(
    params: {
      period_range: string,
      period_type: string,
      date_from?: string,
      date_to?: string,
      location?: string
    }
  ) {
    const paramsQuery = Object.entries(params).map((value) => value.join('=')).join('&');

    return this.http.get(ENDPOINTS.HR_DASHBOARD_HEADCOUNT_DATA(paramsQuery));
  }

  public getTurnoverData(
    params: {
      period_range: string,
      period_type: string,
      tab: string,
      date_from?: string,
      date_to?: string,
      location?: string
    }
  ) {
    const paramsQuery = Object.entries(params).map((value) => value.join('=')).join('&');

    return this.http.get(ENDPOINTS.HR_DASHBOARD_TURNOVER_DATA(paramsQuery));
  }

  public getTurnoverCSV(params: {
    period_range: string,
    period_type: string,
    tab: string,
    date_from?: string,
    date_to?: string,
    location?: string
  }) {
    const paramsQuery = Object.entries(params).map((value) => value.join('=')).join('&');
    this.http.setResponseType('arraybuffer');

    return this.http.get(ENDPOINTS.HR_DASHBOARD_TURNOVER_EXPORT_CSV(paramsQuery));
  }

  public getTenureCSV(filter: string, from: Date, to: Date, lmId: string, seniority: string) {
    const params = {
      type: filter,
      from: DateHelper.getFormatted2Date(from),
      to: DateHelper.getFormatted2Date(to),
      lineManagerId: lmId,
      seniority: seniority,
    }
    this.http.setResponseType('arraybuffer');
    return this.http.get(ENDPOINTS.HR_DASHBOARD_TENURE_EXPORT_CSV(JSON.stringify(params)));
  }

  public getTenureData(page: number, pageSize: number, filter: string, from: Date, to: Date, lmId: string, seniority: string) {
    const params = {
      type: filter,
      from: DateHelper.getFormatted2Date(from),
      to: DateHelper.getFormatted2Date(to),
      lineManagerId: lmId,
      seniority: seniority,
    }
    return this.http.get(ENDPOINTS.HR_DASHBOARD_TENURE_DATA(page, pageSize, JSON.stringify(params)));
  }

  public getLeaversData(
    params: {
      periodRange: string,
      duration: string,
      turnoverType: string,
      dateFrom?: string,
      dateTo?: string,
      location?: string
    }
  ) {
    const paramsConverted: any = {
      period_range: params.periodRange,
      duration: params.duration,
      turnover_type: params.turnoverType
    };
    if (params.dateFrom) {
      paramsConverted.date_from = params.dateFrom;
    }
    if (params.dateTo) {
      paramsConverted.date_to = params.dateTo;
    }
    if (params.location) {
      paramsConverted.location = params.location;
    }
    const paramsQuery = Object.entries(paramsConverted).map((value) => value.join('=')).join('&');

    return this.http.get(ENDPOINTS.HR_DASHBOARD_LEAVERS_DATA(paramsQuery));
  }

  public getComplianceSubordinates(page: number, columns: Array<ColumnT>, filters: FilterOptionsT, sorting: any = {}) {
    const paramsF = {};
    const paramsS = {};
    columns.forEach((column) => {
      if (filters[column.accessor]?.length) {
        const values = filters[column.accessor].map((item) => {
          return column.backendKey ? item[column.backendKey] : item.id;
        });
        Object.defineProperty(paramsF, `${column.backend}`, { value: values, enumerable: true });
      }
      if (sorting.hasOwnProperty(column.accessor) && sorting[column.accessor]) {
        Object.defineProperty(paramsS, `${column.backend}`, { value: sorting[column.accessor], enumerable: true });
      }
    });
    return this.http.get(ENDPOINTS.COMPLIANCE_SUBORDINATES_LIST(page, JSON.stringify(paramsF), JSON.stringify(paramsS)));
  }

  public getComplianceAll(page: number, columns: Array<ColumnT>, filters: FilterOptionsT, sorting: any = {}) {
    const paramsF = {};
    const paramsS = {};
    columns.forEach((column) => {
      if (filters[column.accessor]?.length) {
        const values = filters[column.accessor].map((item) => {
          return column.backendKey ? item[column.backendKey] : item.id;
        });
        Object.defineProperty(paramsF, `${column.backend}`, { value: values, enumerable: true });
      }
      if (sorting.hasOwnProperty(column.accessor) && sorting[column.accessor]) {
        Object.defineProperty(paramsS, `${column.backend}`, { value: sorting[column.accessor], enumerable: true });
      }
    });
    return this.http.get(ENDPOINTS.COMPLIANCE_ALL_LIST(page, JSON.stringify(paramsF), JSON.stringify(paramsS)));
  }

  public getComplianceCSV(columns: Array<ColumnT>, filters: FilterOptionsT, sorting: any = {}) {
    const paramsF = {};
    const paramsS = {};
    columns.forEach((column) => {
      if (filters[column.accessor].length) {
        const values = filters[column.accessor].map((item) => {
          return column.backendKey ? item[column.backendKey] : item.id;
        });
        Object.defineProperty(paramsF, `${column.backend}`, { value: values, enumerable: true });
      }
      if (sorting.hasOwnProperty(column.accessor) && sorting[column.accessor]) {
        Object.defineProperty(paramsS, `${column.backend}`, { value: sorting[column.accessor], enumerable: true });
      }
    });
    this.http.setResponseType('arraybuffer');
    return this.http.get(ENDPOINTS.COMPLIANCE_EXPORT_CSV(JSON.stringify(paramsF), JSON.stringify(paramsS)));
  }
  
  public getComplianceSubordinatesCSV(columns: Array<ColumnT>, filters: FilterOptionsT, sorting: any = {}, peoplePartnerId: number) {
    const paramsF = {};
    const paramsS = {};
    columns.forEach((column) => {
      if (filters[column.accessor].length) {
        const values = filters[column.accessor].map((item) => {
          return column.backendKey ? item[column.backendKey] : item.id;
        });
        Object.defineProperty(paramsF, `${column.backend}`, { value: values, enumerable: true });
      }
      if (sorting.hasOwnProperty(column.accessor) && sorting[column.accessor]) {
        Object.defineProperty(paramsS, `${column.backend}`, { value: sorting[column.accessor], enumerable: true });
      }
    });
    if (peoplePartnerId) {
      Object.defineProperty(paramsF, 'peoplePartner', { value: peoplePartnerId, enumerable: true });
    }
    this.http.setResponseType('arraybuffer');
    return this.http.get(ENDPOINTS.COMPLIANCE_EXPORT_CSV(JSON.stringify(paramsF), JSON.stringify(paramsS)));
  }

}

export default new HRDashboardClient(http);
