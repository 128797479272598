import React, {Fragment, FunctionComponent, ReactElement, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import ValueStaff from '../../../models/ValueStaff';

import './wr-attribute.scss';

interface Props {
  name: string,
  value: ValueStaff | null,
  tooltip?: ReactElement
}

export const WRAttributeStaff: FunctionComponent<Props> = (props: Props) => {
  const [name] = useState<string>(props.name);
  const [value, setValue] = useState<ValueStaff | null>(props.value);

  useEffect(() => {
    setValue(props.value)
  }, [props.value]);

  return (
    <div className="work-record-attribute">
      <div className="work-record-attribute__name">
        {name}
      </div>
      <div className="work-record-attribute__value">
        {
          (value?.staffId)
          ? (
              <Link to={`/staff/${value.staffId}/work-records`}>
                {value.getName()}
              </Link>
            )
          : <></>
        }
        {(props.tooltip) ? (props.tooltip) : ''}
      </div>
    </div>
  );

  return <Fragment/>;
};
