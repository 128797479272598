import React, {FunctionComponent} from 'react';
import {Link} from "react-router-dom";

interface StaffCellProps {
  cell: {
    column: {
      staffId: string;
    };
    value: string;
    row: {
      original: any;
    };
  };
}

export const StaffCell: FunctionComponent<StaffCellProps> = (props: StaffCellProps) => {
  const cell = props.cell;
  const value: string = cell.value;
  const staffId: number = cell.row.original[cell.column.staffId];
  const wrId: number = cell.row.original['id'];

  if (value) {
    return (
      <div className="staff">
        <Link to={`/staff/${staffId}/work-records/${wrId}/main`}>
          {value}
        </Link>
      </div>
    )
  }
  return null;
}
