import React, {FunctionComponent} from 'react';

import './structure-type-switcher.scss';
import {NavLink} from "react-router-dom";

export const StructureTypeSwitcher: FunctionComponent = () => {
  return (
    <div className="p-xl-5 p-sm-2 structure-nav" data-testid="testStructureTypeSwitcher">
      <ul className="nav nav-pills justify-content-end text-uppercase">
        <li className="nav-item ml-3">
          <NavLink className="nav-link py-1 font-size--12" to="/structure/unit-tree" activeClassName="active">By Unit Structure</NavLink>
        </li>
        <li className="nav-item m-0">
          <NavLink className="nav-link py-1 font-size--12" to="/structure/lm-tree" activeClassName="active">By Line Management</NavLink>
        </li>
      </ul>
    </div>
  );
};