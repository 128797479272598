import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';

class FeedbackClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getFeedbacks(wrId: number) {
    return this.http.getAction(ENDPOINTS.FEEDBACK_WORK_RECORD(wrId));
  }

  public create(body: any, wrId: number) {
    return this.http.postAction(ENDPOINTS.FEEDBACK_WORK_RECORD_CREATE(wrId), body);
  }

  public update(body: any, wrId: number, id: number) {
    return this.http.put(ENDPOINTS.FEEDBACK_WORK_RECORD_UPDATE(wrId, id), body);
  }
}

export default new FeedbackClient(http);
