import React from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../store/types';
import { STAFF_STATE } from '../../../../store/reducers';
import { ProfileForm } from './ProfileForm';

interface EditProfileWrapperProps {
  popupName: string;
}

export const EditProfileWrapper: React.FC<EditProfileWrapperProps> = (props: EditProfileWrapperProps) => {
  const { popupName } = { ...props };
  const staff = useSelector((state: GlobalState) => state[STAFF_STATE].staff);

  return (
    <ProfileForm staff={ staff } popupName={ popupName } />
  );
}
