import store from '../store';
import {
  setIsActivateWorkRecordShown,
  setIsAddEducationShown, setIsAddFeedbackShown,
  setIsAddNewWorkRecordShown,
  setIsCloseWorkRecordShown,
  setIsEditEducationShown, setIsEditFeedbackShown,
  setIsEditProfileShown,
  setIsNextEmployerShown,
  setIsPositionDescriptionShown,
  setIsReasonToLeaveShown,
  setIsTurnoverShown,
  setIsEditSkillShown,
  setIsTrialStatusShown, setIsDocumentShown
} from './actions';

export const POPUP_SET_IS_EDIT_PROFILE_SHOWN = 'POPUP_SET_IS_EDIT_PROFILE_SHOWN';
export const POPUP_SET_IS_POSITION_DESCRIPTION_SHOWN = 'POPUP_SET_IS_POSITION_DESCRIPTION_SHOWN';
export const POPUP_SET_IS_ADD_NEW_WORK_RECORD_SHOWN = 'POPUP_SET_IS_ADD_NEW_WORK_RECORD_SHOWN';
export const POPUP_SET_IS_ACTIVATE_WORK_RECORD_SHOWN = 'POPUP_SET_IS_ACTIVATE_WORK_RECORD_SHOWN';
export const POPUP_SET_IS_CLOSE_WORK_RECORD_SHOWN = 'POPUP_SET_IS_CLOSE_WORK_RECORD_SHOWN';
export const POPUP_SET_IS_TURNOVER_SHOWN = 'POPUP_SET_IS_TURNOVER_SHOWN';
export const POPUP_SET_IS_TRIAL_STATUS_SHOWN = 'POPUP_SET_IS_TRIAL_STATUS_SHOWN';
export const POPUP_SET_IS_REASON_TO_LEAVE_SHOWN = 'POPUP_SET_IS_REASON_TO_LEAVE_SHOWN';
export const POPUP_SET_IS_NEXT_EMPLOYER_SHOWN = 'POPUP_SET_IS_NEXT_EMPLOYER_SHOWN';
export const POPUP_SET_IS_ADD_EDUCATION_SHOWN = 'POPUP_SET_IS_ADD_EDUCATION_SHOWN';
export const POPUP_SET_IS_EDIT_EDUCATION_SHOWN = 'POPUP_SET_IS_EDIT_EDUCATION_SHOWN';
export const POPUP_SET_IS_ADD_FEEDBACK_SHOWN = 'POPUP_SET_IS_ADD_FEEDBACK_SHOWN';
export const POPUP_SET_IS_EDIT_FEEDBACK_SHOWN = 'POPUP_SET_IS_EDIT_FEEDBACK_SHOWN';
export const POPUP_SET_IS_EDIT_SKILL_SHOWN = 'POPUP_SET_IS_EDIT_SKILL_SHOWN';
export const POPUP_SET_IS_DOCUMENT_SHOWN = 'POPUP_SET_IS_DOCUMENT_SHOWN';

export interface PopupState {
  editProfile: boolean,
  positionDescription: boolean,
  addNewWorkRecord: boolean,
  activateWorkRecord: boolean,
  closeWorkRecord: boolean,
  turnover: boolean,
  trialStatus: boolean,
  reasonToLeave: boolean,
  nextEmployer: boolean,
  addEducation: boolean,
  editEducation: Map<string, boolean>,
  addFeedback: boolean,
  editFeedback: Map<string, boolean>,
  editSkill: Map<string, boolean>,
  document: Map<string, boolean>
}

interface SetIsEditProfileShownAction {
  type: typeof POPUP_SET_IS_EDIT_PROFILE_SHOWN
  payload: boolean
}

interface SetIsPositionDescriptionShownAction {
  type: typeof POPUP_SET_IS_POSITION_DESCRIPTION_SHOWN
  payload: boolean
}

interface SetIsAddNewWorkRecordShownAction {
  type: typeof POPUP_SET_IS_ADD_NEW_WORK_RECORD_SHOWN
  payload: boolean
}

interface SetIsActivateWorkRecordShownAction {
  type: typeof POPUP_SET_IS_ACTIVATE_WORK_RECORD_SHOWN
  payload: boolean
}

interface SetIsCloseWorkRecordShownAction {
  type: typeof POPUP_SET_IS_CLOSE_WORK_RECORD_SHOWN
  payload: boolean
}

interface SetIsTurnoverShownAction {
  type: typeof POPUP_SET_IS_TURNOVER_SHOWN
  payload: boolean
}

interface SetIsTrialStatusShownAction {
  type: typeof POPUP_SET_IS_TRIAL_STATUS_SHOWN
  payload: boolean
}

interface SetIsReasonToLeaveShownAction {
  type: typeof POPUP_SET_IS_REASON_TO_LEAVE_SHOWN
  payload: boolean
}

interface SetIsNextEmployerShownAction {
  type: typeof POPUP_SET_IS_NEXT_EMPLOYER_SHOWN
  payload: boolean
}

interface SetIsAddEducationShownAction {
  type: typeof POPUP_SET_IS_ADD_EDUCATION_SHOWN
  payload: boolean
}

interface SetIsEditEducationShownAction {
  type: typeof POPUP_SET_IS_EDIT_EDUCATION_SHOWN
  payload: Map<string, boolean>
}

interface SetIsAddFeedbackShownAction {
  type: typeof POPUP_SET_IS_ADD_FEEDBACK_SHOWN
  payload: boolean
}

interface SetIsEditFeedbackShownAction {
  type: typeof POPUP_SET_IS_EDIT_FEEDBACK_SHOWN
  payload: Map<string, boolean>
}

interface SetIsEditSkillShownAction {
  type: typeof POPUP_SET_IS_EDIT_SKILL_SHOWN
  payload: Map<string, boolean>
}

interface SetIsDocumentShownAction {
  type: typeof POPUP_SET_IS_DOCUMENT_SHOWN
  payload: Map<string, boolean>
}

export type PopupTypes =
  SetIsEditProfileShownAction
  | SetIsPositionDescriptionShownAction
  | SetIsAddNewWorkRecordShownAction
  | SetIsActivateWorkRecordShownAction
  | SetIsCloseWorkRecordShownAction
  | SetIsTurnoverShownAction
  | SetIsTrialStatusShownAction
  | SetIsReasonToLeaveShownAction
  | SetIsNextEmployerShownAction
  | SetIsAddEducationShownAction
  | SetIsEditEducationShownAction
  | SetIsAddFeedbackShownAction
  | SetIsEditFeedbackShownAction
  | SetIsEditSkillShownAction
  | SetIsDocumentShownAction

export function dispatchPopupState(popupName: string, isShown: boolean) {
  switch (popupName) {
    case 'editProfile':
      store.dispatch(setIsEditProfileShown(isShown));
      break;
    case 'positionDescription':
      store.dispatch(setIsPositionDescriptionShown(isShown));
      break;
    case 'addNewWorkRecord':
      store.dispatch(setIsAddNewWorkRecordShown(isShown));
      break;
    case 'activateWorkRecord':
      store.dispatch(setIsActivateWorkRecordShown(isShown));
      break;
    case 'closeWorkRecord':
      store.dispatch(setIsCloseWorkRecordShown(isShown));
      break;
    case 'turnover':
      store.dispatch(setIsTurnoverShown(isShown));
      break;
    case 'trialStatus':
      store.dispatch(setIsTrialStatusShown(isShown));
      break;
    case 'reasonToLeave':
      store.dispatch(setIsReasonToLeaveShown(isShown));
      break;
    case 'nextEmployer':
      store.dispatch(setIsNextEmployerShown(isShown));
      break;
    case 'addEducation':
      store.dispatch(setIsAddEducationShown(isShown));
      break;
    case 'addFeedback':
      store.dispatch(setIsAddFeedbackShown(isShown));
      break;
  }
}

export function dispatchIndexedPopupState(popupName: string, index: string, isShown: boolean) {
  switch (popupName) {
    case 'editEducation':
      store.dispatch(setIsEditEducationShown(index, isShown));
      break;
    case 'editFeedback':
      store.dispatch(setIsEditFeedbackShown(index, isShown));
      break;
    case 'editSkill':
      store.dispatch(setIsEditSkillShown(index, isShown));
      break;
    case 'document':
      store.dispatch(setIsDocumentShown(index, isShown));
      break;
  }
}
