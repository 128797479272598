import yup from '../../../../utils/yup-extended';

const dictionaryTransform = (currentV: any, originalV: any) => (originalV.id) ? originalV.id : null;

export const schema = yup.object()
  .shape({
    type: yup.string()
      .required('Type is required')
      .transform(dictionaryTransform),
    reviewer: yup.number()
      .required('Reviewer is required')
      .transform(dictionaryTransform),
    periodStart: yup.date()
      .required('Period start is required'),
    periodEnd: yup.date()
      .required('Period end is required'),
    project: yup.string()
      .nullable()
      .transform(dictionaryTransform),
    projectManager: yup.number()
      .nullable()
      .transform(dictionaryTransform),
    value: yup.string()
      .max(3000)
      .required('Feedback is required')
      .testSpecialChars()
      .testXSS()
  });
