import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import { Field, FieldArray, Form, Formik, ErrorMessage } from 'formik';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

// services
import CVClient from '../../../services/http/CVClient';
import DictionaryStore from '../../../services/dictionary/DictionaryStore';
import EducationClient from '../../../services/http/EducationClient';

import CVModel from '../../../models/CV';

// store
import store from '../../../store/store';
import { cvActions } from '../../../store/cv/actions';
import { loaderActions } from '../../../store/loader/actions';
import { setOverlayIsVisible } from '../../../store/overlay/actions';

// components
import { DateField } from '../../Forms/DateField';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';
import { WRCvList } from '../../WR/WRCv/WRCvList';

import { CV_STATUS_APPROVED } from '../../../store/cv/types';

import { Staff } from '../../../interfaces/Staff';
import { WorkRecord } from '../../../interfaces/WorkRecord';
import { CVInterface } from '../../../interfaces/CVInterface';
import CVMapper from '../../../mappers/response/CVMapper';

import { schema } from './CVFormSchema';
import { WRCvAutoSave } from '../../WR/WRCv/WRCvAutoSave';
import { SelectFieldCustom } from '../../Forms/SelectFieldCustom';

import { ROLES, COMMON } from '../../../constants';

import './cv-form.scss';

export const MODE_CREATE = 'create';
export const MODE_UPDATE = 'update';
type ModeT = typeof MODE_CREATE | typeof MODE_UPDATE;

type PropsType = {
  cv: CVInterface | null,
  staffId: number | null,
  wrId: number | null,
  staff: Staff | null,
  workRecord: WorkRecord | null,
  cvListShow: boolean,
  setIsSaving: Function,
  mode: ModeT
}

export const CvForm: React.FC<PropsType> = (props: PropsType) => {
  const staffId: number = props.staffId ?? 0;
  const wrId: number = props.wrId ?? 0;
  const [initialState, setInitialState] = useState<CVInterface | null>(null);
  const [isUnpublished, setIsUnPublished] = useState<boolean>(false);
  
  useEffect(() => {
    if (props.cv) {
      setInitialState(props.cv);
    } else if (props.workRecord) {
      let unpublishedCv = {};
      let savedCV = localStorage.getItem(`unpublishedCV-${props.wrId}`);
      if (savedCV) {
        const parsed = JSON.parse(savedCV);
        if (Object.keys(parsed).length > 0) {
          unpublishedCv = parsed;
          setIsUnPublished(true);
        }
      }
      const cv = new CVModel();
      let dataLocation = {};
      if (props.workRecord.location) {
        dataLocation = { location: `${props.workRecord.location.value}, ${props.workRecord.location.country}` };
      }
      cv.data = { ...dataLocation, ...unpublishedCv };
      setInitialState(cv);
    }
  }, [props.cv, props.workRecord]);

  const [locations, setLocations] = useState([]);
  const [educationItems, setEducationItems] = useState<Array<any> | null>(null);

  useEffect(() => {
    CVClient.getConfig().then((response: any) => {
      if (response.locations?.length) {
        setLocations(response.locations.sort());
      }
    })
  }, []);

  useEffect(() => {
    if (staffId) {
      DictionaryStore.getDegrees()
        .then((degrees: any) => {
          EducationClient.getEducationItems(staffId).then((educationItems: any) => {
            if (educationItems.length && degrees.length) {
              educationItems.forEach((item: any) => {
                item.degree = degrees[item.degree];
              });
              setEducationItems(educationItems);
            }
          });
        });
    }
  }, [props.staffId]);

  const history = useHistory();

  const submitFunction = (values: any) => {
    props.setIsSaving(true);
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    if (typeof values === 'object') {
      values.firstname = props.staff?.firstName.value;
      values.lastname = props.staff?.lastName.value;
      values.position = props.workRecord?.position.value;
    }
    const body = `{"data":${JSON.stringify(values)}}`;
    if (props.wrId) {
      if (props.cv?.id) {
        CVClient.update(body, props.wrId)
          .then((response: any) => {
            if (response.status === 200) {
              const data = CVMapper.createCVFromResponse(response.data);
              store.dispatch(cvActions.setData(data));
              toastr.success('', 'CV has been saved successfully');
              history.push(`/staff/${props.staffId}/work-records/${props.wrId}/cv/show`);
            }
          })
          .catch((e) => {
            toastr.error('','Oops, CV save failed, try again please');
          })
          .finally(() => {
            props.setIsSaving(false);
            store.dispatch(setOverlayIsVisible(false));
            store.dispatch(loaderActions.hideLoader());
          });
      } else {
        CVClient.create(body, props.wrId)
          .then((response: any) => {
            if (response.status === 200) {
              const data = CVMapper.createCVFromResponse(response.data);
              store.dispatch(cvActions.setData(data));
              toastr.success('', 'CV has been created successfully');
              history.push(`/staff/${props.staffId}/work-records/${props.wrId}/cv/show`);
            }
          })
          .catch((e) => {
            toastr.error('','Oops, CV creating failed, try again please');
          })
          .finally(() => {
            props.setIsSaving(false);
            store.dispatch(setOverlayIsVisible(false));
            store.dispatch(loaderActions.hideLoader());
          });
      }
    }
  }

  const arrayWithoutBlankLines = (array: any) => {
    return array.filter((value: any) => {
      return Boolean(value);
    });
  }

  const isObjectEmpty = (obj: any) => {
    let isEmpty = true;
    if (typeof obj === 'string') {
      if (obj) {
        isEmpty = false;
      }
    } else if (typeof obj === 'object') {
      for (let element in obj) {
        if (element !== '$$hashKey') {
          if (!isObjectEmpty(obj[element])) {
            isEmpty = false;
          }
        }
      }
    } else if (Array.isArray(obj)) {
      if (arrayWithoutBlankLines(obj).length > 0) {
        isEmpty = false;
      }
    }

    return isEmpty;
  }

  const isSuperArrayEmpty = (array: any) => {
    let isEmpty = true;
    if (typeof array !== 'undefined') {
      array.forEach((element: any) => {
        if (!isObjectEmpty(element)) {
          isEmpty = false;
        }
      });
    }
    return isEmpty;
  }
  
  if (!initialState) return <></>;

  return (
    <>
      {
        (props.mode === MODE_UPDATE)
          ? (
            <p className={`work-record-cv__status ${initialState.status === CV_STATUS_APPROVED ? 'work-record-cv__status-approved' : ''}`}>
              { initialState.status } <br/> { initialState.startDate }
            </p>
          )
          : isUnpublished ? <p className="work-record-cv__status work-record-cv__status-unpublished">Unpublished</p> : <></>
      }
      <Formik
        initialValues={ initialState.data }
        validationSchema={ schema }
        onSubmit={ values => submitFunction(values) }
      >
        {({ errors, touched, values, handleChange, setFieldValue }) => (
          <Form className="edb-form form-cv" id="form-cv">
            { props.mode === MODE_CREATE ? <WRCvAutoSave wrId={ wrId } setIsUnPublished={ setIsUnPublished } /> : <></> }
            <section className="border-bottom">
              <div className="box fullName">
                { props.staff && <p><b>{ props.staff.firstName?.value } { props.staff.lastName?.value }</b></p> }
              </div>
              <div className="box location">
                <p>
                  <Field
                    id="location"
                    name="location"
                    className={ `form-control ${errors.location && touched.location ? 'is-invalid' : ''}` }
                  />
                  <ErrorMessage name={ 'location' }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                </p>
              </div>
              <div className="box position">
                { props.workRecord && <p>{ props.workRecord.position?.value }</p> }
              </div>
            </section>
            { props.cvListShow && <WRCvList /> }
            <section className="border-bottom">
              <div>
                <p className="text-uppercase text-muted m-0">
                  Summary Background
                  <OverlayTrigger
                    trigger="focus"
                    placement="right"
                    overlay={(
                      <Popover id="popover1" className="cv-popover">
                        <Popover.Title>Summary Background</Popover.Title>
                        <Popover.Content>
                          <p>Required information (should be written referring to oneself in the third person):</p>
                          <p className="m-0">Years of experience: total in IT and in the dedicated field.</p>
                          <p className="m-0">Main professional skills or (and) duties.</p>
                          <p className="m-0">Personal characteristics which are used in work.</p>
                          <p>Professional interests (optional).</p>
                        </Popover.Content>
                      </Popover>
                    )}>
                    <span className="ask_icon" tabIndex={ -1 }>?</span>
                  </OverlayTrigger>
                </p>
                <div className="position-relative mr-5 mb-2">
                  <div className="row py-2">
                    <div className="col-12">
                      <Field
                        as="textarea"
                        className={ `form-control ${errors.summary && touched.summary ? 'is-invalid' : ''}` }
                        id="summary"
                        name="summary"
                        rows={ 5 }
                        placeholder={
                          "Example:" +
                          "\n4+ years of experience in IT, 2 of them as a QA engineer." +
                          "\nHas a systematic, product-oriented approach in quality assurance: widely uses business logic-based test analysis approach, mind maps, exploratory, usability and functional testing." +
                          "\nHas experience of driving the communication in distributed teams and owning the development process." +
                          "\nInterested in product/marketing management in IT industry."
                        }
                      />
                      <ErrorMessage name={ 'summary' }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        
            <section className="border-bottom">
              <FieldArray
                name="coreskills"
                render={arrayHelpers => (
                  <div>
                    <p className="text-uppercase text-muted m-0">
                      Core Skills
                      <OverlayTrigger
                        trigger="focus"
                        placement="right"
                        overlay={(
                          <Popover id="popover2" className="cv-popover">
                            <Popover.Title>Core Skills</Popover.Title>
                            <Popover.Content>
                              <p>Please fill out the skill information rows, using a comma to separate each element of the list.</p>
                              <ul>
                                <li>Programming Languages;</li>
                                <li>Technologies;</li>
                                <li>Tools;</li>
                                <li>Frameworks;</li>
                                <li>Databases;</li>
                                <li>Operating Systems;</li>
                                <li>Methodologies;</li>
                                <li>Other.</li>
                              </ul>
                            </Popover.Content>
                          </Popover>
                        )}>
                        <span className="ask_icon" tabIndex={ -1 }>?</span>
                      </OverlayTrigger>
                    </p>
                    {
                      (values.coreskills?.length && values.coreskills.length > 0)
                        ? (
                          values.coreskills.map((skill: any, index: number) => {
                            return (
                              <div className="position-relative mr-5 mb-2" key={ index }>
                                <FA icon={['far', 'times-circle']}
                                    className="position-absolute"
                                    onClick={() => arrayHelpers.remove(index)}
                                    style={{right: '8px', top: '16px', zIndex: 5}}
                                />
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <Field
                                      className={ `form-control ${errors.coreskills && touched.coreskills ? 'is-invalid' : ''}` }
                                      id={`coreskills.${index}`}
                                      name={`coreskills.${index}`}
                                    />
                                    <ErrorMessage name={ `coreskills.${index}` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                          
                              </div>
                            )
                          })
                        )
                        : <></>
                    }
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => arrayHelpers.push('')}
                    >
                      + Add Skill
                    </button>
                  </div>
                )}
              />
            </section>
        
            <section className="border-bottom">
              <FieldArray
                name="work2"
                render={arrayHelpers => (
                  <div>
                    <p className="text-uppercase text-muted m-0">Work Experience In { COMMON.GENERAL.APP_NAME }</p>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        arrayHelpers.unshift({
                          position3: '',
                          officelocation: '',
                          from: '',
                          to: '',
                          tillpresent: false,
                          projectname: '',
                          projectdescription: '',
                          technologiesused2: '',
                          responsibilities2: ['']
                        });
                      }}
                    >
                      + Add Project
                    </button>
                    {
                      values.work2?.length
                        ? (
                          values.work2.map((work: any, index: number) => {
                            return (
                              <div className="position-relative bg-light mr-5 mb-2 p-4" key={ index }>
                                <FA icon={['far', 'times-circle']}
                                    className="position-absolute"
                                    onClick={() => arrayHelpers.remove(index)}
                                    style={{right: '-30px'}}
                                />
                            
                                <div className="row py-2">
                                  <div className="col-6">
                                    <label htmlFor={`work2.${index}.position3`}>Position</label>
                                    <Field
                                      className="form-control"
                                      id={`work2.${index}.position3`}
                                      name={`work2.${index}.position3`}
                                    />
                                    <ErrorMessage name={ `work2.${index}.position3` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                  <div className="col-6">
                                    <label htmlFor={ `work2.${index}.officelocation` }>Office Location</label>
                                    <SelectFieldCustom
                                      id={ `work2.${index}.officelocation` }
                                      name={ `work2.${index}.officelocation` }
                                      options={ locations }
                                      simple={ true }
                                    />
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-6">
                                    <label htmlFor={`work2.${index}.from`}>Dates</label>
                                    <DateField
                                      className="form-control"
                                      id={`work2.${index}.from`}
                                      name={`work2.${index}.from`}
                                      placeholderText="From"
                                      showMonthYearPicker
                                      dateFormat="MMM dd, yyyy"
                                      utc={ true }
                                      onChange={() => {}}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label htmlFor={`work2.${index}.to`} style={{color: "transparent"}}>Dates</label>
                                    <DateField
                                      className="form-control"
                                      id={`work2.${index}.to`}
                                      name={`work2.${index}.to`}
                                      placeholderText="To"
                                      showMonthYearPicker
                                      dateFormat="MMM dd, yyyy"
                                      utc={ true }
                                      onChange={() => {}}
                                      disabled={ values.work2![index].tillpresent === true }
                                    />
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <div className="form-group form-check">
                                      <Field
                                        className="form-check-input"
                                        id={`work2.${index}.tillpresent`}
                                        name={`work2.${index}.tillpresent`}
                                        type="checkbox"
                                      />
                                      <label className="form-check-label" htmlFor={`work2.${index}.tillpresent`}>
                                        Till present
                                      </label>
                                    </div>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`work2.${index}.projectname`} className="d-inline-block">
                                      Project Name
                                    </label>
                                    <OverlayTrigger
                                      trigger="focus"
                                      placement="right"
                                      overlay={(
                                        <Popover id="popover3" className="cv-popover">
                                          <Popover.Title>Project Name</Popover.Title>
                                          <Popover.Content>
                                            <p>Most {COMMON.GENERAL.APP_NAME} project names are under NDA and should not be mentioned in CV. Project name field is in the form for reference only and will be ommited in the generated CV file.</p>
                                          </Popover.Content>
                                        </Popover>
                                      )}>
                                      <span className="ask_icon" tabIndex={ -1 }>?</span>
                                    </OverlayTrigger>
                                    <Field
                                      className="form-control"
                                      id={`work2.${index}.projectname`}
                                      name={`work2.${index}.projectname`}
                                    />
                                    <ErrorMessage name={ `work2.${index}.projectname` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`work2.${index}.projectdescription`}>Project Description</label>
                                    <Field
                                      as="textarea"
                                      className="form-control"
                                      id={`work2.${index}.projectdescription`}
                                      name={`work2.${index}.projectdescription`}
                                      rows={5}
                                    />
                                    <ErrorMessage name={ `work2.${index}.projectdescription` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`work2.${index}.technologiesused2`}>Technologies used</label>
                                    <Field
                                      as="textarea"
                                      className="form-control"
                                      id={`work2.${index}.technologiesused2`}
                                      name={`work2.${index}.technologiesused2`}
                                      rows={5}
                                    />
                                    <ErrorMessage name={ `work2.${index}.technologiesused2` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`work2.${index}.responsibilities2`}>Responsibilities</label>
                                    <FieldArray
                                      name={`work2.${index}.responsibilities2`}
                                      render={arrayHelpers => (
                                        <>
                                          {
                                            (work.responsibilities2?.length && work.responsibilities2.length > 0)
                                              ? (
                                                work.responsibilities2.map((responsibility: any, key: number) => {
                                                  return (
                                                    <div className="position-relative mb-2" key={`${index}-${key}`}>
                                                      <FA icon={['far', 'times-circle']}
                                                          className="position-absolute"
                                                          onClick={() => arrayHelpers.remove(key)}
                                                          style={{right: '8px', top: '8px'}}
                                                      />
                                                      <Field
                                                        className="form-control"
                                                        id={`work2.${index}.responsibilities2.${key}`}
                                                        name={`work2.${index}.responsibilities2.${key}`}
                                                      />
                                                      <ErrorMessage name={ `work2.${index}.responsibilities2.${key}` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                                    </div>
                                                  )
                                                })
                                              )
                                              : <></>
                                          }
                                          <button
                                            type="button"
                                            className="btn btn-link"
                                            onClick={() => arrayHelpers.push('')}
                                          >
                                            + Add Responsibility
                                          </button>
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                          
                              </div>
                            )
                          })
                        )
                        : <></>
                    }
                    <button
                      type="button"
                      className="btn btn-link"
                      hidden={values.work2 == undefined || values.work2.length == 0 ? true : false}
                      onClick={() => {
                        arrayHelpers.push({
                          position3: '',
                          officelocation: '',
                          from: '',
                          to: '',
                          tillpresent: false,
                          projectname: '',
                          projectdescription: '',
                          technologiesused2: '',
                          responsibilities2: ['']
                        });
                      }}
                    >
                      + Add Project
                    </button>
              
                  </div>
                )}
              />
            </section>
        
            <section className="border-bottom">
              <FieldArray
                name="workexperiencebeforeCogniance2"
                render={arrayHelpers => (
                  <div>
                    <p className="text-uppercase text-muted m-0">Work Experience Before { COMMON.GENERAL.APP_NAME }</p>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        arrayHelpers.unshift({
                          company2: '',
                          position2: '',
                          officelocation2: '',
                          from2: '',
                          to2: '',
                          tillpresent: false,
                          projectdescription: '',
                          technologiesused: '',
                          responsibilities: ['']
                        });
                      }}
                    >
                      + Add Project
                    </button>
                    {
                      values.workexperiencebeforeCogniance2?.length
                        ? (
                          values.workexperiencebeforeCogniance2.map((work: any, index: number) => {
                            return (
                              <div className="position-relative bg-light mr-5 mb-2 p-4" key={ index }>
                                <FA icon={['far', 'times-circle']}
                                    className="position-absolute"
                                    onClick={() => arrayHelpers.remove(index)}
                                    style={{right: '-30px'}}
                                />
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.company2`}>Company</label>
                                    <Field
                                      className="form-control"
                                      id={`workexperiencebeforeCogniance2.${index}.company2`}
                                      name={`workexperiencebeforeCogniance2.${index}.company2`}
                                    />
                                    <ErrorMessage name={ `workexperiencebeforeCogniance2.${index}.company2` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-6">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.position2`}>Position</label>
                                    <Field
                                      className="form-control"
                                      id={`workexperiencebeforeCogniance2.${index}.position2`}
                                      name={`workexperiencebeforeCogniance2.${index}.position2`}
                                    />
                                    <ErrorMessage name={ `workexperiencebeforeCogniance2.${index}.position2` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                  <div className="col-6">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.officelocation2`}>Office Location</label>
                                    <Field
                                      className="form-control"
                                      id={`workexperiencebeforeCogniance2.${index}.officelocation2`}
                                      name={`workexperiencebeforeCogniance2.${index}.officelocation2`}
                                    />
                                    <ErrorMessage name={ `workexperiencebeforeCogniance2.${index}.officelocation2` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-6">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.from2`}>Dates</label>
                                    <DateField
                                      className="form-control"
                                      id={`workexperiencebeforeCogniance2.${index}.from2`}
                                      name={`workexperiencebeforeCogniance2.${index}.from2`}
                                      placeholderText="From"
                                      showMonthYearPicker
                                      dateFormat="MMM dd, yyyy"
                                      utc={ true }
                                      onChange={() => {}}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.to2`} style={{color: "transparent"}}>Dates</label>
                                    <DateField
                                      className="form-control"
                                      id={`workexperiencebeforeCogniance2.${index}.to2`}
                                      name={`workexperiencebeforeCogniance2.${index}.to2`}
                                      placeholderText="To"
                                      showMonthYearPicker
                                      dateFormat="MMM dd, yyyy"
                                      utc={ true }
                                      onChange={() => {}}
                                      disabled={ values.workexperiencebeforeCogniance2![index].tillpresent === true }
                                    />
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <div className="form-group form-check">
                                      <Field
                                        className="form-check-input"
                                        id={`workexperiencebeforeCogniance2.${index}.tillpresent`}
                                        name={`workexperiencebeforeCogniance2.${index}.tillpresent`}
                                        type="checkbox"
                                      />
                                      <label className="form-check-label" htmlFor={`workexperiencebeforeCogniance2.${index}.tillpresent`}>
                                        Till present
                                      </label>
                                    </div>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.projectdescription`}>Project Description</label>
                                    <Field
                                      as="textarea"
                                      className="form-control"
                                      id={`workexperiencebeforeCogniance2.${index}.projectdescription`}
                                      name={`workexperiencebeforeCogniance2.${index}.projectdescription`}
                                      rows={5}
                                    />
                                    <ErrorMessage name={ `workexperiencebeforeCogniance2.${index}.projectdescription` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.technologiesused`}>Technologies used</label>
                                    <Field
                                      as="textarea"
                                      className="form-control"
                                      id={`workexperiencebeforeCogniance2.${index}.technologiesused`}
                                      name={`workexperiencebeforeCogniance2.${index}.technologiesused`}
                                      rows={5}
                                    />
                                    <ErrorMessage name={ `workexperiencebeforeCogniance2.${index}.technologiesused` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                  </div>
                                </div>
                            
                                <div className="row py-2">
                                  <div className="col-12">
                                    <label htmlFor={`workexperiencebeforeCogniance2.${index}.responsibilities`}>Responsibilities</label>
                                    <FieldArray
                                      name={`workexperiencebeforeCogniance2.${index}.responsibilities`}
                                      render={arrayHelpers => (
                                        <>
                                          {
                                            (work.responsibilities?.length && work.responsibilities.length > 0)
                                              ? (
                                                work.responsibilities.map((responsibility: any, key: number) => {
                                                  return (
                                                    <div className="position-relative mb-2" key={`${index}-${key}`}>
                                                      <FA icon={['far', 'times-circle']}
                                                          className="position-absolute"
                                                          onClick={() => arrayHelpers.remove(key)}
                                                          style={{right: '8px', top: '8px'}}
                                                      />
                                                      <Field
                                                        className="form-control"
                                                        id={`workexperiencebeforeCogniance2.${index}.responsibilities.${key}`}
                                                        name={`workexperiencebeforeCogniance2.${index}.responsibilities.${key}`}
                                                      />
                                                      <ErrorMessage name={ `workexperiencebeforeCogniance2.${index}.responsibilities.${key}` }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                                                    </div>
                                                  )
                                                })
                                              )
                                              : <></>
                                          }
                                          <button
                                            type="button"
                                            className="btn btn-link"
                                            onClick={() => arrayHelpers.push('')}
                                          >
                                            + Add Responsibility
                                          </button>
                                        </>
                                      )}
                                    />
                                  </div>
                                </div>
                          
                              </div>
                            )
                          })
                        )
                        : <></>
                    }
                    <button
                      type="button"
                      className="btn btn-link"
                      hidden={values.workexperiencebeforeCogniance2 == undefined || values.workexperiencebeforeCogniance2.length == 0 ? true : false}
                      onClick={() => {
                        arrayHelpers.push({
                          position3: '',
                          officelocation: '',
                          from: '',
                          to: '',
                          tillpresent: false,
                          projectname: '',
                          projectdescription: '',
                          technologiesused2: '',
                          responsibilities2: ['']
                        });
                      }}
                    >
                      + Add Project
                    </button>
              
                  </div>
                )}
              />
            </section>
        
            <section className="border-bottom section-education">
              <div>
                <p className="text-uppercase text-muted">
                  Education
                  <ElementWithPermission element="editEducationLink" staffToCheck={{role: ROLES.LM, wrId}}>
                    <NavLink className="ml-2 text-muted" to={`/staff/${staffId}/work-records/${wrId}/education`}>
                      <FA icon={['fas', 'pen']} style={{fontSize: '11px', verticalAlign: '-0.1em'}}/>
                    </NavLink>
                  </ElementWithPermission>
                </p>
                <div>
                  {
                    (educationItems?.length && !isSuperArrayEmpty(educationItems)) && educationItems.map((education: any, key) => {
                      return (!isObjectEmpty(education))
                        ? (
                          <div key={key}>
                            <div className="d-flex flex-row">
                              <div className="col">
                                <p className="section-education__value">
                                  {
                                    (education.degree)
                                      ? (
                                        <>
                                          <span className="font-weight-bold">{education.degree}</span>
                                          <span> Degree</span>
                                        </>
                                      )
                                      : <></>
                                  }
                                  {
                                    (education.field_of_study && education.degree)
                                      ? (
                                        <span> in </span>
                                      )
                                      : <></>
                                  }
                                  <span className="font-weight-bold">{ education.field_of_study }</span>
                                  { (education.school_name) ? <span> from</span> : <></> }
                                </p>
                              </div>
                              <div className="col">
                                <p className="section-education__value text-right">
                                  <span>{education.location}</span>
                                  {
                                    (education.location && education.year_of_graduation)
                                      ? (
                                        <span> - {education.year_of_graduation}</span>
                                      )
                                      : <></>
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="d-flex flex-column">
                              <div className="col">
                                <p className="section-education__value">{ education.school_name }</p>
                              </div>
                            </div>
                          </div>
                        )
                        : <></>
                    })
                  }
                </div>
              </div>
            </section>
        
            <section className="border-bottom">
              <div>
                <p className="text-uppercase text-muted m-0">
                  Certifications
                  <OverlayTrigger
                    trigger="focus"
                    placement="right"
                    overlay={(
                      <Popover id="popover4" className="cv-popover">
                        <Popover.Title>Certifications</Popover.Title>
                        <Popover.Content>
                          <p>Mention certificates if they are relevant to work and position.</p>
                        </Popover.Content>
                      </Popover>
                    )}>
                    <span className="ask_icon" tabIndex={-1}>?</span>
                  </OverlayTrigger>
                </p>
                <div className="position-relative mr-5 mb-2">
                  <div className="row py-2">
                    <div className="col-12">
                      <Field
                        as="textarea"
                        className={ `form-control ${errors.certifications && touched.certifications ? 'is-invalid' : ''}` }
                        id="certifications"
                        name="certifications"
                        rows={ 5 }
                        placeholder={
                          "Example:" +
                          "\nCertified Scrum Master (2016)"
                        }
                      />
                      <ErrorMessage name={ 'certifications' }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        
            <section className="border-bottom">
              <div>
                <p className="text-uppercase text-muted m-0">
                  Additional Skills
                  <OverlayTrigger
                    trigger="focus"
                    placement="right"
                    overlay={(
                      <Popover id="popover5" className="cv-popover">
                        <Popover.Title>Additional Skills</Popover.Title>
                        <Popover.Content>
                          <p>Indicate language skills (ex. Upper-Intermediate level of English, basic level of Spanish, native Ukrainian & Russian).</p>
                        </Popover.Content>
                      </Popover>
                    )}>
                    <span className="ask_icon" tabIndex={-1}>?</span>
                  </OverlayTrigger>
                </p>
                <div className="position-relative mr-5 mb-2">
                  <div className="row py-2">
                    <div className="col-12">
                      <Field
                        as="textarea"
                        className={ `form-control ${errors.additionalskills && touched.additionalskills ? 'is-invalid' : ''}` }
                        id="additionalskills"
                        name="additionalskills"
                        rows={ 5 }
                        placeholder={
                          "Example:" +
                          "\nAdvanced level of English, native level of Ukrainian, Polish, Russian"
                        }
                      />
                      <ErrorMessage name={ 'additionalskills' }>{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                    </div>
                  </div>
                </div>
              </div>
            </section>
      
          </Form>
        )}
  
      </Formik>
    </>
  )
}
