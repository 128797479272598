import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import {Skill as SkillInterface} from '../../interfaces/entity/Skill';
import ArrayHelper from '../../utils/ArrayHelper';

import { SKILL_LEVEL } from '../../constants';

import './skill-checkbox-field.scss';

interface SkillCheckboxFieldProps {
  id: string,
  name: string,
  values: Array<SkillInterface>,
  options: Array<any>,
  isLanguage: boolean,
  changeEffect?: Function
}

const isHidden = (parents: Array<number>, list: Array<number>) => {
  return !!(parents.filter((value: number) => list.includes(value)).length);
}

const isOpen = (id: number, list: Array<number>) => {
  return (list.indexOf(id) === -1);
}

export const SkillCheckboxField: React.FC<SkillCheckboxFieldProps> = (props: SkillCheckboxFieldProps) => {
  const {id, options, values, isLanguage, changeEffect} = {...props};
  const levels = (isLanguage) ? SKILL_LEVEL.language : SKILL_LEVEL.hardSoft;
  const [, , {setValue}] = useField<any>(props as any);
  const [currentIds, setCurrentIds] = useState<Array<number>>([]);
  const [currentLevels, setCurrentLevels] = useState<{ [key: number]: string }>([]);
  const [currentValues, setCurrentValues] = useState<Array<SkillInterface>>(values);
  const [isHideList, setIsHideList] = useState<boolean>(false);
  const [hideList, setHideList] = useState<Array<number>>([]);

  const hide = (id: number) => {
    setHideList(ArrayHelper.pushToNew(hideList, id));
  }
  const show = (id: number) => {
    setHideList(ArrayHelper.removeInNew(hideList, id));
  }

  const addSkill = (value: SkillInterface) => {
    setCurrentValues(ArrayHelper.pushToNew(currentValues, value));
  }

  const modifySkill = (level: string, skill: SkillInterface) => {
    //@ts-ignore
    skill.level = level;
    const values = ArrayHelper.replaceInArray(currentValues, skill, 'id');
    setValue(values);
    setCurrentValues(values);
  }

  const removeSkill = (skill: SkillInterface) => {
    setCurrentValues(ArrayHelper.removeInNewByField(currentValues, skill, 'id'));
  }

  useEffect(() => {
    setValue(currentValues);
    if (changeEffect) {
      changeEffect(values);
    }
    const ids: Array<number> = [];
    const levels: { [key: number]: string } = [];
    currentValues.forEach((skill: SkillInterface) => {
      ids.push(skill.id);
      levels[skill.id] = skill.level as string;
    })
    setCurrentIds(ids);
    setCurrentLevels(levels);
    if (!isHideList && options.length) {
      setHideList(options.reduce((acc: Array<number>, skill: SkillInterface) => {
        if (ids.indexOf(skill.id) === -1) {
          acc.push(skill.id);
        }
        return acc;
      }, []));
      setIsHideList(true);
    }
  }, [options, values, currentValues]);

  if (isHideList) {
    return (
      <div className="skill-checkbox-field" id={id}>
        {options.map((skill: SkillInterface) => (
          <div
            className={
              `skill skill--level-${skill.hierarchyLevel} clearfix` +
              `${(isHidden(skill.parents, hideList)) ? ' hidden' : ''}` +
              `${(currentIds.indexOf(skill.id) === -1) ? ' unchecked' : ''}`
            }
            key={skill.id}>
            <div className="skill__control">
              {(skill.hasChildren && isOpen(skill.id, hideList))
                ? <FA icon={['fas', 'caret-down']} size="lg" onClick={() => hide(skill.id)}/>
                : ''
              }
              {(skill.hasChildren && !isOpen(skill.id, hideList))
                ? <FA icon={['fas', 'caret-right']} size="lg" onClick={() => show(skill.id)}/>
                : ''
              }
            </div>
            <div className="skill__checkbox">
              <input
                type="checkbox"
                value={skill.id}
                id={`skill${skill.id}`}
                checked={(currentIds.indexOf(skill.id) !== -1)}
                onChange={(event) => {
                  (event.target.checked) ? addSkill(skill) : removeSkill(skill)
                }}/>
              <label htmlFor={`skill${skill.id}`}> </label>
            </div>
            <div className="skill__name">{skill.originalName}</div>
            <div className="skill__level">
              {
                levels.map((level: string) => (
                  <div className="level clearfix" key={`skill${skill.id}${level}`}>
                    <input
                      type="radio"
                      value={level}
                      id={`skill${skill.id}${level}`}
                      name={`skill${skill.id}`}
                      checked={ (currentIds.indexOf(skill.id) !== -1 && currentLevels[skill.id] === level) }
                      onChange={(event) => modifySkill(event.target.value, skill)}
                    />
                    <label htmlFor={`skill${skill.id}${level}`}> </label>
                    <span className="level-name">{level}</span>
                  </div>
                ))
              }
            </div>
          </div>
        ))}
      </div>
    );
  }
  return <></>;
};
