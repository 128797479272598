import http, { Http } from './Http';
import { ENDPOINTS } from '../../constants';

export const WORK_RECORD = 'workrecord';
export const STAFF = 'staff';

class RequestClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public update(id: number, entity: string, body: any) {
    //TODO Backend has return tenure
    return this.http.postAction(`${ENDPOINTS.REQUEST_CREATE}/${entity}/${id}`, body);
  }
}

export default new RequestClient(http);
