import React from 'react';

interface UnitTreePreviewBreadcrumbsItemProps {
  breadCrumbs: Array<string>;
  item: string;
  index: number;
}

export const UnitTreePreviewBreadcrumbsItem: React.FC<UnitTreePreviewBreadcrumbsItemProps> = (props: UnitTreePreviewBreadcrumbsItemProps) => {
  const { breadCrumbs, item, index } = { ...props };

  if (breadCrumbs.length - 1 === index) {
    return (
      <li className="breadcrumb-item">
        <span className="text-dark">
          <strong className="font-weight--600">{ item }</strong>
        </span>
      </li>
    )
  }

  return (
    <li className="breadcrumb-item">
      <span className="text-muted">{ item }</span>
    </li>
  )
};