import {Value as ValueInterface} from '../interfaces/Value';
import ValueStaff from './ValueStaff';

export default class ValueUnit implements ValueInterface<string> {
  valueId: number | null = null;
  value: string = '';

  id: number | null = null;
  level: number | null = null;

  orgStructure: string = '';
  orgStructureManager: ValueStaff | null = null;
  department: string = '';
  departmentManager: ValueStaff | null = null;
  subDepartment: string = '';
  subDepartmentManager: ValueStaff | null = null;

  getValue(): number | null {
    return this.id;
  }
}
