import {
  STAFF_SET_LOADING,
  STAFF_SET_MY_STAFF,
  STAFF_SET_SHOULD_UPDATE,
  STAFF_SET_STAFF,
  StaffState,
  StaffTypes
} from './types';
import {STAFF_GET_MY_FAILURE, STAFF_GET_MY_LOADING, STAFF_GET_MY_SUCCESS,} from "../workRecord/types";

const defaultState: StaffState = {
  myStaff: null,
  staff: null,
  staffMyData: null,
  shouldUpdate: false,
  loading: false
}

export const staffReducer = (state: StaffState = defaultState, action: StaffTypes) => {
  switch (action.type) {
    case STAFF_SET_MY_STAFF:
      return {...state, myStaff: action.payload};
    case STAFF_SET_STAFF:
      return {...state, staff: action.payload, shouldUpdate: false, loading: false}

    case STAFF_GET_MY_LOADING:
      return {...state, loading: true}
    case STAFF_GET_MY_SUCCESS:
      return {...state, loading: false, staffMyData: action.payload}
    case STAFF_GET_MY_FAILURE:
      return {...state, loading: false,}

    case STAFF_SET_SHOULD_UPDATE:
      return {...state, shouldUpdate: action.payload}
    case STAFF_SET_LOADING:
      return {...state, loading: action.payload}

    default:
      return state;
  }
}
