import ReportItem, {ReportItemI} from "../../models/ReportItem";

class ReportMapper {
  public createFromResponse(data: any): ReportItemI {
    const item = new ReportItem();
    item.id = data.id;
    item.staffId = data.staff_id;
    item.code = data.code;
    item.name = data.staff;
    item.position = (data.position) ? data.position : '';
    item.startDate = (data.start_date) ? new Date(data.start_date) : null;
    item.endDate = (data.end_date) ? new Date(data.end_date) : null;
    item.orgStructure = data.org_structure;
    item.department = data.department;
    item.subDepartment = (data.unit) ? data.unit : '';
    item.lineManager = (data.line_manager) ? data.line_manager : '';
    item.location = (data.location) ? data.location : '';
    item.isBillable = (data.is_billable && data.is_billable === 'Yes');
    item.turnover = (data.turnover) ? data.turnover : '';
    item.reasonToLeave = (data.reason_to_leave) ? data.reason_to_leave : '';
    item.nextEmployer = (data.next_employer) ? data.next_employer : '';
    return item;
  }
}

export default new ReportMapper();