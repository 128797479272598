import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Staff } from '../../../interfaces/Staff';
import { GlobalState } from '../../../store/types';
import store from '../../../store/store';
import { setOverlayIsVisible, setOverlayParams } from '../../../store/overlay/actions';
import useOutsideClick from '../../../hooks/outsideClick';

// components
import { Status } from '../../WR/Common/Status';
import { StaffAvatarPreview } from '../StaffAvatarPreview/StaffAvatarPreview';

// styles
import './wr-navigation-staff.scss';

export const MODE_VIEW = 'view';
export const MODE_PREVIEW = 'preview';
export const DIRECTION_ROW = 'row';
export const DIRECTION_COLUMN = 'column';

type PropsType = {
  mode: typeof MODE_VIEW | typeof MODE_PREVIEW,
  direction: typeof DIRECTION_ROW | typeof DIRECTION_COLUMN
}

export const WRNavigationStaff: FunctionComponent<PropsType> = (props: PropsType) => {

  const staff: Staff = useSelector((state: GlobalState) => state.staff.staff);
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const showAvatarPreview = () => {
    setIsVisible(!isVisible && props.mode === MODE_VIEW);
  }

  useEffect(() => {
    store.dispatch(setOverlayParams({isFullscreen: isVisible, isExtra: false}));
    store.dispatch(setOverlayIsVisible(isVisible));
  }, [isVisible])

  const formatStaffFullName = (staff: Staff) => {
    return staff.middleName.value ?
      `${staff.firstName.value} ${staff.middleName.value} ${staff.lastName.value}` :
      `${staff.firstName.value} ${staff.lastName.value}`;
  }

  if (staff) {
    return (
      <div className="work-record-navigation-staff">
        {
          (props.direction === DIRECTION_ROW)
            ? (
              <div className="d-flex flex-row">
                <div className="avatar" style={{display: "inline-table", backgroundImage: `url(${staff.avatar})`}} onClick={ showAvatarPreview } />
                {
                  props.mode === MODE_VIEW
                    ? (
                      <div ref={ref}>
                        <StaffAvatarPreview staff={staff} visible={isVisible}/>
                      </div>
                    )
                    : <></>
                }
                <div className="info">
                  <div className="full-name">{ formatStaffFullName(staff) }</div>
                  <div className="username username--align">{ staff.username }</div>
                  <Status value={ staff.status } isWR={ true } />
                </div>
              </div>
            )
            : (
              <div className="d-flex flex-column align-items-center">
                <div className="user-card">
                  <div className="avatar" style={{display: "inline-table", backgroundImage: `url(${staff.avatar})`}} onClick={showAvatarPreview}/>
                  <div ref={ref}>
                    <StaffAvatarPreview staff={ staff } visible={ isVisible } />
                  </div>
                  <div className="user-card__status">
                    <Status value={ staff.status } isWR={ true } />
                  </div>
                </div>
                <div className="text-center">
                  <div className="full-name">{formatStaffFullName(staff)}</div>
                  <a className="user-card__email" href={`mailto:${staff.email.value}`} target="_blank" rel="noopener noreferrer">
                    {staff.email.value}
                  </a>
                </div>
              </div>
            )
        }
      </div>
    );
  }

  return <></>;
}
