import {COMMON} from "../../constants/COMMON";
import CVClient from "../http/CVClient";
import {CVStatusType} from "../../store/cv/types";
import FileSaver from "file-saver";
import {toastr} from "react-redux-toastr";

const CV_FILE_FORMAT_PDF = 'pdf';
const CV_FILE_FORMAT_DOCX = 'docx';

type CVFileFormat = typeof CV_FILE_FORMAT_PDF | typeof CV_FILE_FORMAT_DOCX;

type GenerateFileNameProps = {
  firstName: string,
  lastName: string,
  position: string,
  date: string | undefined,
  status: CVStatusType
};

type GetFileProps = {
  format: CVFileFormat,
  firstName: string,
  lastName: string,
  position: string,
  date: string | undefined,
  status: CVStatusType
};

class CVHelper {

  public generateFileName(props: GenerateFileNameProps) {
    return `${COMMON.GENERAL.APP_NAME}_CV_${props.firstName}_${props.lastName}_${props.position}_${props.date}_${props.status.charAt(0).toUpperCase()}${props.status.slice(1)}`;
  }

  public getFile(id: number, props: GetFileProps) {
    const {format, firstName, lastName, position, date, status} = props;
    const fileName = this.generateFileName({firstName, lastName, position, date, status});
    const getContent = format === 'pdf' ? CVClient.getPdfContent(id, status) : CVClient.getDocxContent(id, status);
    getContent
      .then((response: any) => {
        const data = response.data;
        const headers = response.headers;

        if (data) {
          const file = new Blob([data], {
            type: headers['content-type'] || 'application/octet-stream'
          });
          FileSaver.saveAs(file, `${fileName}.${format}`);
          CVClient.http.unsetResponseType();
        }
      })
      .catch((response) => {
        let data;
        try {
          data = new TextDecoder('utf-8').decode(response.data);
          data = data.replace(/['"]+/g, '');
        } catch (e) {
          data = '';
        }

        if (response.status === 403) {
          toastr.error('', 'You have no permission');
        } else if (response.status === 404 && data) {
          toastr.error('', data);
        } else {
          toastr.error('', `No CV with status ${status} available`);
        }
      })
  }
}

export default new CVHelper();