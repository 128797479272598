import React from 'react';

// store
import store from '../../../../store/store';
import { dispatchPopupState } from '../../../../store/popup/types';
import { setShouldUpdate } from '../../../../store/workRecord/actions';
import { setShouldUpdate as setStaffShouldUpdate} from '../../../../store/staff/actions';

import { WorkRecord } from '../../../../interfaces/WorkRecord';
import { Staff } from '../../../../interfaces/Staff';
import wrHelper from '../../../../services/data/WorkRecordHelper';

// components
import { PositionDescriptionForm } from './PositionDescriptionForm';

interface PositionDescriptionUpdateWrapperProps {
  workRecord: WorkRecord;
  staff: Staff,
  popupName: string;
}

export const PositionDescriptionUpdateWrapper: React.FC<PositionDescriptionUpdateWrapperProps> =
  (props: PositionDescriptionUpdateWrapperProps) => {
    const { workRecord, staff, popupName } = { ...props };
    const update = (values: object) => {
      wrHelper.update(workRecord, values).then((data: any) => {
        store.dispatch(setShouldUpdate(true));
        store.dispatch(setStaffShouldUpdate(true));
        dispatchPopupState(popupName, false);
      });
    };

    return (
      <PositionDescriptionForm submitFunction={ update } workRecord={ workRecord } staff={ staff } popupName={ popupName } />
    );
  };
