import React, { ReactElement, useEffect } from 'react'

import drawDonut, { destroyDonut } from '../../../utils/d3/PercentDonut';
import { Value } from '../../../interfaces/Value';

import './wr-attribute.scss';

interface WRAttributePercentProps {
  name: string;
  value: Value<number>;
  tooltip?: ReactElement;
}

const size: number = 75;

export const WRAttributePercent: React.FC<WRAttributePercentProps> = (props: WRAttributePercentProps) => {
  const { name, value } = { ...props };
  const id: string = name.replace(/ /g, '');

  useEffect(() => {
    drawDonut(id, value.value, size);
    return () => destroyDonut(id);
  }, [id, value]);

  return (
    <div className="work-record-attribute work-record-attribute--percent">
      <div className="work-record-attribute__name d-flex">
        { name }
        { (props.tooltip) ? (props.tooltip) : '' }
      </div>
      <div className="work-record-attribute__value">
        <svg id={ id } width={ size } height={ size } />
      </div>
    </div>
  );
}
