import React, {FunctionComponent} from 'react';

export const TableSettingsIcon: FunctionComponent = () =>
  <svg id="Layer_1" data-name="Layer 1" width="14.97" height="15" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 15 15">
    <title>table_settings_icon</title>
    <path fill="#039be5" fillRule="evenodd"
          d="M655.5,627h-14a0.5,0.5,0,0,1-.5-0.5v-14a0.5,0.5,0,0,1,.5-0.5h14a0.5,0.5,0,0,1,.5.5v14A0.5,0.5,0,0,1,655.5,627ZM642,626h13V613H642v13Z"
          transform="translate(-641 -612)"/>
    <path fill="#039be5" fillRule="evenodd" d="M655.5,617h-14a0.5,0.5,0,0,1,0-1h14A0.5,0.5,0,0,1,655.5,617Z"
          transform="translate(-641 -612)"/>
    <path fill="#039be5" fillRule="evenodd" d="M645.5,617a0.5,0.5,0,0,1-.5-0.5v-4a0.5,0.5,0,0,1,1,0v4A0.5,0.5,0,0,1,645.5,617Z"
          transform="translate(-641 -612)"/>
  </svg>

