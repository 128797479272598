import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART_COMMON } from '../../../../constants';

interface Props {
  data: any,
  detailUrls: Array<any>
}

export const ChartBarNotStacked: FunctionComponent<Props> = (props: Props) => {
  const { data, detailUrls } = { ...props };
  
  const [width, setWidth] = useState<number>(0);
  const [labels, setLabels] = useState<Array<any>>([]);
  const [datasets, setDatasets] = useState<Array<any>>([]);
  
  const ref = useRef(null);
  const refChartBar = useRef(null);
  
  useEffect(() => {
    const labelData: Array<any> = [];
    const left: Array<any> = [];
    const joined: Array<any> = [];
    data.map((item: any) => {
      joined.push(item.joined);
      left.push((-1) * item.left);
      const balance = +item.joined - (+item.left);
      let balanceWithSign = balance > 0 ? `+${balance}` : balance;
      if (typeof balanceWithSign === 'number' && balanceWithSign !== balanceWithSign) {
        balanceWithSign = 'no data';
      }
      labelData.push([balanceWithSign, '', item.label]);
    });
    const datasetsData: Array<any> = [
      {
        data: left,
        fill: true,
        borderColor: CHART_COMMON.border.color,
        borderWidth: CHART_COMMON.border.width,
        backgroundColor: CHART_COMMON.colors.left,
        borderSkipped: false,
        barThickness: CHART_COMMON.barThickness
      },
      {
        data: joined,
        fill: true,
        borderColor: CHART_COMMON.border.color,
        borderWidth: CHART_COMMON.border.width,
        backgroundColor: CHART_COMMON.colors.joined,
        barThickness: CHART_COMMON.barThickness
      }
    ];
    let factor = Math.ceil(labelData.length / 12);
    if (window.innerWidth < 1150 && labelData.length >= 12) {
      factor *= 1.3;
    }
    const currentElement = ref.current as unknown as HTMLElement;
    const wrapperElement = currentElement.parentElement as HTMLElement;
    const wrapperWidth = wrapperElement.clientWidth;
    setWidth(wrapperWidth * factor);
    setLabels(labelData);
    setDatasets(datasetsData);
  }, [data]);
  
  useEffect(() => {
    const chartBarElement = refChartBar.current as unknown as Bar;
    if (chartBarElement) chartBarElement.chartInstance.resize();
  }, [width]);

  const handleClickLabel = (event: any) => {
    let reportKey = '';
    if (event.chart) {
      let index = event.dataIndex;
      let datasetIndex = event.datasetIndex;
      reportKey = data[index][detailUrls[datasetIndex]];
    }
    if (reportKey) {
      window.open('/report/' + reportKey, "_blank");
    }
  }

  const handleEnterLabel = (event: any) => {
    labelHover(event, true);
  }

  const handleLeaveLabel = (event: any) => {
    labelHover(event, false);
  }

  const labelHover = (event: any, isHover: boolean) => {
    const chart = event.chart;
    if (!chart) { return; }
    const canvas = chart.canvas;
    if (isHover) {
      canvas.classList.add('hovered');
    } else {
      canvas.classList.remove('hovered');
    }
  }

  const handleHover = (event: any, activeElements: Array<any>) => {
    if (activeElements.length) {
      event.target.style.cursor = 'pointer';
    } else {
      event.target.style.cursor = 'default';
    }
  }

  const handleClick = (event: any, activeElements: Array<any>) => {
    let reportKey = '';
    if (activeElements.length) {
      const activeElement = activeElements[0];
      const index = activeElement._index;
      const datasetIndex = activeElement._datasetIndex;
      reportKey = data[index][detailUrls[datasetIndex]];
    }

    if (reportKey) {
      window.open('/report/' + reportKey, "_blank");
    }
  }
  
  const datasetKeyProvider = () => {
    return btoa(String(Math.random())).substring(0, 12);
  }

  return (
    <div className="chart-bar-wrapper" ref={ ref } style={ { width } }>
      <Bar
        ref={ refChartBar }
        data={{ labels, datasets }}
        datasetKeyProvider={ datasetKeyProvider }
        options={{
          legend: { display: false },
          devicePixelRatio: CHART_COMMON.devicePixelRatio,
          maintainAspectRatio: false,
          tooltips: { enabled: false },
          hover: { mode: 'point' },
          scales: {
            xAxes: [
              {
                stacked: false,
                gridLines: {display: false},
                ticks: {
                  display: true,
                  fontFamily: CHART_COMMON.labels.fontFamily,
                  fontSize: CHART_COMMON.labels.fontSize,
                  fontColor: '#768591',
                  fontStyle: 'bold',
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                display: false
              }
            ]
          },
          plugins: {
            datalabels: {
              display: function (context: any) {
                return context.dataset.data[context.dataIndex] !== 0 && context.dataset.data[context.dataIndex];
              },
              formatter: function (value: any) {
                return value < 0 ? (-1) * value : value;
              },
              clamp: true,
              anchor: 'center',
              align: 'left',
              color: 'black',
              font: {
                family: CHART_COMMON.labels.fontFamily,
                size: CHART_COMMON.labels.fontSize
              },
              offset: function (context: any) {
                const value = context.dataset.data[context.dataIndex] + '';

                return (context.datasetIndex === 0) ? 8 : -5 * value.length - 17;
              },
              listeners: {
                click: handleClickLabel,
                enter: handleEnterLabel,
                leave: handleLeaveLabel,
              }
            },
          },
          onClick: handleClick,
          onHover: handleHover
        }}
        plugins={ [ChartDataLabels] }
      />
    </div>
  );
}
