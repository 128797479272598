import { COMMON } from '../../constants';

export interface SelectValueInterface {
  id?: number;
  name?: string;
}

export const defaultSelectValue: SelectValueInterface = {
  id: NaN,
  name: ''
};

export const notSpecifiedSelectValue: SelectValueInterface = {
  id: NaN,
  name: COMMON.NOT_SPECIFIED
};

export interface SelectKeyValueI {
  id?: string;
  name?: string;
}

export const defaultSelectKeyValue: SelectKeyValueI = { id: '', name: '' };
