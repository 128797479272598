import {PermissionState, PermissionTypes, SET_PERMISSION_FOR_ELEMENTS} from "./types";

const defaultPermissionState: PermissionState = {
  elements: []
}

export const permissionReducer = (state: PermissionState = defaultPermissionState, action: PermissionTypes) => {
  switch (action.type) {
    case SET_PERMISSION_FOR_ELEMENTS:
      return {...state, elements: action.payload};
    default:
      return state;
  }
}