import React, { HTMLAttributes } from 'react';
import Select, { OptionsOrGroups } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useField } from 'formik';
import { groupBy } from 'lodash';

import './select-field-custom.scss';

interface PropsI {
  id: string;
  name: string;
  options: OptionsOrGroups<any, any>;
  isDisabled?: boolean;
  isClearable?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  className?: string;
  group?: boolean;
  simple?: boolean;
  suggestion?: boolean;
}

export const SelectFieldCustom: React.FC<PropsI & HTMLAttributes<HTMLDivElement>> = (props: PropsI & HTMLAttributes<HTMLDivElement>) => {
  const { id, options, menuPlacement } = { ...props };
  const [field, , { setValue, setTouched }] = useField(props as any);
  
  if (props.group) {
    const groups = groupBy(options, 'group');
    const groupedOptions = Object.keys(groups)
      .sort((a: string, b: string) => {
        if (a === "" || a === null) return 1;
        if (b === "" || b === null) return -1;
        if (a === b) return 0;
        return a < b ? -1 : 1;
      })
      .map((groupName: string) => {
        return {
          label: groupName,
          options: groups[groupName]
        }
      });
    return (
      <Select
        id={ id }
        name={ props.name }
        value={ field.value }
        options={ groupedOptions }
        getOptionLabel={ (option) => option.name }
        getOptionValue={ (option) => option.id }
        isDisabled={ props.isDisabled === undefined ? false : props.isDisabled }
        isSearchable={ options && options.length > 10 }
        isClearable={ (field.value && field.value.name) }
        maxMenuHeight={ 200 }
        menuPlacement={ menuPlacement || 'bottom' }
        onChange={ (selected) => setValue(selected) }
        onBlur={ () => setTouched(true) }
        classNamePrefix={ 'select-field-custom' }
        className={ props.className || '' }
      />
    );
  }
  if (props.simple) {
    return props.suggestion
      ? (
        <CreatableSelect
          id={ id }
          name={ props.name }
          value={ field.value ? { value: field.value, label: field.value } : null }
          options={ options.map((item: string) => ({ value: item, label: item })) }
          isDisabled={ props.isDisabled === undefined ? false : props.isDisabled }
          isClearable={ field.value }
          maxMenuHeight={ 200 }
          menuPlacement={ menuPlacement || 'bottom' }
          onChange={ (selected) => setValue(selected ? selected.value : '') }
          onBlur={ () => setTouched(true) }
          classNamePrefix={ 'select-field-custom' }
          className={ props.className || '' }
        />
      )
      : (
        <Select
          id={ id }
          name={ props.name }
          value={ { id: field.value, name: field.value } }
          options={ options.map((item: string) => ({ id: item, name: item })) }
          getOptionLabel={ (option) => option.name }
          getOptionValue={ (option) => option.id }
          isDisabled={ props.isDisabled === undefined ? false : props.isDisabled }
          isSearchable={ options && options.length > 10 }
          isClearable={ !!field.value }
          maxMenuHeight={ 200 }
          menuPlacement={ menuPlacement || 'bottom' }
          onChange={ (selected) => setValue(selected ? selected.id : '') }
          onBlur={ () => setTouched(true) }
          classNamePrefix={ 'select-field-custom' }
          className={ props.className || '' }
        />
      );
  }
  return (
    <Select
      id={ id }
      name={ props.name }
      value={ field.value }
      options={ options }
      getOptionLabel={ (option) => option.name }
      getOptionValue={ (option) => option.id }
      isDisabled={ props.isDisabled === undefined ? false : props.isDisabled }
      isSearchable={ options && options.length > 10 }
      isClearable={ props.isClearable === undefined ? (field.value && field.value.name) : props.isClearable }
      maxMenuHeight={ 200 }
      menuPlacement={ menuPlacement || 'bottom' }
      onChange={ (selected) => setValue(selected) }
      onBlur={ () => setTouched(true) }
      classNamePrefix={ 'select-field-custom' }
      className={ props.className || '' }
    />
  );
};