import http, { Http } from './Http';
import { ENDPOINTS } from '../../constants';
import DateHelper from '../../utils/date/DateHelper';

class WorkRecordClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getEmployeeList(date: Date = new Date(), dateRange: Array<string>) {
    const dateString =  DateHelper.getFormattedDate(date);
    const link = 'daterange[]=';
    const drString = link + dateRange.join(`&${link}`);
    return this.http.getList(`${ENDPOINTS.WORK_RECORD_EMPLOYEE_LIST}?date=${dateString}&${drString}`);
  }

  public getList() {
    return this.http.getList(ENDPOINTS.WORK_RECORD_LIST);
  }

  public get(id: number) {
    return this.http.get(`${ENDPOINTS.WORK_RECORD_GET}/${id}`);
  }

  public validate(body: any) {
    return this.http.post(ENDPOINTS.WORK_RECORD_VALIDATE, body);
  }

  public create(body: any) {
    return this.http.post(ENDPOINTS.WORK_RECORD_SAVE, body);
  }

  public activate(id: number, body: any) {
    return this.http.put(ENDPOINTS.WORK_RECORD_ACTIVATE(id), body);
  }

  public close(id: number, body: any) {
    return this.http.put(ENDPOINTS.WORK_RECORD_CLOSE(id), body);
  }

  public getSkills(id: number) {
    return this.http.get(ENDPOINTS.WORK_RECORD_SKILL_TREE(id));
  }

  public getProjectManager(code: string) {
    return this.http.get(ENDPOINTS.WORK_RECORD_STAFF_BY_PROJECT(code));
  }

  public getIsCm(staffId: number) {
    return this.http.get(ENDPOINTS.WORK_RECORD_IS_CM(staffId));
  }

  public getCloseWorkRecordInfo(staffId: number) {
    return this.http.get(ENDPOINTS.WORK_RECORD_CLOSE_INFO(staffId));
  }

  public geWorkRecordCv(id:number) {
    return this.http.getAction(ENDPOINTS.WORK_RECORD_GET_CV(id));
  }
}

export default new WorkRecordClient(http);
