import React from 'react';

import { TurnoverDetailItem } from '../TurnoverDetailItem/TurnoverDetailItem';

import { HR_DASHBOARD_CONTENT_TABS } from '../../../../constants';

import './turnover-details.scss'

interface Props {
  activeTab: string,
  locations: Array<any>,
  orgStructures: Array<any>,
  departments: Array<any>
}

export const TurnoverDetails: React.FC<Props> = (props: Props) => {
  const { activeTab, locations, orgStructures, departments } = { ...props };
  const renderByActiveTab = (tab: string) => {
    switch (tab) {
      case HR_DASHBOARD_CONTENT_TABS.locations.key:
        return (
          <div className="tab-content tab-active">
            {
              (locations.length)
                ? (
                  <div>
                    {
                      locations.map((location: any, i: number) => (
                        <TurnoverDetailItem key={ i } data={ location } />
                      ))
                    }
                  </div>
                )
                : <div className="empty">There are no details for locations</div>
            }
          </div>
        );
      case HR_DASHBOARD_CONTENT_TABS.units.key:
        return (
          <div className="tab-content tab-active">
            {
              (orgStructures.length)
                ? (
                  <div>
                    {
                      orgStructures.map((orgStructure: any, i: number) => (
                        <TurnoverDetailItem key={ i } data={ orgStructure } />
                      ))
                    }
                  </div>
                )
                : <div className="empty">There are no details for units</div>
            }
          </div>
        );
      case HR_DASHBOARD_CONTENT_TABS.departments.key:
        return (
          <div className="tab-content tab-active">
            {
              (departments.length)
                ? (
                  <div>
                    {
                      departments.map((department: any, i: number) => (
                        <TurnoverDetailItem key={ i } data={ department } />
                      ))
                    }
                  </div>
                )
                : <div className="empty">There are no details for departments</div>
            }
          </div>
        );
      default:
        return <></>;
    }
  }
  return (
    <div className="turnover-details">
      <div className="turnover-details-container">
        { renderByActiveTab(activeTab) }
      </div>
    </div>
  );
}
