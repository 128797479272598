import http, { Http } from './Http';
import { ENDPOINTS } from '../../constants';

export class PermissionClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getElements() {
    return this.http.getAxios(ENDPOINTS.PERMISSION_ELEMENTS);
  }
}

export default new PermissionClient(http);