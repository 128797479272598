import React, {createContext, FunctionComponent, ReactNode, useMemo} from 'react';
import {useLoadGsiClientScript} from '../hooks';

type GoogleOAuthContextProps = {
  clientId: string,
  scriptLoadedSuccessfully: boolean
}

export const GoogleOAuthContext = createContext<GoogleOAuthContextProps>(null!);

type GoogleOAuthProviderProps = {
  clientId: string,
  onScriptLoadSuccess?: () => void,
  onScriptLoadError?: () => void,
  children: ReactNode
}

export const GoogleOAuthProvider: FunctionComponent<GoogleOAuthProviderProps> = (props) => {
  const { clientId, children } = props;
  const scriptLoadedSuccessfully = useLoadGsiClientScript();

  const contextValue = useMemo(
    () => ({
      clientId,
      scriptLoadedSuccessfully,
    }),
    [clientId, scriptLoadedSuccessfully]
  );

  return (
    <GoogleOAuthContext.Provider value={ contextValue }>
      { children }
    </GoogleOAuthContext.Provider>
  )
}