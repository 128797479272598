import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';

import {ATTRIBUTE_NAMES} from '../../../../constants';
import {WorkRecord} from '../../../../interfaces/WorkRecord';
import {Staff} from '../../../../interfaces/Staff';
import {DateField} from '../../../Forms/DateField';
import {PopupCloser} from '../../../Popup/PopupCloser';

import PositionDescriptionState, {PositionDescriptionStateInterface} from './PositionDescriptionState';
import {PositionDescriptionFormFields} from './PositionDescriptionFormFields';
import {schema} from './PositionDescriptionFormSchema';

import './position-description-form.scss';

const labels = ATTRIBUTE_NAMES['wrPositionDescription'];

interface PositionDescriptionFormProps {
  submitFunction: Function;
  workRecord: WorkRecord;
  staff: Staff,
  popupName: string;
}

export const PositionDescriptionForm: React.FC<PositionDescriptionFormProps> =
  (props: PositionDescriptionFormProps) => {
    const { submitFunction, workRecord, staff, popupName } = { ...props };
    const [initialState, setInitialState] = useState<PositionDescriptionStateInterface | null>(null);
    const [saveButtonText, setSaveButtonText] = useState<string>('Save');
    const [saveStateDisabled, setSaveStateDisabled] = useState<boolean>(false);
    const [minApplyDate, setMinApplyDate] = useState<Date | null>(null);
    const [maxApplyDate, setMaxApplyDate] = useState<Date | null>(null);

    useEffect(() => {
      if (workRecord && initialState == null) {
        const wr: PositionDescriptionStateInterface = PositionDescriptionState.generateFromWorkRecord(workRecord);
        setInitialState(wr);
        if (wr.startDate) {
          setMinApplyDate(wr.startDate);
        }
        if (wr.endDate) {
          setMaxApplyDate(wr.endDate);
        }
        setSaveStateDisabled(false);
      }
    }, [initialState, workRecord]);

    if (initialState) {
      return (
        <Formik
          validateOnBlur={ true }
          initialValues={ initialState }
          validationSchema={ schema }
          onSubmit={values => {
            setSaveStateDisabled(true);
            setSaveButtonText('Saving');
            submitFunction(values);
          }}>
          {({
            errors,
            touched,
            values,
            setFieldValue,
            validateForm,
            submitForm
          }) => (
            <Form className="edb-form form-position-description">
              <div className="form-fields">
                <PositionDescriptionFormFields
                  values={ values }
                  workRecord={ workRecord }
                  staff={ staff }
                  mode={ "MODE_WR_EDIT" }
                  formErrors={ [] }
                  errors={ errors }
                  touched={ touched }
                  setFieldValue={ setFieldValue }
                />
              </div>
              <div className="form-footer">
                <div className="row">
                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="applyChanges">{ labels['applyChanges'] }</label>
                      <DateField
                        autoComplete='none'
                        id="applyChanges"
                        name="applyChanges"
                        className={`form-control ${errors.applyChanges && touched.applyChanges ? 'invalid-field' : ''}`}
                        onChange={ () => {} }
                        minDate={ minApplyDate }
                        maxDate={ maxApplyDate }
                        popperPlacement="top-start"
                      />
                      <ErrorMessage name="applyChanges">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
                    </div>
                  </div>
                  <div className="col-6 offset-3 form-buttons">
                    <PopupCloser popupName={ popupName }>
                      <button
                        className="edb-btn edb-btn--secondary"
                        type="reset"
                        data-testid="btnCancel"
                      >
                        Cancel
                      </button>
                    </PopupCloser>
                    <button
                      type="button"
                      className="edb-btn edb-btn--primary"
                      disabled={saveStateDisabled}
                      onClick={() => validateForm().then((errors) => {
                        const keys = Object.keys(errors);
                        if (keys.length > 0) {
                          const errorElement = document.querySelector(`[id="${keys[0]}"]`) as HTMLElement
                            || document.querySelector(`[id="${keys[0]}Wrapper"]`) as HTMLElement;
                          if (errorElement) errorElement.focus();
                        }
                        return submitForm();
                      })}
                      data-testid="btnSubmit"
                    >
                      { saveButtonText }
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
    return <></>;
  }
