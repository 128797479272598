import React, { createContext, useEffect, useState } from 'react';
import { DocumentList } from '../../Document/DocumentList/DocumentList';
import './work-record-document.scss'

const context = { updateList: () => {} };
export const DocumentListContext = createContext(context);

export const DOCUMENT_ITEM_LIMIT = 15;

export const WRDocument: React.FC = () => {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);
  const [start, setStart] = useState<number>(0);
  const [countItems, setCountItems] = useState(0);
  const [hideNext, setHideNext] = useState(true);

  useEffect(() => {
    if (((start + DOCUMENT_ITEM_LIMIT) < countItems)) {
      setHideNext(false);
    } else {
      setHideNext(true);
    }
  }, [countItems, start]);

  const [hidePrevious, setHidePrevious] = useState(true);

  useEffect(() => {
    if (start === 0) {
      setHidePrevious(true);
    } else {
      setHidePrevious(false);
    }
  }, [start]);

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    setStart(start + DOCUMENT_ITEM_LIMIT);
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    setStart(start - DOCUMENT_ITEM_LIMIT);
  };

  return (
    <DocumentListContext.Provider value={ context }>
      <div className="work-record-compliance">
        <div className="compliance-content">
          <div className="compliance-list">
            <table>
              <thead>
              <tr>
                <th>Document/Policy</th>
                <th>Acceptance Status</th>
                <th>Due By</th>
                <th>Acceptance Date</th>
              </tr>
              </thead>
              <tbody>
              <DocumentList start={ start } setPages={ setPages } setCountItems={ setCountItems }/>
              </tbody>
              <tfoot>
              <tr>
                <td colSpan={6}>
                  <p className="float-left m-2">
                    <span>{ DOCUMENT_ITEM_LIMIT } records per page </span>
                    <span>Page# { currentPage } of { pages }</span>
                  </p>
                  <p className="float-right m-2">
                    {
                      (!hidePrevious)
                        ? <button className="nav-previous" onClick={ previousPage }>Prev</button>
                        : <></>
                    }
                    {
                      (!hideNext)
                        ? <button className="nav-next ml-2" onClick={ nextPage }>Next</button>
                        : <></>
                    }
                  </p>
                </td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </DocumentListContext.Provider>
  );
}
