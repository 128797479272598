import {EducationItem} from '../../../../interfaces/EducationItem';

export interface EducationItemStateInterface {
  id: number;
  degree: number;
  fieldOfStudy: string;
  location: string;
  schoolName: string;
  yearOfGraduation: string;
  staffId: number;
}

const initialState: EducationItemStateInterface = {
  id: NaN,
  degree: 0,
  fieldOfStudy: '',
  location: '',
  schoolName: '',
  yearOfGraduation: '',
  staffId: NaN
}

export default class EducationItemState {
  public static generateFromEducationItem(educationItem?: EducationItem): EducationItemStateInterface {
    let state = initialState;
    if (educationItem) {
      state.id = educationItem.id;
      state.degree = educationItem.degree;
      state.fieldOfStudy = educationItem.fieldOfStudy;
      state.location = educationItem.location;
      state.schoolName = educationItem.schoolName;
      state.yearOfGraduation = educationItem.yearOfGraduation;
      state.staffId = educationItem.staffId;
    }
    return state;
  }
}
