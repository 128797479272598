import React, { useContext } from 'react';
import {ErrorMessage} from 'formik';

import { Skill as SkillInterface } from '../../../../interfaces/entity/Skill';
import { SkillCheckboxField } from '../../../Forms/SkillCheckboxField';
import { SkillEditFormContext } from './SkillEditForm';


interface SkillEditFormFieldsProps {
  valuesSkills: Array<SkillInterface>,
}

export const SkillEditFormFields: React.FC<SkillEditFormFieldsProps> = (props: SkillEditFormFieldsProps) => {
  const { valuesSkills } = { ...props };
  const { type, filteredSkills, setFormSkills } = useContext(SkillEditFormContext);

  if (filteredSkills.length) {
    return (
      <>
        <div className="form-group">
          <SkillCheckboxField
            id="skills"
            name="skills"
            isLanguage={ (type === 'languageSkills') }
            values={ valuesSkills }
            options={ filteredSkills }
            changeEffect={ setFormSkills }
          />
          <ErrorMessage name="skills" />
        </div>
      </>
    );
  }

  return <></>;
}
