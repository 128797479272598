import React, {FunctionComponent, useEffect, useState} from 'react';
import {toastr} from "react-redux-toastr";
import {DatePickerField} from "../../Forms/DatePickerField";
import DateHelper from '../../../utils/date/DateHelper';

import './date-column.scss';

interface DateColumnFilterProps {
  column: {
    filterValue: string;
    setFilter: Function;
  };
}

export const DateColumnFilter: FunctionComponent<DateColumnFilterProps> = (props: DateColumnFilterProps) => {
  const column = props.column;
  // @ts-ignore
  const {filterValue, setFilter, id} = {...column};
  const [from, setFrom] = useState<Date | null>();
  const [to, setTo] = useState<Date | null>();

  useEffect(() => {
    if (filterValue === undefined) {
      setFrom(undefined);
      setTo(undefined);
    }
  }, [filterValue]);

  const [error, setError] = useState<string>('');

  const validate = (from: Date | null | undefined, to: Date | null | undefined) => {
    if (!from && !to) {
      setError('Please enter at least one date');
      return false;
    }

    if (from && to && from > to) {
      setError('StartFrom date should be less than StartTo');
      return false;
    }

    return true;
  }

  useEffect(() => {
    if (error.length) {
      toastr.error('', error);
      setError('');
    }
  }, [error])

  return (
    <div className="date-filter-wrapper">
      <label>From</label>
      <DatePickerField
        id={`${id}-from`}
        name="from"
        value={(from) ? DateHelper.getFormattedDate(from) : ''}
        onChange={(val) => {
          setFrom(val);
        }}/>
      <label>To</label>
      <DatePickerField
        id={`${id}-to`}
        name="to"
        value={(to) ? DateHelper.getFormattedDate(to) : ''}
        onChange={(val) => {
          setTo(val);
        }}/>
      <button
        className="button button-ok"
        onClick={() => {
          if (validate(from, to)) {
            setFilter([from, to]);
            document.dispatchEvent(new Event('mousedown'));
          }
        }}>
        OK
      </button>
      <button
        className="button button-cancel"
        onClick={() => {
          document.dispatchEvent(new Event('mousedown'));
        }}>
        Cancel
      </button>
    </div>
  )
}
