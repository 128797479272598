import yup from '../../../../utils/yup-extended';

export const schema = yup.object()
  .shape({
    fieldOfStudy: yup.string()
      .max(255)
      .required('Field of study is required')
      .testSpecialChars()
      .testXSS(),
    degree: yup.number()
      .nullable(),
    schoolName: yup.string()
      .max(255)
      .required('School name is required')
      .testSpecialChars()
      .testXSS(),
    location: yup.string()
      .matches(/^[a-zA-Z-\s\,]+$/, 'Only letters, spaces, commas allowed')
      .max(255)
      .required('Location is required'),
    yearOfGraduation: yup.string()
      .notRequired()
      .test(
        'len',
        'Year of graduation must be exactly 4 characters',
        function (val: any) {
          if (!!val) {
            let message: Array<string> = [];

            const schemaLength = yup.string().length(4);
            if (!schemaLength.isValidSync(val)) {
              message.push(
                val.length > 4
                  ? 'Year of graduation cannot be more than 4 characters'
                  : 'Year of graduation cannot be less than 4 characters'
              );
            }

            const schemaMatches = yup.string().matches(/^[0-9]+$/);
            if (!schemaMatches.isValidSync(val)) {
              message.push('Only numbers allowed');
            }
            if (message.length) {
              return this.createError({
                message: message.join('. '),
                path: 'yearOfGraduation',
              });
            }
          }
          return true;
        }
      )
  })