import {StaffResponse} from '../../interfaces/http/staff/StaffResponse';
import {Staff as StaffInterface} from '../../interfaces/Staff';
import {WorkRecord as WorkRecordInterface} from '../../interfaces/WorkRecord';
import Staff from '../../models/Staff';

import wrMapper from './WorkRecordMapper';
import wrHelper from '../../services/data/WorkRecordHelper';
import {WorkRecordStructure} from '../../interfaces/http/staff/WorkRecordStructure';
import {AttributeStructure} from "../../interfaces/http/common/AttributeStructure";
import {ATTRIBUTES_MAP} from "../../constants/ATTRIBUTES_MAP";
import attributeMapper from "./AttributeMapper";

class StaffMapper {
  public creatStaffFromStaffResponse(data: StaffResponse): StaffInterface {
    let staff: StaffInterface = new Staff();
    staff.id = data.id;
    staff.alumni = data.alumni;
    staff.avatar = data.avatar;
    staff.code = data.code;
    staff.companyStartDate = (data.company_start_date) ? new Date(data.company_start_date) : null;
    staff.fullName = data.full_name;
    staff.status = data.status;
    staff.username = data.username;

    data.attributes.forEach((attr: AttributeStructure) => {
      const alias: string = attr.attribute;
      const name: string = ATTRIBUTES_MAP.staff[alias];
      if (!name) {
        return true;
      }
      const attribute = attributeMapper.creatStaffAttributeFromStaffResponse(attr);
      staff.attributes.set(name, attribute);
      // @ts-ignore
      staff[name] = attribute.value;
    });

    if (data.permissions) {
      staff.permissions = data.permissions;
    }

    if (data.subordinates) {
      staff.subordinates = data.subordinates;
    }

    let workRecords: Array<WorkRecordInterface> = [];
    if (data.work_records) {
      workRecords = data.work_records.map((wr: WorkRecordStructure) => {
        return wrMapper.creatWorkRecordFromStaffResponse(wr, data.id);
      });
    }

    staff.workRecords = workRecords;
    staff.activeWRs = wrHelper.sort(wrHelper.filter(workRecords, ['Active', 'Offer Accepted']));
    staff.inactiveWRs = wrHelper.sort(wrHelper.filter(workRecords, ['Inactive']));

    return staff;
  }
}

export default new StaffMapper();
