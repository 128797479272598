import React, {FunctionComponent, useEffect, useState} from 'react';
import {FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome';

import './employee-date-range.scss';
import useOutsideClick from "../../../hooks/outsideClick";

interface EmployeeDateRangeProps {
  value: Array<string>
  setter: Function
}

const employeeStates = [
  {value: 'past', label: 'Past'},
  {value: 'current', label: 'Current'},
  {value: 'future', label: 'Future'}
]

export const EmployeeDateRange: FunctionComponent<EmployeeDateRangeProps> = (props: EmployeeDateRangeProps) => {
  const {value, setter} = {...props};
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [values, setValues] = useState<Array<string>>(value);
  const [stringValue, setStringValue] = useState<string>('');

  const selectAll = () => {
    const values = employeeStates.map((item) => item.value);
    setter(values);
    setValues(values);
  }
  const selectOption = (event: any) => {
    const target = event.target;
    const current: Array<string> = [];
    Object.assign(current, values);
    if (target.checked) {
      current.push(target.value);
    } else {
      const position = current.indexOf(target.value);
      if (position !== -1) {
        current.splice(position, 1);
      }
    }
    if (!current.length) {
      employeeStates.forEach((item) => {
        current.push(item.value);
      });
    }
    setter(current);
    setValues(current);
    setIsVisible(!isVisible);
  }

  useEffect(() => {
    const length = values.length;
    if (length === 1) {
      //@ts-ignore
      setStringValue(employeeStates.filter((item) => (item.value === values[0]))[0].label);
    } else {
      setStringValue(`${length} statuses selected`);
    }
  }, [values])

  return (
    <div className="employee-date-range">
      <FA icon={['fas', 'caret-down']}/>
      <input
        className="trigger"
        type="text"
        value={stringValue}
        readOnly={true}
        onClick={() => {
          setIsVisible(!isVisible)
        }}/>
      <div className={`options ${(!isVisible) ? 'options-hidden' : ''}`} ref={ref}>
        <div className="option">
          <span className="item" onClick={selectAll}>Select All</span>
        </div>
        {employeeStates.map((item) => (
            <div className="option" key={item.value}>
              <label htmlFor={item.value} className="item">
                <input
                  id={item.value}
                  value={item.value}
                  type="checkbox"
                  checked={(value.indexOf(item.value) !== -1)}
                  onChange={selectOption}/>
                {item.label}
              </label>
            </div>
          )
        )}
      </div>
    </div>
  );
};
