// import * as d3 from 'd3';
const d3 = require('d3v3');

export default function layoutGalaxy() {
  const hierarchy = d3.layout.hierarchy();
  let spread = 4,
    initialAngle = - Math.PI / 2,
    size = [1, 1],
    radius: number;

  function galaxy(d: any, i: any) {
    // @ts-ignore
    const nodes = hierarchy.call(this, d, i);
    const root = nodes[0];
    const w = size[0];
    const h = size[1];
    const r = (radius == null)
        ? Math.sqrt
        : typeof radius === 'function'
          ? radius
          : function() { return radius; };

    root.angle = initialAngle;
    root.x = root.y = 0;

    d3_layout_hierarchyVisitAfter(root, function(d: any) { d.r = +r(d.value); });

    d3_layout_hierarchyVisitBefore(root, function(x: any) { return d3_layout_galaxySiblings(x, spread); });

    const bounds = d3_layout_galaxyBound(root);
    const k = Math.min(w / (bounds[1] - bounds[0]), h / (bounds[3] - bounds[2]));
    const dx = (w - k * (bounds[1] + bounds[0])) / 2;
    const dy = (h - k * (bounds[3] + bounds[2])) / 2;
    d3_layout_galaxyTransform(root, dx, dy, 10);
    return nodes;
  }

  galaxy.size = function(_: any) {
    if (!arguments.length) return size;
    size = _;
    return galaxy;
  };

  galaxy.initialAngle = function(_: any) {
    if (!arguments.length) return initialAngle;
    initialAngle = _;
    return galaxy;
  };

  galaxy.radius = function(_: any) {
    if (!arguments.length) return radius;
    radius = _ == null || typeof _ === 'function' ? _ : +_;
    return galaxy;
  };

  galaxy.spread = function(_: any) {
    if (!arguments.length) return spread;
    spread = +_;
    return galaxy;
  };

  return d3_layout_hierarchyRebind(galaxy, hierarchy);
}

function d3_layout_galaxyBound(node: any) {

  const b = [- node.r, + node.r, - node.r, + node.r];
  const nodes = node.children;

  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      let nb = d3_layout_galaxyBound(nodes[i]);
      b[0] = Math.min(b[0], nb[0] + nodes[i].x);
      b[1] = Math.max(b[1], nb[1] + nodes[i].x);
      b[2] = Math.min(b[2], nb[2] + nodes[i].y);
      b[3] = Math.max(b[3], nb[3] + nodes[i].y);
    }
  }

  return b;
}

function d3_layout_galaxySiblings(node: any, spread: any) {

  let nodes, n, n2;
  if (!(nodes = node.children) || !(n = nodes.length)) return;

  if (n <= 2) {
    n2 = 6 * n;
  } else if (n === 3) {
    n2 = 4 * n;
  } else if (n <= 6) {
    n2 = 2 * n;
  } else {
    n2 = n;
  }

  for (let i = 0; i < n; i++) {

    if (nodes[i].depth === 1) {
      n2 = n;
    }
    nodes[i].angle = node.angle - (i - (n - 1) % 2 / 2) * 2 * Math.PI / n2;
    let distance = node.r + spread * nodes[i].r;
    nodes[i].x = distance * Math.sin(nodes[i].angle);
    nodes[i].y = distance * Math.cos(nodes[i].angle);
  }

}

function d3_layout_galaxyTransform(node: any, x: any, y: any, k: any) {
  const children = node.children;
  node.x = x += k * node.x;
  node.y = y += k * node.y;
  node.r *= k;
  if (children) {
    let i = -1, n = children.length;
    while (++i < n) d3_layout_galaxyTransform(children[i], x, y, k);
  }
}

function d3_layout_hierarchyRebind(object: any, hierarchy: any) {
  d3.rebind(object, hierarchy, 'sort', 'children', 'value');
  object.nodes = object;
  object.links = d3_layout_hierarchyLinks;

  return object;
}

function d3_layout_hierarchyLinks(nodes: any) {
  return d3.merge(nodes.map(function(parent: any) {
    return (parent.children || []).map(function(child: any) {
      return {source: parent, target: child};
    });
  }));
}

function d3_layout_hierarchyVisitBefore(node: any, callback: any) {
  const nodes = [node];
  while ((node = nodes.pop()) != null) {
    callback(node);
    let n, children;
    if ((children = node.children) && (n = children.length)) {
      while (--n >= 0) nodes.push(children[n]);
    }
  }
}

function d3_layout_hierarchyVisitAfter(node: any, callback: any) {
  const nodes = [node];
  let nodes2 = [];
  while ((node = nodes.pop()) != null) {
    nodes2.push(node);
    let n, children;
    if ((children = node.children) && (n = children.length)) {
      let i = -1;
      while (++i < n) nodes.push(children[i]);
    }
  }
  while ((node = nodes2.pop()) != null) {
    callback(node);
  }
}