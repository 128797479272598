import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import Select from 'react-select';

import store from '../../../../store/store';
import { setShouldUpdate } from '../../../../store/workRecord/actions';
import { dispatchPopupState } from '../../../../store/popup/types';

import wrHelper from '../../../../services/data/WorkRecordHelper';

import { WorkRecord } from '../../../../interfaces/WorkRecord';

import { PopupCloser } from '../../../Popup/PopupCloser';
import { DateField } from '../../../Forms/DateField';

import { schema } from './TrialStatusFormSchema';
import TrialStatusState, {TrialStatusStateInterface} from './TrialStatusState';

import { TRIAL_STATUSES, ATTRIBUTE_NAMES } from '../../../../constants';

import './trial-status-form.scss';

type PropsType = {
  workRecord: WorkRecord
}

const labels = ATTRIBUTE_NAMES.wrTrialStatus;

const trialStatusList = [
  {
    label: TRIAL_STATUSES.IN_PROGRESS.name,
    value: TRIAL_STATUSES.IN_PROGRESS.name
  },
  {
    label: TRIAL_STATUSES.PASSED.name,
    value: TRIAL_STATUSES.PASSED.name
  },
  {
    label: TRIAL_STATUSES.FAILED.name,
    value: TRIAL_STATUSES.FAILED.name
  }
];

export const TrialStatusForm: React.FC<PropsType> = (props: PropsType) => {

  const [initialState, setInitialState] = useState<TrialStatusStateInterface | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (initialState === null) {
      setInitialState(TrialStatusState.generateFromWorkRecord(props.workRecord));
    }
  }, [initialState]);

  const submission = (values: TrialStatusStateInterface) => {
    setIsSaving(true);
    const workRecord = props.workRecord;
    if (workRecord) {
      if (values.trialStatus === workRecord.trialStatus && values.trialEndDate === workRecord.trialEndDate) {
        return;
      }

      wrHelper.updateTrialStatus(workRecord, values)
        .then((data: any) => {
          store.dispatch(setShouldUpdate(true));
          dispatchPopupState('trialStatus', false);
        })
        .finally(() => {
          setIsSaving(false);
        })
    }
  };

  if (!initialState) return <></>;

  return (
    <Formik
      initialValues={ initialState }
      validationSchema={ schema }
      onSubmit={ values => { submission(values) } }>
      {({errors, touched, values, setFieldValue}) => (
        <Form className="edb-form form-trialStatus">

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="trialStatus">{ labels.trialStatus }</label>
                <Select
                  id="trialStatus"
                  name="trialStatus"
                  value={ trialStatusList.filter((item) => item.value === values.trialStatus) }
                  options={ trialStatusList }
                  isSearchable={ false }
                  isClearable={ false }
                  maxMenuHeight={ 200 }
                  menuPlacement={ 'bottom' }
                  onChange={ (selected) => setFieldValue('trialStatus', selected ? selected.value : '') }
                  classNamePrefix={ 'select-field-custom' }
                  className={ errors.trialStatus && touched.trialStatus ? 'invalid-field' : '' }
                />
                <ErrorMessage name="trialStatus">{ msg => <div className="text-danger">{ msg }</div> }</ErrorMessage>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="trialEndDate">{labels.trialEndDate}</label>
                <DateField
                  autoComplete='off'
                  id="trialEndDate"
                  name="trialEndDate"
                  className="form-control"
                  onChange={() => {}}
                />
                <ErrorMessage name="trialEndDate"/>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col text-center form-buttons">
              <PopupCloser popupName="trialStatus">
                <button className="edb-btn edb-btn--secondary" type="reset">
                  Cancel
                </button>
              </PopupCloser>
              <button
                type="submit"
                className="edb-btn edb-btn--primary"
                disabled={ isSaving }
              >
                { isSaving ? 'Saving' : 'Save' }
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
};