import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {ErrorMessage} from 'formik';

import {SkillSetInterface} from '../../../../interfaces/SkillSetInterface';
import dictionaryStore from '../../../../services/dictionary/DictionaryStore';
import mapper from '../../../../mappers/response/SkillMapper';
import {ATTRIBUTE_NAMES} from '../../../../constants/ATTRIBUTE_NAMES';

import {SkillField} from '../../../Forms/SkillField';

import './skill-form-fields.scss';

const labels = ATTRIBUTE_NAMES['wrSkills'];

interface SkillFormFieldsProps {
  values: any;
}

export const SkillFormFields: FunctionComponent<SkillFormFieldsProps> = (props: SkillFormFieldsProps) => {
  const {values} = {...props};

  const [isLoaded, setIsLoaded] = useState(false);
  const [skills, setSkills] = useState<SkillSetInterface>({hardSkills: [], softSkills: [], languageSkills: []});

  useEffect(() => {
    if (!isLoaded) {
      dictionaryStore.getByAlias('skill').then((data: any) => {
        if (typeof data !== 'undefined') {
          setSkills(mapper.convertSkillsToSet(data));
        }
      });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded])

  return (
    <Fragment>
      <div className="skills-form--hard-skills">
        <p className="skills-form--title">{labels['hardSkills']}</p>
        <SkillField
          id="hardSkills"
          name="hardSkills"
          labelKey="name"
          isLanguage={false}
          options={skills.hardSkills}/>
        <ErrorMessage name="hardSkills"/>
      </div>
      <div className="skills-form--soft-skills">
        <p className="skills-form--title">{labels['softSkills']}</p>
        <SkillField
          id="softSkills"
          name="softSkills"
          labelKey="name"
          isLanguage={false}
          options={skills.softSkills}/>
        <ErrorMessage name="softSkills"/>
      </div>
      <div className="skills-form--languages">
        <p className="skills-form--title">{labels['languageSkills']}</p>
        <SkillField
          id="languageSkills"
          name="languageSkills"
          labelKey="name"
          isLanguage={true}
          options={skills.languageSkills}/>
        <ErrorMessage name="languageSkills"/>
      </div>
    </Fragment>
  );
}
