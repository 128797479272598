import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../store/types';
import PermissionWRService from '../../../services/security/PermissionWRService';

import './employee-employment-type.scss';

interface EmployeeDateRangeProps {
  value: string;
  setter: Function;
  list: Array<any>;
}

export const EMPLOYMENT_TYPE_ALL = 'all';
export const EMPLOYMENT_TYPE_EMPLOYEE = 'employee';
export const EMPLOYMENT_TYPE_CONTRACTOR = 'contractor';

export type EmploymentTypes = typeof EMPLOYMENT_TYPE_ALL |
  typeof EMPLOYMENT_TYPE_EMPLOYEE |
  typeof EMPLOYMENT_TYPE_CONTRACTOR;

const employmentTypes = [
  {value: EMPLOYMENT_TYPE_ALL, label: 'All', count: 0, isVisible: true},
  {value: EMPLOYMENT_TYPE_EMPLOYEE, label: 'Employees', count: 0, isVisible: false},
  {value: EMPLOYMENT_TYPE_CONTRACTOR, label: 'Contractors', count: 0, isVisible: false}
]

export const EmployeeEmploymentType: FunctionComponent<EmployeeDateRangeProps> = (props: EmployeeDateRangeProps) => {
  const {value, setter, list} = {...props};

  const myStaff = useSelector((state: GlobalState) => state.staff.myStaff);

  useEffect(() => {
    if (myStaff) {
      PermissionWRService.setUser(myStaff);
      employmentTypes.forEach((type: any) => {
        if (type.value !== EMPLOYMENT_TYPE_ALL) {
          type.isVisible = PermissionWRService.isGranted('employment', 'view');
        }
      })
    }
  }, [myStaff]);

  const selectType = (value: string) => {
    setter(value);
  };

  useEffect(() => {
    employmentTypes.forEach((type: any) => {
      if (list[type.value]) {
        type.count = list[type.value].length;
      }
    });
  }, [list]);

  return (
    <div className="employee-employment-type">
      {
        employmentTypes.map((item, index) => (
            (item.isVisible)
              ? (
                <div
                  className={ `type ${(item.value === value) ? 'active': ''}` }
                  key={ index }
                  onClick={ () => selectType(item.value) }>
                  { item.label } { item.count ? `(${item.count})` : '' }
                </div>
              )
              : <Fragment key={ index }></Fragment>
          )
        )
      }
    </div>
  );
};
