import React, {useEffect} from 'react';
import { ReportWrapper } from '../components/Report/ReportWrapper';
import ReportClient from "../services/http/ReportClient";
import store from "../store/store";
import {reportActions} from "../store/report/actions";

export const Report: React.FC = () => {
  useEffect(() => {
    ReportClient.getConfig()
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          store.dispatch(reportActions.setConfig(response.data));
        }
      })
      .catch((e) => {
        console.log(e);
      })
  }, []);
  
  return (
    <ReportWrapper />
  );
}
