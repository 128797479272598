import React, { ReactElement, PropsWithChildren, cloneElement, useRef } from 'react';

import useOutsideCloser from '../../../hooks/outsideClickHook';
import { FILTER_WRAPPER_CLASSNAME } from './FilterTrigger';

import './filter.scss';

interface FilterWrapperProps {
  column: any;
}


export const FilterWrapper: React.FC<FilterWrapperProps> = (props: PropsWithChildren<FilterWrapperProps>) => {
  const column = props.column;
  const ref = useRef(null);

  const close = () => {
    // @ts-ignore
    const element = ref.current as HTMLElement;
    if (column.isFilterOpen && element) {
      element.classList.remove('active');
      ((element.parentElement as HTMLElement).closest('th') as HTMLElement).classList.remove('filtering');
      column.isFilterOpen = false;
    }
  };
  useOutsideCloser(ref, close);

  return (
    <div ref={ ref } className={ FILTER_WRAPPER_CLASSNAME }>
      { cloneElement(props.children as ReactElement) }
    </div>
  );
}
