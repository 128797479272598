import {CVDataInterface, CVInterface} from "../interfaces/CVInterface";
import {CVStatusType} from "../store/cv/types";

export default class CVModel implements CVInterface {
  id: number | undefined = undefined;
  status: CVStatusType | null = null;
  attribute: string = 'cv';
  updatedAt: string = '';
  startDate: string = '';
  endDate?: string = '';
  value?: string = '';
  data: {[key in keyof CVDataInterface]: CVDataInterface[key]} = {};
}