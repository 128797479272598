import { FeedbackI } from '../interfaces/entity/Feedback';
import { FeedbackStaff } from '../interfaces/entity/FeedbackStaff';
import { FeedbackType } from '../interfaces/entity/FeedbackType';
import { FeedbackProject } from '../interfaces/entity/FeedbackProject';
import DateHelper from '../utils/date/DateHelper';

export default class EducationItem implements FeedbackI {
  id: number = NaN;
  value: string = '';
  periodEnd: Date | null = null;
  periodStart: Date | null = null;
  startDate: Date | null = null;
  status: string = '';
  createdAt: string = '';
  updatedAt: Date | null = null;
  author: FeedbackStaff | null = null;
  type: FeedbackType | null = null;
  crmId?: string;
  project?: FeedbackProject;
  projectManager?: FeedbackStaff;

  getPeriod = (): string => {
    let dates: string = '';
    if (this.periodStart && this.periodEnd) {
      dates += DateHelper.getFormattedDate(this.periodStart);
      dates += ' - ';
      dates += DateHelper.getFormattedDate(this.periodEnd);
    }

    return dates;
  }

  getDate = (): string => {
    return this.createdAt ?? '';
  }
}
