import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';

class MediaClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public addAttachment(body: any) {
    return this.http.post(ENDPOINTS.MEDIA, body);
  }

}

export default new MediaClient(http);
