import {FC, useEffect} from 'react';
import {useFormikContext} from 'formik';

export const ScrollToError: FC = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();
  const keys = Object.keys(errors);

  useEffect(() => {
    if (isSubmitting && !isValidating && keys.length > 0) {
      const errorElement = document.querySelector(`[id="${keys[0]}"]`) as HTMLElement
        || document.querySelector(`[id="${keys[0]}Wrapper"]`) as HTMLElement;
      if (errorElement) errorElement.focus();
    }
  }, [isSubmitting, isValidating, keys]);

  return null;
}