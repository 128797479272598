import React, {FunctionComponent} from 'react';

export const CVIcon: FunctionComponent = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13">
    <g fill="none" fillRule="evenodd">
      <path stroke="#FFF" d="M.5.5h14v12H.5z"/>
      <path stroke="#FFF" strokeLinecap="square" d="M3.5 9.5h8"/>
      <path fill="#FFF" d="M3.02 7.455H7c-.268-1.322-.929-1.983-1.983-1.983s-1.72.66-1.996 1.983z"/>
      <circle cx="5" cy="4" r="1" fill="#FFF"/>
    </g>
  </svg>
