import React from 'react';
import FileSaver from 'file-saver';

import {
  HR_DASHBOARD_GROUP_BY_TYPES_INFO,
  HR_DASHBOARD_KEYWORDS
} from '../../../constants';
import DateHelper from '../../../utils/date/DateHelper';
import { DownloadIcon } from '../../Icon/DownloadIcon';
import client from '../../../services/http/HRDashboardClient';

import { DatePickerField } from '../../Forms/DatePickerField';

interface GroupByTypeInterface {
  label: string,
  key: string
}

interface Props {
  asForDate: Date,
  compareDate: Date | null,
  groupBy: string,
  setAsForDate: Function,
  setCompareDate: Function,
  setGroupBy: Function
}

export const HCSnapshotHeader: React.FC<Props> = (props: Props) => {
  const { asForDate, compareDate, groupBy, setAsForDate, setCompareDate, setGroupBy } = { ...props };

  const exportCSV = (): void => {
    client.getSnapshotCSV(asForDate, compareDate, groupBy).then((data: any) => {
      if (data) {
        const file = new Blob([data], { type: 'text/csv' });
        const date = DateHelper.getFormatted2Date(asForDate);
        FileSaver.saveAs(file, `headcount-${date}.csv`);
        client.http.unsetResponseType();
      }
    });
  };

  const exportNavision = (): void => {
    client.getSnapshotNavision(asForDate, compareDate).then((data: any) => {
      if (data) {
        const file = new Blob([data], { type: 'application/octet-stream' });
        const date = DateHelper.getFormatted2Date(asForDate);
        FileSaver.saveAs(file, `headcount-${date}.zip`);
        client.http.unsetResponseType();
      }
    });
  };

  return (
    <div className="headcount-csv">
      <div className="row align-items-end">
        <div className="col-auto mr-auto">
          <div className="filter-group">
            <div className="employee-list__datepicker-container m-0">
              <p className="employee-list__label mb-2">
                { HR_DASHBOARD_KEYWORDS.LABEL_AS_FOR }
              </p>
              <div className="employee-list__overlay">
                <DatePickerField
                  name="as_for"
                  value={ (asForDate) ? DateHelper.getFormattedDate(asForDate) : '' }
                  selected={ asForDate }
                  className="input-group ui-datepicker"
                  onChange={(val: Date) => {
                    setAsForDate(val)
                  }}/>
              </div>
            </div>
            <div className="employee-list__datepicker-container" style={{marginLeft: "20px"}}>
              <p className="employee-list__label mb-2">
                { HR_DASHBOARD_KEYWORDS.LABEL_COMPARE_DATE }
              </p>
              <div className="employee-list__overlay">
                <DatePickerField
                  name="compare_date"
                  value={(compareDate) ? DateHelper.getFormattedDate(compareDate) : ''}
                  selected={ compareDate }
                  className="input-group ui-datepicker"
                  onChange={(val: Date) => {
                    setCompareDate(val)
                  }}/>
              </div>
            </div>
            <div className="employee-list__datepicker-container" style={{marginLeft: "20px"}}>
              <p className="employee-list__label mb-2">
                &nbsp;
              </p>
              <div className="employee-list__overlay">
                <span className="custom-label">Group by</span>
                <span className="buttons-list">
                  {HR_DASHBOARD_GROUP_BY_TYPES_INFO.map((groupingType: GroupByTypeInterface) => (
                      <span
                        className={`button ${(groupingType.key === groupBy) ? 'active': ''}`}
                        key={groupingType.key}
                        onClick={() => setGroupBy(groupingType.key)}>
                        {groupingType.label}
                      </span>
                    )
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-auto ml-auto">
          <span className="table-settings__control-link" onClick={exportCSV}>
            <span className="table-settings__control-icon">
              <DownloadIcon/>
            </span>
            Export to CSV
          </span>
          <span className="table-settings__control-link" onClick={exportNavision}>
            <span className="table-settings__control-icon">
              <DownloadIcon/>
            </span>
            Export to Navision
          </span>
        </div>
      </div>
    </div>
  );
}
