import React, {FunctionComponent} from 'react';

export const SorterIcon: FunctionComponent = () =>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       width="7" height="8" viewBox="0 0 102.3 139" preserveAspectRatio="xMidYMid">
    <g>
      <g>
        <path className="st1" d="M51.2,0L0,52.1h102.3L51.2,0z"/>
        <path className="st2" d=" M51.2,139l51.2-52.1H0L51.2,139z"/>
      </g>
    </g>
  </svg>

