import React, {FunctionComponent, PropsWithChildren, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useStore} from 'react-redux';

import {POPUP_STATE} from '../../store/reducers';
import {PopupProps} from './PopupPropsInterface';

const defaultProps: PopupProps = {
  popupName: 'none',
  id: `modal-${(new Date()).getTime()}`,
  title: '',
  size: 'sm',
  centered: false
};

export const Popup: FunctionComponent<PopupProps> = (props: PropsWithChildren<PopupProps>) => {
  const {getState, subscribe} = useStore();
  const [show, setShow] = useState(getState()[POPUP_STATE][props.popupName]);

  useEffect(() => {
    return subscribe(() => {
      setShow(getState()[POPUP_STATE][props.popupName]);
    });
  }, [show])

  return (
    <Modal
      id={props.id ?? defaultProps.id}
      size={props.size ?? defaultProps.size}
      centered={props.centered ?? defaultProps.centered}
      show={show}
      backdropClassName={'extend-modal-backdrop'}
      onHide={() => {
      }}>
      <Modal.Header>
        <Modal.Title>
          {props.title ?? defaultProps.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  );
}
