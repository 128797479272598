import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {Form, Formik} from 'formik';

import {EducationItem} from '../../../../interfaces/EducationItem';


import EducationItemState, {EducationItemStateInterface} from './EducationItemState';
import {EducationItemFormFields} from './EducationItemFormFields';
import {schema} from './EducationItemFormSchema';

import './education-item-form.scss';

interface EducationItemFormProps {
  submitFunction: Function,
  educationItem: EducationItem,
  popupName: string,
  handleCancel: Function,
  saving: boolean
}

export const EducationItemForm: FunctionComponent<EducationItemFormProps> =
  (props: EducationItemFormProps) => {
    const {submitFunction, educationItem, popupName} = {...props};
    const [initialState, setInitialState] = useState<EducationItemStateInterface | null>(null);

    useEffect(() => {
      if (initialState == null) {
        setInitialState(EducationItemState.generateFromEducationItem(educationItem));
      }
    }, [initialState])

    if (initialState) {
      return (
        <Formik
          validateOnBlur={ true }
          initialValues={ initialState }
          validationSchema={ schema }
          onSubmit={ values => submitFunction(values) }>
          {({errors, touched, values, setFieldValue }) => (
            <Form className="edb-form form-position-description">
              <div className="form-fields">
                <EducationItemFormFields
                  values={ values }
                  educationItem={ educationItem }
                  errors={ errors }
                  touched={ touched }
                  setFieldValue={ setFieldValue }
                />
              </div>
              <div className="form-footer">
                <div className="form-buttons">
                  <button className="edb-btn edb-btn--secondary" type="reset" onClick={() => props.handleCancel()}>
                    Cancel
                  </button>
                  <button className="edb-btn edb-btn--primary" type="submit" disabled={props.saving}>
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
    return <Fragment/>;
  }
