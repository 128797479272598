import {DICTIONARY_STATE} from '../../store/reducers';
import store from '../../store/store';
import {
  setDegrees,
  setDictionaryActiveStaff,
  setDictionaryAll,
  setDictionaryProjects, setDictionaryUsers,
  setSchools
} from '../../store/dictionary/actions';

import client, {DictionaryClient} from '../http/DictionaryClient';
import educationClient from '../http/EducationClient';
import ArrayHelper from '../../utils/ArrayHelper';

class DictionaryStore {
  client: DictionaryClient

  constructor(client: DictionaryClient) {
    this.client = client;
  }

  public initialize() {
    return client.getAll().then((data) => {
      store.dispatch(setDictionaryAll(data));
      return data;
    });
  }

  public initializeEducation() {
    return Promise.all([
      educationClient.getDegrees().then((data: any) => {
        const degrees = ArrayHelper.fromObjectIndexed(data);
        store.dispatch(setDegrees(degrees));
        return degrees;
      }),
      educationClient.getSchools().then((data: any) => {
        const schools = ArrayHelper.fromObject(data);
        store.dispatch(setSchools(schools));
        return schools;
      }),
    ]);
  }

  public getAll() {
    const all = this.getDictionaryState().all;
    if (!Object.keys(all).length) {
      return this.initialize();
    } else {
      return new Promise((resolve) => {
        resolve(all.all);
      });
    }
  }

  public getActiveStaff() {
    const activeStaff = this.getDictionaryState().activeStaff;
    if (!Object.keys(activeStaff).length) {
      return client.getActiveStaff(true).then((data) => {
        store.dispatch(setDictionaryActiveStaff(data));
        return data;
      });
    } else {
      return new Promise((resolve) => {
        resolve(activeStaff.activeStaff);
      });
    }
  }

  public getByAlias(alias: string) {
    const all = this.getDictionaryState().all;
    if (!Object.keys(all).length) {
      return client.getAll().then((data) => {
        store.dispatch(setDictionaryAll(data));
        return data[alias];
      });
    } else {
      return new Promise((resolve) => {
        resolve(all.all[alias])
      });
    }
  }

  public getUsers() {
    const users = this.getDictionaryState().users;
    if (!Object.keys(users).length) {
      return client.getUsers().then((data) => {
        store.dispatch(setDictionaryUsers(data));
        return data;
      }).catch((error) => {
        return [];
      });
    } else {
      return new Promise((resolve) => {
        resolve(users);
      });
    }
  }

  public getDegrees() {
    const degrees = this.getDictionaryState().degrees;
    if (!Object.keys(degrees).length) {
      return this.initializeEducation().then((data: any) => {
        return data[0];
      });
    } else {
      return new Promise((resolve) => {
        resolve(degrees);
      });
    }
  }

  public getSchools() {
    const schools = this.getDictionaryState().schools;
    if (!Object.keys(schools).length) {
      return this.initializeEducation().then((data: any) => {
        return data[1];
      });
    } else {
      return new Promise((resolve) => {
        resolve(schools);
      });
    }
  }

  public getProjects() {
    const projects = this.getDictionaryState().projects;
    if (!Object.keys(projects).length) {
      return client.getProjects().then((data) => {
        store.dispatch(setDictionaryProjects(data));
        return data;
      }).catch((error) => {
        return [];
      });
    } else {
      return new Promise((resolve) => {
        resolve(projects);
      });
    }
  }

  private getDictionaryState(): any {
    return store.getState()[DICTIONARY_STATE];
  }
}

export default new DictionaryStore(client);
