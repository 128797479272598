import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react'
import ValuePhysicalLocation from '../../../models/ValuePhysicalLocation';

interface Props {
  name: string,
  value: ValuePhysicalLocation,
  tooltip?: ReactElement;
}

export const StaffAttributePhysicalLocation: FunctionComponent<Props> = (props: Props) => {
  const [name] = useState<string>(props.name);
  const [value, setValue] = useState<ValuePhysicalLocation>(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props]);

  return (
    <div className="staff-attribute">
      <div className="staff-attribute__name">
        {name}
      </div>
      <div className="staff-attribute__value">
        {
          value.countryName
            ? (
              <>
                {value.countryName}{(value.cityName) ? `, ${value.cityName}` : ''}
                {(props.tooltip) ? (props.tooltip) : ''}
              </>
            )
            : <></>
        }
      </div>
    </div>
  );
}