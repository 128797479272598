import {Value as ValueInterface} from '../interfaces/Value';

export default class ValueLocation implements ValueInterface<string> {
  valueId: number | null = null;
  value: string = '';

  id: number | null = null;
  country: string = '';

  getValue(): number | null {
    return this.id;
  }
}
