import {OVERLAY_SET_IS_VISIBLE, OVERLAY_SET_PARAMS, OverlayParams, OverlayTypes} from './types';

export function setOverlayIsVisible(isVisible: boolean): OverlayTypes {
  return {
    type: OVERLAY_SET_IS_VISIBLE,
    payload: isVisible
  }
}

export function setOverlayParams(params: OverlayParams): OverlayTypes {
  return {
    type: OVERLAY_SET_PARAMS,
    payload: params
  }
}