const IMAGE_SIZE = 28;
const RADIUS = IMAGE_SIZE / 2;
const EXT_BTN_RADIUS = 5;
const BIG_IMAGE_SIZE = IMAGE_SIZE * 1.5;
const BIG_RADIUS = BIG_IMAGE_SIZE / 2;
const CONTROL_IMAGE_SIZE = 28;
const STROKE_WIDTH = 3;
const INIT_VERT_INDENTATION = 50;
const VERT_INDENTATION = 80;
const DEPTH_WIDTH = 300;
const EXPAND_NODE_TIME = 650;
const TOOLTIP_TRANSITION_TIME = 350;
const CENTER_NODE_TIME = 750;
const MARGIN = {
  TOP: 20,
  LEFT: 150,
  RIGHT: 120,
  BOTTOM: 20
};
const TOOLTIP = {
  DIRECT_REPORTS: 'Direct Reports',
  SUBORDINATES: 'Subordinates',
  AVG_SPAN: 'Average Span of Control'
};
const CONTROLS_TITLES = {
  INFO: 'Key stats',
  TREE_EXPANDER: 'Expand sub-tree'
};

export const LM_CHART = {
  IMAGE_SIZE,
  RADIUS,
  EXT_BTN_RADIUS,
  BIG_IMAGE_SIZE,
  BIG_RADIUS,
  CONTROL_IMAGE_SIZE,
  STROKE_WIDTH,
  INIT_VERT_INDENTATION,
  VERT_INDENTATION,
  DEPTH_WIDTH,
  EXPAND_NODE_TIME,
  TOOLTIP_TRANSITION_TIME,
  CENTER_NODE_TIME,
  MARGIN,
  TOOLTIP,
  CONTROLS_TITLES
};