import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { toastr } from 'react-redux-toastr'

import store from '../../../store/store'
import { GlobalState } from '../../../store/types'
import { loaderActions } from '../../../store/loader/actions'
import { setOverlayIsVisible } from '../../../store/overlay/actions'

import CVClient from '../../../services/http/CVClient'

import CVMapper from '../../../mappers/response/CVMapper'

import DateHelper from '../../../utils/date/DateHelper'

type ParamsT = {
  staffId: string,
  wrId: string
}

type CvItem = {
  id: number,
  code: string,
  startDate: Date,
  endDate: Date | null,
  cvId: number,
  cvStatus: string,
  cvDate: Date,
  title: any | null
}

export const WRCvList: React.FC = () => {
  const params = useParams<ParamsT>()
  const cParams = {
    staffId: Number(params.staffId),
    wrId: Number(params.wrId)
  }
  
  const { setValues } = useFormikContext()
  
  const { staff } = useSelector((state: GlobalState) => state.staff)
  
  const [data, setData] = React.useState<Array<CvItem>>([])
  
  React.useEffect(() => {
    store.dispatch(setOverlayIsVisible(true))
    store.dispatch(loaderActions.showLoader())
    CVClient.getList(cParams.staffId)
      .then((response: any) => {
        if (response.data) setData(response.data)
      })
      .catch((error: any) => {
        console.error(error)
      })
      .finally(() => {
        store.dispatch(setOverlayIsVisible(false))
        store.dispatch(loaderActions.hideLoader())
      })
  }, [cParams.staffId])
  
  const handleClick = (cvId: number) => {
    store.dispatch(setOverlayIsVisible(true))
    store.dispatch(loaderActions.showLoader())
    CVClient.clone(cParams.wrId, cvId)
      .then((response: any) => {
        if (response.status === 200) {
          const formatted = CVMapper.createCVDataFromResponse(response.data)
          setValues(formatted)
        }
      })
      .catch((error: any) => {
        console.error(error)
        toastr.error('','Oops, CV cloning failed, try again please')
      })
      .finally(() => {
        store.dispatch(setOverlayIsVisible(false))
        store.dispatch(loaderActions.hideLoader())
      })
  }
  
  if (!data.length) return <></>
  
  return (
    <section className="border-bottom cv-list">
      { staff && (
        <p className="cv-list__title">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16">
            <path fill="#FE8D08" fillRule="nonzero" d="M9.5.506l8.741 14.985H.759L9.5.506zm0 1.985l-7 12h14l-7-12zM9 5h1v6H9V5zm0 7h1v1H9v-1z" />
          </svg>
          <span>CV data found in other Work Records of { staff.firstName?.value } { staff.lastName?.value }:</span>
        </p>
      ) }
      {
        data.map((item: CvItem, index: number) => {
          return (
            <div className="d-flex align-items-center justify-content-between mb-2" key={ index }>
              <p className="cv-list__item">WR { item.code } { item.title ? item.title.name : '' } { DateHelper.getFormattedDate(new Date(item.startDate)) } - { item.endDate ? DateHelper.getFormattedDate(new Date(item.endDate)) : 'Now' } CV
                as of { DateHelper.getFormattedDate(new Date(item.cvDate)) } in { item.cvStatus } status</p>
              <button type="button" className="edb-btn edb-btn--primary" onClick={ () => handleClick(item.cvId) }>Clone CV data</button>
            </div>
          )
        })
      }
    </section>
  )
}
