import http, {Http} from './Http';
import { ENDPOINTS } from '../../constants';

class DocumentClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getDocumentItems(staffId: number) {
    return this.http.get(`${ENDPOINTS.DOCUMENT_ITEMS_STAFF}/${staffId}`);
  }

  public getMyDocumentItems() {
    return this.http.get(ENDPOINTS.DOCUMENT_MY_LIST);
  }

  public getPdfContent(id: number) {
    this.http.setResponseType('arraybuffer');
    return this.http.getAction(`${ENDPOINTS.DOCUMENT_ITEMS_PDF_CONTENT(id)}`);
  }

  public sign(id: number, status: number) {
    const body = {status};
    this.http.unsetResponseType();
    return this.http.postAction(`${ENDPOINTS.DOCUMENT_ITEMS_SIGN(id)}`, body);
  }
}

export default new DocumentClient(http);
