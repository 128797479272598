import React, {FunctionComponent} from 'react';

interface AvatarCellProps {
  cell: {
    value: string;
  };
}

export const AvatarCell: FunctionComponent<AvatarCellProps> = (props: AvatarCellProps) => {
  const cell = props.cell;
  const value:string = cell.value;

  return (
    <div className="avatar" style={{
      width: '28px',
      height: '28px',
      borderRadius: '50%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 50%',
      backgroundImage: `url(${value})`
    }}/>
  )
}
