import React, { useState } from 'react';
import { useField } from 'formik';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

interface DataTestProps {
  testId?: string;
  utc?: boolean;
}

export const DateField: React.FC<ReactDatePickerProps & DataTestProps> = (props: ReactDatePickerProps & DataTestProps) => {
  const [field, , { setValue, setTouched }] = useField(props as any);
  const { disabled, onChange } = { ...props };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickOpen = () => {
    if (!disabled) {
      setIsOpen(true);
    }
  }

  const onClickToday = (event: React.MouseEvent<HTMLButtonElement>) => {
    const val = new Date();
    setValue(val);
    setIsOpen(false);
    onChange(val, event);
  }

  const onClickClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    const val = null;
    setValue(val);
    setIsOpen(false);
    onChange(val, event);
  }

  const onClickClose = () => {
    setIsOpen(false);
  }

  const calendarIconStyles = () => {
    return {
      position: 'absolute' as 'absolute',
      top: '8px',
      right: '10px',
      cursor: disabled ? 'default' : 'pointer'
    };
  }
  
  const convertUTCToLocalDate = (date: any) => {
    if (date === null || typeof date === 'undefined') return date;
    if (typeof date === 'string') {
      date = new Date(date);
    }
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date;
  }
  
  const convertLocalToUTCDate = (date: any) => {
    if (!date) return date;
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date;
  }
  
  const getValue = (value: any) => {
    if (props.utc) {
      return convertUTCToLocalDate(value);
    }
    return new Date(value);
  }
  
  return (
    <div className="position-relative">
      <DatePicker
        popperModifiers={{
          preventOverflow: { enabled: false },
          flip: { behavior: ["bottom"] },
          hide: { enabled: false }
        }}
        { ...field }
        { ...props }
        value={ field.value ? getValue(field.value) : undefined }
        dateFormat={ props.dateFormat || "MMM d, yyyy" }
        yearDropdownItemNumber={ 5 }
        scrollableYearDropdown={ true }
        showYearDropdown
        showMonthDropdown
        open={ isOpen }
        onClickOutside={ onClickClose }
        selected={ field.value ? getValue(field.value) : undefined }
        onChange={(val, event) => {
          setTouched(true);
          if (props.utc) val = convertLocalToUTCDate(val);
          setValue(val);
          setIsOpen(false);
          onChange(val, event);
        }}
        onKeyDown={ (e) => e.preventDefault() }
        customInput={ <input data-testid={ props.testId } /> }
      >
        <div className="date-picker--footer">
          <div className="d-flex p-2">
            <div className="col btn-group px-0">
              <button className="btn btn-sm btn-info" onClick={ onClickToday }>Today</button>
              <button className="btn btn-sm btn-danger" onClick={ onClickClear }>Clear</button>
            </div>
            <div className="col px-0">
              <button className="btn btn-sm btn-success float-right" onClick={ onClickClose }>Close</button>
            </div>
          </div>
        </div>
      </DatePicker>
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ onClickOpen } style={ calendarIconStyles() }>
        <path d="M16 2H15V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 17C16 17.55 15.55 18 15 18H3C2.45 18 2 17.55 2 17V7H16V17ZM4 9H6V11H4V9ZM8 9H10V11H8V9ZM12 9H14V11H12V9Z" fill="#A6B7C2"/>
      </svg>
    </div>

  );
};
