export default class ArrayHelper {
  public static copy(array: Array<any>): Array<any> {
    const newArray = new Array<any>();
    return newArray.concat(array);
  }

  public static pushToNew(array: Array<any>, value: any): Array<any> {
    const newArray = ArrayHelper.copy(array);
    newArray.push(value);
    return newArray;
  }

  public static replaceInArray(array: Array<any>, value: any, field: string) {
    const newArray = ArrayHelper.copy(array);
    let length = newArray.length;
    for (let i: number = 0; i < length; i++) {
      if (newArray[i][field] === value[field]) {
        newArray[i] = value;
        break;
      }
    }
    return newArray;
  }

  public static removeInNew(array: Array<any>, value: any): Array<any> {
    const newArray = ArrayHelper.copy(array);
    let length = newArray.length;
    let index = undefined;
    for (let i: number = 0; i < length; i++) {
      if (newArray[i] === value) {
        index = i;
        break;
      }
    }
    if (typeof index !== 'undefined') {
      newArray.splice(index, 1);
    }
    return newArray;
  }

  public static removeInNewByField(array: Array<any>, value: any, field: string): Array<any> {
    const newArray = ArrayHelper.copy(array);
    let length = newArray.length;
    let index = undefined;
    for (let i: number = 0; i < length; i++) {
      if (newArray[i][field] === value[field]) {
        index = i;
        break;
      }
    }
    if (typeof index !== 'undefined') {
      newArray.splice(index, 1);
    }
    return newArray;
  }

  public static sortByField(array: Array<any>, field: string): Array<any> {
    return array.sort((i1: any, i2: any) => {
      const h1: string = (i1[field] ?? '') as string;
      const h2: string = (i2[field] ?? '') as string;
      return h1.localeCompare(h2);
    })
  }

  public static fromObjectIndexed(object: any): Array<any> {
    const array: any = [];
    Object.keys(object).forEach((key) => {
      array[key] = object[key];
    });

    return array;
  }

  public static fromObject(object: any): Array<any> {
    return Object.keys(object).map((key) => {
      return object[key];
    });
  }
}
