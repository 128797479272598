import React, {cloneElement, Fragment, FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {PopupIndexedTriggerProps} from './PopupPropsInterface';
import {dispatchIndexedPopupState} from '../../store/popup/types';

export const PopupIndexedTrigger: FunctionComponent<PopupIndexedTriggerProps> =
  (props: PropsWithChildren<PopupIndexedTriggerProps>) => {
    const handleShow = () => {
      dispatchIndexedPopupState(props.popupName, props.popupIndex, true);
    };

    return (
      <Fragment>
        {cloneElement(props.children as ReactElement, {onClick: handleShow})}
      </Fragment>
    );
  };
