import React, {FunctionComponent, useEffect, useState} from 'react';
import {ORG_STRUCTURE_TYPE, UNIT_TYPE} from "../../../interfaces/entity/hrDashboard/HCSnapshotSettings";
import DateHelper from "../../../utils/date/DateHelper";

interface Props {
  classValue: string,
  data: number,
  diff: number,
  showDiff: boolean,
  isBillable?: boolean,
  isNotClickable?: boolean,
  type?: string,
  orgStructure?: string,
  department?: string,
  unit?: string,
  subunit?: string,
  location?: string,
  forDate?: Date
}

export const HCSnapshotHeadcountData: FunctionComponent<Props> = (props: Props) => {
  const {classValue, data, diff, showDiff} = {...props};

  const [isAnalyzable, setIsAnalyzable] = useState<boolean>(false);
  const [link, setLink] = useState<string | undefined>();

  useEffect(() => {
    let cond = (props.orgStructure || props.location) && !props.isNotClickable;
    setIsAnalyzable(cond as boolean);
    setLink(getLink);
  }, [props]);

  const getDiffClassName = (diff: number) => {
    if (diff > 0) {
      return 'text-success';
    } else if (diff < 0) {
      return 'text-danger';
    } else {
      return 'text-black-50';
    }
  }

  const getLink = () => {
    let filter: any = {};

    if (props.location) {
      filter.location = props.location;
    }

    if (props.orgStructure && props.type === 'OrgStructure') {
      filter.orgStructure = props.orgStructure;
    }

    if (props.orgStructure && props.type === 'Unit') {
      filter.department = props.orgStructure;
    }

    if (props.department) {
      filter.department = props.department;
    }

    if (props.unit) {
      filter.unit = props.unit;
    }

    if (props.subunit) {
      filter.subunit = props.subunit
    }

    if (props.isBillable !== undefined) {
      filter.isBillable = props.isBillable ? 'Yes' : 'No';
    }

    if (Object.keys(filter).length) {
      filter.employment = 'Employee';
    }

    if (props.forDate) {
      filter.date = DateHelper.getFormatted2Date(props.forDate);
    }

    let parameters: Array<any> = [];
    Object.keys(filter).forEach((parameter) => {
      parameters.push(`${parameter}=${filter[parameter]}`);
    });

    return parameters.join('&');
  }

  return (
    <div className={classValue}>
      {
        isAnalyzable
          ? <a href={`/employee/list?${link}`} target="_blank">{ data }</a>
          : <span>{ data }</span>
      }
      {showDiff &&
        <span className={`d-block ${getDiffClassName(diff)}`}>
          ({ diff })
        </span>
      }
    </div>
  );
}
