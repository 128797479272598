import {SKILL_RESPONSE_MAP} from '../../constants/WORK_RECORD';
import {SkillSetInterface} from '../../interfaces/SkillSetInterface';
import {Skill as SkillInterface} from '../../interfaces/entity/Skill';
import Skill from '../../models/entity/Skill';

class SkillMapper {
  public convertSkillsToSet(data: any): SkillSetInterface {
    const skillsSet: SkillSetInterface = {
      hardSkills: [],
      softSkills: [],
      languageSkills: [],
    }
    skillsSet.hardSkills = this.buildList(
      data.filter((skill: any) => (skill.name === SKILL_RESPONSE_MAP.hardSkills))[0].children
    );
    skillsSet.softSkills = this.buildList(
      data.filter((skill: any) => (skill.name === SKILL_RESPONSE_MAP.softSkills))[0].children
    );
    skillsSet.languageSkills = this.buildList(
      data.filter((skill: any) => (skill.name === SKILL_RESPONSE_MAP.languageSkills))[0].children
    );
    return skillsSet;
  }

  private buildList(data: any, parent?: Skill): Array<SkillInterface> {
    let list: Array<SkillInterface> = [];
    data.map((skill: any) => {
      const currentSkill = new Skill();
      currentSkill.id = skill.id;
      currentSkill.name = (parent) ? `${parent.name} ${currentSkill.getSeparator()} ${skill.name}` : skill.name;
      currentSkill.originalName = skill.name;
      if (skill.attributes?.length) {
        currentSkill.level = skill.attributes[0].level;
        currentSkill.attributeId = skill.attributes[0].id;
      }
      currentSkill.hierarchyLevel = (parent) ? parent.hierarchyLevel + 1 : 1;
      currentSkill.parent = (parent) ? parent.id : NaN;
      currentSkill.parents = (parent) ? Array.prototype.concat(parent.parents, parent.id) : [];
      currentSkill.hasChildren = !!(skill.children.length);
      list.push(currentSkill);
      if (skill.children.length) {
        list = list.concat(this.buildList(skill.children, currentSkill));
      }
    });

    return list;
  }
}

export default new SkillMapper();
