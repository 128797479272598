// Core
import React, { useContext } from 'react';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import client from '../../../services/http/EducationClient';

import { EducationListContext } from '../../WR/WREducation/WREducation';

import {EducationItem as EducationItemInterface} from '../../../interfaces/EducationItem';

// Components
import { EditEducationFormWrapper } from '../Forms/EducationItem/EditEducationFormWrapper';
import { PopupIndexed } from '../../Popup/PopupIndexed';
import { PopupIndexedTrigger } from '../../Popup/PopupIndexedTrigger';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';

// Constants
import { ROLES } from '../../../constants';

// Styles
import './education-item.scss';

interface EducationItemProps {
  educationItem: EducationItemInterface;
  degrees: Array<string>;
  wrId: number;
}

export const EducationItem: React.FC<EducationItemProps> = (props: EducationItemProps) => {
  const { educationItem, degrees } = { ...props };
  const context = useContext(EducationListContext);
  
  const removeEducationItem = (id: number) => {
    client.delete(id).then((data: any) => {
      context.updateList();
      toastr.success('', 'Education item successfully deleted');
    });
  };

  return <div className="education-item" key={ educationItem.id }>
    <div className="info row">
      <div className="col-6">
        <p>
          {
            (degrees[educationItem.degree] === 'No degree')
              ? <b>{educationItem.fieldOfStudy}</b>
              : (
                <>
                  <b>{ degrees[educationItem.degree] }</b>
                  { (educationItem.degree) ? <span> Degree </span> : '' }
                  { (educationItem.fieldOfStudy && educationItem.degree) ? <span> in </span> : '' }
                  <b>{ educationItem.fieldOfStudy }</b>
                  { (educationItem.schoolName) ? <span> from </span> : '' }
                </>
              )
          }
        </p>
      </div>
      <div className="col-6 text-right">
        <p>
          { educationItem.location }
          { (educationItem.location && educationItem.yearOfGraduation) ? <span>-</span> : '' }
          { educationItem.yearOfGraduation }
        </p>
      </div>
      <div className="col-6">
        <p>{ educationItem.schoolName }</p>
      </div>
    </div>
    <div className="actions">
      <ElementWithPermission element="editEducation" staffToCheck={ { role: ROLES.LM, wrId: props.wrId } }>
        <PopupIndexedTrigger popupName="editEducation" popupIndex={ String(educationItem.id) }>
          <FA icon={['far', 'edit']} />
        </PopupIndexedTrigger>
        <PopupIndexed
          popupName="editEducation"
          popupIndex={ String(educationItem.id) }
          id="editEducation"
          title="Education item"
          size="lg">
          <EditEducationFormWrapper popupName="editEducation" educationItem={ educationItem } />
        </PopupIndexed>
      </ElementWithPermission>
      <ElementWithPermission element="deleteEducation" staffToCheck={ { role: ROLES.LM, wrId: props.wrId } }>
        <FA icon={['far', 'trash-alt']} onClick={(event) => removeEducationItem(educationItem.id)} />
      </ElementWithPermission>
    </div>
  </div>;
}
