import { cvTypes, CVState } from './types';

const defaultState: CVState = {
  data: null,
  hasAccess: false,
  isPending: false,
  isApproved: false,
  hasLatestApprovedVersion: false,
  hasLatestPendingVersion: false,
  requestLatestPendingVersion: false
}

export const cvReducer = (state: CVState = defaultState, action: any): CVState => {
  switch (action.type) {
    case cvTypes.CV_SET_LOADING:
      return { ...defaultState };
    case cvTypes.CV_SET_DATA:
      return { ...state, data: action.payload };
    case cvTypes.CV_SET_SUCCESS_PENDING:
      return { ...state, hasAccess: true, data: action.payload, isPending: true, isApproved: false };
    case cvTypes.CV_SET_SUCCESS_APPROVED:
      return { ...state, hasAccess: true, data: action.payload, isApproved: true };
    case cvTypes.CV_SET_DENY:
      return { ...state, hasAccess: false, data: null };
    case cvTypes.CV_SET_NOT_FOUND:
      return { ...state, hasAccess: true, data: null };
    case cvTypes.CV_SET_IS_PENDING:
      return { ...state, isPending: true };
    case cvTypes.CV_SET_IS_APPROVED:
      return { ...state, isApproved: true };
    case cvTypes.CV_SET_HAS_LATEST_APPROVED_VERSION:
      return { ...state, hasLatestApprovedVersion: action.payload };
    case cvTypes.CV_SET_HAS_LATEST_PENDING_VERSION:
      return { ...state, hasLatestPendingVersion: action.payload, requestLatestPendingVersion: true };
    default:
      return state;
  }
}