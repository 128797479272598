import React, {FunctionComponent} from 'react';
import './headcount-dynamic-value.scss';
import {HorizontalBars} from "../HorizontalBars/HorizontalBars";

interface Props {
  mb: boolean,
  balance: boolean,
  itemData: any,
  headcount?: number,
  label?: string,
  endDate?: string
}

interface HandledParams {
  joined: number,
  left: number,
  start: string,
  end: string,
  period: string,
  balanceValue: number,
  balanceValueWithSign: number | string,
  isBalance: boolean,
  dataForHorizontalBars: Array<any>
}

export const HeadcountDynamicValue: FunctionComponent<Props> = (props: Props) => {
  const {mb, balance, itemData, headcount, label, endDate} = {...props};

  const handleParams = (): HandledParams => {
    const data = itemData.data;
    const {joined, left} = data.reduce((acc: any, currentItem: any) => {
      if (currentItem.left) acc.left += currentItem.left;
      if (currentItem.joined) acc.joined += currentItem.joined;
      return acc;
    }, {joined: 0, left: 0});

    const start = data[0].label.replace("'", ' 20');
    const end = data[data.length - 1].label.replace("'", ' 20');
    const period = (start === end) ? end : `${start} - ${end}`;

    const balanceValue = joined - left;
    const balanceValueWithSign = balanceValue > 0 ? `+${balanceValue}` : balanceValue;
    const isBalance = balance && data.length > 0;
    const dataForHorizontalBars = [{left, joined}];

    return {
      joined,
      left,
      start,
      end,
      period,
      balanceValue,
      balanceValueWithSign,
      isBalance,
      dataForHorizontalBars
    };
  }

  const handledParams = handleParams();

  return (
    <div className={`headcount-dynamic-value-wrapper${!mb ? ' mb' : ''}`}>
      {endDate && <div className="headcount-dynamic-value-label">
        {label}
          <div className="date-wrapper">in {endDate}</div>
      </div>
      }
      {handledParams.isBalance && <div className="date-wrapper">in {handledParams.period}</div>}
      {!handledParams.isBalance &&
      <HorizontalBars
        data={itemData.data}
        details={[itemData.billable_detail_url, itemData.not_billable_detail_url]}
      />}
      {handledParams.isBalance &&
      <HorizontalBars
        data={handledParams.dataForHorizontalBars}
        details={[itemData.joined_detail_url, itemData.left_detail_url]}
        balance={handledParams.balanceValue}
      />}
      <div className="headcount-dynamic-value">
        {headcount || handledParams.balanceValueWithSign}
        {endDate && <div className="text">people</div>}
        {!endDate && <div className="text">balance</div>}
      </div>
    </div>
  );
}
