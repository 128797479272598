import * as type from './types';
import DictionaryClient from "../../services/http/DictionaryClient";

export function setDictionaryAll(all: any): type.DictionaryTypes {
  return {
    type: type.DICTIONARY_SET_ALL,
    payload: {
      all: all
    }
  }
}

export function setDictionaryActiveStaff(activeStaff: any): type.DictionaryTypes {
  return {
    type: type.DICTIONARY_SET_ACTIVE_STAFF,
    payload: {
      activeStaff: activeStaff
    }
  }
}

export function setDictionaryUsers(users: any): type.DictionaryTypes {
  return {
    type: type.DICTIONARY_SET_USERS,
    payload: {
      users: users
    }
  }
}

export function setDegrees(degrees: any): type.DictionaryTypes {
  return {
    type: type.DICTIONARY_SET_DEGREES,
    payload: degrees
  }
}

export function setSchools(schools: any): type.DictionaryTypes {
  return {
    type: type.DICTIONARY_SET_SCHOOLS,
    payload: schools
  }
}

export function setDictionaryProjects(projects: any): type.DictionaryTypes {
  return {
    type: type.DICTIONARY_SET_PROJECTS,
    payload: projects
  }
}

export function getDictionaryReportFilters(): any {
  return async (dispatch: any) => {
    dispatch({ type: type.DICTIONARY_GET_REPORT_FILTERS_LOADING });
    try {
      const { data, status }:any = await DictionaryClient.getReportFilters();
      if (status > 199 && status < 300) {
        dispatch({ type: type.DICTIONARY_GET_REPORT_FILTERS_SUCCESS, payload: data });
      }
    } catch (e) {
      dispatch({ type: type.DICTIONARY_GET_REPORT_FILTERS_FAILURE });
      console.error('error',e);
    }
  };
}

export function getDictionaryCountries(): any {
  return async (dispatch: any) => {
    dispatch({ type: type.DICTIONARY_GET_COUNTRIES_LOADING });
    try {
      const { data, status }: any = await DictionaryClient.getFilterCountries();
      if (status > 199 && status < 300) {
        data.sort((item1: { id: string, name: string }, item2: { id: string, name: string }) => {
          if (item1.id === item2.id) {
            return 0;
          }
          return item1.id > item2.id ? 1 : -1;
        });
        dispatch({ type: type.DICTIONARY_GET_COUNTRIES_SUCCESS, payload: data });
      }
    } catch (e) {
      dispatch({ type: type.DICTIONARY_GET_COUNTRIES_FAILURE });
      console.log('error', e);
    }
  };
}
