import {
  CV_STATUS_APPROVED,
  CV_STATUS_PENDING,
  cvTypes,
  SetDataAction,
  SetDenyAction,
  SetHasLatestApprovedVersion,
  SetHasLatestPendingVersion,
  SetIsApprovedAction,
  SetIsPendingAction,
  SetLoadingAction,
  SetNotFoundAction,
  SetSuccessApprovedAction,
  SetSuccessPendingAction,
} from './types';
import { CVInterface } from '../../interfaces/CVInterface';
import CVClient from '../../services/http/CVClient';
import CVMapper from '../../mappers/response/CVMapper';
import { loaderActions } from '../loader/actions';
import { setOverlayIsVisible } from '../overlay/actions';

export const cvActions = Object.freeze({
  setLoading: (): SetLoadingAction => {
    return {
      type: cvTypes.CV_SET_LOADING
    };
  },
  setData: (data: CVInterface): SetDataAction => {
    return {
      type: cvTypes.CV_SET_DATA,
      payload: data
    };
  },
  setSuccessPending: (data: CVInterface): SetSuccessPendingAction => {
    return {
      type: cvTypes.CV_SET_SUCCESS_PENDING,
      payload: data
    };
  },
  setSuccessApproved: (data: CVInterface): SetSuccessApprovedAction => {
    return {
      type: cvTypes.CV_SET_SUCCESS_APPROVED,
      payload: data
    };
  },
  setNotFound: (): SetNotFoundAction => {
    return {
      type: cvTypes.CV_SET_NOT_FOUND
    }
  },
  setDeny: (): SetDenyAction => {
    return {
      type: cvTypes.CV_SET_DENY
    }
  },
  setIsPending: (): SetIsPendingAction => {
    return {
      type: cvTypes.CV_SET_IS_PENDING
    }
  },
  setIsApproved: (): SetIsApprovedAction => {
    return {
      type: cvTypes.CV_SET_IS_APPROVED
    }
  },
  setHasLatestApprovedVersion: (hasLatestApprovedVersion: boolean): SetHasLatestApprovedVersion => {
    return {
      type: cvTypes.CV_SET_HAS_LATEST_APPROVED_VERSION,
      payload: hasLatestApprovedVersion
    }
  },
  setHasLatestPendingVersion: (hasLatestPendingVersion: boolean): SetHasLatestPendingVersion => {
    return {
      type: cvTypes.CV_SET_HAS_LATEST_PENDING_VERSION,
      payload: hasLatestPendingVersion
    }
  }
});

export function setCVThunk(id: number): any {
  return async (dispatch: any) => {
    let loading = false;
    dispatch(setOverlayIsVisible(true));
    dispatch(loaderActions.showLoader());
    dispatch(cvActions.setLoading());
    try {
      const { status, data }: any = await CVClient.getByStatus(id, CV_STATUS_PENDING);
      if (status === 200) {
        loading = true;
        const formattedData = CVMapper.createCVFromResponse(data);
        dispatch(cvActions.setSuccessPending(formattedData));
      }
    } catch (e) {
      loading = false;
      dispatch(cvActions.setIsPending());
    }
    if (!loading) {
      try {
        const { status, data }: any = await CVClient.getByStatus(id, CV_STATUS_APPROVED);
        if (status === 200) {
          loading = true;
          const formattedData = CVMapper.createCVFromResponse(data);
          dispatch(cvActions.setSuccessApproved(formattedData));
        }
      } catch (e: any) {
        loading = false;
        dispatch(cvActions.setIsApproved());
        if (e?.status === 404) {
          console.error('error', e);
          dispatch(cvActions.setNotFound());
        } else {
          console.error('error', e);
          dispatch(cvActions.setDeny());
        }
      }
    }
    dispatch(loaderActions.hideLoader());
    dispatch(setOverlayIsVisible(false));
  }
}

export function setCVPendingLatestVersionThunk(id: number): any {
  return async (dispatch: any) => {
    dispatch(setOverlayIsVisible(true));
    dispatch(loaderActions.showLoader());
    try {
      const { status, data }: any = await CVClient.checkIsPending(id);
      if (status === 200) {
        dispatch(cvActions.setHasLatestPendingVersion(data));
      }
    } catch (e) {
      console.error('error', e);
      dispatch(cvActions.setHasLatestPendingVersion(false));
    }
    dispatch(loaderActions.hideLoader());
    dispatch(setOverlayIsVisible(false));
  }
}

export function setCVApprovedLatestVersionThunk(id: number): any {
  return async (dispatch: any) => {
    try {
      const { status, data }: any = await CVClient.checkIsApproved(id);
      if (status === 200) {
        dispatch(cvActions.setHasLatestApprovedVersion(data));
      }
    } catch (e) {
      console.error('error', e);
      dispatch(cvActions.setHasLatestApprovedVersion(false));
    }
  }
}