import React, {useEffect, useState} from 'react';
import {useStore} from 'react-redux';
import {OVERLAY_STATE} from '../../store/reducers';

import './overlay.scss';
import {Spinner} from "../Spinner/Spinner";

export const Overlay = () => {
  const { getState, subscribe } = useStore();
  const [isVisible, setIsVisible] = useState(getState()[OVERLAY_STATE].isVisible);
  const [params, setParams] = useState(getState()[OVERLAY_STATE].params);

  const [style, setStyle] = useState<any>({});

  useEffect(() => {

    if (isVisible) {
      if (params.isFullscreen) {
        setStyle({
          height: '100%'
        });
      } else {
        let mainHeader = document.getElementsByTagName('header')[0] as HTMLElement;
        let maxWindowHeight = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight
        );
        setStyle({
          top: mainHeader.clientHeight,
          height: `${(maxWindowHeight - mainHeader.clientHeight)}px`
        });
      }
    } else {
      setStyle({});
    }

    return subscribe(() => {
      setIsVisible(getState()[OVERLAY_STATE].isVisible);
      setParams(getState()[OVERLAY_STATE].params);
    });
  }, [isVisible, params]);

  return (
    <div
      style={ style }
      className={ `edb-overlay ${isVisible ? "active" : ""} ${params.isExtra ? "edb-overlay--extra" : ""}` }
    >
    </div>
  );
}