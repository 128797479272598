import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

// Store
import { GlobalState } from '../../../../store/types';
import { WORK_RECORD_STATE } from '../../../../store/reducers';
import { dispatchIndexedPopupState } from '../../../../store/popup/types';

import { FeedbackListContext } from '../../../WR/WRFeedback/WRFeedback';
import { FeedbackI } from '../../../../interfaces/entity/Feedback';
import feedbackHelper from '../../../../services/data/FeedbackHelper';
import FeedbackMapper from '../../../../mappers/response/FeedbackMapper';
import { FeedbackItemStateInterface } from './FeedbackItemState';

// Components
import { FeedbackItemForm } from './FeedbackItemForm';

import { ATTRIBUTES_MAP } from '../../../../constants';

interface EditFeedbackFormWrapperProps {
  popupName: string;
  feedback: FeedbackI;
}

export const EditFeedbackFormWrapper: React.FC<EditFeedbackFormWrapperProps> = (props: EditFeedbackFormWrapperProps) => {
  const { popupName, feedback } = { ...props };
  const wrId = useSelector((state: GlobalState) => state[WORK_RECORD_STATE].activeId);
  const context = useContext(FeedbackListContext);
  const [saving, setSaving] = useState(false);
  const update = (values: FeedbackItemStateInterface, setFieldError: Function, setFieldTouched: Function) => {
    setSaving(true);
    feedbackHelper.update(feedback, values, wrId)
      .then((data: any) => {
        const f: FeedbackI = FeedbackMapper.creatFeedbackFromResponse(data);
        context.updateList();
        dispatchIndexedPopupState(popupName, String(f.id), false);
      })
      .catch((error: any) => {
        let data = error.data;
        if (data.length) {
          data.forEach((item: any) => {
            setFieldError(ATTRIBUTES_MAP.feedbackItem[item.property_path], item.message);
          });
        }
      })
      .finally(() => {
        setSaving(false);
      })
  }

  return (
    <FeedbackItemForm
      submitFunction={ update }
      feedback={ feedback }
      popupName={ popupName }
      saving={ saving }
      handleCancel={() => {
        dispatchIndexedPopupState(props.popupName, String(feedback.id), false);
      }}
    />
  );
}
