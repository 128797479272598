import http, { Http } from './Http';
import { ENDPOINTS } from '../../constants';

class VersionClient {
  public http: Http;
  
  constructor(http: Http) {
    this.http = http;
  }
  
  public get() {
    return this.http.getAction(ENDPOINTS.VERSION);
  }
}

export default new VersionClient(http);
