import * as types from './types';

const defaultState: types.DictionaryState = {
  all: {},
  activeStaff: [],
  users: [],
  degrees: [],
  schools: [],
  projects: [],
  reportFilters: null,
  loading: false,
  countries: []
};

export const dictionaryReducer = (state: types.DictionaryState = defaultState, action: types.DictionaryTypes) => {
  switch (action.type) {
    case types.DICTIONARY_SET_ALL:
      return {...state, all: action.payload}
    case types.DICTIONARY_SET_ACTIVE_STAFF:
      return {...state, activeStaff: action.payload}
    case types.DICTIONARY_SET_USERS:
      return {...state, users: action.payload.users}
    case types.DICTIONARY_SET_DEGREES:
      return {...state, degrees: action.payload}
    case types.DICTIONARY_SET_SCHOOLS:
      return {...state, schools: action.payload}
    case types.DICTIONARY_SET_PROJECTS:
      return {...state, projects: action.payload}

    case types.DICTIONARY_GET_REPORT_FILTERS_LOADING:
      return {...state, loading: true}
    case types.DICTIONARY_GET_REPORT_FILTERS_SUCCESS:
      return {...state, loading: false, reportFilters: action.payload}
    case types.DICTIONARY_GET_REPORT_FILTERS_FAILURE:
      return {...state, loading: false,}

    case types.DICTIONARY_GET_COUNTRIES_LOADING:
      return {...state, loading: true}
    case types.DICTIONARY_GET_COUNTRIES_SUCCESS:
      return {...state, loading: false, countries: action.payload}
    case types.DICTIONARY_GET_COUNTRIES_FAILURE:
      return {...state, loading: false}

    default:
      return state;
  }
}
