import {WorkRecord} from '../../../../interfaces/WorkRecord';
import {SelectValueInterface, defaultSelectValue} from '../../../../interfaces/form/SelectValueInterface';
import ValueDictionary from '../../../../models/ValueDictionary';

export interface ReasonToLeaveStateInterface {
  reasonToLeave: number | undefined
}

const initialState: ReasonToLeaveStateInterface = {
  reasonToLeave: undefined
}

export default class ReasonToLeaveState {
  public static generateFromWorkRecord(workRecord?: WorkRecord): ReasonToLeaveStateInterface {
    let state = initialState;
    if (workRecord) {
      state.reasonToLeave = (workRecord.reasonToLeave as ValueDictionary).id;
    }
    return state;
  }
}