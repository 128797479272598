import axios, {
  RawAxiosRequestHeaders,
  AxiosRequestConfig,
  AxiosStatic,
  ResponseType,
  RawAxiosRequestConfig
} from 'axios';

import store from './../../store/store';
import {changeIsLoggedIn, changeIsTokenValid} from '../../store/authorization/actions';

export class Http {
  private static instance: Http;

  private client: AxiosStatic;
  private options: RawAxiosRequestConfig;

  private constructor() {
    this.client = axios;
    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    };
    // @ts-ignore
    this.options = { headers }
  }

  public setToken(token: string) {
    if (this.options.headers) {
      this.options.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  public setResponseType(responseType: ResponseType) {
    this.options.responseType = responseType;
  }

  public unsetResponseType() {
    delete this.options.responseType;
  }

  public unsetToken() {
    if (this.options.headers) {
      delete this.options.headers['Authorization'];
    }
  }

  public getList(endpoint: string) {
    return this.client.get(endpoint, this.options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  };

  public get<T>(endpoint: string): Promise<T> {
    return this.client.get(endpoint, this.options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  };

  public getAxios<T>(endpoint: string): Promise<T> {
    return this.client.get(endpoint, this.options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  };

  public getAction<T>(endpoint: string): Promise<T> {
    return this.client.get(endpoint, this.options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  };

  public post<T>(endpoint: string, body: object): Promise<T> {
    return this.client.post(endpoint, body, this.options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  }

  public postAction<T>(endpoint: string, body: object): Promise<T> {
    return this.client.post(endpoint, body, this.options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  }

  public put<T>(endpoint: string, body: object): Promise<T> {
    return this.client.put(endpoint, body, this.options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  }

  public putAction<T>(endpoint: string, body: object): Promise<T> {
    return this.client.put(endpoint, body, this.options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  }

  public delete<T>(endpoint: string): Promise<T> {
    return this.client.delete(endpoint, this.options)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return this.errorHandler(error);
      });
  }

  public static getInstance(): Http {
    if (!Http.instance) {
      Http.instance = new Http();
    }

    return Http.instance;
  }

  private errorHandler(error: any): any {
    const response = error.response;
    this.checkJWTError(response);
    throw response;
  }

  private checkJWTError(response: any): void {
    if (response.status && (response.status === 500 || response.status === 401)) {
      store.dispatch(changeIsLoggedIn(false));
      store.dispatch(changeIsTokenValid(false));
    }
  }
}

export default Http.getInstance();
