export const SET_PERMISSION_FOR_ELEMENTS = 'SET_PERMISSION_FOR_ELEMENTS';

export interface Roles {
  name: string,
  element_visibility: {
    is_shown: boolean
  }
}

export interface PermissionForElements {
  identifier: string,
  roles: Array<Roles>
}

export interface PermissionState {
  elements: Array<PermissionForElements> | []
}

export interface SetPermissionForElements {
  type: typeof SET_PERMISSION_FOR_ELEMENTS,
  payload: Array<PermissionForElements>
}

export type PermissionTypes = SetPermissionForElements;