import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from 'react-bootstrap';

// store
import { GlobalState } from '../../../store/types';

// components
import { AttributeWithPermission } from '../Common/Attribute/AttributeWithPermission';
import {
  StaffAttributeLink,
  StaffAttributePhysicalLocation,
  StaffAttributePlain,
  StaffAttributeString
} from '../StaffAttribute';

// interfaces
import { Staff } from '../../../interfaces/Staff';

// constants
import { ATTRIBUTE_NAMES } from '../../../constants';

// styles
import './wr-navigation-staff-attributes.scss';

const labels = ATTRIBUTE_NAMES['wrMainStaff'];

type PropsType = {
  open: boolean
}

export const WRNavigationStaffAttributes: React.FC<PropsType> = (props: PropsType) => {

  const staff: Staff = useSelector((state: GlobalState) => state.staff.staff);

  if (staff) {
    return (
      <div className="work-record-navigation-staff-attributes">
        <Collapse in={ props.open }>
          <div className="contact-info">
            <div className="mb-2">
              <div className="staff-attribute">
                <div className="staff-attribute__name">
                  { labels.username }
                </div>
                <div className="staff-attribute__value">
                  { staff.username }
                </div>
              </div>
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="physicalLocation"
                attribute={
                  <StaffAttributePhysicalLocation name={labels.physicalLocation} value={staff.physicalLocation}/>
                }
              />
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="skype"
                attribute={
                  <StaffAttributeString name={labels.skype} value={staff.skype}/>
                }
              />
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="phone1"
                attribute={
                  <StaffAttributeString name={labels.phone1} value={staff.phone1}/>
                }
              />
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="phone2"
                attribute={
                  <StaffAttributeString name={labels.phone2} value={staff.phone2}/>
                }
              />
            </div>
            <div className="mb-2 d-flex align-items-end">
              <AttributeWithPermission
                staff={staff}
                name="facebook"
                attribute={
                  <StaffAttributeLink name={labels.facebook} value={staff.facebook} text={"Facebook"}/>
                }
              />
              {
                (staff.linkedIn?.value)
                  ? (
                    <>
                      <span className="px-2" style={{color: '#768891'}}>|</span>
                      <AttributeWithPermission
                        staff={staff}
                        name="linkedIn"
                        attribute={
                          <StaffAttributeLink name={""} value={staff.linkedIn} text={"Linkedin"}/>
                        }
                      />
                    </>
                  ) : <></>
              }
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="wiki"
                attribute={
                  <StaffAttributeLink name={labels.wiki} value={staff.wiki}/>
                }
              />
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="dateOfBirth"
                attribute={
                  <StaffAttributePlain name={labels.dateOfBirth} value={staff.getDateOfBirth()}/>
                }
              />
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="gender"
                attribute={
                  <StaffAttributeString name={labels.gender} value={staff.gender}/>
                }
              />
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="emergContactName"
                attribute={
                  <StaffAttributeString name={labels.emergContactName} value={staff.emergContactName}/>
                }
              />
            </div>
            <div className="mb-2">
              <AttributeWithPermission
                staff={staff}
                name="emergContactPhone"
                attribute={
                  <StaffAttributeString name={labels.emergContactPhone} value={staff.emergContactPhone}/>
                }
              />
            </div>
          </div>
        </Collapse>
      </div>
    );
  }

  return <Fragment/>;
}
