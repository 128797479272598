import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import useOutsideClick from '../../hooks/outsideClick';
import DateHelper from '../../utils/date/DateHelper';

import './date-range-picker-field.scss';

interface DateRangePickerProps {
  setPeriodRange: Function,
  setFromDate: Function,
  setToDate: Function,
  initialRangeType?: string
}

export const periodsRange = {
  custom: 'custom',
  lastYear: 'last_year',
  lastQuarter: 'last_quarter',
}

export const rangeTypes = {
  custom: 'custom',
  thisYear: 'thisYear',
  lastYear: 'lastYear',
  lastQuarter: 'lastQuarter',
  prevAndCurrentQuarter: 'prevAndCurrentQuarter'
}

export const DateRangePickerField: FC<DateRangePickerProps> = (props: DateRangePickerProps) => {
  const { setPeriodRange, setFromDate, setToDate, initialRangeType } = { ...props };
  const [isShowing, setIsShowing] = useState<boolean>(false);
  
  const [monthFrom, setMonthFrom] = useState<string | null>(null);
  const [yearFrom, setYearFrom] = useState<string | null>(null);
  const [monthTo, setMonthTo] = useState<string | null>(null);
  const [yearTo, setYearTo] = useState<string | null>(null);
  
  const [rangeType, setRangeType] = useState<string>(initialRangeType ?? rangeTypes.lastQuarter);

  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  
  useEffect(() => {
    if (!isVisible) {
      cancel();
    }
  }, [isVisible]);

  useEffect(() => {
    switch (rangeType) {
      case rangeTypes.lastQuarter:
        setPreviousQuarter();
        break;
      case rangeTypes.lastYear:
        setLastYear();
        break;
      case rangeTypes.thisYear:
        setThisYear();
        break;
      case rangeTypes.prevAndCurrentQuarter:
        setPrevAndCurrentQuarter();
        break;
      case rangeTypes.custom:
        setCustom();
        break;
    }
  }, [rangeType]);

  const setDateRange = (startDate: Date, endDate: Date) => {
    setMonthFrom(DateHelper.getMonthShort(startDate));
    setMonthTo(DateHelper.getMonthShort(endDate));
    setYearFrom(startDate.getFullYear().toString());
    setYearTo(endDate.getFullYear().toString());
  };

  const setPreviousQuarter = () => {
    const today = new Date(), quarter: number = Math.floor((today.getMonth() / 3));
    const startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);

    setDateRange(startDate, endDate);

    setFromDate(startDate);
    setToDate(endDate);
    setPeriodRange(periodsRange.lastQuarter);
  }

  const setLastYear = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear() - 1, 0, 1);
    const endDate = new Date(today.getFullYear() - 1, 11, 31);

    setDateRange(startDate, endDate);

    setFromDate(startDate);
    setToDate(endDate);
    setPeriodRange(periodsRange.lastYear);
  }

  const setThisYear = () => {
    const endDate = new Date();
    const startDate = new Date(endDate.getFullYear(), 0, 1);

    setDateRange(startDate, endDate);

    setFromDate(startDate);
    setToDate(endDate);
    setPeriodRange(periodsRange.custom);
  }

  const setPrevAndCurrentQuarter = () => {
    const today = new Date();
    const quarter = Math.floor((today.getMonth() / 3));
    const startDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 6, 0);
    setDateRange(startDate, endDate);

    setFromDate(startDate);
    setToDate(endDate);
    setPeriodRange(periodsRange.custom);
  }

  const setCustom = () => {
    const dateFrom = new Date(`01 ${monthFrom}, ${yearFrom}`);
    const dateTo = new Date(`01 ${monthTo} ${yearTo}`);
    const lastDayOfDateTo = new Date(dateTo.getFullYear(), dateTo.getMonth() + 1, 0);
    
    setDateRange(dateFrom, lastDayOfDateTo);

    setFromDate(dateFrom);
    setToDate(lastDayOfDateTo);
    setPeriodRange(periodsRange.custom);
  }

  const months = () => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (mon) {
      return DateHelper.getMonthShort(new Date(2000, mon));
    });
  };

  const years = (): Array<number> => {
    const yearStart = +new Date('2008-04-01').getFullYear();
    let yearEnd = +new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getFullYear();

    const years = [];
    for (let i = yearStart; i <= yearEnd; i++) {
      years.push(i);
    }

    return years;
  }

  const cancel = () => {
    setIsShowing(false);
  }

  const changeRangeType = (type: string) => {
    setRangeType(type);
  }

  const select = () => {
    const rangeTypePrev = rangeType;
    changeRangeType(rangeTypes.custom);
    if (rangeTypePrev === rangeTypes.custom) {
      setCustom();
    }
    setIsShowing(false);
  }

  return (
    <div className="custom-datepicker">
      <span className="period-title">Period</span>
      <div className="custom-datepicker-outer" onClick={() => { setIsShowing(!isShowing); setIsVisible(true); }}>
        {
          monthFrom &&
          <span>{monthFrom} {yearFrom} - {monthTo} {yearTo}</span>
        }
      </div>
      {isShowing &&
        <div className="custom-datepicker-inner" ref={ref}>
          <Formik
            initialValues={{ monthFrom: monthFrom, yearFrom: yearFrom, monthTo: monthTo, yearTo: yearTo }}
            onSubmit={() => { }}>
            {({ values }) => (
              <Form className="edb-form">
                <div className="custom-datepicker-title">Select period</div>
                <div className="custom-datepicker-content">
                  <span className="custom-datepicker-label">
                    Start
                  </span>
                  <Field
                    className="edb-select datepicker-flex2"
                    as="select"
                    id="monthFrom"
                    name="monthFrom"
                    value={monthFrom}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      setMonthFrom(event.target.value);
                    }}>
                    {months().map((month: string) => <option value={month} key={month}>{month}</option>)}
                  </Field>
                  <Field
                    className="edb-select"
                    as="select"
                    id="yearFrom"
                    name="yearFrom"
                    value={yearFrom}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      setYearFrom(event.target.value);
                    }}>
                    {years().map((year: number) => <option value={year} key={year}>{year}</option>)}
                  </Field>
                </div>
                <div className="custom-datepicker-content">
                  <span className="custom-datepicker-label">
                    End
                  </span>
                  <Field
                    className="edb-select datepicker-flex2"
                    as="select"
                    id="monthTo"
                    name="monthTo"
                    value={monthTo}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      setMonthTo(event.target.value);
                    }}>
                    {months().map((month: string) => <option value={month} key={month}>{month}</option>)}
                  </Field>
                  <Field
                    className="edb-select"
                    as="select"
                    id="yearTo"
                    name="yearTo"
                    value={yearTo}
                    onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                      setYearTo(event.target.value);
                    }}>
                    {years().map((year: number) => <option value={year} key={year}>{year}</option>)}
                  </Field>
                </div>
                <div className="custom-datepicker-actions">
                  <button className="btn-cancel" onClick={cancel}>Cancel</button>
                  <button className="btn-select" onClick={select}>Select</button>
                </div>
                <div className="custom-datepicker-filters">
                  <span className={`range-filter${rangeType === rangeTypes.thisYear ? ' active' : ''}`}
                    onClick={() => {
                      changeRangeType(rangeTypes.thisYear);
                      setIsShowing(false);
                    }}>
                    This year
                  </span>
                  <span className={`range-filter${rangeType === rangeTypes.lastYear ? ' active' : ''}`}
                    onClick={() => {
                      changeRangeType(rangeTypes.lastYear);
                      setIsShowing(false);
                    }}>
                    Last year
                  </span>
                  <span className={`range-filter${rangeType === rangeTypes.lastQuarter ? ' active' : ''}`}
                    onClick={() => {
                      changeRangeType(rangeTypes.lastQuarter);
                      setIsShowing(false);
                    }}>
                    Last quarter
                  </span>
                </div>
              </Form>)}
          </Formik>
        </div>}
    </div>
  );
};
