import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import { FeedbackI } from '../../../interfaces/entity/Feedback';

import { EditFeedbackFormWrapper } from '../Forms/FeedbackItem/EditFeedbackFormWrapper';
import { PopupIndexed } from '../../Popup/PopupIndexed';
import { PopupIndexedTrigger } from '../../Popup/PopupIndexedTrigger';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';

import { ROLES } from '../../../constants';

import './feedback-item.scss';

interface FeedbackItemProps {
  feedback: FeedbackI;
  wrId: number;
}

export const FeedbackItem: React.FC<FeedbackItemProps> = (props: FeedbackItemProps) => {
  const { feedback } = { ...props };

  return <div className="feedback-item" key={feedback.id}>
    <div className="info">
      <div className="row">
        <div className="col-3">
          <div className="feedback-item-attribute">
            <div className="feedback-item-attribute__label">
              Feedback type
            </div>
            <div className="feedback-item-attribute__value">
              { feedback.type?.title }
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="feedback-item-attribute">
            <div className="feedback-item-attribute__label">
              By
            </div>
            <div className="feedback-item-attribute__value">
              {
                feedback.author
                  ? <Link to={`/staff/${ feedback.author.id }/work-records`}>{ feedback.author.fullName }</Link>
                  : <></>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div className="feedback-item-attribute">
            <div className="feedback-item-attribute__label">
              Review period
            </div>
            <div className="feedback-item-attribute__value">
              { feedback.getPeriod() }
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="feedback-item-attribute">
            <div className="feedback-item-attribute__label">
              Date
            </div>
            <div className="feedback-item-attribute__value">
              { feedback.getDate() }
            </div>
          </div>
        </div>
      </div>
      {
        (feedback.project)
          ? (
            <div className="row">
              <div className="col-3">
                <div className="feedback-item-attribute">
                  <div className="feedback-item-attribute__label">
                    Project
                  </div>
                  <div className="feedback-item-attribute__value">
                    { feedback.project.name }
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="feedback-item-attribute">
                  <div className="feedback-item-attribute__label">
                    Project Manager
                  </div>
                  <div className="feedback-item-attribute__value">
                    {
                      feedback.projectManager
                        ? <Link to={`/staff/${ feedback.projectManager.id }/work-records`}>{ feedback.projectManager.fullName }</Link>
                        : <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          )
          : <></>
      }
      <div className="row">
        <div className="col-12">
          <div className="feedback-value">
            { feedback.value }
          </div>
        </div>
      </div>
    </div>
    <div className="actions">
      <ElementWithPermission element="editFeedbackButton" staffToCheck={{role: ROLES.LM, wrId: props.wrId}}>
        <PopupIndexedTrigger popupName="editFeedback" popupIndex={String(feedback.id)}>
          <FA icon={['far', 'edit']}/>
        </PopupIndexedTrigger>
        <PopupIndexed
          popupName="editFeedback"
          popupIndex={String(feedback.id)}
          id="editFeedback"
          title="Provide Feedback"
          size="lg">
          <EditFeedbackFormWrapper popupName="editFeedback" feedback={ feedback } />
        </PopupIndexed>
      </ElementWithPermission>
    </div>
  </div>;
}
