import React, {FunctionComponent} from 'react';
import {useField} from 'formik';
import {Typeahead} from 'react-bootstrap-typeahead';

import './typeahead.scss';

interface TypeaheadSuggestionFieldProps {
  id: string;
  name: string;
  labelKey: string;
  multiple: boolean;
  options: Array<any>;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (selected: any[]) => void;
  className?: string;
  testId?: string;
}

export const TypeaheadSuggestionField: FunctionComponent<TypeaheadSuggestionFieldProps> = (props: TypeaheadSuggestionFieldProps) => {
  const { id, name, labelKey, multiple, options, disabled, placeholder, onChange } = { ...props };
  const [field, , {setValue}] = useField(props as any);
  return (
    <Typeahead
      id={id}
      labelKey={labelKey}
      disabled={disabled}
      placeholder={placeholder}
      multiple={multiple}
      selected={field.value ? [field.value] : undefined}
      onChange={onChange ? onChange : (selected => {
        const value = (selected && selected[0]) ? selected[0] : '';
        if (value) setValue(value);
      })}
      onInputChange={(text, event) => setValue(text)}
      options={ options }
      inputProps={{
        className: props.className ? props.className : '',
        // @ts-ignore
        'data-testid': props.testId
      }}
    />
  );
};