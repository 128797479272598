import {Value as ValueInterface} from '../interfaces/Value';

export default class ValueBoolean implements ValueInterface<boolean> {
  valueId: number | null = null;
  value: boolean = false;

  getValue(): boolean {
    return this.value;
  }
}
