import {useEffect, useState} from 'react';

export const useLoadGsiClientScript = () => {
  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setScriptLoadedSuccessfully(true);
    };
    script.onerror = () => {
      setScriptLoadedSuccessfully(false);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return scriptLoadedSuccessfully;
}