import {WorkRecord} from '../../../../interfaces/WorkRecord';
import {Skill} from "../../../../interfaces/entity/Skill";

export interface SkillStateInterface {
  hardSkills: Array<Skill>;
  softSkills: Array<Skill>;
  languageSkills: Array<Skill>;
}

const initialState: SkillStateInterface = {
  hardSkills: [],
  softSkills: [],
  languageSkills: [],
}

export default class SkillState {
  public static generateFromWorkRecord(workRecord?: WorkRecord): SkillStateInterface {
    let state = initialState;
    if (workRecord) {

    }
    return state;
  }
}
