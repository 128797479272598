import * as Yup from 'yup';
import {VALIDATION_NAMES} from "../../../../constants";

export const schema = Yup.object()
  .shape({
    username: Yup.string().nullable(),
    photo: Yup.string().nullable(),
    firstName: Yup.string()
      .required('First Name is required')
      .matches(VALIDATION_NAMES),
    lastName: Yup.string()
      .required('First Name is required')
      .matches(VALIDATION_NAMES),
    middleName: Yup.string()
      .nullable()
      .matches(VALIDATION_NAMES),
    dateOfBirth: Yup.date().nullable(),
    gender: Yup.string().nullable(),
    email: Yup.string().email("Email has to be valid").nullable(),
    skype: Yup.string().nullable(),
    phone1: Yup.string().nullable(),
    phone2: Yup.string().nullable(),
    emergContactName: Yup.string().nullable(),
    emergContactPhone: Yup.string().nullable(),
    facebook: Yup.string().nullable(),
    linkedIn: Yup.string().nullable(),
    wiki: Yup.string().nullable(),
    companyStartDate: Yup.date().nullable(),
    alumni: Yup.boolean(),
    country: Yup
      .string()
      .nullable(),
    city: Yup
      .string()
      .nullable()
      .test(
        'required',
        'City cannot be defined without Country',
        function (val: any) {
          if (!this.parent.country && val) {
            return this.createError({
              message: 'City cannot be defined without Country',
              path: 'city',
            });
          }
          return true
        }
      )
  })
