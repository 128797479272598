import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikErrors, FormikValues } from 'formik';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { WorkRecord as WorkRecordInterface } from '../../../../interfaces/WorkRecord';
import Staff from '../../../../models/Staff';
import WorkRecord from '../../../../models/WorkRecord';
import ValueNumber from '../../../../models/ValueNumber';
import WorkRecordMapper from '../../../../mappers/response/WorkRecordMapper';

import { schema } from './EmployeeFormSchema';

import EmployeeFormState, {EmployeeFormStateInterface} from './EmployeeFormState';

import { ProfileFormFields } from '../EditProfile/ProfileFormFields';
import ProfileState from '../EditProfile/ProfileState';

import { PositionDescriptionFormFields } from '../../../WR/Forms/PositionDescription/PositionDescriptionFormFields';
import PositionDescriptionState from '../../../WR/Forms/PositionDescription/PositionDescriptionState';

import { SkillFormFields } from '../../../WR/Forms/Skill/SkillFormFields';
import SkillState from '../../../WR/Forms/Skill/SkillState';

import staffHelper from '../../../../services/data/StaffHelper';
import wrHelper from '../../../../services/data/WorkRecordHelper';

import { TRIAL_STATUSES, ATTRIBUTES_MAP } from '../../../../constants';
import dictionaryStore from '../../../../services/dictionary/DictionaryStore';

import {initialState as fieldsProfile} from '../EditProfile/ProfileState';
import {initialState as fieldsPosition} from '../../../WR/Forms/PositionDescription/PositionDescriptionState';

import { Spinner } from '../../../Spinner/Spinner';
import { ScrollToError } from '../../../Forms/ScrollToError';

import './add-employee-form.scss';

const attributes = ATTRIBUTES_MAP.workRecord;
const fullUserAttributes = {...ATTRIBUTES_MAP.staff, ...ATTRIBUTES_MAP.workRecord};

const pages = [0, 1, 2];
const defaultFilled = {0: false, 1: false};

export const AddEmployeeForm: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [filled, setFilled] = useState<any>(defaultFilled);
  const [initialState, setInitialState] = useState<EmployeeFormStateInterface | null>(null);
  const [formErrors, setFormErrors] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  const staff = new Staff();
  const workRecord = new WorkRecord();
  workRecord.trialStatus = TRIAL_STATUSES.IN_PROGRESS.name;
  workRecord.maxTotalLoad = new ValueNumber(100);
  const currentValidationSchema = schema[page];

  const callValidation = (values: object, errors: FormikErrors<FormikValues>, setFieldError: Function, setFieldTouched: Function) => {
    if (page === 0) {
      validateProfile(values, errors, setFieldError, setFieldTouched);
    }
    if (page === 1) {
      validatePosition(values, errors, setFieldError, setFieldTouched);
    }
  };

  const validateProfile = (values: object,
                           errors: FormikErrors<FormikValues>,
                           setFieldError: Function,
                           setFieldTouched: Function) => {
    Object.keys(fieldsProfile).forEach((key) => {
      setFieldTouched(key, true);
    });
    if (!Object.keys(errors).length) {
      staffHelper.validate(staff, values).then((data: any) => {
        setPage(page + 1);
        setFilled({0: true, 1:filled[1]});
        setIsLoading(false);
      }).catch((error: any) => {
        let data = error.data, fetchedFormErrors = [];
        if (data.required) {
          Object.keys(data.required).forEach((key) => {
            setFieldError(attributes[key], data.required[key]);
            fetchedFormErrors.push(fullUserAttributes[key]);
          });
        }
        if (data.username) {
          setFieldError('username', data.username);
          fetchedFormErrors.push('username');
        }
        setFormErrors(fetchedFormErrors);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }

  const validatePosition = (values: object,
                            errors: FormikErrors<FormikValues>,
                            setFieldError: Function,
                            setFieldTouched: Function) => {
    Object.keys(fieldsPosition).forEach((key) => {
      setFieldTouched(key, true);
    });
    if (!Object.keys(errors).length) {
      wrHelper.validate(workRecord, values).then((data: any) => {
        setPage(page + 1);
        setFilled({0: filled[0], 1:true});
        setIsLoading(false);
      }).catch((error: any) => {
        let data = error.data, fetchedFormErrors = [];
        if (data.required) {
          Object.keys(data.required).forEach((key) => {
            setFieldError(attributes[key], data.required[key]);
            fetchedFormErrors.push(fullUserAttributes[key]);
          });
        }
        if (data.start_date) {
          setFieldError('startDate', data.start_date);
          fetchedFormErrors.push('startDate');
        }
        if (data.trial_end_date) {
          setFieldError('trialEndDate', data.trial_end_date);
          fetchedFormErrors.push('trialEndDate');
        }
        setFormErrors(fetchedFormErrors);
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }

  const createEmployee = (values: object) => {
    wrHelper.createWithStaff(staff, workRecord, values)
      .then((data: any) => {
        const wr: WorkRecordInterface = WorkRecordMapper.creatWorkRecordFromStaffResponse(data, workRecord.staffId);
        staff.id = wr.staffId;
        const staffMedia = staffHelper.saveStaffMedia(staff, values);
        if (staffMedia) {
          staffMedia
            .then((data: any) => {
              history.push(`/staff/${wr.staffId}/work-records/${wr.id}/main`);
            })
            .catch((error: any) => {
              toastr.error('','Photo save failed, try again please');
            });
        } else {
          history.push(`/staff/${wr.staffId}/work-records/${wr.id}/main`);
        }
      })
      .catch((error: any) => {
        toastr.error('','Employee create failed, try again please');
      });
  }

  useEffect(() => {
    if (initialState == null) {
      dictionaryStore.initialize();
      const state = EmployeeFormState.generateFromStaffnWRnSkills(
        ProfileState.generateFromStaff(staff),
        PositionDescriptionState.generateFromWorkRecord(workRecord),
        SkillState.generateFromWorkRecord()
      )
      setInitialState(state);
    }
  }, [initialState])

  if (initialState) {
    return (
      <div className="add-employee-form-wrapper">
        <div className="add-employee-form-wrapper__header">Add New Employee</div>
        <div className="add-employee-form-wrapper__body">
          <div className="container">
            <div className="row h-100">
              <div className="col-3">
                <div className="progress-tabs-column">
                  <ul className="progress-tabs">
                    <li className={`progress-tab ${page === 0 ? 'active': ''} ${filled[0] ? 'filled' : ''}`}>
                      Personal Information
                    </li>
                    <li className={`progress-tab ${page === 1 ? 'active': ''} ${filled[1] ? 'filled' : ''}`}>
                      Position Description
                    </li>
                    <li className={`progress-tab ${page === 2 ? 'active': ''} `}>
                      Skills
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-9">
                <div className="add-employee-form-wrapper__content">
                  <Formik
                    validateOnBlur={ true }
                    initialValues={ initialState }
                    validationSchema={ currentValidationSchema }
                    onSubmit={values => createEmployee(values)}>
                    {({values, errors, setFieldError, setFieldTouched, setFieldValue, touched, validateForm}) => (
                      <Form className="edb-form add-employee-form">
                        <ScrollToError />
                        {(page === 0) ?
                          <div className="add-employee-form__content">
                            <div className="form-page-header">
                              <p>Personal Information</p>
                              <span>All fields marked with the red asterisks (<span className="text-danger">*</span>) are mandatory to fill in</span>
                            </div>
                            <ProfileFormFields
                              values={ values }
                              staff={ staff }
                              mode={ "MODE_STAFF_ADD" }
                              formErrors={ formErrors }
                              errors={ errors }
                              touched={ touched }
                              setFieldValue={ setFieldValue }
                            />
                          </div> : <></>}
                        {(page === 1) ?
                          <div className="add-employee-form__content">
                            <div className="form-page-header">
                              <p>Position Description</p>
                              <span>All fields marked with the red asterisks (<span className="text-danger">*</span>) are mandatory to fill in</span>
                            </div>
                            <PositionDescriptionFormFields
                              values={ values }
                              workRecord={ workRecord }
                              staff={ null }
                              mode={ "MODE_STAFF_ADD" }
                              formErrors={ formErrors }
                              errors={ errors }
                              touched={ touched }
                              setFieldValue={ setFieldValue }
                            />
                          </div> : <></>}
                        {
                          (page === 2)
                            ? (
                              <div className="add-employee-form__content">
                                <div className="form-page-header">
                                  <p>Skills</p>
                                  <span>Describe your professional skills</span>
                                </div>
                                <SkillFormFields values={values}/>
                              </div>
                            )
                            : <></>
                        }
                        <div className="form-footer">
                          <div className="form-footer__container">
                            <div className="d-flex justify-content-end align-items-center">
                              {(page !== 0) ?
                                <div className="col-auto">
                                  <button className="edb-btn edb-btn--secondary"
                                          type="button"
                                          onClick={() => setPage(page - 1)}
                                          data-testid="btnPrevious">
                                    Previous
                                  </button>
                                </div> : <></>
                              }
                              {(page < pages.length - 1) ?
                                <div className="col-auto pr-0">
                                  <button
                                    className="edb-btn edb-btn--primary"
                                    type="button"
                                    onClick={() => validateForm().then((errors) => {
                                      const keys = Object.keys(errors);
                                      if (keys.length > 0) {
                                        const errorElement = document.querySelector(`[id="${keys[0]}"]`) as HTMLElement
                                          || document.querySelector(`[id="${keys[0]}Wrapper"]`) as HTMLElement;
                                        if (errorElement) errorElement.focus();
                                      }
                                      return callValidation(values, errors, setFieldError, setFieldTouched)
                                    })}
                                    data-testid="btnNext">
                                    Next
                                  </button>
                                </div> : <></>
                              }
                              {(page === pages.length - 1) ?
                                <div className="col-auto pr-0">
                                  <button className="edb-btn edb-btn--primary"
                                          type="submit"
                                          data-testid="btnCreateRecord">
                                    Create record
                                  </button>
                                </div> : <></>
                              }
                            </div>
                          </div>
                        </div>
                      </Form>

                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        { isLoading ? <Spinner/> : <></> }
      </div>
    );
  }
  return <></>;
}
