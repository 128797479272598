import React, {Fragment, FunctionComponent, useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import useWindowDimensions from '../../../hooks/windowDimensionsHook';

interface Props {
  records: Array<any>,
  refTenure: any,
  trackScroll: Function
}

const emptyValue = '-';

export const TenureList: FunctionComponent<Props> = (props: Props) => {
  const {records, refTenure, trackScroll} = {...props};
  const {height} = useWindowDimensions();

  const getTenureDataHeight = (): number => {
    return height - 280;
  }

  return (
    <div className="tenure-list">
      <div className="tenure-data-table">
        <div className="tenure-data-table--header text-uppercase row ml-0 mr-0">
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Employee</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Location</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Org Structure</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Department</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Sub-Department</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Team</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Line Manager</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell align-middle text-left">Seniority</div>
          </div>
          <div className="col">
            <div className="cell d-table-cell pr-15 align-middle text-right">Tenure</div>
          </div>
        </div>
        {records.length > 0 &&
        <div
            className="tenure-data-table--body font-size--14"
            ref={refTenure}
            style={{height: getTenureDataHeight()}}
            onScroll={(event) => {
              const scrollTop = Math.round(event.currentTarget.scrollTop);
              const scrollHeight = event.currentTarget.scrollHeight - getTenureDataHeight();
              trackScroll(scrollTop, scrollHeight);
            }}
        >
          {records.map((record: any) => (
            <div className="record-row row ml-0 mr-0" key={record.staff_id}>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">
                  <NavLink to={`/staff/${record.staff_id}/work-records`}>
                    {record.full_name}
                  </NavLink>
                </div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">{record.location || emptyValue}</div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">{record.org_structure || emptyValue}</div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">{record.department || emptyValue}</div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">{record.sub_department || emptyValue}</div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">{record.team || emptyValue}</div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">{record.line_manager || emptyValue}</div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-left">{record.seniority || emptyValue}</div>
              </div>
              <div className="col">
                <div className="cell d-table-cell align-middle text-right font-weight--600">{record.tenure}</div>
              </div>
            </div>
          ))}
        </div>}
        {records.length === 0 &&
        <div className="tenure-data-table--body font-size--14">
          <div className="record-row ml-0 mr-0">
            <div className="col">
              <div className="cell d-table-cell align-middle">
                No results found. Please clear the filters or try another search.
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
}
