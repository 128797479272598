import React, {FunctionComponent} from 'react';

import {DetailsInterface} from '../../../../interfaces/dashboard/DetailsInterface';
import './turnover-horizontal-bars.scss'

interface Props {
  data: Array<any>,
  value: number,
  details: DetailsInterface
}

export const TurnoverHorizontalBars: FunctionComponent<Props> = (props: Props) => {
  const {data, value, details} = props;

  const getPercentValue = (param1: number, param2: number, number: number, sign: string) => {
    let value = param1/(param1 + param2) * 100;
    let formattedValue = value ? value.toFixed(number) : 0;

    return sign ? `${formattedValue}%` : formattedValue;
  }

  const getPercentAbsoluteValue = (param1: number, param2: number, percent: number, number: number, sign: string) => {
    let value = param1/(param1 + param2) * percent;
    let formattedValue = value ? value.toFixed(number) : 0;

    return sign ? `${formattedValue}%` : formattedValue;
  }

  const {good, bad} = data.reduce((acc, currentValue) => {
    acc.good += currentValue.good;
    acc.bad += currentValue.bad;

    return acc;
  }, {good: 0, bad: 0});

  const goodPercent = getPercentValue(good, bad, 2, '%');
  const badPercent = getPercentValue(bad, good, 2, '%');
  const goodPercentAbsolute = getPercentAbsoluteValue(good, bad, value, 1, '%');
  const badPercentAbsolute = getPercentAbsoluteValue(bad, good, value, 1, '%');

  return (
    <div className="horizontal-bar-wrapper">
      <div>
        <div className="horizontal-bar" onClick={() => window.open('/report/' + details.good_detail_url, '_blank')}>
          <span className="bar-overlay good" style={{width: goodPercent}}/>
        </div>
        <span className="count">{goodPercentAbsolute} ({good})</span>
      </div>
      <div>
        <div className="horizontal-bar" onClick={() => window.open('/report/' + details.bad_detail_url, '_blank')}>
          <span className="bar-overlay bad" style={{width: badPercent}}/>
        </div>
        <span className="count">{badPercentAbsolute} ({bad})</span>
      </div>
    </div>
  );
}
