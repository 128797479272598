import React, {Fragment, FunctionComponent, ReactElement, useEffect, useState} from 'react'
import ValueLocation from '../../../models/ValueLocation';


interface Props {
  name: string;
  value: ValueLocation;
  tooltip?: ReactElement;
  isLink?: boolean;
}

const link: string = 'https://officemaps.star.global/go/';

export const WRAttributeLocation: FunctionComponent<Props> = (props: Props) => {
  const {isLink} = {...props};
  const [name] = useState<string>(props.name);
  const [value, setValue] = useState<ValueLocation>(props.value);

  useEffect(() => {
    setValue(props.value)
  }, [props]);

  if (value.id && value.valueId) {
    return (
      <div className="work-record-attribute">
        <div className="work-record-attribute__name">
          {name}
        </div>
        <div className="work-record-attribute__value">
          {
            isLink ?
              <a href={`${link}872`} target="_blank" rel="noopener noreferrer">{value.value}, {value.country}</a>
              :
              <span>{value.value}, {value.country}</span>
          }
          {(props.tooltip) ? (props.tooltip) : ''}
        </div>
      </div>
    );
  }

  return <Fragment/>;
}
