import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

// store
import { STAFF_STATE, WORK_RECORD_STATE } from '../../../../store/reducers';
import { GlobalState } from '../../../../store/types';

import { FeedbackI } from '../../../../interfaces/entity/Feedback';
import FeedbackItemState, { FeedbackItemStateInterface } from './FeedbackItemState';
import { FeedbackItemFormFields } from './FeedbackItemFormFields';
import { schema } from './FeedbackItemFormSchema';
import { WorkRecord } from '../../../../interfaces/WorkRecord';
import { Staff } from '../../../../interfaces/Staff';

import './feedback-item-form.scss';

interface FeedbackItemFormProps {
  submitFunction: Function;
  feedback: FeedbackI;
  popupName: string;
  handleCancel: Function;
  saving: boolean;
}

export const FeedbackItemForm: React.FC<FeedbackItemFormProps> =
  (props: FeedbackItemFormProps) => {
    const { submitFunction, feedback } = { ...props };
    const [initialState, setInitialState] = useState<FeedbackItemStateInterface | null>(null);
    const workRecord: WorkRecord = useSelector((state: GlobalState) => state[WORK_RECORD_STATE].workRecord);
    const myStaff: Staff = useSelector((state: GlobalState) => state[STAFF_STATE].myStaff);
    
    useEffect(() => {
      if (initialState == null) {
        setInitialState(FeedbackItemState.generateFromFeedback(workRecord, myStaff, feedback));
      }
    }, [initialState]);

    if (initialState) {
      return (
        <Formik
          initialValues={ initialState }
          validationSchema={ schema }
          onSubmit={(values, {setFieldError, setFieldTouched}) => {
            submitFunction(values, setFieldError, setFieldTouched);
          }}>
          {({errors, touched, values, handleChange, setFieldValue}) => (
            <Form className="edb-form form-feedback">
              <div className="form-fields">
                <FeedbackItemFormFields
                  values={ values }
                  feedback={ feedback }
                  errors={ errors }
                  touched={ touched }
                  handleChange={ handleChange }
                  setFieldValue={ setFieldValue }
                />
              </div>
              <div className="form-footer">
                <div className="form-buttons">
                  <button className="edb-btn edb-btn--secondary" type="reset" onClick={() => props.handleCancel()}>
                    Cancel
                  </button>
                  <button className="edb-btn edb-btn--primary" type="submit" disabled={ props.saving }>
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
    return <></>;
  }
