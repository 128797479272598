import React, {FunctionComponent, HTMLAttributes} from 'react';
import {useField} from 'formik';

import './switcher.scss';

interface CheckboxFieldProps {
  id: string;
  name: string;
  on?: string;
  off?: string;
  disabled?: boolean;
}

export const SwitcherField: FunctionComponent<CheckboxFieldProps & HTMLAttributes<HTMLDivElement>> =
  (props: CheckboxFieldProps & HTMLAttributes<HTMLDivElement>) => {
    const {id, name, on, off, disabled} = {...props};
    const [field, , {setValue}] = useField(props as any);

    return (
      <div className={`switcher ${disabled ? 'switcher-disabled' : ''}`}>
        <input
          id={id}
          type="checkbox"
          name={name}
          value={field.name}
          checked={field.value}
          onChange={() => {
            setValue(!field.value)
          }}
          className="switcher__checkbox"/>
        <label
          htmlFor={id}
          className="switcher__label">
         <span
           className="switcher__inner"
           data-content-on={(on) ? on : 'Yes'}
           data-content-off={(off) ? off : 'No'}/>
          <span className="switcher__switch"/>
        </label>
      </div>
    );
  };
