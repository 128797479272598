import React, {FunctionComponent} from 'react';
import './horizontal-bars.scss';

interface Props {
  data: any,
  details: Array<any>,
  balance?: number
}

export const HorizontalBars: FunctionComponent<Props> = (props: Props) => {
  const {data, details, balance} = {...props};

  const isBalance = (arg: any) => {
    return typeof arg === 'number';
  }

  const getPercentValue = (param1:number, param2: number, number: number, sign: string): string | number => {
    const value = param1 / (param1 + param2) * 100;
    const formattedValue = value ? value.toFixed(number) : 0;
    return sign ? `${formattedValue}%` : formattedValue;
  }

  let billable, not_billable, left, joined, joined_width, left_width, not_billable_width, billable_width;
  const dataLength = data.length;

  if (isBalance(balance)) {
    left = data[0].left;
    joined = data[0].joined;
    joined_width = getPercentValue(joined, left, 2, "%");
    left_width = getPercentValue(left, joined, 2, "%");
  } else {
    billable = data[dataLength - 1].billable;
    not_billable = data[dataLength - 1].not_billable;
    not_billable_width = getPercentValue(not_billable, billable, 2, "%");
    billable_width = getPercentValue(billable, not_billable, 2, "%");
  }

  const top_value = isBalance(balance) ? joined : billable;
  const bottom_value = isBalance(balance) ? left : not_billable;

  return (
    <div className="horizontal-bar-wrapper">
      <div>
        <div onClick={() => window.open('/report/' + details[0], "_blank")} className="horizontal-bar">
          <span
            className={`bar-overlay ${isBalance(balance) ? 'joined' : 'billable'}`}
            style={{width: isBalance(balance) ? joined_width : billable_width}}>
          </span>
        </div>
        <span className="count">{top_value}</span>
      </div>
      <div>
        <div onClick={() => window.open('/report/' + details[1], "_blank")} className="horizontal-bar">
          <span
            className={`bar-overlay ${isBalance(balance) ? 'left' : 'not_billable'}`}
            style={{width: isBalance(balance) ? left_width : not_billable_width}}>
          </span>
        </div>
        <span className="count">{bottom_value}</span>
      </div>
    </div>
  );
}
