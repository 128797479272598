import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { GlobalState } from '../../../store/types';
import { setCVApprovedLatestVersionThunk, setCVThunk } from '../../../store/cv/actions';

import PermissionWRService from '../../../services/security/PermissionWRService';

import { WRCvRead } from './WRCvRead';
import { WRCvCreate } from './WRCvCreate';
import { WRCvUpdate } from './WRCvUpdate';

import './work-record-cv.scss';

type Params = {
  staffId: string,
  wrId: string
}

export const WRCv: React.FC = () => {
  const dispatch = useDispatch();
  const { myStaff } = useSelector((state: GlobalState) => state.staff);
  PermissionWRService.setUser(myStaff);

  const params = useParams<Params>();
  const cParams = {
    wrId: Number(params.wrId),
    staffId: Number(params.staffId)
  };

  useEffect(() => {
    dispatch(setCVThunk(cParams.wrId));
    dispatch(setCVApprovedLatestVersionThunk(cParams.wrId));
  },[cParams.wrId, cParams.staffId]);
  
  return (
    <div className="work-record-cv">
      <Switch>
        <Route path="/staff/:staffId/work-records/:wrId/cv/show" component={ WRCvRead } />
        <Route path="/staff/:staffId/work-records/:wrId/cv/create" component={ WRCvCreate } />
        <Route path="/staff/:staffId/work-records/:wrId/cv/edit" component={ WRCvUpdate } />
        <Redirect from="/staff/:staffId/work-records/:wrId/cv" to="/staff/:staffId/work-records/:wrId/cv/show" />
      </Switch>
    </div>
  )
};