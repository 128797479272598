import { loaderTypes } from './types';

export const loaderActions = Object.freeze({
  showLoader: () => {
    return {
      type: loaderTypes.SHOW_LOADER
    };
  },
  hideLoader: () => {
    return {
      type: loaderTypes.HIDE_LOADER
    };
  },
});