import React, {cloneElement, Fragment, FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {PopupTriggerProps} from './PopupPropsInterface';
import {dispatchPopupState} from '../../store/popup/types';

export const PopupTrigger: FunctionComponent<PopupTriggerProps> = (props: PropsWithChildren<PopupTriggerProps>) => {
  const handleShow = () => {
    dispatchPopupState(props.popupName, true);
  };

  return (
    <Fragment>
      {cloneElement(props.children as ReactElement, {onClick: handleShow})}
    </Fragment>
  );
}
