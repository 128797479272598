import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useFilters, useGlobalFilter, useSortBy, useTable} from 'react-table';
import {FontAwesomeIcon as FA} from '@fortawesome/react-fontawesome';

import myTeamClient from '../../../../services/http/MyTeamClient';
import EmployeeTableHelper from '../../../../services/data/EmployeeTableHelper';
import { TextColumnFilter, FilterTrigger, FilterWrapper } from '../../../Tables/Filters';
import {MyTeamTableShortInfo} from '../MyTeamShortInfo/MyTeamTableShortInfo';
import {EmployeeTableSettings} from '../../EmployeeTable/EmployeeTableSettings';
import {EmployeeTableFilter} from '../../EmployeeTable/EmployeeTableFilter';
import {Spinner} from '../../../Spinner/Spinner';

import {WRPreview} from "../../../WR/WRPreview/WRPreview";
import client from "../../../../services/http/StaffClient";
import StaffMapper from "../../../../mappers/response/StaffMapper";
import store from "../../../../store/store";
import {setStaff} from "../../../../store/staff/actions";
import {GlobalState} from "../../../../store/types";
import { MY_TEAM_TYPES, MY_TEAM_TABS, MY_TEAM_TABS_INFO, TABLE_NAMES } from '../../../../constants';
import {betweenDatesFilterFn, fuzzyTextFilterFn, multipleFilterFn} from "../../../Tables/TableFunctions";

import '../../EmployeeTable/employee-table.scss';
import './tabset.scss';

interface MyTeamTableProps {
  subordinatesColumns: Array<any>;
  directColumns: Array<any>;
}

interface MyTeamTab {
  label: string,
  key: string,
  isActive: boolean
}

export const MyTeamTable: React.FC<MyTeamTableProps> = (props: MyTeamTableProps) => {
  const { subordinatesColumns, directColumns } = props;
  const myStaff = useSelector((state: GlobalState) => state.staff.myStaff);
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingShared, setIsLoadingShared] = useState<boolean>(true);
  const [columns, setColumns] = useState<Array<any>>([]);
  const [dataSubordinates, setDataSubordinates] = useState<Array<any>>([]);
  const [dataDirect, setDataDirect] = useState<Array<any>>([]);
  const [data, setData] = useState<Array<any>>([]);
  const [myTeamType, setMyTeamType] = useState<string>(MY_TEAM_TYPES.SUBORDINATE);
  const [csvDataFilePrefix, setCsvDataFilePrefix] = useState<string>(MY_TEAM_TYPES.CSV_DATA_FILE_PREFIX_SUBORDINATE);
  const [activeTab, setActiveTab] = useState<string>(MY_TEAM_TABS.TEAM_INFO);
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [tableName, setTableName] = useState(TABLE_NAMES.SUBORDINATES);

  const changeMyTeamType = (value: string) => {
    setMyTeamType(value);
    switch (value) {
      case MY_TEAM_TYPES.SUBORDINATE:
        setData(dataSubordinates);
        setColumns(subordinatesColumns);
        setCsvDataFilePrefix(MY_TEAM_TYPES.CSV_DATA_FILE_PREFIX_SUBORDINATE);
        setTableName(TABLE_NAMES.SUBORDINATES);
        break;
      case MY_TEAM_TYPES.DIRECT_TEAM:
        setData(dataDirect);
        setColumns(directColumns);
        setCsvDataFilePrefix(MY_TEAM_TYPES.CSV_DATA_FILE_PREFIX_DIRECT_TEAM);
        setTableName(TABLE_NAMES.DIRECT);
        break;
      default:
        setData(dataSubordinates);
        setColumns(subordinatesColumns);
        setCsvDataFilePrefix(MY_TEAM_TYPES.CSV_DATA_FILE_PREFIX_SUBORDINATE);
        setTableName(TABLE_NAMES.SUBORDINATES);
    }
  }

  useEffect(() => {
    if (!isLoading || !myStaff) {
      return;
    }

    const getDataSubordinates: Promise<any> = myTeamClient.getSubordinates(myStaff.id).then((response) => {
      setDataSubordinates(EmployeeTableHelper.convertListToData(response));
    });
    const getDataDirect: Promise<any> = myTeamClient.getDirectMyTeam(myStaff.id).then((response) => {
      setDataDirect(EmployeeTableHelper.convertListToData(response));
    });

    Promise.all([getDataSubordinates, getDataDirect]).then(() => {
      setIsLoading(false);
      setIsLoadingShared(false);
    });
  }, [isLoading, myStaff]);

  useEffect(() => {
    changeMyTeamType(myTeamType);
  }, [dataSubordinates, dataDirect]);

  const filterTypes = useMemo(() => ({
    text: fuzzyTextFilterFn,
    multiple: multipleFilterFn,
    betweenDates: betweenDatesFilterFn,
  }), []);

  const defaultColumn: any = useMemo(() => ({
    Filter: TextColumnFilter,
  }), []);

  const hiddenColumns: Array<string> = [];
  const columnsMap: Array<string> = [];
  columns.map(column => {
    columnsMap[column.accessor] = column.Header;
    if (!column.isVisible) {
      hiddenColumns.push(column.accessor);
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    allColumns,
    state,
    //@ts-ignore
    setFilter, setAllFilters
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      //@ts-ignore
      filterTypes,
      initialState: {
        hiddenColumns: hiddenColumns
      }
    },
    useFilters, useGlobalFilter, useSortBy
  )

  // @ts-ignore
  const filters = state.filters;
  const filterProps = {columnsMap, filters, setFilter, setAllFilters, setFilterHeight};
  
  const [selectedWRId, setSelectedWRId] = useState<number | null>(null);
  const [showWRPreview, setShowWRPreview] = useState<boolean>(false);

  const showPreviewWR = (event: React.MouseEvent<HTMLLIElement>): void => {
    const idWr = event.currentTarget.getAttribute('data-wr-id');
    const idStaff = event.currentTarget.getAttribute('data-staff-id');

    if (idStaff) {
      client.get(parseInt(idStaff)).then((data: any) => {
        const staff = StaffMapper.creatStaffFromStaffResponse(data);
        store.dispatch(setStaff(staff));
        if (idWr) {
          setSelectedWRId(parseInt(idWr));
        } else if (staff.activeWRs.length) {
          setSelectedWRId(staff.activeWRs[staff.activeWRs.length - 1].id);
        } else {
          setSelectedWRId(staff.inactiveWRs[0].id);
        }
      });
    }
  };

  const tableWrapperStyles = {
    minHeight: `calc(100vh - 246px - ${filterHeight}px)`,
    height: `calc(100vh - 247px - ${filterHeight}px)`
  };

  return (
    <div className="employee-table-wrapper employee-table-wrapper--my-team">
      <MyTeamTableShortInfo
          myStaff={myStaff}
          myTeamEmployCount={dataSubordinates.length}
          directReportsCount={dataDirect.length}
          isLoading={isLoading}
          myTeamType={myTeamType}
          changeMyTeamType={changeMyTeamType}
      />

      <div className="edb-tabset">
        <ul className="edb-tabset__tabs edb-tabset__tabs--my-team">
          {
            MY_TEAM_TABS_INFO.map((item: MyTeamTab) => (
                <li className="edb-tabset__tab-title edb-tabset__tab-title--my-team" key={item.key}>
                  <a className={`edb-tabset__tab-link edb-tabset__tab-link--my-team ${item.key === activeTab ? "edb-tabset__tab-link--my-team-active" : ""}`}
                     onClick={() => setActiveTab(item.key)}>
                    <FA icon={['fas', 'list-alt']}/> &nbsp;{item.label}</a>
                </li>
              )
            )
          }
        </ul>
      </div>

      <div className={`tab-panel-team-info ${MY_TEAM_TABS.TEAM_INFO === activeTab ? "active-panel" : ""}`}>
        <div className="employee-table-header">
          <div className="employee-table-header__title">{ rows.length } records found</div>
          <EmployeeTableSettings
            columns={ allColumns }
            columnsTable={ columns }
            data={ rows }
            asOfDate={ new Date() }
            setIsLoadingShared={ setIsLoadingShared }
            csvDataFilePrefix={ csvDataFilePrefix }
            tableName={ tableName }
          />
        </div>
        <EmployeeTableFilter {...filterProps}/>
        <div className="table-wrapper" style={tableWrapperStyles}>
          <table className="table" {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                      <th {...column.getHeaderProps()} style={{width: `${column.width}px`}}>
                        {column.sortable
                            ? <Fragment>
                             <span {...column.getHeaderProps(column.getSortByToggleProps())} className="table-sort">
                               {column.render('Header')}
                               {' '}
                               {column.isSorted ? column.isSortedDesc ?
                                   <FA icon={['fas', 'long-arrow-alt-down']}/> :
                                   <FA icon={['fas', 'long-arrow-alt-up']}/> :
                                   <FA icon={['fas', 'arrows-alt-v']}/>
                               }
                             </span>
                            </Fragment>
                            : <Fragment>{column.render('Header')}</Fragment>
                        }
                        {column.canFilter && column.Filter
                            ? <span className="table-filter">
                    <FilterTrigger column={column}>
                      <FA icon={['fas', 'filter']}/>
                    </FilterTrigger>
                    <FilterWrapper column={column}>
                      {column.render('Filter')}
                    </FilterWrapper>
                  </span>
                            : <Fragment/>
                        }
                      </th>
                  ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {
              rows.map((row: any, i: number) => {
                prepareRow(row);
                if (i === 0) {
                  row.stained = false;
                } else {
                  const prev: any = rows[i - 1];
                  row.stained = prev.original.staff_id === row.original.staff_id ? prev.stained : !prev.stained;
                }
                return (
                  <tr className={ row.stained ? 'row-blue' : 'row-white' } {...row.getRowProps()} data-wr-id={row.original.id} data-staff-id={row.original.staff_id} onClick={showPreviewWR}>
                    {
                      row.cells.map((cell: any) => {
                        return (<td { ...cell.getCellProps() }>{ cell.render('Cell') }</td>)
                      })
                    }
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          <WRPreview
            selectedWRId={ selectedWRId }
            setSelectedWRId={ setSelectedWRId }
            showWRPreview={ showWRPreview }
            setShowWRPreview={ setShowWRPreview }
          />
        </div>
      </div>

      <div className={`tab-panel-leaves ${MY_TEAM_TABS.LEAVES === activeTab ? "active-panel" : ""}`}>
        <a className="my-team-leaves" target="_blank" href={ MY_TEAM_TABS.LEAVES_LINK } rel="noreferrer">{ MY_TEAM_TABS.LEAVES_LINK_NAME }</a>
      </div>

      {
        (isLoadingShared) ? <Spinner/> : <Fragment/>
      }
    </div>
  );
}
