import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons';

import store from './store/store';
import authorization from './services/authorization/Authorization';

import { LoginPage } from './pages/LoginPage';
import { AuthorizedPages } from './pages/AuthorizedPages';
import { GoogleOAuthProvider } from './lib';

import 'react-datepicker/dist/react-datepicker.css';
import 'rc-slider/assets/index.css';

import './styles/index.scss';

library.add(far, fas);
authorization.checkStatus();

export function App() {
  const { hash, href } = window.location;
  if (hash) {
    const url = href.replace('/#/', '/');
    window.location.replace(url);
  }
  return (
    <GoogleOAuthProvider clientId={ process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID! }>
      <Provider store={ store }>
        <Router>
          <Switch>
            <Route path="/login" component={ LoginPage } />
            <AuthorizedPages />
          </Switch>
        </Router>
        <ReduxToastr
          newestOnTop={ false }
          preventDuplicates
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          closeOnToastrClick
        />
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
