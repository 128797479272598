import React, { FunctionComponent, useState } from 'react';
import { useAsyncDebounce } from 'react-table'
import DateHelper from '../../../utils/date/DateHelper';
import { EmployeeDateRange } from '../../Forms/Standalone/EmployeeDateRange';
import { EmployeeEmploymentType, EmploymentTypes } from '../../Forms/Standalone/EmployeeEmploymentType';
import { DatePickerField } from '../../Forms/DatePickerField';

import './employee-table-short-info.scss';

interface EmployeeTableShortInfoProps {
  setFilterEmployment: Function,
  list: Array<any>,
  asOfDate: Date,
  setAsOfDate: Function,
  dateRange: Array<string>,
  setDateRange: Function
}

export const employmentTypes = {
  all: undefined,
  employee: 'Employee',
  contractor: ['External Contractor', 'Internal Contractor', 'Vendor']
};

export const EmployeeTableShortInfo: FunctionComponent<EmployeeTableShortInfoProps> = (props: EmployeeTableShortInfoProps) => {
  const [employment, setEmployment] = useState<string>('all');

  const changeEmployment = useAsyncDebounce((value: EmploymentTypes, data: Array<any>) => {
    setEmployment(value);
    props.setFilterEmployment(value);
  }, 100);

  return (
    <div className="table-short-info-wrapper">
      <div className="row">
        <div className="col-auto grid-wrapper">
          <div className="form-group input-wrapper">
            <label>Show employees</label>
            <br/>
            <EmployeeDateRange value={ props.dateRange } setter={ props.setDateRange } />
          </div>
          <div className="form-group input-wrapper">
            <label>As of</label>
            <br/>
            <DatePickerField
              name="from"
              value={ (props.asOfDate) ? DateHelper.getFormattedDate(props.asOfDate) : '' }
              selected={ props.asOfDate }
              onChange={ (val: Date) => props.setAsOfDate(val) }
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group input-wrapper float-right">
            <label>Employment type</label>
            <br/>
            <EmployeeEmploymentType
              value={ employment }
              setter={ changeEmployment }
              list={ props.list }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
