import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useResizeHeightForChartHook from '../../../hooks/resizeHeightForChartHook';
import { LM_CHART } from '../../../constants';

import iconInfoActive from './../../../assets/images/lm-tree/info_active.svg';
import iconInfoNormal from './../../../assets/images/lm-tree/info_normal.svg';
import iconTreeActive from './../../../assets/images/lm-tree/tree_active.svg';
import iconTreeNormal from './../../../assets/images/lm-tree/tree_normal.svg';

import './tree-chart.scss';

const d3 = require('d3v3');

interface LmTreeChartProps {
  tree: any;
}

type Params = {
  staffId: string,
  wrId: string
}

export const TreeChart: React.FC<LmTreeChartProps> = (props: LmTreeChartProps) => {
  const { tree } = { ...props };
  const params = useParams<Params>();
  const cParams = {
    staffId: Number(params.staffId),
    wrId: Number(params.wrId)
  };

  const dimensions = useResizeHeightForChartHook();

  const svgRef = useRef(null);

  useEffect(() => {
    let lmList: any = [];
    let prevActiveNode: any;
    let tooltipShouldBeShown: boolean = false;
    let controlsTitles = {
      info: LM_CHART.CONTROLS_TITLES.INFO,
      tree_expander: LM_CHART.CONTROLS_TITLES.TREE_EXPANDER
    };
    let tooltipLocalesCaptions = {
      direct_reports: LM_CHART.TOOLTIP.DIRECT_REPORTS,
      subordinates: LM_CHART.TOOLTIP.SUBORDINATES,
      avg_span: LM_CHART.TOOLTIP.AVG_SPAN
    };

    setInitialStates(tree[0]);

    let i: number = 0;

    const initLmTreeTopMargin = (dimensions.height - tree[0].children.length * LM_CHART.INIT_VERT_INDENTATION) / 2;

    const zoom = d3.behavior.zoom()
      .scaleExtent([0.2, 2])
      .on('zoom', redraw);
    const tooltip = d3.select('.lm-tree-chart--tooltip')
      .style('opacity', 0)
      .on('mouseenter', function() {
        if (tooltipShouldBeShown) {
          showTooltip(true);
        }
      })
      .on('mouseleave', function() {
        tooltipShouldBeShown = false;
        showTooltip(false);
      });
    let treeLayout = d3.layout.tree()
      .size([dimensions.height, '100%']);
    const svg = d3.select(svgRef.current).call(zoom);
    const container = svg.select('g')
      .attr('transform', `translate(${LM_CHART.MARGIN.LEFT},${initLmTreeTopMargin})`);
    const diagonal = d3.svg.diagonal()
      .projection((d: any) => {
        return [d.y, d.x];
      });
    const root = tree[0];
    root.x0 = dimensions.height / 2;
    root.y0 = 0;

    sortTree();
    update(root);

    function redraw() {
      svg.select('g').attr('transform', `translate(${d3.event.translate}) scale(${d3.event.scale})`);
    }

    function sortTree() {
      treeLayout.sort(function(a: any, b: any) {
        return b.name.toLowerCase() < a.name.toLowerCase() ? 1 : -1;
      });
    }

    function setInitialStates(root: any) {
      let childrenList = root.children || root._children;

      root.state = {};
      root.state.active = false;
      root.state.allSubordinatesExpanded = false;
      root.state.hasSubordinates = !!childrenList.length;
      root.state.tooltipIsOpened = false;

      if (root.state.hasSubordinates) {
        root.state.directReportsExpanded = !!(root.children);
      } else {
        root.state.directReportsExpanded = false;
      }

      childrenList.forEach((node: any) => {
        setInitialStates(node);

        let combinedStaffWrKey = getCombinedStaffWorkRecordKey(node.staff_id, node.wr_id);
        lmList[combinedStaffWrKey] =
          node.parent_staff_id && node.parent_wr_id ? {'staffId': node.parent_staff_id, 'wrId': node.parent_wr_id}
            : null;
      });
    }

    function update(source: any) {
      let levelWidth = [1];

      function childCount(level: number, data: any) {
        if (data.children && data.children.length > 0) {
          if (levelWidth.length <= level + 1) {
            levelWidth.push(0);
          }

          levelWidth[level + 1] += data.children.length;
          data.children.forEach(function(d: any) {
            childCount(level + 1, d);
          });
        }
      }

      function treeHeightRecalculation() {
        let newHeight;
        childCount(0, root);
        switch (levelWidth.length) {
          case 2:
            newHeight = d3.max(levelWidth) * LM_CHART.INIT_VERT_INDENTATION;
            break;
          default:
            newHeight = d3.max(levelWidth) * LM_CHART.VERT_INDENTATION;
            break;
        }
        treeLayout = treeLayout.size([newHeight, '100%']);
      }

      treeHeightRecalculation();

      // Compute the new tree layout.
      const nodes = treeLayout.nodes(root).reverse(),
        links = treeLayout.links(nodes);

      // Normalize for fixed-depth.
      nodes.forEach(function (d: any) {
        // here you can set horiz. distance between nodes
        d.y = d.depth * LM_CHART.DEPTH_WIDTH;
      });

      // Update the nodes…
      const node = container.selectAll('g.node')
        .data(nodes, function (d: any) {
          return d.id || (d.id = ++i);
        });

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', function () {
          return `translate(${source.y0},${source.x0})`;
        });

      const avatarAndControlsContainer = nodeEnter.append('g')
        .attr('class', 'avatar-and-controls-container')
        .on('mouseleave', function() {
          d3.select(d3.event.currentTarget).select('g.control')
            .transition()
            .duration(550)
            .style('opacity', 0)
            .remove();
        });

      const avatar = avatarAndControlsContainer.append('g')
        .attr('class', 'avatar-node')
        .style('stroke', '#000')
        .style('stroke-width', '2px')
        .on('mouseenter', avatarMouseover)
        .on('mouseleave', avatarMouseout);

      avatar.append('circle')
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', function (d: any) {
          return d._children ? LM_CHART.RADIUS + 1 : LM_CHART.RADIUS;
        });

      avatar.append('image')
        .attr('href', function(d: any) {
          let url = `/api/v1/media/avatar/${d.staff_id}?style=small`;
          if (d.avatar) {
            url = d.avatar;
          }
          return url;
        })
        .attr('clip-path', 'url(#image-clip)')
        .attr('width', LM_CHART.IMAGE_SIZE)
        .attr('height', LM_CHART.IMAGE_SIZE)
        .attr('preserveAspectRatio', 'xMidYMid slice')
        .attr('x', -LM_CHART.RADIUS)
        .attr('y', -LM_CHART.RADIUS)
        .on('click', directReportsToggler);
  
      const iconMinus = `<svg class="minus-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2 9.2" style="background: #FFFFFF;"><circle cx="4.6" cy="4.6" r="4.6" style="fill: #039BE5; stroke: transparent"/><path d="M2.6,4.1h4v1h-4V4.1z" style="fill: #FFFFFF; stroke: transparent"/></svg>`;
      const iconPlus = `<svg class="plus-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.2 9.2" style="background: #FFFFFF;"><circle cx="4.6" cy="4.6" r="4.1" style="fill: #FFFFFF; stroke: transparent"/><path d="M4.6,1c2,0,3.6,1.6,3.6,3.6S6.6,8.2,4.6,8.2S1,6.6,1,4.6C1,2.6,2.6,1,4.6,1 M4.6,0C2.1,0,0,2.1,0,4.6 s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S7.1,0,4.6,0C4.6,0,4.6,0,4.6,0L4.6,0z" style="fill: #039BE5; stroke: transparent"/><path d="M4.1,2.6h1v4h-1V2.6z" style="fill: #039BE5; stroke: transparent"/><path d="M2.6,4.1h4v1h-4V4.1z" style="fill: #039BE5; stroke: transparent"/></svg>`;
  
      avatar.append('foreignObject')
        .attr('class', 'extend-button')
        .attr('width', LM_CHART.EXT_BTN_RADIUS * 2)
        .attr('height', LM_CHART.EXT_BTN_RADIUS * 2)
        .attr('x', LM_CHART.RADIUS - LM_CHART.EXT_BTN_RADIUS)
        .attr('y', -LM_CHART.EXT_BTN_RADIUS)
        .on('click', directReportsToggler)
        .append('xhtml:body')
        .style('background', 'transparent')
        .style('border-radius', '50%')
        .style('opacity', function(d: any) {
          return d.children || d._children ? 1 : 1e-6;
        })
        .html(iconMinus + iconPlus);

      nodeEnter.append('text')
        .attr('x', LM_CHART.RADIUS + LM_CHART.STROKE_WIDTH + 2)
        .attr('dy', -3)
        .attr('text-anchor', 'end')
        .attr('class', function (d: any) {
          if (d.url !== null) {
            return 'hyper';
          }
        })
        .text((d: any) => d.name)
        .style('fill-opacity', 1e-6)
        .on('click', function (d: any) {
          if (d.staff_id){
            let url = `/staff/${d.staff_id}/work-records`;
            if (d3.event.button === 0) {
              window.open(url, '_blank');
            } else if (d3.event.button === 1) {
              window.open(url, '_blank');
            }
          }
        });

      nodeEnter.append('text')
      .attr({
        'dy': 16,
        'text-anchor': 'end',
        'class': 'position'
      })
      .attr('x', function (d: any) {
        let x = d.state.active ? LM_CHART.BIG_RADIUS : LM_CHART.RADIUS;
        x += d.state.active ? LM_CHART.STROKE_WIDTH + 2 : 3;
        return -x;
      })
      .text(function (d: any) {
        return d.position;
      })
      .call(wrap, 220);

      // Transition nodes to their new position.
      const nodeUpdate = node.transition()
        .duration(LM_CHART.EXPAND_NODE_TIME)
        .attr('transform', function (d: any){
          return `translate(${d.y},${d.x})`;
        });

      nodeUpdate.select('circle')
        .attr('r', function(d: any) {
          return d.state.active ? LM_CHART.BIG_RADIUS : LM_CHART.RADIUS;
        })
        .style('stroke', function(d: any) {
          return d.state.active ? '#039be5' : '#e0e0e0';
        })
        .style('stroke-width', function(d: any) {
          return d.state.active ? LM_CHART.STROKE_WIDTH : 1;
        });

      nodeUpdate.select('svg.minus-circle')
        .style('display', (d: any) => {
          return d.children ? 'block' : 'none';
        });
      nodeUpdate.select('svg.plus-circle')
        .style('display', (d: any) => {
          return d.children ? 'none' : (d._children ? 'block' : 'none');
        });

      nodeUpdate.select('image')
        .attr({
          'height': function(d: any) {
            return d.state.active ? LM_CHART.BIG_IMAGE_SIZE : LM_CHART.IMAGE_SIZE;
          },
          'width': function(d: any) {
            return d.state.active ? LM_CHART.BIG_IMAGE_SIZE : LM_CHART.IMAGE_SIZE;
          },
          'x': function(d: any) {
            return d.state.active ? -LM_CHART.BIG_RADIUS : -LM_CHART.RADIUS;
          },
          'y': function(d: any) {
            return d.state.active ? -LM_CHART.BIG_RADIUS : -LM_CHART.RADIUS;
          },
          'clip-path': function(d: any) {
            return d.state.active ? 'url(#image-clip-big)' : 'url(#image-clip)';
          }
        });

      nodeUpdate.selectAll('text')
        .attr('x', function(d: any) {
          let x = d.state.active ? LM_CHART.BIG_RADIUS : LM_CHART.RADIUS;
          x += d.state.active ? LM_CHART.STROKE_WIDTH + 2 : 3;
          return -x;
        })
        .style('fill-opacity', 1);
  
      nodeUpdate.selectAll('text.position > tspan')
        .attr('x', function(d: any) {
          var x = d.state.active ? LM_CHART.BIG_RADIUS : LM_CHART.RADIUS;
          x += d.state.active ? LM_CHART.STROKE_WIDTH + 2 : 3;
          return -x;
        })
        .style('fill-opacity', 1);

      nodeUpdate.select('.extend-button')
        .attr({
          'x': function(d: any) {
            let x = -LM_CHART.EXT_BTN_RADIUS;
            x += d.state.active ? LM_CHART.BIG_RADIUS : LM_CHART.RADIUS;
            return x;
          },
          'y': -LM_CHART.EXT_BTN_RADIUS
        });

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node.exit().transition()
        .duration(LM_CHART.EXPAND_NODE_TIME)
        .attr('transform', function () {
          return `translate(${source.y},${source.x})`;
        })
        .remove();

      nodeExit.select('circle')
        .attr('r', 1e-6);

      nodeExit.select('text')
        .style('fill-opacity', 1e-6);

      // Update the links…
      const link = container.selectAll('path.link')
        .data(links, (d: any) => d.target.id);

      // Enter any new links at the parent's previous position.
      link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', function () {
          let o = {x: source.x0, y: source.y0};
          return diagonal({source: o, target: o});
        });

      // Transition links to their new position.
      link.transition()
        .duration(LM_CHART.EXPAND_NODE_TIME)
        .attr('d', diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(LM_CHART.EXPAND_NODE_TIME)
        .attr('d', function () {
          let o = {x: source.x, y: source.y};
          return diagonal({source: o, target: o});
        })
        .remove();

      // Stash the old positions for transition.
      nodes.forEach(function (d: any) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    function wrap(text: any, width: any) {
      text.each(function() {
        // @ts-ignore
        let text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          x = text.attr('x'),
          dy = parseFloat(text.attr('dy')),
          tspan = text.text(null).append('tspan').attr('x', x).attr('dy', dy);
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text.append('tspan').attr('x', x).attr('dy', ++lineNumber + dy).text(word);
          }
        }
      });
    }

    function getCombinedStaffWorkRecordKey(staffId: number, wrId: number) {
      return `${staffId}-${wrId}`;
    }

    function showTooltip(state: boolean = false) {
      let opacity = Number(state);
      tooltip.transition()
        .duration(LM_CHART.TOOLTIP_TRANSITION_TIME)
        .style('opacity', opacity);
    }

    function avatarMouseover(d: any) {
      if (d.children  || d._children) {
        // @ts-ignore
        let parentNode = d3.select(this.parentNode);
        // if there is no g.control in g.node element,
        // we should add it
        if (!parentNode.select('g.control')[0][0]) {
          let controlsNode = parentNode.append('g')
            .attr('class', 'control')
            .on('mouseenter', function() {
              // @ts-ignore
              d3.select(this).transition()
                .duration(0)
                .style('opacity', 1);
            })
            .on('mouseleave', avatarMouseout);

          controlsNode.append('image')
            .attr('href', (d: any) => d.state.allSubordinatesExpanded ? iconTreeActive : iconTreeNormal)
            .attr('width', LM_CHART.CONTROL_IMAGE_SIZE + 'px')
            .attr('height', LM_CHART.CONTROL_IMAGE_SIZE + 'px')
            .attr('x', LM_CHART.RADIUS + LM_CHART.STROKE_WIDTH + 5)
            .attr('y', 10)
            .on('click', allSubordinatesToggler)
            .append('title')
            .text(controlsTitles.tree_expander);

          controlsNode.append('image')
            .attr('href', (d: any) => d.state.tooltipIsOpened ? iconInfoActive : iconInfoNormal)
            .attr('width', LM_CHART.CONTROL_IMAGE_SIZE + 'px')
            .attr('height', LM_CHART.CONTROL_IMAGE_SIZE + 'px')
            .attr('x', LM_CHART.RADIUS + LM_CHART.STROKE_WIDTH + 5)
            .attr('y', -40)
            .on('click', tooltipToggler)
            .append('title')
            .text(controlsTitles.info);
        }

        parentNode.select('g.control').transition()
          .duration(380)
          .style('opacity', 1);
      }
    }

    function avatarMouseout(d: any) {
      d.state.tooltipIsOpened = false;
      showTooltip(false);
    }

    function centerNode(source: any) {
      let scale = zoom.scale(),
        x = -source.y0 * scale + dimensions.width / 2,
        y = -source.x0 * scale + (dimensions.height - LM_CHART.MARGIN.BOTTOM) / 2;

      d3.select('g').transition()
        .duration(LM_CHART.CENTER_NODE_TIME)
        .attr('transform', 'translate(' + x + ',' + y + ')scale(' + scale + ')');

      zoom.scale(scale);
      zoom.translate([x, y]);
    }

    function directReportsToggler(d: any) {
      if (prevActiveNode) {
        prevActiveNode.state.active = false;
        update(prevActiveNode);
      }
      prevActiveNode = d;
      d.state.active = true;
      highlightThePath(d);

      if (d.state.hasSubordinates) {
        d.state.directReportsExpanded = !d.state.directReportsExpanded;
        d = childrenListToggler(d);
      }

      update(d);
      centerNode(d);
    }

    function childrenListToggler(d: any) {
      if (d.children) {
        d._children = d.children;
        d.children = null;
        d.state.directReportsExpanded = false;
      } else {
        d.children = d._children;
        d._children = null;
        d.state.directReportsExpanded = true;
      }
      return d;
    }

    function treeTraversal(d: any, shouldBeToggled: string) {
      let childrenList = d.children || d._children;
      if (childrenList) {
        childrenList.forEach((node: any) => {
          if (node[shouldBeToggled]) {
            node = childrenListToggler(node);
            node.state.allSubordinatesExpanded = shouldBeToggled === '_children';
          }
          treeTraversal(node, shouldBeToggled);
        });
      }
    }

    function allSubordinatesToggler(d: any) {
      if (d.state.hasSubordinates) {
        d3.select(d3.event.currentTarget)
          .attr('href', (d: any) => d.state.allSubordinatesExpanded ? iconTreeNormal : iconTreeActive);

        let shouldBeToggled = d.state.allSubordinatesExpanded ? 'children' : '_children';
        treeTraversal(d, shouldBeToggled);
        if (d.state.directReportsExpanded) {
          update(d);
        } else {
          directReportsToggler(d);
        }

        d.state.allSubordinatesExpanded = !d.state.allSubordinatesExpanded;
        centerNode(d);
      }
    }

    function tooltipToggler(d: any) {
      d3.select(d3.event.currentTarget)
        .attr('href', (d: any) => d.state.tooltipIsOpened ? iconInfoNormal : iconInfoActive);

      if (!d.state.tooltipIsOpened) {
        let tooltipTemplate = '',
          elTooltip = document.getElementsByClassName('lm-tree-chart--tooltip')[0] as HTMLElement,
          verticalOffset: number = elTooltip.clientHeight;

        tooltipTemplate += getTooltipItemHTML(tooltipLocalesCaptions.direct_reports, d.info.direct_count);
        tooltipTemplate += getTooltipItemHTML(tooltipLocalesCaptions.subordinates, d.info.subordinates_count);
        tooltipTemplate += getTooltipItemHTML(tooltipLocalesCaptions.avg_span, d.info.asoc);

        tooltipShouldBeShown = true;
        tooltip.html(tooltipTemplate)
          .style('left', (d3.event.pageX + 30) + 'px')
          .style('top', (d3.event.pageY - 74) + 'px');
      } else {
        tooltipShouldBeShown = false;
      }

      showTooltip(tooltipShouldBeShown);
      d.state.tooltipIsOpened = !d.state.tooltipIsOpened;
    }

    function getTooltipItemHTML (caption: string, data: number) {
      return `<div class="lm-tree-chart--tooltip__caption d-flex flex-row">
                <div class="flex-grow-1">${caption}</div>
                <div class="lm-tree-chart--tooltip__value">${data}</div>
            </div>`;
    }

    // traverse through the chain
    function highlightThePath(d: any) {
      let ancestors: any = [],
        parent = d,
        links: any;

      while (parent) {
        ancestors.push(parent.name);
        parent = parent.parent;
      }

      // reset previous highlighted path
      links = d3.selectAll('path.link');
      links.attr('class', 'link');

      // Get the matched links
      links.each((p: any, i: number) => {
        let matchedLinks = ancestors.some( (ancestor: string) => {
          return ancestor === p.target.name;}
        );

        if (matchedLinks) {
          d3.select(links[0][i])
            .attr('class', 'link link--active');
        }
      });
    }

    function expandNode(lmKeyList: any) {
      if (!lmKeyList || !cParams.staffId || !cParams.wrId) {
        return false;
      }

      const lmKeyTree = [{'staffId': cParams.staffId, 'wrId': cParams.wrId}];
      let currentCombinedLmKey = getCombinedStaffWorkRecordKey(cParams.staffId, cParams.wrId);
      while (lmKeyList.hasOwnProperty(currentCombinedLmKey) && lmKeyList[currentCombinedLmKey]) {
        let parentNode = lmKeyList[currentCombinedLmKey];
        lmKeyTree.unshift({'staffId': parentNode.staffId, 'wrId': parentNode.wrId});
        currentCombinedLmKey = getCombinedStaffWorkRecordKey(parentNode.staffId, parentNode.wrId);
      }

      let lmNode = root;
      lmKeyTree.forEach((lmKey) => {
        if(lmNode.staff_id === lmKey.staffId && lmNode.wr_id === lmKey.wrId) {
          return;
        }
        let childrenList = lmNode.children || lmNode._children;
        for (let i = 0; i < childrenList.length; i++) {
          let childrenNode = childrenList[i];
          if(childrenNode.staff_id === lmKey.staffId && childrenNode.wr_id === lmKey.wrId) {
            directReportsToggler(childrenNode);
            lmNode = childrenNode;
            break;
          }
        }
      });
    }

    expandNode(lmList);
  }, []);

  return (
    <div className="lm-tree-chart" style={{height: dimensions.height}}>
      <div className="lm-tree-chart--tooltip"></div>
      <svg ref={svgRef} height={dimensions.height} width={dimensions.width}><g></g></svg>
      <svg className="lm-tree-chart--image-clip">
        <defs>
          <clipPath id="image-clip">
            <circle cx="0" cy="0" r="14"/>
          </clipPath>
        </defs>
      </svg>
      <svg className="lm-tree-chart--image-clip-big">
        <defs>
          <clipPath id="image-clip-big">
            <circle cx="0" cy="0" r="20"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};