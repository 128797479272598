import {Value as ValueInterface} from '../interfaces/Value';

export default class ValueDictionary implements ValueInterface<string> {
  valueId: number | null = null;
  value: string = '';
  id: number | undefined  = undefined;
  description: string = '';

  getValue(): number | undefined {
    return this.id;
  }
}
