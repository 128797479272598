import * as d3 from 'd3';
import {Pie} from 'd3-shape';

interface DataSet {
  lower: Array<number>,
  upper: Array<number>
}

export function destroyDonut(id: string) {
  d3.select(`svg#${id}`).select("g").remove();
}

export default function drawDonut(id: string, value: number = 50, size: number = 70) {
  const progress: number = 0;
  const percent: number = value;
  const dataSet: DataSet = {
    lower: [0, 100],
    upper: [percent, 100 - percent]
  };
  const radius: number = size / 3;
  const pie: Pie<any, number | { valueOf(): number }> = d3.pie().sort(null);
  const format: (n: number | { valueOf(): number }) => string = d3.format(".0%");

  const arc: any = d3.arc()
    .innerRadius(radius * 0.85)
    .outerRadius(radius);

  const svg = d3.select(`svg#${id}`)
    .append("g")
    .attr("transform", `translate(${size / 2},${size / 2})`);

  let path = svg.selectAll("path")
    .data(pie(dataSet.lower))
    .enter()
    .append("path")
    .attr("class", function (d, i) {
      return "color" + i;
    })
    .attr("d", arc)
    .each(function (d: any, idx: any, n: any) {
      n[idx]._current = d;
    });

  const text = svg.append("text")
    .attr("text-anchor", "middle")
    .attr("class", "chart-legend")
    .attr("dy", ".3em");


  path.data(pie(dataSet.upper))
    .transition()
    .attrTween("d", function (d: any, idx: any, n: any) {
      const i = d3.interpolate(n[idx]._current, d);
      const i2 = d3.interpolate(progress, percent);
      n[idx]._current = i(1);
      return function (t: number) {
        text.text(format(i2(t) / 100));
        return arc(i(t));
      };
    });
}
