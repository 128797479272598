import moment from 'moment';

import { FeedbackItemResponse } from '../../interfaces/http/feedback/FeedbackItemResponse';
import { FeedbackI } from '../../interfaces/entity/Feedback';
import Feedback from '../../models/Feedback';

class FeedbackMapper {
  public creatFeedbackFromResponse(data: FeedbackItemResponse): FeedbackI {
    let feedback: FeedbackI = new Feedback();
    feedback.id = data.id;
    feedback.type = data.type;
    feedback.value = data.value;
    const author = data.author;
    if (author) {
      feedback.author = {
        id: author.id,
        fullName: author.full_name,
        firstName: author.first_name,
        lastName: author.last_name,
        status: author.status,
        alumni: author.alumni,
        companyStartDate: author.company_start_date,
      };
    }
    feedback.periodStart = data.period_start ? new Date(data.period_start) : null;
    feedback.periodEnd = data.period_end ? new Date(data.period_end) : null;
    feedback.startDate = new Date(data.start_date);
    feedback.createdAt = moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY HH:mm');
    feedback.updatedAt = new Date(data.updated_at);

    feedback.crmId = data.crm_id;
    const project = data.project;
    if (project) {
      feedback.project = {
        id: project.id,
        name: project.name,
        pmWrId: project.pm_wr_id
      }
    }
    const pm = data.project_manager;
    if (pm) {
      feedback.projectManager = {
        id: pm.id,
        fullName: pm.full_name,
        firstName: pm.first_name,
        lastName: pm.last_name,
        status: pm.status,
        alumni: pm.alumni,
        companyStartDate: pm.company_start_date,
      };
    }


    return feedback;
  }
}

export default new FeedbackMapper();
