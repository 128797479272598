import React, { FunctionComponent } from 'react';
import { HRDashboardSidebar } from '../components/HRDashboard/HRDashboardSidebar/HRDashboardSidebar';
import { HRDashboardWrapper } from '../components/HRDashboard/HRDashboardWrapper/HRDashboardWrapper';

export const HRDashboard: FunctionComponent = () => {
  return (
      <>
        <HRDashboardSidebar />
        <HRDashboardWrapper />
      </>
  );
}
