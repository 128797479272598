import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, Switch, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import client from './../services/http/StaffClient';
import staffMapper from '../mappers/response/StaffMapper';

import store from '../store/store';
import { setLoading, setStaff } from '../store/staff/actions';
import { setMainId } from '../store/workRecord/actions';
import { loaderActions } from '../store/loader/actions';
import { setOverlayIsVisible } from '../store/overlay/actions';
import { GlobalState } from '../store/types';
import { STAFF_STATE, WORK_RECORD_STATE } from '../store/reducers';

import { WRNavigation } from '../components/WR/WRNavigation/WRNavigation';
import { WRMain } from '../components/WR/WRMain/WRMain';
import { WREducation } from '../components/WR/WREducation/WREducation';
import { WRDocument } from '../components/WR/WRDocument/WRDocument';
import { WRCv } from '../components/WR/WRCv/WRCv';
import { WRFeedback } from '../components/WR/WRFeedback/WRFeedback';

import './wr-page.scss'

interface Params {
  staffId: string
}

export const WRPage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  
  const params = useParams<Params>();
  const cParams = {
    staffId: Number(params.staffId)
  };

  const wrNavRef = useRef<HTMLDivElement>(null);
  const toggleWrNav = () => {
    if (wrNavRef.current) {
      if (!wrNavRef.current.classList.contains('work-record-navigation-wrapper--hidden')) {
        wrNavRef.current.classList.add('work-record-navigation-wrapper--hidden');
      } else {
        wrNavRef.current.classList.remove('work-record-navigation-wrapper--hidden');
      }
    }
  };

  const { mainId } = useSelector((state: GlobalState) => state[WORK_RECORD_STATE]);
  const { staff, shouldUpdate: staffForceUpdate } = useSelector((state: GlobalState) => state[STAFF_STATE]);

  useEffect(() => {
    if (cParams?.staffId !== staff?.id || staffForceUpdate) {
      store.dispatch(setOverlayIsVisible(true));
      store.dispatch(loaderActions.showLoader());
      store.dispatch(setLoading(true));
      client.get(cParams.staffId).then((data) => {
        if (!data) {
          return;
        }
        const staffR = staffMapper.creatStaffFromStaffResponse(data);
        store.dispatch(setStaff(staffR));

        const wrId = staffR.activeWRs.length ? staffR.activeWRs[0].id : staffR.inactiveWRs[0].id;
        store.dispatch(setMainId(wrId));
        store.dispatch(setOverlayIsVisible(false));
        store.dispatch(loaderActions.hideLoader());
        setIsLoaded(true);
      });
    }

  }, [cParams.staffId, staffForceUpdate]);
  
  if (isLoaded) {
    return (
      <div className="content">
        <div className="h-100 row no-gutters">
          <div className="work-record-navigation-toggle" onClick={ () => toggleWrNav() }>
            <FA icon={['fas', 'user']} size="lg"/>
          </div>
          <div className="col-auto h-100">
            <div className="work-record-navigation-wrapper work-record-navigation-wrapper--hidden" ref={ wrNavRef }>
              { staff && <WRNavigation /> }
            </div>
          </div>
          <div className="col h-100">
            <Switch>
              <Route exact={ true } path={ `/staff/${cParams.staffId}/work-records` }>
                { mainId && <Redirect to={ `/staff/${cParams.staffId}/work-records/${mainId}/main` } /> }
              </Route>
              <Route path='/staff/:staffId/work-records/:wrId/main' component={ WRMain } />
              <Route path='/staff/:staffId/work-records/:wrId/education' component={ WREducation } />
              <Route path='/staff/:staffId/work-records/:wrId/documents' component={ WRDocument } />
              <Route path='/staff/:staffId/work-records/:wrId/feedbacks' component={ WRFeedback } />
              <Route path='/staff/:staffId/work-records/:wrId/cv' component={ WRCv } />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
  
  return <></>;
}
