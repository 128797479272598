import {Staff as StaffInterface} from '../../interfaces/Staff';
import {WorkRecord as WorkRecordInterface} from '../../interfaces/WorkRecord';
import {ROLES, TRIAL_STATUSES} from '../../constants/WORK_RECORD';

export const CV_PERMISSION_CREATE = 'CREATE';
export const CV_PERMISSION_EDIT = 'EDIT';
export const CV_PERMISSION_APPROVE = 'APPROVE';
export const CV_PERMISSION_VIEW_PENDING = 'VIEW_PENDING';
export const CV_PERMISSION_VIEW_APPROVED = 'VIEW_APPROVED';
export const CV_PERMISSION_DOWNLOAD_APPROVED = 'DOWNLOAD_APPROVED';
export const CV_PERMISSION_DOWNLOAD_PENDING = 'DOWNLOAD_PENDING';
export const CV_PERMISSION_REQUEST_UPDATE = 'REQUEST_UPDATE';

type CvPermissionType = typeof CV_PERMISSION_CREATE
  | typeof CV_PERMISSION_EDIT
  | typeof CV_PERMISSION_APPROVE
  | typeof CV_PERMISSION_VIEW_PENDING
  | typeof CV_PERMISSION_VIEW_APPROVED
  | typeof CV_PERMISSION_DOWNLOAD_PENDING
  | typeof CV_PERMISSION_DOWNLOAD_APPROVED
  | typeof CV_PERMISSION_REQUEST_UPDATE;

class PermissionWRService {
  private params: any;
  private user: StaffInterface | null = null;

  public setParams(params: any) {
    this.params = params;
  }

  public setUser(user: StaffInterface) {
    this.user = user;
  }

  public hasRole(roles: Array<any>): boolean {
    let userRoles = this.user?.permissions.roles || [];
    //every
    return userRoles.some((role: string) => roles.includes(role));
  }

  public isGranted(elementName: string, action: string, wr: WorkRecordInterface | null = null) {
    let granted = false;
    if (elementName === 'cv') {
      return granted;
    }
    let userPermissions = (this.user)
      ? (this.user.permissions.attributes) ? this.user.permissions.attributes : {}
      : {};
    //@ts-ignore
    let permissions: any = userPermissions[elementName] ?? [];
    if (action === 'view' && elementName.startsWith('trial') && wr) {
      return this.isGrantedToViewTrialPeriod(permissions, wr);
    }
    Object.keys(permissions).forEach((roleName: string) => {
      // business logic:
      // 1. Not an owner of profile - apply all roles permissions except role Owner
      // 2. Not LM of wr - apply all roles permissions except role LM
      if (granted || (roleName === ROLES.OWNER && !this.isOwner()) ||
        (wr && !this.isLM(wr) && roleName === ROLES.LM)) {
        return;
      }
      granted = permissions[roleName].indexOf(action.toUpperCase()) !== -1;
    });

    return granted;
  }

  public isGrantedToViewTrialPeriod(permissions: any, wr: WorkRecordInterface) {
    let trialStatus = wr.trialStatus;
    // should be shown for all roles when trial status is 'in progress' or 'pending'
    if (trialStatus === TRIAL_STATUSES.IN_PROGRESS.name || trialStatus === TRIAL_STATUSES.PENDING.name) {
      return true;
    }
    // should be always shown for HR, PP and Admin roles
    if (permissions[ROLES.HR] || permissions[ROLES.PP] || permissions[ROLES.ADMIN]) {
      return true;
    }
    return false;
  }

  public isGrantedEditWR() {
    if (this.hasRole([ROLES.HR, ROLES.HR_REC, ROLES.PP])) {
      return true;
    }
    return false;
  }

  public isGrantedArrayAttrs(attributes: Array<any>, action: string, wr: WorkRecordInterface): boolean {
    let hasPermission = false;
    attributes.forEach((value) => {
      hasPermission = hasPermission || this.isGranted(value, action, wr);
    });
    return hasPermission;
  }

  public isGrantedCv(action: CvPermissionType, wr: WorkRecordInterface): boolean {
    let granted = false;

    // @ts-ignore
    const cvPermissions = this.user?.permissions?.attributes?.cv || [];

    Object.keys(cvPermissions).forEach((roleName: string) => {
      if (granted
        || (roleName === ROLES.OWNER && !this.isOwner())
        || (roleName === ROLES.LM && !this.isLM(wr))) {
        return;
      }
      granted = cvPermissions[roleName].indexOf(action.toUpperCase()) !== -1;
    });

    return granted;
  }

  public isOwner() {
    const staffId = (this.params && this.params.staffId) ? parseInt(this.params.staffId) : 0;
    return this.user && (staffId === this.user.id);
  }

  public isLM(wr: WorkRecordInterface): boolean {
    //@ts-ignore
    let subordinates: Array<number> = this.user.subordinates || [];
    let isLineManager = false;
    subordinates.forEach((subordinateId) => {
      isLineManager = isLineManager || subordinateId === (wr && wr.id);
    });

    return isLineManager;
  }
}

export default new PermissionWRService();
