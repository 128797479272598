import {WorkRecord} from '../../../../interfaces/WorkRecord';

export interface NextEmployerStateInterface {
  nextEmployer: string;
}

const initialState = {
  nextEmployer: '',
}

export default class PositionDescriptionState {
  public static generateFromWorkRecord(workRecord?: WorkRecord): NextEmployerStateInterface {
    let state = initialState;
    if (workRecord) {
      state.nextEmployer = workRecord.nextEmployer.value;
    }
    return state;
  }
}
