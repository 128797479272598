import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import store from '../../../store/store';
import { setWorkRecord } from '../../../store/workRecord/actions';

import { WorkRecord as WorkRecordInterface } from './../../../interfaces/WorkRecord';
import { SkillSetInterface } from '../../../interfaces/SkillSetInterface';
import client from './../../../services/http/WorkRecordClient';
import SkillMapper from './../../../mappers/response/SkillMapper';
import WorkRecordMapper from './../../../mappers/response/WorkRecordMapper';

import { WRAttributes } from '../WRAttributes/WRAttributes';
import { WRSkillTree } from '../WRSkillTree/WRSkillTree';
import { DIRECTION_ROW, MODE_PREVIEW, WRNavigationStaff } from '../../Staff/WRNavigationStaff/WRNavigationStaff';

import './wr-preview.scss';

interface WRPreviewProps {
  selectedWRId: number | null;
  setSelectedWRId: Function;
  showWRPreview: boolean;
  setShowWRPreview: Function;
}

export const WRPreview: React.FC<WRPreviewProps> = (props: WRPreviewProps) => {
  const { selectedWRId, setSelectedWRId, showWRPreview, setShowWRPreview } = { ...props };

  const [workRecord, setWR] = useState<WorkRecordInterface | null>(null);
  const [skills, setSkills] = useState<SkillSetInterface>({
    hardSkills: [],
    softSkills: [],
    languageSkills: []
  });

  useEffect(() => {
    if (selectedWRId) {
      client.get(selectedWRId)
        .then((data: any) => {
          let wr = WorkRecordMapper.creatWorkRecordFromStaffResponse(data);
          store.dispatch(setWorkRecord(wr));
          setWR(wr);
          return client.getSkills(selectedWRId);
        })
        .then((data: any) => {
          setSkills(SkillMapper.convertSkillsToSet(data));
          setShowWRPreview(true);
        });
    }
  }, [selectedWRId]);

  function closeWRPreview() {
    setShowWRPreview(false);
    setSelectedWRId(null);
  }

  if (workRecord) {
    return (
      <div className={`wr-preview ${showWRPreview ? "d-block" : "d-none"}`}>
        <div className="wr-preview__header d-flex flex-row">
          <div className="col-4 pl-0">
            <WRNavigationStaff mode={ MODE_PREVIEW } direction={ DIRECTION_ROW } />
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <a className="edb-btn edb-btn--secondary"
                   href={`/staff/${workRecord.staffId}/work-records`}
                   target="_blank"
                   rel="noreferrer"
                >
                  View Profile
                </a>
                <FA icon={['fas', 'times']} className="wr-preview__header-close-icon" onClick={ closeWRPreview } />
              </div>
            </div>
          </div>
        </div>
        <div className="wr-preview__content">
          <div className="row">
            <div className="col-8">
              <div className="edb-wr-preview__content--position">
                <div className="edb-wr__title">
                  POSITION DESCRIPTION
                </div>
                <WRAttributes mode={MODE_PREVIEW} />
              </div>
            </div>
            <div className="col-4">
              <div className="edb-wr__title">
                HARD SKILLS
              </div>
              <WRSkillTree skills={skills.hardSkills}/>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <div className="edb-wr__title">
                SOFT SKILLS
              </div>
              <WRSkillTree skills={skills.softSkills}/>
            </div>
            <div className="col">
              <div className="edb-wr__title">
                LANGUAGES
              </div>
              <WRSkillTree skills={skills.languageSkills}/>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }

  return <Fragment/>;
};