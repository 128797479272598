import React, {FunctionComponent} from 'react';

interface Props {
  periodFilter: any,
  activePeriod: any,
  setActivePeriod: any,
}

export const HeadcountByPeriod: FunctionComponent<Props> = (props: Props) => {
  const { periodFilter, activePeriod, setActivePeriod } = props;

  return (
      <div>
        <span className="custom-label">Group by</span>
        <span className="buttons-list">
          {periodFilter && Object.entries(periodFilter).map((value: any, i: number) => {
            const period = value[0];
            const periodTitle = value[1].slice(3);
            return (
              <span
                className={`button ${period === activePeriod ? ' active' : ''}`}
                onClick={() => setActivePeriod(period)}
                key={i}>
                {periodTitle}
              </span>
            )
          })}
        </span>
      </div>
  );
}
