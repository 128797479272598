import {
  OVERLAY_SET_IS_VISIBLE,
  OVERLAY_SET_PARAMS,
  OverlayState,
  OverlayTypes} from './types';

const defaultOverlayState: OverlayState = {
  isVisible: false,
  params: {
    isExtra: false,
    isFullscreen: false
  }
}

export const overlayReducer = (state: OverlayState = defaultOverlayState, action: OverlayTypes) => {
  switch (action.type) {
    case OVERLAY_SET_IS_VISIBLE:
      return {...state, isVisible: action.payload};
    case OVERLAY_SET_PARAMS:
      return {...state, params: action.payload};
    default:
      return state;
  }
}