import React from 'react';

interface SkillCellProps {
  cell: {
    value: Array<string>;
  };
}

export const SkillCell: React.FC<SkillCellProps> = (props: SkillCellProps) => {
  const cell = props.cell;
  const value: Array<string> = cell.value;
  
  if (value?.length) {
    return (
      <div className="skill">
        {
          value.slice(0, 3).map((item: string, index: number) => {
            return (<p key={ index } className="m-0">{ item }</p>)
          })
        }
      </div>
    )
  }
  return null;
}
