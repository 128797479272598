import React, { FunctionComponent, useEffect, useState } from 'react';
import { ColumnInstance } from 'react-table';
import { Overlay, Popover, Tab, Tabs } from 'react-bootstrap';

import EmployeeTableHelper from '../../../services/data/EmployeeTableHelper';
import DictionaryClient from '../../../services/http/DictionaryClient';

// components
import { EmployeeTableExport } from './EmployeeTableExport';
import { TableSettingsIcon } from '../../Icon/TableSettingsIcon';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';

// hooks
import useOutsideClick from '../../../hooks/outsideClick';

// constants
import { TABLE_NAMES } from '../../../constants';

// styles
import './employee-table-settings.scss';

export type TableNameType = typeof TABLE_NAMES.ALL | typeof TABLE_NAMES.SUBORDINATES | typeof TABLE_NAMES.DIRECT;

interface EmployeeTableSettingsProps {
  columns: Array<any>;
  columnsTable: Array<any>;
  data: Array<any>;
  asOfDate: Date;
  csvDataFilePrefix: string;
  tableName: TableNameType;
  setIsLoadingShared: Function;
}

const groupTitles: any = {
  'staff' : 'Profile',
  'work-record': 'Work Record'
};

export const EmployeeTableSettings: React.FC<EmployeeTableSettingsProps> = (props: EmployeeTableSettingsProps) => {
  const { columns, data, asOfDate, setIsLoadingShared, csvDataFilePrefix } = { ...props };

  const [poolIdToShow, setPoolIdToShow] = useState<Array<string>>([]);
  const [poolIdToHide, setPoolIdToHide] = useState<Array<string>>([]);
  const [target, setTarget] = useState(null);
  const [searchColumnsStr, setSearchColumnsStr] = useState<string>('');
  const { ref, isVisible, setIsVisible } = useOutsideClick(false);
  const [isAllColumnsChecked, setIsAllColumnsChecked] = useState<boolean>(false);
  const [groups, setGroups] = useState<Array<string>>([]);
  const [columnGroups, setColumnGroups] = useState<any>({});

  useEffect(() => {
    if (!isVisible) {
      setIsVisible(false);
      setTarget(null);
      setSearchColumnsStr('');
    }
  }, [isVisible]);

  useEffect(() => {
    defineIsAllColumnsChecked();
  }, [poolIdToShow, poolIdToHide]);

  const handleClick = (event: any) => {
    setIsVisible(!isVisible);
    setTarget(event.target);
  };
  
  useEffect(() => {
    if (!columns) return;
    const columnsByGroup: any = {};
    columns.map((column) => {
      if (typeof columnsByGroup[column.group] === 'undefined') {
        columnsByGroup[column.group] = [];
      }
      if (column.Header.toLowerCase().includes(searchColumnsStr.toLowerCase())) {
        columnsByGroup[column.group].push(column);
      }
    });
    for (const key in columnsByGroup) {
      columnsByGroup[key].sort((c1: ColumnInstance, c2: ColumnInstance) => {
        const h1: string = (c1.Header ?? '') as string;
        const h2: string = (c2.Header ?? '') as string;
        return h1.localeCompare(h2);
      });
    }
    setColumnGroups(columnsByGroup);
    setGroups(Object.keys(columnsByGroup));
  }, [columns, searchColumnsStr]);

  const resetToDefault = () => {
    setIsAllColumnsChecked(false);
    setPoolIdToShow([]);
    setPoolIdToHide([]);
    groups.forEach((key) => {
      columnGroups[key].map((column: any) => {
        if (column.default) {
          setPoolIdToShow(poolId => [...poolId, column.id]);
        } else {
          setPoolIdToHide(poolId => [...poolId, column.id]);
        }
      });
    });
  }

  const resetToRequired = () => {
    setIsAllColumnsChecked(false);
    setPoolIdToShow([]);
    setPoolIdToHide([]);
    groups.forEach((key) => {
      columnGroups[key].map((column: any) => {
        if (column.required) {
          setPoolIdToShow(poolId => [...poolId, column.id]);
        } else {
          setPoolIdToHide(poolId => [...poolId, column.id]);
        }
      });
    });
  }

  const toggleAllCheckboxes = (toggle: boolean) => {
    setIsAllColumnsChecked(toggle);
    if (toggle) {
      setPoolIdToShow([]);
      setPoolIdToHide([]);
      groups.forEach((key) => {
        columnGroups[key].map((column: any) => {
          setPoolIdToShow(poolId => [...poolId, column.id]);
        });
      });
    } else {
      resetToRequired();
    }
  }

  const onOkClick = () => {
    setIsLoadingShared(true);
    poolIdToShow.map((id: string) => {
      const column: ColumnInstance = columns.find(col => {
        return col.id == id;
      });
      const columnTable: any = props.columnsTable.find(col => {
        return col.accessor == id;
      });

      if (!column.isVisible) {
        column.toggleHidden();
        columnTable.isVisible = true;
      }
    });
    poolIdToHide.map((id: string) => {
      const column: ColumnInstance = columns.find(col => {
        return col.id == id;
      });
      const columnTable: any = props.columnsTable.find(col => {
        return col.accessor == id;
      });
      
      if (column.isVisible) {
        column.toggleHidden();
        columnTable.isVisible = false;
      }
    });
    setIsVisible(false);
    setTarget(null);
    setPoolIdToShow([]);
    setPoolIdToHide([]);
    setTimeout(() => {
      const preparedColumns = EmployeeTableHelper.prepareForSavingConfig(columns);
      DictionaryClient.saveTableConfig(preparedColumns, props.tableName)
        .then((response: any) => {})
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoadingShared(false);
        })
    }, 400);
  }

  const onCancelClick = () => {
    setIsVisible(false);
    setTarget(null);
    setPoolIdToShow([]);
    setPoolIdToHide([]);
  }
  
  const decorateToggleHiddenProps = (column: ColumnInstance) => {
    const toggleHiddenProps = column.getToggleHiddenProps();
    toggleHiddenProps.onChange = (e: any) => {
      toggleHiddenProps.checked = e.target.checked;
      if (e.target.checked) {
        if (!poolIdToShow.includes(column.id)) {
          setPoolIdToShow(poolId => [...poolId, column.id]);
        }
        setPoolIdToHide(poolIdToHide.filter((id) => {
          return id !== column.id;
        }));
      } else {
        setIsAllColumnsChecked(false);
        if (!poolIdToHide.includes(column.id)) {
          setPoolIdToHide(poolId => [...poolId, column.id]);
        }
        setPoolIdToShow(poolIdToShow.filter((id) => {
          return id !== column.id;
        }));
      }
    };

    toggleHiddenProps.checked = column.isVisible || poolIdToShow.includes(column.id);
    if (poolIdToHide.includes(column.id)) {
      toggleHiddenProps.checked = false;
    }

    return toggleHiddenProps;
  }

  const defineIsAllColumnsChecked = () => {
    if (!columns.length) {
      return;
    }

    let isAllChecked = true;
    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];
      if((!column.isVisible && !poolIdToShow.includes(column.id)) || poolIdToHide.includes(column.id)) {
        isAllChecked = false
        break;
      }
    }

    setIsAllColumnsChecked(isAllChecked);
  }

  return (
    <div className="table-settings-wrapper" ref={ref}>
      <ElementWithPermission element="exportToCSV">
        <EmployeeTableExport
          columns={ columns }
          data={ data }
          asOfDate={ asOfDate }
          setIsLoadingShared={ setIsLoadingShared }
          csvDataFilePrefix={ csvDataFilePrefix }
        />
      </ElementWithPermission>
      <span onClick={ handleClick } className={`table-settings-button popup-trigger${isVisible ? ' active' : ''}`}>
        <TableSettingsIcon/> Table settings
      </span>

      <Overlay
        show={isVisible}
        target={target}
        placement="bottom"
        container={ ref.current }
        containerPadding={0}>
        <Popover id="allEmployeeTableSettingsPopover">
          <span className="table-settings__reset-button" onClick={resetToDefault}>Reset to default</span>
          <input type="text" className="table-settings__search-field" placeholder="Search" onChange={(event) => {
            setSearchColumnsStr(event.currentTarget.value || '');
          }}/>
          <Popover.Content>
            <Tabs defaultActiveKey={groups[0]} id="allEmployeeTableSettings">
              {
                groups.map((group: string) => (
                    <Tab eventKey={group} title={groupTitles[group]} key={group} className="group-tab">
                      {
                        columnGroups[group].map((column: any) => (
                          <div key={ column.id }>
                            <label>
                              <input type="checkbox" { ...decorateToggleHiddenProps(column) } disabled={column?.required}/>{' '}
                              {column.Header}
                            </label>
                          </div>
                        ))
                      }
                    </Tab>
                  )
                )
              }
            </Tabs>
          </Popover.Content>
          <div className="pure-g table-settings__bottom">
            <div className="pure-u-1-3 table-settings__select-all">
              <label className="ng-binding">
                <input type="checkbox" className="checkbox" checked={isAllColumnsChecked} onChange={(event) => {
                  toggleAllCheckboxes(event.currentTarget.checked);
                }}/>
                Select All
              </label>
            </div>
            <div className="pure-u-2-3 table-settings__buttons-group">
              <button className="edb-big-button" onClick={ onOkClick }>Ok</button>
              <button className="edb-big-button edb-big-button--nobackground" onClick={ onCancelClick }>Cancel</button>
            </div>
          </div>
        </Popover>
      </Overlay>
    </div>
  );
}
