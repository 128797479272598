import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../store/types';
import { WORK_RECORD_STATE } from '../../../../store/reducers';
import { dispatchPopupState } from '../../../../store/popup/types';

import { FeedbackListContext } from '../../../WR/WRFeedback/WRFeedback';
import { FeedbackI } from '../../../../interfaces/entity/Feedback';
import Feedback from '../../../../models/Feedback';
import feedbackHelper from '../../../../services/data/FeedbackHelper';
import FeedbackMapper from '../../../../mappers/response/FeedbackMapper';
import { FeedbackItemForm } from './FeedbackItemForm';
import { FeedbackItemStateInterface } from './FeedbackItemState';

import { ATTRIBUTE_NAMES, ATTRIBUTES_MAP } from '../../../../constants';

interface AddFeedbackFormWrapperProps {
  popupName: string;
}

export const AddFeedbackFormWrapper: React.FC<AddFeedbackFormWrapperProps> = (props: AddFeedbackFormWrapperProps) => {
  const { popupName } = { ...props };
  const wrId = useSelector((state: GlobalState) => state[WORK_RECORD_STATE].activeId);
  const feedback: FeedbackI = new Feedback();
  const context = useContext(FeedbackListContext);
  const [saving, setSaving] = useState(false);
  const create = (values: FeedbackItemStateInterface, setFieldError: Function, setFieldTouched: Function) => {
    setSaving(true);
    Object.keys(ATTRIBUTE_NAMES.feedbackItem).forEach((key) => {
      setFieldTouched(key, true);
    });
    feedbackHelper.create(feedback, values, wrId)
      .then((response: any) => {
        const f: FeedbackI = FeedbackMapper.creatFeedbackFromResponse(response.data);
        context.updateList();
        dispatchPopupState(popupName, false);
      })
      .catch((error: any) => {
        let data = error.data;
        if (data.length) {
          data.forEach((item: any) => {
            setFieldError(ATTRIBUTES_MAP.feedbackItem[item.property_path], item.message);
          });
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <FeedbackItemForm
      submitFunction={ create }
      feedback={ feedback }
      popupName={ popupName }
      saving={ saving }
      handleCancel={() => {
        dispatchPopupState(props.popupName, false)
      }}
    />
  );
};
