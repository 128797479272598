import {
  POPUP_SET_IS_ACTIVATE_WORK_RECORD_SHOWN,
  POPUP_SET_IS_ADD_EDUCATION_SHOWN,
  POPUP_SET_IS_ADD_FEEDBACK_SHOWN,
  POPUP_SET_IS_ADD_NEW_WORK_RECORD_SHOWN,
  POPUP_SET_IS_CLOSE_WORK_RECORD_SHOWN,
  POPUP_SET_IS_DOCUMENT_SHOWN,
  POPUP_SET_IS_EDIT_EDUCATION_SHOWN,
  POPUP_SET_IS_EDIT_FEEDBACK_SHOWN,
  POPUP_SET_IS_EDIT_PROFILE_SHOWN,
  POPUP_SET_IS_EDIT_SKILL_SHOWN,
  POPUP_SET_IS_NEXT_EMPLOYER_SHOWN,
  POPUP_SET_IS_POSITION_DESCRIPTION_SHOWN,
  POPUP_SET_IS_REASON_TO_LEAVE_SHOWN,
  POPUP_SET_IS_TRIAL_STATUS_SHOWN,
  POPUP_SET_IS_TURNOVER_SHOWN,
  PopupTypes
} from './types';

export function setIsEditProfileShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_EDIT_PROFILE_SHOWN,
    payload: isShown
  }
}

export function setIsPositionDescriptionShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_POSITION_DESCRIPTION_SHOWN,
    payload: isShown
  }
}

export function setIsAddNewWorkRecordShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_ADD_NEW_WORK_RECORD_SHOWN,
    payload: isShown
  }
}

export function setIsActivateWorkRecordShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_ACTIVATE_WORK_RECORD_SHOWN,
    payload: isShown
  }
}

export function setIsCloseWorkRecordShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_CLOSE_WORK_RECORD_SHOWN,
    payload: isShown
  }
}

export function setIsTurnoverShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_TURNOVER_SHOWN,
    payload: isShown
  }
}

export function setIsTrialStatusShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_TRIAL_STATUS_SHOWN,
    payload: isShown
  }
}

export function setIsReasonToLeaveShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_REASON_TO_LEAVE_SHOWN,
    payload: isShown
  }
}

export function setIsNextEmployerShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_NEXT_EMPLOYER_SHOWN,
    payload: isShown
  }
}

export function setIsAddEducationShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_ADD_EDUCATION_SHOWN,
    payload: isShown
  }
}

export function setIsEditEducationShown(index: string, isShown: boolean): PopupTypes {
  const payload: Map<string, boolean> = new Map<string, boolean>();
  payload.set(index, isShown);
  return {
    type: POPUP_SET_IS_EDIT_EDUCATION_SHOWN,
    payload: payload
  }
}

export function setIsAddFeedbackShown(isShown: boolean): PopupTypes {
  return {
    type: POPUP_SET_IS_ADD_FEEDBACK_SHOWN,
    payload: isShown
  }
}

export function setIsEditFeedbackShown(index: string, isShown: boolean): PopupTypes {
  const payload: Map<string, boolean> = new Map<string, boolean>();
  payload.set(index, isShown);
  return {
    type: POPUP_SET_IS_EDIT_FEEDBACK_SHOWN,
    payload: payload
  }
}

export function setIsEditSkillShown(index: string, isShown: boolean): PopupTypes {
  const payload: Map<string, boolean> = new Map<string, boolean>();
  payload.set(index, isShown);
  return {
    type: POPUP_SET_IS_EDIT_SKILL_SHOWN,
    payload: payload
  }
}

export function setIsDocumentShown(index: string, isShown: boolean): PopupTypes {
  const payload: Map<string, boolean> = new Map<string, boolean>();
  payload.set(index, isShown);
  return {
    type: POPUP_SET_IS_DOCUMENT_SHOWN,
    payload: payload
  }
}
