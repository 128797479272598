import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import {Staff} from '../../../../interfaces/Staff';
import {WorkRecord as WorkRecordInterface} from '../../../../interfaces/WorkRecord';
import WorkRecord from '../../../../models/WorkRecord';
import ValueNumber from '../../../../models/ValueNumber';

import WorkRecordMapper from '../../../../mappers/response/WorkRecordMapper';
import wrHelper from '../../../../services/data/WorkRecordHelper';

import AddWorkRecordState, {AddWorkRecordStateInterface} from './AddWorkRecordState';
import {schema} from './AddWorkRecordFormSchema';
import {PositionDescriptionFormFields} from '../PositionDescription/PositionDescriptionFormFields';
import PositionDescriptionState from '../PositionDescription/PositionDescriptionState';
import SkillState from '../Skill/SkillState';
import {SkillFormFields} from '../Skill/SkillFormFields';

import {PopupCloser} from '../../../Popup/PopupCloser';

import store from "../../../../store/store";
import {GlobalState} from '../../../../store/types';
import {dispatchPopupState} from '../../../../store/popup/types';
import {setShouldUpdate} from "../../../../store/staff/actions";

import { ATTRIBUTES_MAP, TRIAL_STATUSES } from '../../../../constants';

import '../PositionDescription/position-description-form.scss';

const attributes = ATTRIBUTES_MAP.workRecord;
const fullUserAttributes = {...ATTRIBUTES_MAP.staff, ...ATTRIBUTES_MAP.workRecord};

interface AddWorkRecordFormProps {
  popupName: string;
}

const pages = [0, 1];

export const AddWorkRecordForm: React.FC<AddWorkRecordFormProps> = (props: AddWorkRecordFormProps) => {
  const { popupName } = { ...props };
  const [page, setPage] = useState<number>(0);
  const [initialState, setInitialState] = useState<AddWorkRecordStateInterface | null>(null);
  const [formErrors, setFormErrors] = useState<Array<string>>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const history = useHistory();

  const staff: Staff = useSelector((state: GlobalState) => state.staff.staff);
  const workRecord = new WorkRecord();
  workRecord.staffId = staff.id;
  workRecord.trialStatus = TRIAL_STATUSES.IN_PROGRESS.name;
  workRecord.maxTotalLoad = new ValueNumber(100);

  const validatePosition = (values: object, errors: any, setFieldError: Function, setFieldTouched: Function) => {
    Object.keys(values).forEach((key) => {
      setFieldTouched(key, true);
    });
    if (!Object.keys(errors).length) {
      wrHelper.validate(workRecord, values).then((data: any) => {
        setPage(page + 1);
      }).catch((error: any) => {
        let data = error.data, fetchedFormErrors = formErrors;
        if (data.required) {
          Object.keys(data.required).forEach((key) => {
            setFieldError(attributes[key], data.required[key]);
            fetchedFormErrors.push(fullUserAttributes[key]);
          });
        }
        if (data.start_date) {
          setFieldError('startDate', data.start_date);
          fetchedFormErrors.push('startDate');
        }
        setFormErrors(fetchedFormErrors);
      });
    }
  }

  const createWorkRecord = (values: object) => {
    setIsSaving(true);
    wrHelper.create(workRecord, values).then((data: any) => {
      const wr: WorkRecordInterface = WorkRecordMapper.creatWorkRecordFromStaffResponse(data, workRecord.staffId);
      store.dispatch(setShouldUpdate(true));
      history.push(`/staff/${workRecord.staffId}/work-records/${wr.id}/main`)
      dispatchPopupState(popupName, false);
    });
  }

  useEffect(() => {
    if (initialState == null) {
      const state = AddWorkRecordState.generateFromWRnSkills(
        PositionDescriptionState.generateFromWorkRecord(workRecord),
        SkillState.generateFromWorkRecord(workRecord)
      )
      setInitialState(state);
    }
  }, [initialState])

  if (initialState) {
    return (
      <Formik
        validateOnBlur={ true }
        initialValues={ initialState }
        validationSchema={schema}
        onSubmit={values => createWorkRecord(values)}>
        {({
            values,
            errors,
            touched,
            setFieldError,
            setFieldTouched,
            setFieldValue,
            validateForm
        }) => (
          <Form className="edb-form form-add-work-record">
            <div className="form-fields">
              {
                (page === 0)
                  ? (
                    <PositionDescriptionFormFields
                      values={ values }
                      workRecord={ workRecord }
                      staff={ staff }
                      mode={ "MODE_WR_ADD" }
                      formErrors={ formErrors }
                      errors={ errors }
                      touched={ touched }
                      setFieldValue={ setFieldValue }
                    />
                  )
                  : <></>
              }
              {(page === 1) ? <SkillFormFields values={values}/> : <></>}
            </div>
            <div className="form-footer">
              <div className="form-buttons">
                {(page === pages[0]) ?
                  <PopupCloser popupName={popupName}>
                    <button className="edb-btn edb-btn--secondary" type="reset">
                      Cancel
                    </button>
                  </PopupCloser> : <></>
                }
                {(page !== 0) ?
                  <button
                    className="edb-btn edb-btn--secondary"
                    type="button"
                    onClick={ () => setPage(page - 1) }
                    data-testid="btnPrevious"
                  >
                    Previous
                  </button> : <></>
                }
                {
                  (page < pages.length - 1)
                    ? (
                      <button
                        className="edb-btn edb-btn--primary"
                        type="button"
                        onClick={() => validateForm().then((errors) => {
                          const keys = Object.keys(errors);
                          if (keys.length > 0) {
                            const errorElement = document.querySelector(`[id="${keys[0]}"]`) as HTMLElement
                              || document.querySelector(`[id="${keys[0]}Wrapper"]`) as HTMLElement;
                            if (errorElement) errorElement.focus();
                          }
                          return validatePosition(values, errors, setFieldError, setFieldTouched);
                        })}
                        data-testid="btnNext"
                      >
                        Next
                      </button>
                    )
                    : <></>
                }
                {(page === pages.length - 1) ?
                  <button
                    className="edb-btn edb-btn--primary"
                    type="submit"
                    data-testid="btnSave"
                    disabled={isSaving}
                  >
                    Save
                  </button> : <></>
                }
              </div>
            </div>
          </Form>

        )}
      </Formik>
    );
  }
  return <></>;
}
