import {MT_SET_DIRECT_ATTRIBUTES,
  MT_SET_DIRECT_ATTRIBUTES_DEFAULT,
  MT_SET_SUBORDINATES_ATTRIBUTES,
  MT_SET_SUBORDINATES_ATTRIBUTES_DEFAULT,
  MtTypes} from './types';

export function setMtSubordinatesAttributes(subordinatesAttributes: Array<any>): MtTypes {
  return {
    type: MT_SET_SUBORDINATES_ATTRIBUTES,
    payload: {
      subordinatesAttributes: subordinatesAttributes
    }
  }
}

export function setMtSubordinatesAttributesDefault(subordinatesAttributesDefault: Array<any>): MtTypes {
  return {
    type: MT_SET_SUBORDINATES_ATTRIBUTES_DEFAULT,
    payload: {
      subordinatesAttributesDefault: subordinatesAttributesDefault
    }
  }
}

export function setMtDirectAttributes(directAttributes: Array<any>): MtTypes {
  return {
    type: MT_SET_DIRECT_ATTRIBUTES,
    payload: {
      directAttributes: directAttributes
    }
  }
}

export function setMtDirectAttributesDefault(directAttributesDefault: Array<any>): MtTypes {
  return {
    type: MT_SET_DIRECT_ATTRIBUTES_DEFAULT,
    payload: {
      directAttributesDefault: directAttributesDefault
    }
  }
}

