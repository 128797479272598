import {defaultSelectKeyValue, defaultSelectValue} from '../../../../interfaces/form/SelectValueInterface';
import {ProfileStateInterface} from '../EditProfile/ProfileState';
import {PositionDescriptionStateInterface} from '../../../WR/Forms/PositionDescription/PositionDescriptionState';
import {SkillStateInterface} from '../../../WR/Forms/Skill/SkillState';

export interface EmployeeFormStateInterface extends ProfileStateInterface,
  PositionDescriptionStateInterface,
  SkillStateInterface {
}

const initialState: EmployeeFormStateInterface = {
  username: '',
  photo: '',
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: null,
  gender: '',
  email: '',
  skype: '',
  phone1: '',
  phone2: '',
  emergContactName: '',
  emergContactPhone: '',
  facebook: '',
  linkedIn: '',
  wiki: '',
  companyStartDate: null,
  alumni: false,
  country: '',
  city: '',
  employment: defaultSelectValue,
  startDate: null,
  endDate: null,
  position: defaultSelectValue,
  seniority: defaultSelectKeyValue,
  unit: defaultSelectValue,
  location: defaultSelectValue,
  costLocation: defaultSelectValue,
  isBillable: false,
  billingLevel: defaultSelectValue,
  lineManager: defaultSelectValue,
  mentor: defaultSelectValue,
  trialPeriod: false,
  trialEndDate: null,
  jiraRecId: '',
  recruitmentId: '',
  peoplePartner: defaultSelectValue,
  grLevel: defaultSelectValue,
  maxTotalLoad: 0,
  maxBillableLoad: 0,
  costCenter: defaultSelectValue,

  hardSkills: [],
  softSkills: [],
  languageSkills: [],

  //TODO Refactor
  applyChanges: new Date(),
}

export default class EmployeeFormState {
  public static generateFromStaffnWRnSkills(
    profileState: ProfileStateInterface,
    positionState: PositionDescriptionStateInterface,
    skillState: SkillStateInterface
  ): EmployeeFormStateInterface {
    let state = initialState;
    if (profileState) {
      Object.keys(profileState).forEach((key) => {
        //@ts-ignore
        state[key] = profileState[key];
      });
    }
    if (positionState) {
      Object.keys(positionState).forEach((key) => {
        //@ts-ignore
        state[key] = positionState[key];
      });
    }
    if (skillState) {
      Object.keys(skillState).forEach((key) => {
        //@ts-ignore
        state[key] = skillState[key];
      });
    }
    return state;
  }
}
