import React, {Fragment, FunctionComponent} from 'react';
import {HR_DASHBOARD_CONTENT_TABS} from '../../../../constants/HR_DASHBOARD';
import {HeadcountDetailItem} from '../HeadcountDetailItem/HeadcountDetailItem';
import './headcount-details-list.scss';

interface IData {
  locations: Array<any>,
  orgStructures: Array<any>,
  departments: Array<any>,
  overview: any
}

interface Props {
  activeTab: string,
  activePeriod: string,
  data: IData | null,
  chartType: string
}

export const HeadcountDetailsList: FunctionComponent<Props> = (props: Props) => {
  const { activeTab, activePeriod, data, chartType } = { ...props };
  
  if (!data) return <></>;
  
  const renderByActiveTab = (tab: string) => {
    switch (tab) {
      case HR_DASHBOARD_CONTENT_TABS.locations.key:
        return (
          <div className="tab-content tab-active">
            {
              (data.locations?.length)
                ? (
                  <div>
                    {
                      (data.overview?.headcount)
                        ? (
                          data.locations.map((location: any, i: number) => {
                            return location?.headcount
                              ? (
                                <HeadcountDetailItem
                                  key={ i }
                                  activePeriod={ activePeriod }
                                  data={ location }
                                  overview={ data.overview }
                                  chartType={ chartType }
                                />
                              )
                              : <Fragment key={ i } />
                          })
                        )
                        : <div className="empty">There are no details for locations</div>
                    }
                  </div>
                )
                : <div className="empty">There are no details for locations</div>
            }
          </div>
        );
      case HR_DASHBOARD_CONTENT_TABS.units.key:
        return (
          <div className="tab-content tab-active">
            {
              (data.orgStructures.length)
                ? (
                  <div>
                    {
                      data.orgStructures.map((orgStructure: any, i: number) => {
                        if (!orgStructure.headcount) {
                          return <Fragment key={ i } />
                        }
                        return (
                          <HeadcountDetailItem
                            key={ i }
                            activePeriod={ activePeriod }
                            data={ orgStructure }
                            overview={ data.overview }
                            chartType={ chartType }
                          />
                        )
                      })
                    }
                  </div>
                )
                : (
                  <div className="empty">There are no details for units</div>
                )
            }
          </div>
        );
      case HR_DASHBOARD_CONTENT_TABS.departments.key:
        return (
          <div className="tab-content tab-active">
            {
              (data.departments.length)
                ? (
                  <div>
                    {
                      data.departments.map((department: any, i: number) => {
                        if (!department.headcount) {
                          return <Fragment key={ i } />
                        }
                        return (
                          <HeadcountDetailItem
                            key={ i }
                            activePeriod={ activePeriod }
                            data={ department }
                            overview={ data.overview }
                            chartType={ chartType }
                          />
                        )
                      })
                    }
                  </div>
                )
                : (
                  <div className="empty">There are no details for departments</div>
                )
            }
          </div>
        );
      default:
        return <></>;
    }
  }
  return (
    <div className="headcount-dynamic-details">
      <div className="headcount-dynamic-details-container">
        { renderByActiveTab(activeTab) }
      </div>
    </div>
  );
}
