import React, {cloneElement, Fragment, FunctionComponent, PropsWithChildren, ReactElement} from 'react';
import {PopupCloserProps} from './PopupPropsInterface';
import {dispatchPopupState} from '../../store/popup/types';

export const PopupCloser: FunctionComponent<PopupCloserProps> = (props: PropsWithChildren<PopupCloserProps>) => {
  const handleClose = () => {
    dispatchPopupState(props.popupName, false);
  };

  return (
    <Fragment>
      {cloneElement(props.children as ReactElement, {onClick: handleClose})}
    </Fragment>
  );
}
