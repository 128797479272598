import {ET_SET_ATTRIBUTES, ET_SET_ATTRIBUTES_DEFAULT, EtState, EtTypes} from './types';

const defaultState: EtState = {
  attributes: [],
  attributesDefault: []
}

export const etReducer = (state: EtState = defaultState, action: EtTypes) => {
  switch (action.type) {
    case ET_SET_ATTRIBUTES:
      return {...state, attributes: action.payload}
    case ET_SET_ATTRIBUTES_DEFAULT:
      return {...state, attributesDefault: action.payload}
    default:
      return state;
  }
}
