import React from 'react';
import { useFormikContext } from 'formik';

type PropsT = {
  wrId: number;
  setIsUnPublished: Function;
}

export const WRCvAutoSave: React.FC<PropsT> = (props: PropsT) => {
  const { values } = useFormikContext();
  
  React.useEffect(() => {
    if (!values) return;
    if (localStorage !== undefined) {
      localStorage.setItem(`unpublishedCV-${props.wrId}`, JSON.stringify(values));
    }
    if (Object.keys(values).length > 1) {
      props.setIsUnPublished(true);
    }
  }, [values, props]);
  
  return null;
}
