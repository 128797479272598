import React, {Fragment, FunctionComponent, ReactElement, useEffect, useState} from "react";
import {Value} from "../../../interfaces/Value";

type PropsType = {
  name: string
  value: Value<string>
  tooltip?: ReactElement
}

const JIRA_LINK = 'http://jira.star.global';

export const WRAttributeJiraLink: FunctionComponent<PropsType> = (props: PropsType) => {
  const {name, value} = {...props};
  const [link, setLink] = useState<string>('');

  const getLink = (val: string) => {
    return `${JIRA_LINK}/browse/${val}`;
  }

  useEffect(() => {
    setLink(getLink(value.value));
  }, [value]);

  return (
    <div className="work-record-attribute">
      <div className="work-record-attribute__name">
        {name}
      </div>
      <div className="work-record-attribute__value">
        {
          (value.value) ? <a href={link} target="_blank">{value.value}</a> : <></>
        }
        {(props.tooltip) ? (props.tooltip) : ''}
      </div>
    </div>
  );
}