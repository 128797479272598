import {PermissionForElements, SET_PERMISSION_FOR_ELEMENTS} from "./types";
import PermissionClient from "../../services/http/PermissionClient";

export function setPermissionForElements(elements: Array<PermissionForElements>) {
  return {
    type: SET_PERMISSION_FOR_ELEMENTS,
    payload: elements
  }
}

export function setPermissionForElementsThunk(): any {
  return async (dispatch: any) => {
    try {
      const { status, data }: any = await PermissionClient.getElements();
      if (status === 200) {
        dispatch(setPermissionForElements(data));
      }
    } catch (e) {
      console.error('error', e);
    }
  };
}