import {EducationItem as EducationItemInterface} from '../../interfaces/EducationItem';
import client from '../http/EducationClient';

class EducationHelper {

  public create(educationItem: EducationItemInterface, formValues: any) {
    return client.create(this.createEducationItemBody(educationItem, formValues));
  }

  public update(educationItem: EducationItemInterface, formValues: any) {
    return client.update(this.createEducationItemBody(educationItem, formValues), educationItem.id);
  }

  private createEducationItemBody(educationItem: EducationItemInterface, formValues: any): any {
    let body: any = {
      id: (educationItem.id) ? educationItem.id : null,
      staff_id: educationItem.staffId
    };
    body.degree = formValues.degree;
    body.field_of_study = formValues.fieldOfStudy;
    body.location = formValues.location;
    body.school_name = formValues.schoolName;
    body.year_of_graduation = formValues.yearOfGraduation;
    return body;
  }
}

export default new EducationHelper();
