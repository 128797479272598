import {ROLES} from "../../constants/WORK_RECORD";
import {Staff} from "../../interfaces/Staff";
import {PermissionForElements} from "../../store/permission/types";

class PermissionElementService {

  private createAllowedMap(items: Array<any>) {
    let map: any = [];
    items.forEach((item: any) => {
      if (item.element_visibility.is_shown) {
        map[item.name] = item.element_visibility.is_shown;
      }
    });

    return map;
  }

  public isUIShown(
    element: string,
    user: Staff,
    permissions: Array<PermissionForElements>,
    staffToCheck?: any,
    isOwner?: boolean | null | undefined) {

    let foundElement = permissions.find((x: any) => x.identifier === element);

    if (foundElement) {
      let isShown = false;
      let localMap = this.createAllowedMap(foundElement.roles);
      let keys = Object.keys(localMap);
      let length = keys.length;

      //Check Owner
      isOwner = isOwner ?? false;
      let isOnOwnerSkip = false;
      if (localMap[ROLES.OWNER]) {
        isOnOwnerSkip = true;
        if (keys.length === 1) {
          if (!isOwner) {
            length = 0;
          }
        } else {
          localMap[ROLES.OWNER] = isOwner;
        }
      }
      //Check LM
      let isLmNeeded = (staffToCheck && staffToCheck.role === ROLES.LM);
      // @ts-ignore
      let allowedForLm = (isLmNeeded && user.subordinates && user.subordinates.indexOf(staffToCheck.wrId) !== -1);
      if (!allowedForLm && isOwner) {
        allowedForLm = true;
      }

      for (let i = 0; i < length; i++) {
        let roleName = keys[i];
        let visibility = localMap[keys[i]];
        if (user?.permissions.roles.indexOf(roleName) !== -1) {
          if (visibility === true) {
            if (roleName === ROLES.LM && allowedForLm === false) {
              isShown = false;
            } else {
              isShown = true;
            }
            break;
          }
        }
      }

      return isShown;
    } else {
      return true;
    }
  }
}

export default new PermissionElementService();