import React, {FunctionComponent, useContext, useState} from 'react';
import {useSelector} from 'react-redux';

import {GlobalState} from '../../../../store/types';
import {STAFF_STATE} from '../../../../store/reducers';
import {dispatchPopupState} from '../../../../store/popup/types';

import {EducationListContext} from '../../../WR/WREducation/WREducation';
import {EducationItem as EducationItemInterface} from '../../../../interfaces/EducationItem';
import EducationItem from '../../../../models/EducationItem';
import educationHelper from '../../../../services/data/EducationHelper';
import EducationMapper from '../../../../mappers/response/EducationMapper';
import {EducationItemForm} from './EducationItemForm';
import {toastr} from "react-redux-toastr";

interface AddEducationFormWrapperProps {
  popupName: string;
}

export const AddEducationFormWrapper: FunctionComponent<AddEducationFormWrapperProps> =
  (props: AddEducationFormWrapperProps) => {
    const {popupName} = {...props};
    const [saving, setSaving] = useState(false);
    const staff = useSelector((state: GlobalState) => state[STAFF_STATE].staff);
    const educationItem: EducationItemInterface = new EducationItem();
    educationItem.staffId = staff.id;
    const context = useContext(EducationListContext);
    const create = (values: object) => {
      setSaving(true);
      educationHelper.create(educationItem, values).then((data: any) => {
        const ei: EducationItemInterface = EducationMapper.creatEducationItemFromResponse(data);
        context.updateList();
        setSaving(false);
        toastr.success('', 'Education item successfully saved');
        dispatchPopupState(popupName, false);
      });
    }

    return (
      <EducationItemForm
        submitFunction={create}
        educationItem={educationItem}
        popupName={popupName}
        handleCancel={() => {
          dispatchPopupState(props.popupName, false)
        }}
        saving={saving}
      />
    );
  }
