import React, {Fragment, FunctionComponent, useContext} from 'react';
import {ErrorMessage} from 'formik';

import {Skill as SkillInterface} from '../../../../interfaces/entity/Skill';
import {SkillCheckboxListField} from '../../../Forms/SkillCheckboxListField';
import {SkillEditFormContext} from './SkillEditForm';


interface SkillEditFormSearchFieldsProps {
  valuesSkills: Array<SkillInterface>,
}

export const SkillEditFormSearchFields: FunctionComponent<SkillEditFormSearchFieldsProps> =
  (props: SkillEditFormSearchFieldsProps) => {
    const {valuesSkills} = {...props};
    const {type, filteredSkills, setFormSkills} = useContext(SkillEditFormContext);

    if (filteredSkills.length) {
      return (
        <Fragment>
          <div className="form-group">
            <SkillCheckboxListField
              id="skills"
              name="skills"
              isLanguage={(type === 'languageSkills')}
              values={valuesSkills}
              options={filteredSkills}
              changeEffect={setFormSkills}/>
            <ErrorMessage name="skills"/>
          </div>
        </Fragment>
      );
    }

    return <Fragment/>;
  }
