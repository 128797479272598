import React from 'react';
import { UnitTreePreviewBreadcrumbsItem } from './UnitTreePreviewBreadcrumbsItem';

interface UnitTreePreviewBreadcrumbsProps {
  breadCrumbs: Array<string>;
}

export const UnitTreePreviewBreadcrumbs: React.FC<UnitTreePreviewBreadcrumbsProps> = (props: UnitTreePreviewBreadcrumbsProps) => {
  const { breadCrumbs } = { ...props };
  const items = breadCrumbs.reverse().map((item: string, index: number, data: any) => {
    return <UnitTreePreviewBreadcrumbsItem key={ `item-${index}` } breadCrumbs={ data } item={ item } index={ index } />
  });
  if (breadCrumbs.length) {
    return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb breadcrumb--custom">
          { items }
        </ol>
      </nav>
    );
  }
  return <></>;
};