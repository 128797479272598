import {WorkRecord} from '../../interfaces/WorkRecord';

export const WORK_RECORD_SET_MAIN_ID = 'WORK_RECORD_SET_MAIN_ID';
export const WORK_RECORD_SET_ACTIVE_ID = 'WORK_RECORD_SET_ACTIVE_ID';
export const WORK_RECORD_SET_WORK_RECORD = 'WORK_RECORD_SET_WORK_RECORD';
export const WORK_RECORD_SET_SHOULD_UPDATE = 'WORK_RECORD_SET_SHOULD_UPDATE';
export const STAFF_GET_MY_LOADING = 'STAFF_GET_MY_LOADING';
export const STAFF_GET_MY_SUCCESS = 'STAFF_GET_MY_SUCCESS';
export const STAFF_GET_MY_FAILURE = 'STAFF_GET_MY_FAILURE';

export interface WorkRecordState {
  mainId: number | null,
  activeId: number | null,
  workRecord: WorkRecord | null,
  shouldUpdate: boolean,
  cvData: object | null,
  loading: boolean,
  cvDataForbidden: boolean,
}

interface SetMainIdAction {
  type: typeof WORK_RECORD_SET_MAIN_ID
  payload: number
}


interface SetActiveIdAction {
  type: typeof WORK_RECORD_SET_ACTIVE_ID
  payload: number
}

interface SetWorkRecordAction {
  type: typeof WORK_RECORD_SET_WORK_RECORD
  payload: WorkRecord
}

interface SetShouldUpdateAction {
  type: typeof WORK_RECORD_SET_SHOULD_UPDATE
  payload: boolean
}

export type WorkRecordTypes =
    | SetMainIdAction
    | SetActiveIdAction
    | SetWorkRecordAction
    | SetShouldUpdateAction
