import React, { FC, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import './datepicker-field.scss';

export const DatePickerField: FC<ReactDatePickerProps> = (props: ReactDatePickerProps) => {
  const { onChange, ...restProps } = { ...props };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const position = props.id && props.id.includes('date_of_birth')
    ? 'bottom-end'
    : 'bottom-start';

  const onClickOpen = () => {
    setIsOpen(true)
  }

  const onClickToday = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onChange(new Date(), event);
    setIsOpen(false);
  }

  const onClickClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onChange(null, event);
    setIsOpen(false);
  }

  const onClickClose = () => {
    setIsOpen(false);
  }

  return (
    <div className="position-relative">
      <DatePicker
        autoComplete={ "off" }
        className={ props.className ? props.className : "date-picker" }
        popperPlacement={ position }
        popperModifiers={{
          computeStyles: {
            adaptive: false
          },
          flip: {
            behavior: ["bottom"]
          }
        }}
        dateFormat="MMM d, yyyy"
        dropdownMode={ 'select' }
        showYearDropdown
        showMonthDropdown
        open={ isOpen }
        onClickOutside={ onClickClose }
        onInputClick={ onClickOpen }
        onChange={(val) => {
          onChange(val, undefined);
          setIsOpen(false);
        }}
        { ...restProps }
      >
        <div className="date-picker--footer">
          <div className="d-flex p-2">
            <div className="col btn-group px-0">
              <button className="btn btn-sm btn-info" onClick={ onClickToday }>Today</button>
              <button className="btn btn-sm btn-danger" onClick={ onClickClear }>Clear</button>
            </div>
            <div className="col px-0">
              <button className="btn btn-sm btn-success float-right" onClick={ onClickClose }>Close</button>
            </div>
          </div>
        </div>
      </DatePicker>
      {/*<FA icon={ ['far', 'calendar-alt'] } onClick={ onClickOpen } />*/}
    </div>
  );
};