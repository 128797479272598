import React, {FC} from 'react';

interface Props {
  typeView: any,
  activeTypeView: any,
  setActiveTypeView: any
}

export const HeadcountByTypeView: FC<Props> = (props: Props) => {
  const {  typeView, activeTypeView, setActiveTypeView } = props;
  return (
    <div>
      <span className="custom-label">Show as</span>
      <span className="buttons-list">
          {typeView.map((value: string, i: number) => {
            return (
              <span
                className={`button btn-small ${value === activeTypeView ? ' active' : ''}`}
                key={i}
                onClick={() => setActiveTypeView(value)}>
                {value}
              </span>
            )
          })}
        </span>
    </div>
  );
}
