import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';

import store from '../../../../store/store';
import { dispatchPopupState } from '../../../../store/popup/types';
import { setShouldUpdate } from '../../../../store/workRecord/actions';
import dictionaryStore from '../../../../services/dictionary/DictionaryStore';

import { WorkRecord } from '../../../../interfaces/WorkRecord';
import wrHelper from '../../../../services/data/WorkRecordHelper';

import { TypeaheadSuggestionField } from '../../../Forms/TypeaheadSuggestionField';
import { PopupCloser } from '../../../Popup/PopupCloser';

import NextEmployerState, {NextEmployerStateInterface} from './NextEmployerState';
import { schema } from './NextEmployerFormSchema';

import './next-employer-form.scss';

interface NextEmployerFormProps {
  workRecord?: WorkRecord;
}

export const NextEmployerForm: React.FC<NextEmployerFormProps> = (props: NextEmployerFormProps) => {
  const [initialState, setInitialState] = useState<NextEmployerStateInterface | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [nextEmployers, setNextEmployers] = useState([]);

  const submission = (values: NextEmployerStateInterface) => {
    const workRecord = props.workRecord;
    if (workRecord) {
      wrHelper.updateNextEmployer(workRecord, values.nextEmployer).then((data: any) => {
        store.dispatch(setShouldUpdate(true));
        dispatchPopupState('nextEmployer', false);
      });
    }
  };

  useEffect(() => {
    if (initialState == null) {
      setInitialState(NextEmployerState.generateFromWorkRecord(props.workRecord));
    }
    if (!isLoaded) {
      dictionaryStore.getByAlias('next_employer').then((data: any) => {
        if (typeof data !== 'undefined') {
          setNextEmployers(data);
        }
      });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded])

  if (initialState) {
    return (
      <Formik
        initialValues={ initialState }
        validationSchema={ schema }
        onSubmit={ values => submission(values) }>
        {({errors, touched, values}) => (
          <Form className="edb-form form-turnover">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="nextEmployer">Set Data</label>
                  <TypeaheadSuggestionField
                    id="nextEmployer"
                    name="nextEmployer"
                    labelKey=""
                    multiple={ false }
                    options={ nextEmployers }
                    className="form-control w-100"
                  />
                  <ErrorMessage name="turnover"/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col text-center form-buttons">
                <PopupCloser popupName="nextEmployer">
                  <button className="edb-btn edb-btn--secondary" type="reset">
                    Cancel
                  </button>
                </PopupCloser>
                <button className="edb-btn edb-btn--primary" type="submit">
                  Save
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  return <></>;
}
