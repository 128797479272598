import React, {Fragment, FunctionComponent, useEffect, useState} from 'react'
import DateHelper from '../../../utils/date/DateHelper';

import './wr-attribute.scss';


interface Props {
  name: string,
  value: Date|null
}

export const WRAttributeDate: FunctionComponent<Props> = (props: Props) => {
  const [name] = useState<string>(props.name);
  const [value, setValue] = useState<Date|null>(props.value);

  useEffect(() => {
    setValue(props.value)
  }, [props]);

  if (value) {
    return (
      <div className="work-record-attribute">
        <div className="work-record-attribute__name">
          {name}
        </div>
        <div className="work-record-attribute__value">
          {(value) ? DateHelper.getFormattedDate(value) : ''}
        </div>
      </div>
    );
  }

  return <Fragment/>;
}
