import store from '../../store/store';
import { setMyStaff } from '../../store/staff/actions';

import { Staff } from '../../interfaces/Staff';
import { StaffResponse } from '../../interfaces/http/staff/StaffResponse';
import client from './../http/StaffClient';
import StaffMapper from '../../mappers/response/StaffMapper';

class CurrentUserService {
  private static instance: CurrentUserService;

  public static getInstance(): CurrentUserService {
    if (!CurrentUserService.instance) {
      CurrentUserService.instance = new CurrentUserService();
    }
    return CurrentUserService.instance;
  }

  public fetchCurrentUser() {
    client.getMy().then((data: StaffResponse) => {
      const staff: Staff = StaffMapper.creatStaffFromStaffResponse(data);
      store.dispatch(setMyStaff(staff));
    });
  }
}

export default CurrentUserService.getInstance();
