import React from 'react';

interface WorkRecordListItemProps {
  workRecord: any;
  avatar: string;
  showPreviewWR: (event: React.MouseEvent<HTMLLIElement>) => void;
}

export const WorkRecordListItem: React.FC<WorkRecordListItemProps> = (props: WorkRecordListItemProps) => {
  const { workRecord, avatar, showPreviewWR } = { ...props };

  return (
    <li className="media col-custom" data-wr-id={ `${workRecord.id}` } data-staff-id={ `${workRecord.staffId}` } onClick={ showPreviewWR }>
      <div className="rounded-circle overflow-hidden media-image" style={{backgroundImage: `url(${avatar})`}}></div>
      <div className="media-body">
        <h5><strong>{ workRecord.firstName } { workRecord.lastName }</strong></h5>
        <span className="text-muted">{ workRecord.position.name }</span>
      </div>
    </li>
  )
};