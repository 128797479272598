import React from 'react';
import { AddEmployeeForm } from "../components/Staff/Forms/AddEmployee/AddEmployeeForm";

export const AddEmployeePage: React.FC = () => {
  return (
    <>
       <AddEmployeeForm />
    </>
  );
}
