import React, { createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Store
import store from '../../../store/store';
import { GlobalState } from '../../../store/types';
import { STAFF_STATE, WORK_RECORD_STATE } from '../../../store/reducers';
import { setActiveId, setWorkRecord } from '../../../store/workRecord/actions';

import WorkRecord from '../../../models/WorkRecord';
import { WorkRecord as WorkRecordInterface } from '../../../interfaces/WorkRecord';

// Components
import { Popup } from '../../Popup/Popup';
import { PopupTrigger } from '../../Popup/PopupTrigger';
import { FeedbackList } from '../../Feedback/FeedbackList/FeedbackList';
import { AddFeedbackFormWrapper } from '../../Feedback/Forms/FeedbackItem/AddFeedbackFormWrapper';
import { ElementWithPermission } from '../../ElementWithPermission/ElementWithPermission';

import { ROLES } from '../../../constants';

import './work-record-feedback.scss';

const context = { updateList: () => {} };
export const FeedbackListContext = createContext(context);

type Params = {
  staffId: string,
  wrId: string
}

export const WRFeedback: React.FC = () => {
  const params = useParams<Params>();
  const cParams = {
    wrId: Number(params.wrId)
  };
  const staff = useSelector((state: GlobalState) => state[STAFF_STATE].staff);
  const workRecord = useSelector((state: GlobalState) => state[WORK_RECORD_STATE].workRecord);

  useEffect(() => {
    if (staff && cParams.wrId && !workRecord) {
      const wr: WorkRecord = staff.workRecords.filter((wr: WorkRecordInterface) => wr.id === cParams.wrId)[0];
      store.dispatch(setWorkRecord(wr));
      store.dispatch(setActiveId(cParams.wrId));
    }
  }, [staff]);

  return <FeedbackListContext.Provider value={context}>
    <div className="work-record-main work-record-feedback">
      <div className="work-record-main__header"></div>
      <div className="work-record-main__body">
        <div className="card position-relative">
          <div className="card-body">
            <p className="card-title">Record's Feedbacks</p>
            <FeedbackList />
          </div>
          { workRecord?.code ? <p className="staff-wr-code">{ workRecord.code }</p> : <></> }
        </div>
      </div>
      <div className="work-record-main__footer">
        <ElementWithPermission element="provideFeedbackButton" staffToCheck={ { role: ROLES.LM, wrId: cParams.wrId } }>
          <PopupTrigger popupName="addFeedback">
            <span className="edb-btn edb-btn--lightblue">+ Provide feedback</span>
          </PopupTrigger>
          <Popup popupName="addFeedback" id="addFeedback" title="Provide Feedback" size="lg">
            <AddFeedbackFormWrapper popupName="addFeedback"/>
          </Popup>
        </ElementWithPermission>
      </div>
    </div>
  </FeedbackListContext.Provider>;
}
