import * as Yup from 'yup';
import {Skill} from '../../../../interfaces/entity/Skill';

export const schema = Yup.object()
  .shape({
    hardSkills: Yup.array<Skill>()
      .nullable(),
    softSkills: Yup.array<Skill>()
      .nullable(),
    languageSkills: Yup.array<Skill>()
      .nullable(),

  })
