import React, { Fragment, HTMLAttributes } from 'react';
import { useField } from 'formik';
import { Typeahead, Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';
import { groupBy } from 'lodash';

import './typeahead.scss';

interface TypeaheadFieldProps {
  id: string;
  name: string;
  labelKey: string;
  multiple: boolean;
  options: Array<any>;
  disabled?: boolean;
  groupBy?: string;
  testId?: string;
  onChange?: (selected: any[]) => void;
  clearButton?: boolean;
}

export const TypeaheadField: React.FC<TypeaheadFieldProps & HTMLAttributes<HTMLDivElement>> =
  (props: TypeaheadFieldProps & HTMLAttributes<HTMLDivElement>) => {
  const { id, labelKey, multiple, options, disabled, className, name } = { ...props };
  const [field, , { setValue, setTouched }] = useField(props as any);

  let renderMenu;
  if (props.groupBy) {
    renderMenu = (results: any[], menuProps: any) => {
      let index = 0;
      const groups = groupBy(results, props.groupBy);
      const items = Object.keys(groups).sort((a: string, b: string) => {
        if (a === "" || a === null) return 1;
        if (b === "" || b === null) return -1;
        if (a === b) return 0;
        return a < b ? -1 : 1;
      }).map((group) => (
        <Fragment key={group}>
          {index !== 0 && <Menu.Divider />}
          <Menu.Header>{group}</Menu.Header>
          {
            groups[group].map((i: any) => {
              const item =
                <MenuItem key={index} option={i} position={index}>
                  <Highlighter>{i.name}</Highlighter>
                </MenuItem>;

              index += 1;
              return item;
            })
          }
        </Fragment>
      ));
      return <Menu { ...menuProps }>{ items }</Menu>;
    };

  }

  return (
    <Typeahead
      flip={ true }
      clearButton={ props.clearButton ? props.clearButton : false }
      id={ id }
      inputProps={ {
        id: id,
        name: name,
        className: className,
        autoComplete: 'off',
        autoCorrect: 'off',
        spellCheck: 'false',
        // @ts-ignore
        'data-testid': props.testId
      } }
      labelKey={ labelKey }
      multiple={ multiple }
      selected={ field.value ? [field.value] : [] }
      disabled={ disabled }
      onChange={ props.onChange ? props.onChange : (selected => {
        const value = (selected && selected[0]) ? selected[0] : '';
        setValue(value);
      }) }
      // onInputChange={ (text, event) => {
      //   setValue(text)
      // } }
      options={ options }
      renderMenu={ renderMenu }
      onBlur={ () => setTouched(true) }
    />
  );
};

