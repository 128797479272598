import React, {FunctionComponent, ReactElement, useEffect, useState} from 'react';
import {Value} from '../../../interfaces/Value';

type PropsType = {
  name: string
  value: Value<string>
  tooltip?: ReactElement
}

export const WRAttributeBreezyLink: FunctionComponent<PropsType> = (props: PropsType) => {

  const [name] = useState<string>(props.name);
  const [value, setValue] = useState<Value<string>>(props.value);

  useEffect(() => {
    setValue(props.value)
  }, [props]);

  return (
    <div className="work-record-attribute">
      <div className="work-record-attribute__name">
        {name}
      </div>
      <div className="work-record-attribute__value">
        {
          (value.value) ? <a href={ value.value } target="_blank">Breezy</a> : <></>
        }
        { (props.tooltip) ? (props.tooltip) : '' }
      </div>
    </div>
  );
}