import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART_COMMON } from '../../../../constants';
import './chart-line.scss';

interface Props {
  data: any,
  joinedStat: boolean,
  detailUrls: Array<any>
}

export const ChartLine: FunctionComponent<Props> = (props: Props) => {
  const { data, joinedStat, detailUrls } = { ...props };
  
  const [width, setWidth] = useState<number>(0);
  const [labels, setLabels] = useState<Array<any>>([]);
  const [datasets, setDatasets] = useState<Array<any>>([]);
  
  const ref = useRef(null);
  const refChartLine = useRef(null);
  
  useEffect(() => {
    const labelData: Array<string> = [];
    const joined: Array<any> = [];
    const left: Array<any> = [];
    const billable: Array<any> = [];
    const notBillable: Array<any> = [];
    data.map((item: any) => {
      labelData.push(item.label);
      if (joinedStat) {
        joined.push(item.joined);
        left.push(item.left);
      } else {
        billable.push(item.billable);
        notBillable.push(item.not_billable);
      }
    });
    const datasetsData: Array<any> = (joinedStat)
      ? [
        {
          data: left,
          fill: true,
          backgroundColor: CHART_COMMON.colors.leftOpacity,
          borderWidth: 2,
          borderColor: CHART_COMMON.colors.bad,
          pointBorderColor: 'white',
          pointBackgroundColor: CHART_COMMON.labels.backgroundColor
        },
        {
          data: joined,
          fill: true,
          backgroundColor: CHART_COMMON.colors.joinedOpacity,
          borderWidth: 2,
          borderColor: CHART_COMMON.colors.good,
          pointBorderColor: 'white',
          pointBackgroundColor: CHART_COMMON.labels.backgroundColor
        }
      ]
      : [
        {
          data: notBillable,
          fill: true,
          backgroundColor: CHART_COMMON.colors.nonBillableOpacity,
          borderWidth: 2,
          borderColor: CHART_COMMON.colors.bad,
          pointBorderColor: 'white',
          pointBackgroundColor: CHART_COMMON.labels.backgroundColor
        },
        {
          data: billable,
          fill: true,
          backgroundColor: CHART_COMMON.colors.billableOpacity,
          borderWidth: 2,
          borderColor: CHART_COMMON.colors.good,
          pointBorderColor: 'white',
          pointBackgroundColor: CHART_COMMON.labels.backgroundColor
        }
      ];
    const currentElement = ref.current as unknown as HTMLElement;
    const wrapperElement = currentElement.parentElement as HTMLElement;
    const wrapperWidth = wrapperElement.clientWidth;
    let factor = Math.ceil(labelData.length / 12);
    if (window.innerWidth < 1150 && labelData.length >= 12) {
      factor *= 1.3;
    }
    setLabels(labelData);
    setDatasets(datasetsData);
    setWidth(wrapperWidth * factor);
  }, [data]);
  
  useEffect(() => {
    const chartLineElement = refChartLine.current as unknown as Line;
    if (chartLineElement) chartLineElement.chartInstance.resize();
  }, [width]);
  
  const datasetKeyProvider = () => {
    const random = Math.random().toString();
    return btoa(random).substring(0, 12);
  }

  const handleClickLabel = (event: any) => {
    let reportKey = '';
    if (event.chart) {
      let index = event.dataIndex;
      let datasetIndex = event.datasetIndex;
      reportKey = data[index][detailUrls[datasetIndex]];
    }
    if (reportKey) {
      window.open('/report/' + reportKey, "_blank");
    }
  }

  const handleEnterLabel = (event: any) => {
    labelHover(event, true);
  }

  const handleLeaveLabel = (event: any) => {
    labelHover(event, false);
  }

  const labelHover = (event: any, isHover: boolean) => {
    const chart = event.chart;
    if (!chart) { return; }
    const canvas = chart.canvas;
    if (isHover) {
      canvas.classList.add('hovered');
    } else {
      canvas.classList.remove('hovered');
    }
  }

  return (
    <div className="chart-hd-wrapper chart-hd-line-wrapper" ref={ ref } style={{ width }}>
      <Line
        ref={ refChartLine }
        datasetKeyProvider={ datasetKeyProvider }
        data={{ labels, datasets }}
        options={{
          devicePixelRatio: CHART_COMMON.devicePixelRatio,
          maintainAspectRatio: false,
          tooltips: { enabled: false },
          hover: { mode: null },
          layout: {
            padding: {
              left: 30,
              right: 30,
              top: 5,
            }
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                categoryPercentage: 1.0,
                gridLines: {display: false},
                ticks: {
                  display: true,
                  min: 0,
                  max: 100,
                  stepSize: 5,
                  fontFamily: CHART_COMMON.labels.fontFamily,
                  fontSize: CHART_COMMON.labels.fontSize,
                  fontColor: CHART_COMMON.labels.fontColor,
                }
              }
            ],
            yAxes: [
              {
                stacked: true,
                display: false
              }
            ]
          },
          legend: {
            display: false
          },
          plugins: {
            datalabels: {
              display: function(context: any) {
                return context.dataset.data[context.dataIndex] !== 0;
              },
              clamp: true,
              anchor: 'center',
              align: 'left',
              offset: function(context: any) {
                const value = context.dataset.data[context.dataIndex] + '';

                return (context.datasetIndex === 0) ? 3 : -9 * value.length - 3;
              },
              color: 'black',
              font: {
                family: CHART_COMMON.labels.fontFamily,
                size: CHART_COMMON.labels.fontSize
              },
              padding: {left: 0},
              listeners: {
                click: handleClickLabel,
                enter: handleEnterLabel,
                leave: handleLeaveLabel
              }
            }
          }
        }}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
}
