import { AttributeStructure, AttributeStructureRequest } from '../../interfaces/http/dictionary/AttributeStructure';
import { WorkRecordEResponse } from '../../interfaces/http/workRecord/WorkRecordEResponse';
import { ColumnInterface } from '../../interfaces/table/ColumnInterface';
import { TABLE_CONFIG } from '../../constants';

const employeeTableColumns = TABLE_CONFIG['employeeTable'] ;

class EmployeeTableHelper {

  public convertAttributesToColumns(attributes: Array<AttributeStructure>): Array<any> {
    const columns: Array<any> = [];
    if (!attributes) {
      return columns;
    }
    attributes.forEach((attribute: AttributeStructure) => {
      let column: ColumnInterface  = {
        Header: attribute.header_name,
        accessor: attribute.attribute,
        default: attribute.default,
        order: attribute.order,
        group: attribute.group,
        isVisible: attribute.visible,
        isFilterOpen: false,
        sortable: true,
        required: attribute.required
      };
      //@ts-ignore
      if (employeeTableColumns[attribute.attribute]) {
        //@ts-ignore
        const configColumn = employeeTableColumns[attribute.attribute];
        column.order = configColumn.order ?? column.order;
        column.Filter = configColumn.Filter;
        column.filter = configColumn.filter;
        column.width = configColumn.width ?? 250;
        if (typeof configColumn.sortable !== "undefined") {
          column.sortable = configColumn.sortable;
        }
        if (configColumn.Cell) {
          column.Cell = configColumn.Cell;
        }
        if (configColumn.staffId) {
          column.staffId = configColumn.staffId;
        }
      }
      columns.push(column);
    });
    columns.sort((c1, c2) => {
      if (c1.order > c2.order) {
        return 1;
      }
      if (c1.order < c2.order) {
        return -1;
      }
      return 0;
    });

    return columns;
  }

  public convertListToData(list: Array<WorkRecordEResponse>): Array<any> {
    const data: Array<any> = [];
    if (!list) {
      return data;
    }
    list.forEach((wr: WorkRecordEResponse) => {
      let skills: Array<string> = [];
      if (wr['skill'] && Array.isArray(wr['skill'])) {
        wr['skill'].forEach((skill: any) => {
          skills.push(skill.name);
        })
      }
      data.push({
        id: wr.id,
        code: wr.code,
        image_url: wr.avatar,
        staff: wr.staff,
        staff_id: wr.staff_id,
        position: wr.position,
        start_date: wr.start_date,
        end_date: wr.end_date,
        org_structure: wr.org_structure,
        department: wr.department,
        unit: wr.unit,
        subunit: wr.subunit,
        status: wr.status,
        location: wr.location,
        cost_location: wr.cost_location,
        physical_location: wr.physical_location,
        line_manager: wr.line_manager,
        line_manager_id: wr.line_manager_id,
        mentor: wr.mentor,
        mentor_id: wr.mentor_id,
        employment: wr.employment,
        is_billable: wr.is_billable,
        max_total_load: wr.max_total_load !== undefined ? String(wr.max_total_load) : '',
        max_billable_load: wr.max_billable_load !== undefined ? String(wr.max_billable_load) : '',
        billing_level: wr.billing_level,
        seniority: wr.seniority,
        username: wr.username,
        email: wr.email,
        people_partner:wr.people_partner,
        people_partner_id:wr.people_partner_id,
        trial_status: wr.trial_status,
        trial_end_date: wr.trial_end_date,
        jira_rec_id: wr.jira_rec_id,
        recruitment_id: wr.recruitment_id,
        gender: wr.gender,
        company_start_date: wr.company_start_date,
        alumni: wr.alumni,
        turnover: wr.turnover,
        reason_to_leave: wr.reason_to_leave,
        date_of_birth: wr.date_of_birth,
        cost_center: wr.cost_center,
        gr_level: wr.gr_level,
        next_employer: wr.next_employer,
        skill: skills
      })
    });

    return data;
  }

  public prepareForSavingConfig(data: Array<ColumnInterface>): Array<AttributeStructureRequest> {
    const columns: Array<AttributeStructureRequest> = [];
    data.forEach((value: ColumnInterface, index) => {
      columns.push({
        // @ts-ignore
        attribute: value.id,
        order: value.order,
        visible: value.isVisible
      })
    });
    return columns;
  }
}

export default new EmployeeTableHelper();
