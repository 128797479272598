import React, {Fragment, FunctionComponent} from 'react';
import {useField} from 'formik';
import Slider from 'rc-slider';

import './slider.scss';

interface SliderFieldProps {
  id: string;
  name: string;
  valueLabel?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
}

export const SliderField: FunctionComponent<SliderFieldProps> = (props: SliderFieldProps) => {
  const {id, valueLabel, min, max, disabled} = {...props};
  const [field, , {setValue}] = useField(props as any);

  return (
    <Fragment>
      <span className="slider-value">{field.value}{valueLabel}</span>
      <Slider
        min={(min) ? min : 0}
        max={(max) ? max : 100}
        value={field.value}
        disabled={disabled}
        onChange={val => setValue(val)}/>
    </Fragment>
  );
};
