import {Value as ValueInterface} from '../interfaces/Value';

export default class ValueStaff implements ValueInterface<string> {
  valueId: number | null = null;
  value: string = '';

  staffId: number | null = null;
  staffFullName: string = '';
  firstName: string = '';
  lastName: string = '';

  public getName():string {
    return `${this.firstName} ${this.lastName}`;
  }

  public getValue(): number | null {
    return this.staffId;
  }
}
