import http, {Http} from './Http';
import { ENDPOINTS, TABLE_NAMES } from '../../constants';
import {AttributeStructureRequest} from "../../interfaces/http/dictionary/AttributeStructure";
import {TableNameType} from "../../components/EmployeeList/EmployeeTable/EmployeeTableSettings";

export class DictionaryClient {
  public http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  public getAll() {
    return this.http.getList(ENDPOINTS.DICTIONARY_ALL);
  }

  public getReportFilters() {
    return this.http.getAxios(ENDPOINTS.DICTIONARY_REPORT_FILTERS);
  }

  public getActiveStaff(active: boolean = false) {
    const endpoint = `${ENDPOINTS.DICTIONARY_STAFF_ALL}?active=${(active) ? '0' : '1'}`
    return this.http.getList(endpoint);
  }

  public getUsers() {
    return this.http.getList(ENDPOINTS.DICTIONARY_USERS);
  }

  public getAllAttribute() {
    return this.http.getList(ENDPOINTS.ATTRIBUTES_ALL);
  }

  public saveTableConfig(columns: Array<AttributeStructureRequest>, tableName: TableNameType = TABLE_NAMES.ALL) {
    let endpoint;
    switch (tableName) {
      case TABLE_NAMES.SUBORDINATES:
        endpoint = ENDPOINTS.SUBORDINATES_ATTRIBUTES;
        break;
      case TABLE_NAMES.DIRECT:
        endpoint = ENDPOINTS.DIRECT_ATTRIBUTES;
        break;
      default:
        endpoint = ENDPOINTS.ATTRIBUTES_ALL;
        break;
    }
    return this.http.post(endpoint, {columns});
  }

  public getAllAttributeDefault() {
    return this.http.getList(ENDPOINTS.ATTRIBUTES_ALL_DEFAULT);
  }

  public getSubordinatesAllAttribute() {
    return this.http.getList(ENDPOINTS.SUBORDINATES_ATTRIBUTES);
  }

  public getSubordinatesAllAttributeDefault() {
    return this.http.getList(ENDPOINTS.SUBORDINATES_ATTRIBUTES_DEFAULT);
  }

  public getDirectAllAttribute() {
    return this.http.getList(ENDPOINTS.DIRECT_ATTRIBUTES);
  }

  public getDirectAllAttributeDefault() {
    return this.http.getList(ENDPOINTS.DIRECT_ATTRIBUTES_DEFAULT);
  }

  public getProjects() {
    return this.http.get(ENDPOINTS.PROJECT_ALL);
  }

  public getCountries() {
    return this.http.get(ENDPOINTS.COUNTRIES);
  }

  public getFilterCountries() {
    return this.http.getAxios(ENDPOINTS.COUNTRIES);
  }

  public getReasonsToLeave() {
    return this.http.get(ENDPOINTS.REASONS_TO_LEAVE);
  }

  public getComplianceDocumentAll() {
    return this.http.get(ENDPOINTS.COMPLIANCE_DOCUMENT_ALL);
  }

  public getComplianceStatusAll() {
    return this.http.get(ENDPOINTS.COMPLIANCE_STATUS_ALL);
  }

  public getCountryList() {
    return this.http.getAction(ENDPOINTS.DICTIONARY_COUNTRY_LIST);
  }

  public getCityList(name: string) {
    return this.http.getAction(ENDPOINTS.DICTIONARY_CITY_LIST(name));
  }
}

export default new DictionaryClient(http);
