import {
  WORK_RECORD_SET_ACTIVE_ID,
  WORK_RECORD_SET_MAIN_ID,
  WORK_RECORD_SET_SHOULD_UPDATE,
  WORK_RECORD_SET_WORK_RECORD,
  WorkRecordTypes
} from './types';
import {WorkRecord} from '../../interfaces/WorkRecord';

export function setMainId(id: number): WorkRecordTypes {
  return {
    type: WORK_RECORD_SET_MAIN_ID,
    payload: id
  }
}

export function setActiveId(id: number): WorkRecordTypes {
  return {
    type: WORK_RECORD_SET_ACTIVE_ID,
    payload: id
  }
}

export function setWorkRecord(workRecord: WorkRecord): WorkRecordTypes {
  return {
    type: WORK_RECORD_SET_WORK_RECORD,
    payload: workRecord
  }
}

export function setShouldUpdate(shouldUpdate: boolean): WorkRecordTypes {
  return {
    type: WORK_RECORD_SET_SHOULD_UPDATE,
    payload: shouldUpdate
  }
}
