import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Field, FormikErrors, FormikTouched, FormikValues} from 'formik';

import dictionaryStore from '../../../../services/dictionary/DictionaryStore';

import { SelectFieldCustom } from '../../../Forms/SelectFieldCustom';

import { ATTRIBUTE_NAMES } from '../../../../constants';

const labels = ATTRIBUTE_NAMES['educationItem'];

interface EducationItemFormFieldsProps {
  values: any,
  educationItem: any,
  errors: FormikErrors<FormikValues>,
  touched: FormikTouched<FormikValues>,
  setFieldValue: Function
}

export const EducationItemFormFields: React.FC<EducationItemFormFieldsProps> = (props: EducationItemFormFieldsProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [degrees, setDegrees] = useState<Array<any>>([]);
  const [schools, setSchools] = useState<Array<any>>([]);

  useEffect(() => {
    if (!isLoaded) {
      dictionaryStore.getDegrees().then((data: any) => {
        if (data) {
          setDegrees(data.map((item: string, index: number) => ({ id: index, name: item })));
        }
      });
      dictionaryStore.getSchools().then((data: any) => {
        setSchools(data);
      });
      setIsLoaded(true);
    }
    return () => {
      setIsLoaded(true);
    }
  }, [isLoaded]);

  if (isLoaded) {
    return (
      <>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="fieldOfStudy">{labels['fieldOfStudy']}</label>
              <Field
                className={`form-control ${props.errors.fieldOfStudy && props.touched.fieldOfStudy ? 'invalid-field' : ''}`}
                as="input"
                type="text"
                id="fieldOfStudy"
                name="fieldOfStudy"
              />
              {
                (props.errors.fieldOfStudy && props.touched.fieldOfStudy)
                  ? <div className="text-danger">{ props.errors.fieldOfStudy }</div>
                  : null
              }
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="degree">{ labels['degree'] }</label>
              <Select
                id="degree"
                name="degree"
                value={ degrees.filter((item) => item.id === props.values.degree) }
                options={ degrees }
                getOptionLabel={ (option) => option.name }
                getOptionValue={ (option) => option.id }
                isSearchable={ false }
                isClearable={ false }
                maxMenuHeight={ 200 }
                menuPlacement={ 'bottom' }
                onChange={ (selected) => props.setFieldValue('degree', selected ? selected.id : '') }
                classNamePrefix={ 'select-field-custom' }
                className={ props.errors.degree && props.touched.degree ? 'invalid-field' : '' }
              />
              {
                (props.errors.degree && props.touched.degree)
                  ? <div className="text-danger">{ props.errors.degree }</div>
                  : null
              }
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="schoolName">{ labels['schoolName'] }</label>
              <SelectFieldCustom
                id="schoolName"
                name="schoolName"
                options={ schools }
                simple={ true }
                suggestion={ true }
                className={ props.errors.schoolName && props.touched.schoolName ? 'invalid-field' : '' }
              />
              {
                (props.errors.schoolName && props.touched.schoolName)
                  ? <div className="text-danger">{ props.errors.schoolName }</div>
                  :  null
              }
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="location">{labels['location']}</label>
              <Field
                className={`form-control ${props.errors.location && props.touched.location ? 'invalid-field' : ''}`}
                as="input"
                type="text"
                id="location"
                name="location"
              />
              {
                (props.errors.location && props.touched.location)
                  ? (<div className="text-danger">{props.errors.location}</div>)
                  : null
              }
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="yearOfGraduation">{labels['yearOfGraduation']}</label>
              <Field
                className={`form-control ${props.errors.yearOfGraduation && props.touched.yearOfGraduation ? 'invalid-field' : ''}`}
                as="input"
                type="text"
                id="yearOfGraduation"
                name="yearOfGraduation"
              />
              {
                (props.errors.yearOfGraduation && props.touched.yearOfGraduation)
                  ? (<div className="text-danger">{props.errors.yearOfGraduation}</div>)
                  : null
              }
            </div>
          </div>
        </div>
      </>
    );
  }
  return <></>;
}
