import React, {FunctionComponent, HTMLAttributes, useState} from 'react';
import {useField} from 'formik';
import ImageUploader from 'react-images-upload';

import './image.scss';
import {toastr} from "react-redux-toastr";

interface ImageFieldProps {
  name: string;
  buttonText: string;
  value: string;
  disabled?: boolean;
}

const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const ImageField: FunctionComponent<ImageFieldProps & HTMLAttributes<HTMLDivElement>> =
  (props: ImageFieldProps & HTMLAttributes<HTMLDivElement>) => {
    const {name, buttonText, value, disabled} = {...props};
    const [, , {setValue}] = useField(props as any);
    const [image, setImage] = useState<string>(value);
    const onChange = (file: File) => {
      toBase64(file)
        .then((base64: any) => {
          setImage(base64);
          setValue(base64.substr(base64.indexOf('base64,') + 'base64,'.length));
          toastr.success('', 'Photo is correct');
        })
        .catch(() => {
          toastr.error('', 'Photo format or size is not supported');
        });
    }

    return (
      <div className={`image-field clearfix ${disabled ? 'image-field--disabled' : ''}`}>
        <div className="preview">
          <div className="avatar" style={ image ? {backgroundImage: `url(${image})`} : {backgroundColor: '#ebf2f5'}}/>
        </div>
        <ImageUploader
          className="pl-4"
          name={name}
          withPreview={true}
          withIcon={false}
          buttonText={buttonText}
          label=""
          imgExtension={['.jpg', '.jpeg', '.png']}
          fileTypeError={'Photo should be .jpg,.jpeg,.png'}
          maxFileSize={16000000}
          singleImage={true}
          fileContainerStyle={{}}
          onChange={(image:File[]) => onChange(image[0])}
        />
      </div>
    );
  };
