import React, {FC, Fragment} from "react";
import {NavLink} from "react-router-dom";
import {StatusType} from "../QuickSearch";

type PropsType = {
  status: StatusType
  list: Array<any>
  statusToggle: (status: StatusType) => void
}

export const QuickSearchFilteredItems: FC<PropsType> = (props: PropsType) => {
  const {status, list, statusToggle} = {...props};
  const filtered = list.filter((person) => person.status === status.name);

  if (filtered.length) {
    return (
      <div className="quick-search--results_item">
        <p className="quick-search--results_item_title"
           onClick={(e) => statusToggle(status)}>{status.name}
        </p>
        {
          status.visible &&
          <div className="quick-search--results_item_content">
            {filtered.map((person: any, index: number) =>
              <div key={`person-${index}`} className="quick-search--results_item_content_person">
                <NavLink to={`/staff/${person.id}/work-records`}
                         title={person.full_name}>
                  <img src={person.avatar} />
                  {person.full_name}
                </NavLink>
              </div>)}
          </div>
        }
      </div>
    )
  }

  return <Fragment />
}